import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";

export const blueTheme = createTheme({
    palette: {
        primary: {
            main: '#2853ff'
        },
        secondary: {
            main: '#ffffff'
        },
        error: {
            main: red.A400
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    //backgroundColor: 'red',
                   width: 230
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    // background: 'blue',
                    minHeight: '35px',
                    fontSize: 14
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: 14
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: 14
                }
            }
        },
    }
})