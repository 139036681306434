import React from "react";
import { Route, Routes } from "react-router-dom";
import { TransportistaPage } from "../../../pages/app/transportistas/TransportistaPage";
import { TransportistasPage } from "../../../pages/app/transportistas/TransportistasPage";

export const TransportistasRoute = () => {
  return (
    <Routes>
      <Route path="/*" element={<TransportistasPage />} />
      <Route path="/:id" element={<TransportistaPage />} />
    </Routes>
  );
};
