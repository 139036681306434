import { Edit } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import EmisorModal from "./EmisorModal";

const EmisorInfo = ({ emisor, reload = () => {} }) => {

  const [modal, setModal] = useState(false);

  const handleOpen = () => setModal(true);
  const handleClose = () => setModal(false);

  return (
    <Grid>
      <Grid
        height={40}
        flexDirection={"row"}
        display={"flex"}
        alignItems={"center"}
        position={"relative"}
      >
        <Typography fontWeight={"bold"} color={"darkgrey"}>
          EMISOR
        </Typography>
        {/* Acciones */}
        <Grid position={"absolute"} right={0}>
          <Tooltip placement={"left-start"} title={"Editar datos de emisor"}>
            <IconButton onClick={handleOpen} color="info">
              <Edit style={{ height: 20, width: 20 }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container flexDirection={"row"} justifyContent={"space-between"}>
        <Typography fontWeight={"bold"}>Nombre / Razon social</Typography>
        <Typography>{emisor.razon_social_emisor}</Typography>
      </Grid>
      <Grid
        container
        flexDirection={"row"}
        justifyContent={"space-between"}
        mt={1}
      >
        <Typography fontWeight={"bold"}>RUC</Typography>
        <Typography>{emisor.ruc_emisor}</Typography>
      </Grid>
      <Grid
        container
        flexDirection={"row"}
        justifyContent={"space-between"}
        mt={1}
      >
        <Typography fontWeight={"bold"}>
          Obligado a llevar contabilidad
        </Typography>
        <Typography>{emisor.obligado_contabilidad}</Typography>
      </Grid>
      <Grid
        container
        flexDirection={"row"}
        justifyContent={"space-between"}
        mt={1}
      >
        <Typography fontWeight={"bold"}>Tipo Contribuyente</Typography>
        <Typography>
          {emisor.tipo_contribuyente.descripcion_contribuyente}
        </Typography>
      </Grid>
      {/* MODAL EDIT */}
      {emisor.id_emisor && (
        <EmisorModal
          emisor={emisor}
          visible={modal}
          close={handleClose}
          reload={reload}
        />
      )}
    </Grid>
  );
};

export default EmisorInfo;
