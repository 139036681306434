import API from "./ApiConfig"

const URL_TRANSPORTE = '/transportista';


export const transportistasRequest = async (data) => {
    const request = await API.post(URL_TRANSPORTE + '/transportistas_establecimiento', data);
    const response = request.data;
    return response;
}


export const crearTransportistaRequest = async (data) => {
    const request = await API.post(URL_TRANSPORTE + '/crear_transportista', data);
    const response = request.data;
    return response;
}

export const transportistaRequest = async (data) => {
    const request = await API.post(URL_TRANSPORTE + '/transportista_id', data);
    const response = request.data;
    return response;
}

export const transportistaIdentificacionRequest = async (data) => {
    const request = await API.post(URL_TRANSPORTE + '/transportista_identificacion', data);
    const response = request.data;
    return response;
}

export const editarTransportistaRequest = async (data) => {
    const request = await API.post(URL_TRANSPORTE + '/editar_transportista', data);
    const response = request.data;
    return response;
}

export const eliminarTransportistaRequest = async (data) => {
    const request = await API.post(URL_TRANSPORTE + '/eliminar_transportista', data);
    const response = request.data;
    return response;
}

export const transportistasBusquedaRequest = async (data) => {
    const request = await API.post(URL_TRANSPORTE + '/consultar_producto_nombre', data);
    const response = request.data;
    return response;
}
