import { Add, RemoveCircle } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ModalAnexos } from "./ModalAnexos";

export const CrearFacturaProductoItem = ({
  index,
  item,
  onChangeCantidad,
  onChangePrecio,
  quitarProducto,
  addAnexo,
  removeAnexo,
  onChangeIva,
  ivas = [],
}) => {
  const [modalAnexos, setModalAnexos] = useState(false);
  const openModalAnexos = () => setModalAnexos(true);
  const closeModalAnexos = () => setModalAnexos(false);
  return (
    <Box key={index}>
      <Box
        mt={2}
        component={"div"}
        sx={{
          minHeight: "40px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
        }}
      >
        <Box
          sx={{
            width: "20%",
            //backgroundColor: "green",
          }}
        >
          
          <TextField
            sx={{
              width: '100%'
            }}
            // inputProps={{
            //   maxlength: 4
            // }}
            size="small"
            label="Cantidad"
      
          
            value={item.cantidad}
            onChange={(e) => {
              if (e.target.value.includes(",")) {
                let cantidad_number = e.target.value;
                let fix_number = cantidad_number.replace(",", "");

                onChangeCantidad(index, fix_number);
              } else {
                onChangeCantidad(index, e.target.value);
              }
            }}
          />
    
        </Box>
        <Box
          sx={{
            width: "35%",
            //backgroundColor: "green",
            position: "relative",
          }}
        >
          <Typography>{item.nombre_producto}</Typography>
          {item.anexos?.map((a) => {
            return <Typography>{a.anexo}</Typography>;
          })}
          {/* Anexos */}
          <Tooltip placement="top" title="Agregar anexo">
            <IconButton
              size="small"
              color="primary"
              onClick={openModalAnexos}
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
            >
              <Add />
            </IconButton>
          </Tooltip>
          <ModalAnexos
            item={item}
            index={index}
            visible={modalAnexos}
            close={closeModalAnexos}
            addAnexo={addAnexo}
            removeAnexo={removeAnexo}
          />
        </Box>
        <Box
          sx={{
            width: "15%",
            //backgroundColor: "green",
          }}
        >
          <TextField
            sx={{
              //backgroundColor: 'red',
              width: '100%'
            }}
            size="small"
            label="Precio"
            type={"number"}
            fullWidth
            value={item.precio_unitario}
            onChange={(e) => onChangePrecio(index, e.target.value)}
          />
          {/* <Typography> {Number(item.precio_unitario).toFixed(4)}</Typography> */}
        </Box>
        <Box
          sx={{
            width: "15%",
            //backgroundColor: 'green',
          }}
        >
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label" size="small">
              Valor IVA
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size="small"
              value={item.id_iva}
              label="Valor IVA"
              onChange={(e) => onChangeIva(index, e.target.value)}
            >
              {ivas.map((iva) => {
                return (
                  <MenuItem value={iva.id_iva}>{iva.descripcion}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            width: "15%",
            //backgroundColor: 'green',
          }}
        >
          <Typography textAlign={"center"}>
            {Number(item.valor_total).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Box>
        <Box
          sx={{
            //backgroundColor: 'green',
            width: "5%",
          }}
        >
          <IconButton color={"error"} onClick={() => quitarProducto(index)}>
            <RemoveCircle />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
