import React, { createContext, useEffect, useState } from "react";
import {
  authStorageGet,
  authStorageRemove,
  authStorageSave,
} from "../helpers/AuthStorage";
import { refrescarUsuarioRequest } from './../services/api/AuthApi';
import { LoadingLayout } from "../layouts/LoadingLayout";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // "verify";
  // "not-authenticated";
  // "authenticated";

  const [authState, setAuthState] = useState("not-authenticated");
  const [ loading, setLoading ] = useState(false);

  const [user, setUser] = useState({
    establecimientos: [],
    puntos_emision: [],
    usuario: {},
  });

  const [establecimiento, setEstablecimiento] = useState({});

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      verificarUsuario()
        .finally(() => {
          setLoading(false);
        });
    }, 800);
    return () => {};
  }, []);
  
  const verificarUsuario = async () => {
    const data = authStorageGet();
    if (!data) return;  
      const usuarioInformacion = await refrescarUsuarioRequest({ id_usuario: data.usuario?.id_usuario });
      login(usuarioInformacion?.usuario, usuarioInformacion?.establecimientos, usuarioInformacion?.puntos_emision);
  }


  const login = (usuario, establecimientos, puntos_emision) => {
    setAuthState("authenticated");
    setUser({
      usuario,
      establecimientos,
      puntos_emision,
    });
    setEstablecimiento(establecimientos[0] ?? {});
    authStorageSave({
      usuario,
      establecimientos,
      puntos_emision,
    });
  };

  const logout = () => {
    setAuthState("not-authenticated");
    setUser({
      establecimientos: [],
      puntos_emision: [],
      usuario: {},
    });
    setEstablecimiento({});
    authStorageRemove();
  };

  return (
    <AuthContext.Provider
      value={{
        authState,
        user,
        establecimiento,
        setEstablecimiento,
        login,
        logout,
      }}
    >
      {children}
      <LoadingLayout isLoading={loading}  text={'Verificando informacion'}/>
    </AuthContext.Provider>
  );
};
