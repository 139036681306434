import * as React from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import {
  autorizarDocumentoRequest,
  cambiarEstadoCobroRequest,
  generarDocumentoRequest,
  generarDocumentoXMLRequest,
} from "../../../../services/api/DocumentoApi";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import {
  alpha,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  Link,
  MenuItem,
  Select,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import fileDownload from "js-file-download";
import { AccionesContext } from "../../../../context/AccionesContext";
import { useState } from "react";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { TableRowFactura } from "./TableRow";
import { CheckBoxOutlineBlank } from "@mui/icons-material";
import { ESTADO_COBRO, ESTADO_COBRO_MAP } from "../../../../configs/configs";
import { useContext } from "react";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    // backgroundColor: theme.palette.background.paper,
    // border: '1px solid #ced4da',
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // borderRadius: 4,
      // borderColor: '#80bdff',
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const columns = [
  {
    id: "estado_cobro",
    label: "E. Cobro",
    minWidth: 150,
  },
  {
    id: "estado_documento_electronico",
    label: "Estado documento",
    minWidth: 150,
  },
  {
    id: "numero_documento",
    label: "Documento",
    minWidth: 100,
  },
  {
    id: "fecha_emision_documento",
    label: "Fecha emision",
    minWidth: 80,
  },
  {
    id: "razon_social_cliente",
    label: "Cliente",
    minWidth: 80,
  },
  {
    id: "num_identificacion_cliente",
    label: "Identificación cliente",
    minWidth: 60,
  },
  {
    id: "valor_total",
    label: "Total",
    minWidth: 20,
  },
  {
    id: "PDF",
    label: "G.PDF",
    minWidth: 20,
    options: true,
  },
  {
    id: "XML",
    label: "G.XML",
    minWidth: 20,
    options: true,
  },
  {
    id: "ANULAR",
    label: "Anular",
    minWidth: 20,
    options: true,
  },
  {
    id: "opciones",
    label: "Reenviar",
    options: true,
    minWidth: 20,
  },
];

export const TablaFacturas = ({ data = [] }) => {
  const { accionFacturas } = useContext(AccionesContext);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [selectedList, setSelectedList] = useState({
    show: false,
    id: "",
  });

  const [estadoCobroSelect, setEstadoCobroSelect] = useState("");

  const [selected, setSelected] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleShowSelectedList = (value, id) => {
    const event = {
      target: {
        checked: value,
      },
    };
    handleSelectAllClick(event);
    setSelectedList({
      show: value,
      id: value ? id : "",
    });
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.id_documento);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClickRow = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeSelect = (e, id) => {
    const v = e.target.value;
    const confirm = window.confirm(
      `Esta seguro de modificar el estado de ${selected.length} documentos a ${ESTADO_COBRO[v]}`
    );
    if (!confirm) {
      return;
    }

    const dataRequest = {
      array: true,
      ids_documentos: selected,
      estado_cobro: v,
    };

    cambiarEstadoCobroRequest(dataRequest)
      .then((res) => {})
      .catch((error) => {})
      .finally(() => {
        accionFacturas();
        setSelectedList({
          show: false,
          id: "",
        });
        setSelected([]);
      });
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {selected.length > 0 && (
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(selected.length > 0 && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity
                ),
            }),
          }}
        >
          <Grid container xs={3}>
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {selected.length} documentos seleccionados
            </Typography>
          </Grid>

          {selectedList.id == "estado_cobro" && (
            <Grid
              container
              sx={{
                alignItems: "center",
                justifyContent: {
                  xs: "flex-start",
                  md: "flex-end",
                },
              }}
            >
              <Grid mx={1}>
                <Typography fontSize={16} fontWeight={"bold"}>
                  ESTADO COBRO
                </Typography>
              </Grid>
              <Grid
                sx={{
                  width: 150,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Select
                  sx={{
                    height: 35,
                    width: "100%",
                    textAlign: "center",
                  }}
                  input={<BootstrapInput />}
                  size="small"
                  displayEmpty
                  value={estadoCobroSelect}
                  onChange={handleChangeSelect}
                >
                  <MenuItem key={"121"} value={""}>
                    SELECCIONE
                  </MenuItem>
                  {ESTADO_COBRO_MAP.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
          )}
        </Toolbar>
      )}
      <TableContainer sx={{ maxHeight: "65vh" }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              {selectedList.show && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selected.length > 0 && selected.length < data.length
                    }
                    checked={data.length > 0 && selected.length === data.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                </TableCell>
              )}
              {columns.map((column) => {
                if (column.id == "estado_cobro") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Grid
                        container
                        flexDirection={"row"}
                        alignItems={"center"}
                      >
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleShowSelectedList(
                              !selectedList.show,
                              column.id
                            )
                          }
                        >
                          <CheckBoxOutlineBlank
                            sx={{
                              height: "18px",
                            }}
                          />
                        </IconButton>
                        <Typography>{column.label}</Typography>
                      </Grid>
                    </TableCell>
                  );
                }

                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography>{column.label}</Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRowFactura
                    handleClickRow={handleClickRow}
                    key={row.id_documento}
                    index={index}
                    row={row}
                    columns={columns}
                    isSelected={isSelected}
                    selectedList={selectedList}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
