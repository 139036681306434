import { Add } from "@mui/icons-material";

import {
  Alert,
  CircularProgress,
  Divider,
  Fab,
  Grid,
  Snackbar,
  Box,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AccionesContext } from "../../../context/AccionesContext";
import { AuthContext } from "../../../context/AuthContext";
import { PageSearch } from "../../../global/components/PageSearch";
import { AppLayout } from "../../../layouts/AppLayout";

import { ModalPlan } from "./components/ModalPlan";
import { TablaPlanes } from "./components/TablaPlanes";
import {
  busquedaPlanesRequest,
  planesBusquedaRequest,
  planesRequest,
} from "../../../services/api/PlanesApi";

export const PlanesPage = () => {
  const { user } = useContext(AuthContext);
  const { recargarPlanes } = useContext(AccionesContext);
  const [modalCreate, setModalCreate] = useState(false);
  const [planes, setPlanes] = useState([]);
  const [planesSearch, setPlanesSearch] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const [toast, setToast] = useState({
    open: false,
    text: "",
  });

  useEffect(() => {
    setPlanes([]);
    setLoading(true);
    const dataRequest = {
      id_usuario: user.usuario?.id_usuario,
    };
    planesRequest(dataRequest)
      .then((res) => {
        if (res.result && res.planes) {
          const planes = res.planes;
          setPlanes(planes);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [recargarPlanes]);

  useEffect(() => {
    if (search.length > 1) {
      setLoading(true);
      const dataRequest = {
        id_usuario: user.usuario?.id_usuario,
        busqueda: search,
      };
      busquedaPlanesRequest(dataRequest)
        .then((res) => {
          if (res.result && res.planes) {
            setPlanesSearch(res.planes);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [search]);

  const openModal = () => setModalCreate(true);
  const closeModal = () => setModalCreate(false);

  const onChangeSearch = (text) => {
    setSearch(text);
  };

  const closeToast = () => {
    setToast({
      open: false,
      text: "",
    });
  };

  const openToast = (text = "") => {
    setToast({
      open: true,
      text: text,
    });
  };

  return (
    <AppLayout>
      <Grid minWidth={"800px"}>
        {/* Header */}
        <PageSearch
          title="Planes"
          searchText="Buscar plan"
          textButtton="Crear plan"
          actionButton={openModal}
          onChangeSearch={onChangeSearch}
          search={search}
        />
        <Divider />
        {/* Body */}
        <Grid
          sx={{
            minHeight: 100,
          }}
        >
          {search.length > 1 ? (
            <TablaPlanes data={planesSearch} />
          ) : (
            <TablaPlanes data={planes} />
          )}
          {loading && <CircularProgress size={25} />}
          <Snackbar
            open={toast.open}
            autoHideDuration={6000}
            onClose={closeToast}
          >
            <Alert
              onClose={closeToast}
              severity="success"
              sx={{ width: "100%" }}
            >
              {toast.text}
            </Alert>
          </Snackbar>
        </Grid>
        <Box
          sx={{
            position: "absolute",
            bottom: 10,
            right: 10,
            display: {
              xs: "block",
              sm: "none",
            },
          }}
        >
          <Fab onClick={openModal} color="primary" aria-label="add">
            <Add />
          </Fab>
        </Box>
        <ModalPlan
          modal={modalCreate}
          close={closeModal}
          openToast={openToast}
        />
      </Grid>
    </AppLayout>
  );
};
