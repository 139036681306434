import { Close } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  InputBase,
  Modal,
  TextField,
} from "@mui/material";
import { width } from "@mui/system";
import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import {
  buscarFacturasRequest,
  listaFacturasRequest,
} from "../../../../services/api/NotaCreditoApi";
import { FacturaItem } from "./FacturaItem";

export const FacturasModal = ({ visible, close, seleccionarFactura }) => {
  const { establecimiento } = useContext(AuthContext);

  const [facturas, setFacturas] = useState([]);
  const [busquedaFacturas, setBusquedaFacturas] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  //Lista de todas las facturas
  useEffect(() => {
    setLoading(true);
    const dataRequest = {
      id_establecimiento: establecimiento.id_establecimiento,
    };
    listaFacturasRequest(dataRequest)
      .then((res) => {
        if (res.result && res.facturas_autorizadas) {
          setFacturas(res.facturas_autorizadas);
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  //Busqueda de factura
  useEffect(() => {
    if (search.length > 1) {
      setLoading(true);
      const dataRequest = {
        id_establecimiento: establecimiento.id_establecimiento,
        busqueda: search,
      };
      buscarFacturasRequest(dataRequest)
        .then((res) => {
          if (res.result && res.facturas_autorizadas) {
            setBusquedaFacturas(res.facturas_autorizadas);
          }
        })
        .catch((err) => {
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [search]);

  //onChangeSearch
  const onChangeSearch = (text) => {
    setSearch(text);
  };

  return (
    <Modal
      open={visible}
      onClose={() => {
        setSearch("");
        close();
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          width: { xs: "100%", sm: "80%", md: "900px" },
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: 2,
          borderRadius: 3,
        }}
      >
        <Box>
          <IconButton
            onClick={() => {
              setSearch("");
              close();
            }}
          >
            <Close />
          </IconButton>
          <InputBase
            value={search}
            onChange={(e) => onChangeSearch(e.target.value)}
            placeholder="Buscar factura"
            sx={{
              width: "85%",
            }}
          />
        </Box>
        <Box
          sx={{
            maxHeight: 500,
            overflow: "auto",
          }}
        >
          {loading && (
            <Box component={"div"} display={"flex"} justifyContent={"center"}>
              <CircularProgress size={25} />
            </Box>
          )}

          {/* LISTA */}
          {search.length > 1 ? (
            <>
              {/* BUSQUEDA */}
              {busquedaFacturas.map((factura) => {
                return (
                  <FacturaItem
                    factura={factura}
                    seleccionarFactura={(f) => {
                      seleccionarFactura(f);
                      close();
                      setSearch("");
                    }}
                  />
                );
              })}
            </>
          ) : (
            <>
              {facturas.map((factura) => {
                return (
                  <FacturaItem
                    factura={factura}
                    seleccionarFactura={(f) => {
                      seleccionarFactura(f);
                      close();
                      setSearch("");
                    }}
                  />
                );
              })}
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
