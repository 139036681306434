import API from "./ApiConfig"

const URL_CUENTA = '/gestionCuenta';


export const loginRequest = async (data) => {
    const request = await API.post(URL_CUENTA + '/login_usuario', data);
    const response = request.data;
    return response;
}


export const refrescarUsuarioRequest = async (data) => {
    const request = await API.post(URL_CUENTA + '/refrescar_usuario', data);
    const response = request.data;
    return response;
}


