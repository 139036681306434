import { useState } from "react"

export const usePagination = (limitInit = 100) => {

    const [page, setPage] = useState(1);

    const [limit, setLimit] = useState(limitInit);

    const reset = () => {
        setPage(1);
    }

    const loadMore = () => {
        setPage((prev) => prev + 1);
        console.log('loadMore');
    }

    const onChangeTablePagination = (pageTable = 1, pageSizeTable = 10, dataTable = []) => {

        console.log({ pageTable, pageSizeTable, dataTable });

        const dataLength = dataTable.length;
        const numberPage = dataLength / pageSizeTable;
        const numberPageInt = parseInt(numberPage);
        const lastPageTable = numberPage > numberPageInt ? numberPageInt + 1 : numberPage;

        console.log({ lastPageTable, pageTable });

        lastPageTable === pageTable && loadMore();  
    };

    return {
        page,
        limit,
        reset,
        loadMore,
        onChangeTablePagination,
    }

}