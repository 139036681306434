import { SettingsOverscan } from "@mui/icons-material";
import { Box, Card, CardContent, IconButton, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { CreditoDetalleModal } from "./CreditoDetalleModal";

export const NotasCreditoItem = ({ nota, id = 0}) => {
  const [modalCreditoDetalle, setModalCreditoDetalle] = useState(false);
  const openCreditoDetalle = () => setModalCreditoDetalle(true);
  const closeCreditoDetalle = () => setModalCreditoDetalle(false);
  return (
    <Fragment key={nota.id_documento}>
      <Box mt={1} />
      <Card>
        <CardContent
          sx={{
            position: "relative",
            backgroundColor: id == nota.id_documento ? '#dbecf9' : '#fff'
          }}
        >
          <Typography fontWeight={"bold"} color={"#909497"} gutterBottom>
            NOTA DE CRÉDITO { id == nota.id_documento  && 'A EDITAR'}
          </Typography>

          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontWeight={"bold"}>Documento N.º</Typography>
            <Typography>{nota.numero_documento}</Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontWeight={"bold"}>Fecha</Typography>
            <Typography>
              {nota.fecha_emision_documento?.substring(0, 10)}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontWeight={"bold"}>Cliente</Typography>
            <Typography>{nota.razon_social_cliente}</Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontWeight={"bold"}>Identificación cliente</Typography>
            <Typography>{nota.num_identificacion_cliente}</Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            pb={3}
          >
            <Typography fontWeight={"bold"}>Total</Typography>
            <Typography fontWeight={"bold"}>
              ${" "}
              {Number(nota.valor_total)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Box>
          {nota.id_documento && (
            <IconButton
              onClick={openCreditoDetalle}
              size="large"
              sx={{
                position: "absolute",
                bottom: 5,
                right: 5,
              }}
            >
              <SettingsOverscan color="primary" />
            </IconButton>
          )}
        </CardContent>
      </Card>
      {/* MODAL FACTURA DETALLE */}
      <CreditoDetalleModal
        visible={modalCreditoDetalle}
        close={closeCreditoDetalle}
        documento={nota}
      />
    </Fragment>
  );
};
