import React from "react";
import { Route, Routes } from "react-router-dom";
import { CompraPage } from "../../../pages/app/compras/CompraPage";
import ComprasPage from "../../../pages/app/compras/ComprasPage";
import { SubirComprasPage } from "../../../pages/app/compras/SubirComprasPage";

export const  ComprasRoute = () => {
  return (
    <Routes>
      <Route path="/*" element={<ComprasPage />} />
      <Route path="/:id" element={<CompraPage />} />
      <Route path="/subirCompras" element={<SubirComprasPage />} />
    </Routes>
  );
};
