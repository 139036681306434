import { Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { FormatoNumero } from "../../../../helpers/FormatNumber";

export const ReporteCobroRow = ({ documento }) => {
  const date_emision = new Date(documento?.fecha_emision_documento);
  const date_emision_format = dayjs(date_emision).format("DD/MM/YYYY");

  const date_cobro = new Date(documento?.fecha_cobro);
  const date_cobro_format = dayjs(date_cobro).format("DD/MM/YYYY");

  return (
    <Grid sx={{ minHeight: 30, borderBottom: "1px solid #e1e1e1" }} xs={12}>
      {/* INFO */}
      <Grid
        container
        justifyContent={"space-around"}
        sx={{
          minHeight: 30,
          alignItems: "center",
          borderBottom: "1px solid #f8f8f8",
          backgroundColor: "#fff",
        }}
      >
        <Grid xs={2}>
          <Typography fontWeight={"bold"}>
            {documento.numero_documento}
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Typography fontWeight={"bold"}>
            {documento.razon_social_cliente}
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Typography fontWeight={"bold"}>
            {documento.cliente?.direccion_cliente}
          </Typography>
        </Grid>
        <Grid xs={1}>
          <Typography></Typography>
        </Grid>
        <Grid xs={1}>
          <Typography></Typography>
        </Grid>
        <Grid xs={1}>
          <Typography></Typography>
        </Grid>
      </Grid>
      {/* DOCUMENTO */}
      <Grid
        container
        justifyContent={"space-around"}
        xs={12}
        sx={{
          minHeight: 30,
          alignItems: "center",
          borderBottom: "1px solid #f8f8f8",
          backgroundColor: "#fff",
        }}
      >
        <Grid xs={1.5}>
          <Typography>{documento.numero_documento}</Typography>
        </Grid>
        {/* <Grid xs={1}>
          <Typography>FC</Typography>
        </Grid> */}
        <Grid xs={1}>
          <Typography>{date_emision_format}</Typography>
        </Grid>
        {/* <Grid xs={1}>
          <Typography>{date_emision_format}</Typography>
        </Grid> */}
        <Grid xs={3}>
          <Typography>Factura Nº {documento.numero_documento}</Typography>
        </Grid>
        <Grid xs={1}>
          <Typography>{FormatoNumero(documento.valor_total)}</Typography>
        </Grid>
        <Grid xs={1}>
          <Typography></Typography>
        </Grid>
        <Grid xs={1}>
          <Typography></Typography>
        </Grid>
      </Grid>
      {documento.estado_cobro === 1 && (
        <>
          {/* COBRO */}
          <Grid
            container
            justifyContent={"space-around"}
            xs={12}
            sx={{
              minHeight: 30,
              alignItems: "center",
              borderBottom: "1px solid #f8f8f8",
              backgroundColor: "#fff",
            }}
          >
            <Grid xs={1.5}>
              <Typography>{documento.numero_documento}</Typography>
            </Grid>
            {/* <Grid xs={1}>
              <Typography>AB</Typography>
            </Grid> */}
            <Grid xs={1}>
              <Typography>{date_cobro_format}</Typography>
            </Grid>
            {/* <Grid xs={1}>
              <Typography>{date_cobro_format}</Typography>
            </Grid> */}
            <Grid xs={3}>
              <Typography>Pago Factura Nº {documento.numero_documento}</Typography>
            </Grid>
            <Grid xs={1}>
              <Typography></Typography>
            </Grid>
            <Grid xs={1}>
              <Typography>-{FormatoNumero(documento.valor_total)}</Typography>
            </Grid>
            <Grid xs={1}>
              <Typography></Typography>
            </Grid>
          </Grid>
        </>
      )}
      {/* TOTAL */}
      <Grid
        container
        justifyContent={"space-around"}
        xs={12}
        sx={{
          minHeight: 30,
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <Grid xs={1.5}>
          <Typography></Typography>
        </Grid>
        {/* <Grid xs={1}>
          <Typography></Typography>
        </Grid> */}
        <Grid xs={1}>
          <Typography></Typography>
        </Grid>
        {/* <Grid xs={1}>
          <Typography></Typography>
        </Grid> */}
        <Grid xs={3}>
          <Typography></Typography>
        </Grid>
        <Grid xs={1}>
          <Typography fontWeight={'bold'}>{FormatoNumero(documento.valor_total)}</Typography>
        </Grid>
        <Grid xs={1}>
          <Typography fontWeight={'bold'}>-{documento.estado_cobro ? FormatoNumero(documento.valor_total): FormatoNumero(0) }</Typography>
        </Grid>
        <Grid xs={1}>
          <Typography fontWeight={'bold'}>{FormatoNumero(documento.valor_total - (documento.estado_cobro ? documento.valor_total : 0))}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
