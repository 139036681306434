import React from "react";
import { Route, Routes } from "react-router-dom";
import { ReportesComprasPage } from "../../../pages/app/reportes/ReportesComprasPage";
import { ReportesVentasPage } from "../../../pages/app/reportes/ReportesVentasPage";

export const ReportesRoute = () => {
  return (
    <Routes>
      <Route path="/*" element={<ReportesVentasPage />} />
      <Route path="/ventas" element={<ReportesVentasPage />} />
      <Route path="/compras" element={<ReportesComprasPage />} />
    </Routes>
  );
};
