import { ArrowBack } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppLayout } from "../../../layouts/AppLayout";
import { EmisorForm } from "./components/EmisorForm";
import { EstablecimientoForm } from "./components/EstablecimientoForm";
import { FirmaForm } from "./components/FirmaForm";
import { UsuarioForm } from "./components/UsuarioForm";
import { crearUsuarioRequest } from "../../../services/api/UsuariosApi";
import { AuthContext } from "../../../context/AuthContext";
import { Planes } from "../../../global/components/Planes";
import { emailRegexGlobal } from "../../../helpers/email";

export const CrearUsuarioPage = () => {
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);

  const [usuario, setUsuario] = useState({
    email_usuario: "",
    password_usuario: "",
    nombre_usuario: "",
    id_plan: "",
  });

  const [emisor, setEmisor] = useState({
    ruc_emisor: "",
    razon_social_emisor: "",
    obligado_llevar_contabilidad: "",
    tipo_contribuyente: {},
  });

  const [firma, setFirma] = useState({
    firma_url: "",
    firma: {},
    password_firma: "",
    fecha_valida: "",
  });

  const [establecimientos, setEstablecimientos] = useState([]);

  const [establecimiento, setEstablecimiento] = useState({
    direccion_establecimiento: "",
    codigo_establecimiento: "",
    punto_emision: {},
    matriz: true,
    nombre_comercial: "",
    logo: {},
    url_logo: "",
  });

  const [isCreating, setIsCreating] = useState(false);

  const [error, setError] = useState({
    text: "",
    show: false,
  });

  useEffect(() => {
    setFirma({
      ...firma,
      fecha_valida: dayjs(new Date()).add(2, "years").format("YYYY-MM-DD"),
    });
  }, []);

  const agregarEstablecimiento = () => {
    if (!validarDatosEstablecimiento()) {
      window.alert(
        "Por favor, completa los campos obligatorios y asegúrate de que el formato de los campos es correcto."
      );
      return;
    }

    if (
      establecimientos.some(
        (e) =>
          e.codigo_establecimiento == establecimiento.codigo_establecimiento
      )
    ) {
      window.alert("Ya existe un establecimiento con ese codigo");
      return;
    }

    const nuevosEstablecimientos = [...establecimientos];
    nuevosEstablecimientos.push(establecimiento);
    setEstablecimientos(nuevosEstablecimientos);
    setEstablecimiento({
      direccion_establecimiento: "",
      codigo_establecimiento: "",
      punto_emision: {},
      matriz: false,
      nombre_comercial: "",
      logo: {},
      url_logo: "",
    });

    window.scrollTo({
      top: document.body.scrollHeight,
    });
  };

  const validarDatosEstablecimiento = () => {
    if (
      establecimiento.direccion_establecimiento.trim() === "" ||
      establecimiento.codigo_establecimiento.trim() === "" ||
      Object.keys(establecimiento.punto_emision).length === 0
    ) {
      return false;
    }

    const codigoEstablecimientoRegex = /^[0-9]{1,3}$/;
    if (
      !codigoEstablecimientoRegex.test(establecimiento.codigo_establecimiento)
    ) {
      return false;
    }

    const puntoEmisionRegex = /^[0-9]{1,3}$/;
    if (
      !puntoEmisionRegex.test(
        establecimiento.punto_emision?.codigo_punto_emision
      )
    ) {
      return false;
    }

    return true;
  };

  const eliminarEstablecimiento = (index) => {
    const confirm = window.confirm(
      "¿ Esta seguro de eliminar el establecimiento ?"
    );
    if (!confirm) return;
    const filterEstablecimientos = establecimientos.filter(
      (e, i) => i !== index
    );
    setEstablecimientos(filterEstablecimientos);
  };

  const actualizarEstablecimiento = (index) => {
    const establecimientoFind = establecimientos.find(
      (establecimiento, i) => index == i
    );

    const establecimientosFilter = establecimientos.filter(
      (establecimiento, i) => index != i
    );

    setEstablecimiento({
      direccion_establecimiento: establecimientoFind.direccion_establecimiento,
      codigo_establecimiento: establecimientoFind.codigo_establecimiento,
      punto_emision: establecimientoFind.punto_emision,
      matriz: establecimientoFind.matriz,
      nombre_comercial: establecimientoFind.nombre_comercial,
      logo: establecimientoFind.logo,
      url_logo: establecimientoFind.url_logo,
    });

    setEstablecimientos(establecimientosFilter);
  };

  const eliminarLogo = () => {
    setEstablecimiento({
      ...establecimiento,
      logo: {},
      url_logo: "",
    });
  };

  const crearUsuario = () => {
    setError({
      text: "",
      show: false,
    });

    const errores = validarDatos();
    let alert = "";
    if (Object.keys(errores).length > 0) {
      Object.keys(errores).forEach((key) => {
        alert += `${errores[key]}\n`;
      });
      window.alert(alert);
      return;
    }

    //VALIDAR ESTABLECIMIENTO SI ESTA EN FORMULARIO
    const establecimientosFinal = [...establecimientos];
    if (
      establecimiento.direccion_establecimiento.trim().length > 0 ||
      establecimiento.codigo_establecimiento.trim().length > 0 ||
      establecimiento.punto_emision.codigo_punto_emision?.trim().length > 0
    ) {
      if (!validarDatosEstablecimiento()) {
        window.alert(
          "Por favor, completa los campos obligatorios del establecimiento y asegúrate de que el formato de los campos es correcto."
        );
        return;
      }

      establecimientosFinal.push(establecimiento);

      setEstablecimientos(establecimientosFinal);

      setEstablecimiento({
        direccion_establecimiento: "",
        codigo_establecimiento: "",
        punto_emision: {},
        matriz: false,
        nombre_comercial: "",
        logo: {},
        url_logo: "",
      });
    }

    //VALIDAR MATRIZ ESTABLECIMIENTO
    const establecimientoFilter = establecimientosFinal.filter((e) => e.matriz);
    if (establecimientoFilter.length == 0 || establecimientoFilter.length > 1) {
      window.alert(
        "Es obligatorio que el usuario tenga un establecimiento matriz"
      );
      return;
    }

    const dataRequest = new FormData();
    //USUARIO PADRE
    dataRequest.append("id_usuario_padre", user?.usuario?.id_usuario);
    //USUARIO
    dataRequest.append("email_usuario", usuario.email_usuario);
    dataRequest.append("password_usuario", usuario.password_usuario);
    dataRequest.append("nombre_usuario", usuario.nombre_usuario);
    dataRequest.append("id_plan", usuario.id_plan);
    //EMISOR
    dataRequest.append("ruc_emisor", emisor.ruc_emisor);
    dataRequest.append("razon_social_emisor", emisor.razon_social_emisor);
    dataRequest.append(
      "obligado_llevar_contabilidad",
      emisor.obligado_llevar_contabilidad
    );
    dataRequest.append(
      "tipo_contribuyente",
      JSON.stringify(emisor.tipo_contribuyente)
    );

    //FIRMA
    if (firma.firma instanceof File) {
      dataRequest.append("firma", firma.firma);
    }
    dataRequest.append("password_firma", firma.password_firma);
    dataRequest.append("fecha_valida", firma.fecha_valida);

    //ESTABLECIMIENTOS
    dataRequest.append(
      "establecimientos",
      JSON.stringify(
        establecimientosFinal.map((e, index) => {
          return {
            direccion_establecimiento: e.direccion_establecimiento,
            codigo_establecimiento: e.codigo_establecimiento,
            punto_emision: e.punto_emision,
            matriz: e.matriz,
            nombre_comercial: e.nombre_comercial,
            logo: e.logo instanceof File,
          };
        })
      )
    );
    establecimientosFinal.forEach((e, i) => {
      if (e.logo instanceof File) {
        dataRequest.append("establecimientos_logos", e.logo);
      }
    });

    setIsCreating(true);
    crearUsuarioRequest(dataRequest)
      .then((res) => {
        if (res.result) {
          navigate(-1);
          window.alert("Usuario registrado correctamente");
        } else {
          setError({
            show: true,
            text: res.message,
          });
        }
      })
      .finally(() => setIsCreating(false));
  };

  const validarDatos = () => {
    let errores = {};

    // Validar datos de usuario
    if (usuario.id_plan == "") {
      errores.email_usuario = "Seleccione un plan";
    }
    if (usuario.email_usuario.trim() === "") {
      errores.email_usuario = "Email es obligatorio";
    }
    const emailRegex = emailRegexGlobal;
    if (!emailRegex.test(usuario.email_usuario)) {
      errores.email_usuario_formato = "Revise el formato del email";
    }

    if (usuario.password_usuario.trim() === "") {
      errores.password_usuario = "Contraseña es obligatoria";
    }

    if (usuario.password_usuario.length < 8) {
      errores.password_usuario_formato =
        "Contraseña debe tener almenos 8 caracteres";
    }

    if (usuario.nombre_usuario.trim() === "") {
      errores.nombre_usuario = "Nombre es obligatorio";
    }

    // Validar datos de emisor
    if (emisor.ruc_emisor.trim() === "") {
      errores.ruc_emisor = "RUC es obligatorio";
    }

    if (emisor.ruc_emisor.length < 13 || emisor.ruc_emisor.length > 13) {
      errores.ruc_emisor_formato = "El RUC debe tener 13 digitos";
    }

    if (emisor.razon_social_emisor.trim() === "") {
      errores.razon_social_emisor = "Razón social es obligatoria";
    }

    if (emisor.obligado_llevar_contabilidad.trim() === "") {
      errores.obligado_llevar_contabilidad =
        "Obligado a llevar contabilidad es obligatorio";
    }

    if (Object.keys(emisor.tipo_contribuyente).length === 0) {
      errores.tipo_contribuyente = "Tipo de contribuyente es obligatorio";
    }

    // Validar datos de firma
    if (!firma.firma.name) {
      errores.firma = "Firma es obligatoria";
    }

    if (firma.password_firma.trim() === "") {
      errores.password_firma = "Clave de la firma es obligatoria";
    }

    if (firma.fecha_valida.trim() === "") {
      errores.fecha_valida = "Fecha vigente es obligatoria";
    }

    //Validar datos establecimientos
    if (establecimientos.length == 0) {
      if (!validarDatosEstablecimiento()) {
        errores.establecimiento =
          "Complete los datos obligatorios del establecimiento";
      }
    }

    return errores;
  };

  return (
    <AppLayout>
      <Grid
        container
        sx={{
          flexDirection: "column",
          // backgroundColor: 'red',
          p: 1,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "#d4d4ff",
          borderRadius: 3,
        }}
      >
        <Grid display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Tooltip title={"Regresar"}>
            <IconButton
              size="small"
              onClick={() => navigate(-1)}
              color="primary"
            >
              <ArrowBack />
            </IconButton>
          </Tooltip>
          <Typography ml={1} fontWeight={"bold"} color={"GrayText"}>
            CREAR USUARIO
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Grid item xs={12} sm={6} md={6} lg={4}>
            {/* USUARIO FORM */}
            <UsuarioForm data={usuario} setData={setUsuario} />

            {/* PLANES */}
            <Grid mt={1}>
              <Planes
                onPlanSelect={(plan) => {
                  setUsuario({
                    ...usuario,
                    id_plan: plan.id_plan,
                  });
                  return true;
                }}
              />
            </Grid>

            {/* EMISOR FORM */}
            <EmisorForm data={emisor} setData={setEmisor} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            {/* FIRMA FORM */}
            <FirmaForm data={firma} setData={setFirma} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            {/* ESTABLECIMIENTOS FORM */}
            <EstablecimientoForm
              data={establecimientos}
              setData={setEstablecimientos}
              establecimiento={establecimiento}
              setEstablecimiento={setEstablecimiento}
              handleAddEstablecimiento={agregarEstablecimiento}
              handleDeleteEstablecimiento={eliminarEstablecimiento}
              handleUpdateEstablecimiento={actualizarEstablecimiento}
              handleDeleteLogo={eliminarLogo}
            />
          </Grid>
          <Box
            mt={3}
            mb={2}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isCreating && <CircularProgress size={25} />}
            {error.show && <Alert severity="error">{error.text}</Alert>}

            <Button
              onClick={crearUsuario}
              variant="contained"
              disabled={isCreating}
              style={{
                borderRadius: 100,
                px: 4,
                backgroundColor: isCreating ? "GrayText" : "#000590",
                width: "200px",
              }}
            >
              Crear usuario
            </Button>
          </Box>
        </Grid>
      </Grid>
    </AppLayout>
  );
};
