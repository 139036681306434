import {
  Alert,
  CircularProgress,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { AccionesContext } from "../../../../context/AccionesContext";
import { AuthContext } from "../../../../context/AuthContext";
import { useForm } from "../../../../hooks/useForm";
import { ModalLayout } from "../../../../layouts/ModalLayout";
import {
  crearClienteRequest,
  editarClienteRequest,
} from "../../../../services/api/ClientesApi";
import { FormCliente } from "./FormCliente";
import { emailRegexGlobal } from "../../../../helpers/email";

export const ModalEditarCliente = ({
  emailsProps,
  modal,
  close,
  reload,
  openToast,
  cliente,
  tipos_identificaciones,
}) => {
  const { establecimiento } = useContext(AuthContext);
  const { accionClientes } = useContext(AccionesContext);

  const [identificacion, setIdentificacion] = useState("tipo");
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState({
    text: "",
    show: false,
  });

  const [emails, setEmails] = useState([...emailsProps]);

  const [contactos, setContactos] = useState([...cliente.contactos]);

  const [values, onChange, reset, initValues, setValues] = useForm({
    tipo_identificacion: {
      id_tipo_identificacion: cliente.id_tipo_identificacion,
      descripcion_tipo: tipos_identificaciones.find(
        (t) => t.id_tipo_identificacion == cliente.id_tipo_identificacion
      ).descripcion_tipo,
    },
    nombre_cliente: cliente.nombre_cliente,
    apellido_cliente: cliente.apellido_cliente,
    email_cliente: "",
    telefono_cliente: cliente.contactos[0].telefono_cliente,
    direccion_cliente: cliente.contactos[0].direccion_cliente,
    id_contacto: cliente.contactos[0].id_contacto,
    editando_contacto: false,
    nuevo_contacto: false,
    numero_documento_cliente:
      cliente.id_tipo_cliente == 2
        ? cliente.numero_ruc_cliente
        : cliente.numero_documento_cliente,
    razon_social_cliente: cliente.razon_social_cliente,
    empresa: cliente.id_tipo_cliente == 2,
  });


  const crear = () => {

    setError({
      text: "",
      show: false,
    });

    

    //Validaciones
    if (
      (values.empresa
        ? values.razon_social_cliente.length === 0
        : values.nombre_cliente.length === 0 ||
          values.apellido_cliente.length === 0) ||
      !values.tipo_identificacion.id_tipo_identificacion ||
      values.telefono_cliente.length === 0 ||
      values.direccion_cliente.length === 0 ||
      values.numero_documento_cliente.length === 0
    ) {
      setError({
        text: "Complete los campos solicitados",
        show: true,
      });
      return;
    }

    //Validar emails
    const regex_email = emailRegexGlobal;
    if (emails.length === 0) {
      if (values.email_cliente.trim().length === 0) {
        setError({
          text: "Email requerido",
          show: true,
        });
        return;
      }
      if (!regex_email.test(values.email_cliente)) {
        setError({
          text: "Ingrese un correo válido.",
          show: true,
        });
        return;
      }
    } else if (values.email_cliente.length > 0) {
      if (!regex_email.test(values.email_cliente)) {
        setError({
          text: "Ingrese un correo válido.",
          show: true,
        });
        return;
      }
    }

    const em = values.email_cliente != "" ? [values.email_cliente] : [];

    const eArr = [...emails, ...em];

    let c = [];

    c = [
      {
        id_contacto: values.id_contacto,
        telefono_cliente: values.telefono_cliente,
        email_cliente: eArr[0],
        direccion_cliente: values.direccion_cliente,
        emails_contacto: JSON.stringify(eArr),
      },
    ];

    setLoading(true);

    const dataRequest = {
      id_cliente: cliente.id_cliente,
      id_tipo_identificacion: values.tipo_identificacion.id_tipo_identificacion,
      id_establecimiento: cliente.id_establecimiento,
      nombre_cliente: values.nombre_cliente,
      apellido_cliente: values.apellido_cliente,
      numero_documento_cliente: values.numero_documento_cliente,
      email_cliente: values.email_cliente,
      telefono_cliente: values.telefono_cliente,
      direccion_cliente: values.direccion_cliente,
      razon_social_cliente: values.razon_social_cliente,
      id_tipo_cliente: values.empresa ? 2 : 1,
      contactos: c,
    };


    editarClienteRequest(dataRequest)
      .then((res) => {
        if (res.result) {
          close();
          reset();
          reload();
          accionClientes();
          openToast("Cliente actualizado correctamente !");
        } else {
          setError({
            text: res.message,
            show: true,
          });
        }
      })
      .catch((error) => {
        setError({
          text: "Ocurrio un error",
          show: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addContacto = () => {

    if (
      values.telefono_cliente.length === 0 ||
      values.email_cliente.length === 0 ||
      values.direccion_cliente.length === 0
    ) {
      setError({
        text: "Complete los campos de contacto !",
        show: true,
      });
      return;
    }

    setContactos((c) => [
      ...c,
      {
        id_contacto: null,
        telefono_cliente: values.telefono_cliente,
        email_cliente: values.email_cliente,
        direccion_cliente: values.direccion_cliente,
      },
    ]);

    setValues({
      ...values,
      editando_contacto: false,
      telefono_cliente: "",
      email_cliente: "",
      direccion_cliente: "",
    });
  };

  const removeContacto = (index) => {
    const cf = contactos.filter((c, i) => i != index);
    setContactos(cf);
  };

  const editContacto = (index) => {
    const ce = contactos.find((c, i) => i == index);
    setValues({
      ...values,
      email_cliente: ce.email_cliente ?? "",
      telefono_cliente: ce.telefono_cliente ?? "",
      direccion_cliente: ce.direccion_cliente ?? "",
      id_contacto: ce.id_contacto,
      editando_contacto: true,
    });
    const cf = contactos.filter((c, i) => i != index);
    setContactos(cf);
  };

  //Agregar Email
  const addEmail = (t) => {
    const regex = emailRegexGlobal;
    if (!regex.test(t)) {
      setError({
        text: "Ingrese un correo válido.",
        show: true,
      });
      return;
    }
    setEmails((e) => [...e, t]);
    setValues({
      ...values,
      email_cliente: "",
    });
  };

  //Remove Email
  const removeEmail = (index) => {
    const e = emails.filter((e, i) => i != index);
    setEmails(e);
  };

  return (
    <Modal
      open={modal}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout>
        <Grid container alignItems={"center"} flexDirection={"column"}>
          {/* Header */}
          <Grid
            sx={{
              height: 40,
            }}
            alignItems={"center"}
          >
            <Typography
              sx={{
                fontWeight: "600",
              }}
            >
              Nuevo cliente
            </Typography>
          </Grid>
          {/* Form */}
          <Grid>
            <FormCliente
              values={values}
              onChange={onChange}
              cancelar={() => {
                setEmails([...emailsProps]);
                reset();
                close();
              }}
              emails={emails}
              crear={crear}
              edit={true}
              contactos={contactos}
              addContacto={addContacto}
              removeContacto={removeContacto}
              editar={editContacto}
              addEmail={addEmail}
              removeEmail={removeEmail}
            />
          </Grid>
          {error.show && <Alert severity="error">{error.text}</Alert>}
          {loading && <CircularProgress size={25} />}
        </Grid>
      </ModalLayout>
    </Modal>
  );
};
