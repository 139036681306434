import {
  Avatar,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";

export const HeaderSideBarAdmin = () => {
  const { user, establecimiento, setEstablecimiento } = useContext(AuthContext);

  return (
    <Grid container flexDirection={"column"} alignItems={"center"}>
      <Grid
        item
        sx={{
          //backgroundColor: 'red',
          mt: 3,
          width: "90px",
          height: "90px",
        }}
      >
        <Avatar
          src={
            "https://kubalubra.is/wp-content/uploads/2017/11/default-thumbnail.jpg"
          }
          sx={{
            width: "90px",
            height: "90px",
          }}
        />
      </Grid>
      <Grid mt={3} item>
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: 14,
            color: "#fff",
          }}
        >
          {user.usuario?.nombre_usuario}
        </Typography>
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: 14,
            color: "#fff",
          }}
        >
          {user.usuario?.email_usuario}
        </Typography>
      </Grid>
      <Grid
        mt={1}
        mb={1}
        sx={{
          width: "200px",
        }}
      >
        
      </Grid>
    </Grid>
  );
};
