import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const columns = [
  {
    id: "fecha_creacion_usuario",
    label: "Fecha Registro",
    minWidth: 80,
  },
  {
    id: "email_usuario",
    label: "Email",
    minWidth: 80,
  },
  {
    id: "ruc_emisor",
    label: "RUC",
    minWidth: 30,
  },
  {
    id: "razon_social_emisor",
    label: "Razon social",
    minWidth: 80,
  },
  {
    id: "cantidad_documentos_totales",
    label: "T. Doc.",
    minWidth: 30,
    align: "center",
  },
  {
    id: "id_registro_plan",
    label: "PLAN",
    minWidth: 30,
    align: "center",
  },
];

export const TablaUsuarios = ({ data = [] }) => {
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: "70vh" }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    onClick={() => navigate(`/usuarios/${row.id_usuario}`)}
                    key={row.id_usuario}
                    style={{
                      backgroundColor:
                        row.id_registro_plan == null && "rgba(255, 0, 0, 0.1)",
                    }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];

                      if (column.id == "fecha_creacion_usuario") {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value?.substring(0, 10)}
                          </TableCell>
                        );
                      }

                      if (column.id == "id_registro_plan") {
                        const hasAssing = value == null;

                        const cantidad_documentos = row["cantidad_documentos"];

                        return (
                          <TableCell key={column.id} align={"center"}>
                            {hasAssing
                              ? "-"
                              : cantidad_documentos ?? "ILIMITADO"}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
