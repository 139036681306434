import { Add } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  Fab,
  Grid,
  Typography,
} from "@mui/material";
import usePagination from "@mui/material/usePagination/usePagination";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccionesContext } from "../../../context/AccionesContext";
import { AuthContext } from "../../../context/AuthContext";
import { PageSearch } from "../../../global/components/PageSearch";
import { PageSearchCompras } from "../../../global/components/PageSearchCompras";
import { PageSearchDocumento } from "../../../global/components/PageSearchDocumento";
import { AppLayout } from "../../../layouts/AppLayout";
import {
  busquedaComprasRequest,
  comprasRequest,
} from "../../../services/api/ComprasApi";
import { guiasRequest } from "../../../services/api/GuiaRemisionApi";
import {
  buscarNotasCreditoRequest,
  busquedaDocumentoCreditoRequest,
  listaNotasCreditoRequest,
} from "../../../services/api/NotaCreditoApi";
import { TablaCompra } from "./components/TablaCompra";

const ComprasPage = () => {
  const navigate = useNavigate();

  const { establecimiento } = useContext(AuthContext);
  const { recargarCompras } = useContext(AccionesContext);

  const [compras, setCompras] = useState([]);
  const [comprasSearch, setComprasSearch] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchState, setSearchState] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [emptySearch, setEmptySearch] = useState({ show: false, text: "" });

  const [toast, setToast] = useState({
    open: false,
    text: "",
  });

  const [dates, setDates] = useState({
    show: false,
    date_init: "",
    date_end: "",
  });

  const { limit, offset } = usePagination(5);

  useEffect(() => {
    setCompras([]);
    setLoading(true);
    const dataRequest = {
      id_establecimiento: establecimiento.id_establecimiento,
    };
    comprasRequest(dataRequest)
      .then((res) => {
        if (res.documentos) {
          setCompras(res.documentos);
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        setLoading(false);
      });
  }, [establecimiento, recargarCompras]);

  useEffect(() => {
    const date = new Date();
    const dateFormatEnd = dayjs(date).format("YYYY-MM-DD");
    const dateFormatInit = dayjs(date).subtract(1, "M").format("YYYY-MM-DD");
    setDates({
      ...date,
      date_init: dateFormatInit,
      date_end: dateFormatEnd,
    });
  }, []);

  const onChangeSearch = (text) => {
    setSearch(text);
  };

  const closeToast = () => {
    setToast({
      open: false,
      text: "",
    });
  };

  const openToast = (text = "") => {
    setToast({
      open: true,
      text: text,
    });
  };

  const onFilter = (id, value, date) => {
    if (id == "" && !date) {
      setSearchState(false);
      setLoadingSearch(false);
      return;
    }
    setEmptySearch({ show: false, text: "" });
    setSearchState(true);
    setLoadingSearch(true);
    setComprasSearch([]);
    const dataRequest = {
      id_establecimiento: establecimiento.id_establecimiento,
      filter: id,
      filter_value: value,
      date_show: dates.show,
      date_init: dates.date_init,
      date_end: dates.date_end,
    };

    busquedaComprasRequest(dataRequest)
      .then((res) => {
        if (res.result && res.documentos.length > 0) {
          setComprasSearch(res.documentos);
        } else if (res.result && res.documentos.length === 0) {
          setEmptySearch({
            show: true,
            text: id != "estado_documento_electronico" ? value : "",
          });
        }
      })
      .catch()
      .finally(() => {
        setLoadingSearch(false);
      });
  };

  return (
    <AppLayout>
      <Grid>
        {/* Header */}

        <PageSearchCompras
          title="Compras"
          searchText="Buscar compra"
          textButtton="Subir Compras"
          actionButton={() => {
            navigate("/compras/subirCompras");
          }}
          onChangeSearch={onChangeSearch}
          search={search}
          onFilter={onFilter}
          dates={dates}
          setDates={setDates}
        />

        <Divider />
        {/* Body */}
        <Grid
          sx={{
            minHeight: 100,
          }}
        >
          {searchState ? (
            <>
              <TablaCompra data={comprasSearch} />
              {emptySearch.show && (
                <Grid
                  container
                  sx={{
                    paddingX: 1,
                    justifyContent: {
                      sx: "flex-start",
                      md: "center",
                    },
                  }}
                  justifyContent={"center"}
                >
                  <Typography mr={2}>
                    No se encontro documentos "{emptySearch.text}".
                  </Typography>
                </Grid>
              )}
            </>
          ) : (
            <TablaCompra data={compras} />
          )}
          {loadingSearch && (
            <Grid
              container
              sx={{
                paddingX: 1,
                justifyContent: {
                  sx: "flex-start",
                  md: "center",
                },
              }}
              justifyContent={"center"}
            >
              <Typography mr={2}>Buscando documento {""}...</Typography>
              <CircularProgress size={25} />
            </Grid>
          )}
        </Grid>
        {loading && <CircularProgress size={25} />}
      </Grid>
      <Box
        sx={{
          position: "absolute",
          bottom: 10,
          right: 10,
          display: {
            xs: "block",
            sm: "none",
          },
        }}
      >
        <Fab
          onClick={() => {
            navigate("/compras/subirCompras");
          }}
          color="primary"
          aria-label="add"
        >
          <Add />
        </Fab>
      </Box>
    </AppLayout>
  );
};

export default ComprasPage;
