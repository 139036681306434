import { Search, SettingsOverscan } from "@mui/icons-material";
import {
  Button,
  Box,
  Grid,
  Card,
  Typography,
  CardContent,
  IconButton,
} from "@mui/material";
import { padding } from "@mui/system";
import React, { Fragment, useState } from "react";
import { FacturaDetalleModal } from "./FacturaDetalleModal";
import { FacturasModal } from "./FacturasModal";
import { NotasCreditoItem } from "./NotasCreditoItem";

export const CrearCreditoFactura = ({
  seleccionarFactura,
  factura,
  editar = false,
  id = 0,
}) => {
  const [modalFactura, setModalFactura] = useState(false);
  const [modalFacturaDetalle, setModalFacturaDetalle] = useState(false);

  const openFactura = () => setModalFactura(true);
  const closeFactura = () => setModalFactura(false);

  const openFacturaDetalle = () => setModalFacturaDetalle(true);
  const closeFacturaDetalle = () => setModalFacturaDetalle(false);

  return (
    <Grid>
      {/* FACTURA SELECCIONADA */}
      <Grid mt={1}>
        <Card>
          <CardContent
            sx={{
              position: "relative",
            }}
          >
            <Typography fontWeight={"bold"} color={"#909497"} gutterBottom>
              FACTURA
            </Typography>
            {factura.id_documento ? (
              <>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography fontWeight={"bold"}>Documento N.º</Typography>
                  <Typography>{factura.numero_documento}</Typography>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography fontWeight={"bold"}>Fecha</Typography>
                  <Typography>
                    {factura.fecha_emision_documento?.substring(0, 10)}
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography fontWeight={"bold"}>Cliente</Typography>
                  <Typography>{factura.razon_social_cliente}</Typography>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography fontWeight={"bold"}>
                    Identificación cliente
                  </Typography>
                  <Typography>{factura.num_identificacion_cliente}</Typography>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  pb={3}
                >
                  <Typography fontWeight={"bold"}>Total</Typography>
                  <Typography fontWeight={"bold"}>
                    ${" "}
                    {Number(factura.valor_total)?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <Typography>
                  Para crear la nota de credito seleccione una factura.
                </Typography>
              </>
            )}

            {/* BOTON BUSQUEDA FACTURA */}
            {!editar && (
              <Button
                variant={'contained'}
                onClick={openFactura}
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                }}
                endIcon={<Search />}
              >
                Buscar
              </Button>
            )}
            {factura.id_documento && (
              <IconButton
                onClick={openFacturaDetalle}
                size="large"
                sx={{
                  position: "absolute",
                  bottom: 5,
                  right: 5,
                }}
              >
                <SettingsOverscan color="primary" />
              </IconButton>
            )}
          </CardContent>
        </Card>

        {/* NOTAS DE CREDITO */}
        {factura?.notas_credito?.map((nota) => {
          return <NotasCreditoItem key={nota.id_documento} nota={nota} id={id}/>;
        })}
      </Grid>

      {/* MODAL BUSQUEDA FACTURA */}
      <FacturasModal
        visible={modalFactura}
        close={closeFactura}
        seleccionarFactura={seleccionarFactura}
      />
      {/* MODAL FACTURA DETALLE */}
      <FacturaDetalleModal
        visible={modalFacturaDetalle}
        close={closeFacturaDetalle}
        documento={factura}
      />
    </Grid>
  );
};
