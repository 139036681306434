import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";

export const FormProducto = ({ values, onChange, cancelar, crear, ivas = []}) => {
  return (
    <Grid>
      <form>
        <Grid item mt={1}>
          <TextField
            label="Nombre producto"
            size="small"
            fullWidth
            value={values.nombre_producto}
            onChange={(e) =>
              onChange("nombre_producto", e.target.value.toUpperCase())
            }
          />
        </Grid>
        <Grid item mt={1}>
          <TextField
            label="Descripcion"
            size="small"
            fullWidth
            multiline
            maxRows={4}
            value={values.descripcion_producto}
            onChange={(e) => onChange("descripcion_producto", e.target.value)}
          />
        </Grid>
        <Grid item mt={1}>
          <TextField
            label="Observación adicional"
            size="small"
            fullWidth
            multiline
            maxRows={4}
            value={values.observacion_adicional}
            onChange={(e) => onChange("observacion_adicional", e.target.value)}
          />
        </Grid>
        <Grid item mt={1}>
          <TextField
            label="Precio Unitario"
            size="small"
            fullWidth
            type={"number"}
            value={values.precio_unitario}
            onChange={(e) => onChange("precio_unitario", e.target.value)}
          />
        </Grid>
        <Grid container mt={1} justifyContent={"center"}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label" size="small">Valor IVA</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size="small"
              value={values.id_iva}
              label="Valor IVA"
              onChange={(value) => onChange('id_iva', value.target.value)}
            >
              {ivas.map((iva) => {
                return <MenuItem value={iva.id_iva}>{iva.descripcion}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item mt={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.codigo_referencia_activo}
                onChange={(e) => {
                  onChange("codigo_referencia_activo", e.target.checked);
                }}
              />
            }
            label="Añadir Código Referencia"
          />
        </Grid>
        {values.codigo_referencia_activo && (
          <Grid item mt={1}>
            <TextField
              label="Codigo referencia"
              size="small"
              fullWidth
              multiline
              maxRows={4}
              value={values.codigo_referencia}
              onChange={(e) => onChange("codigo_referencia", e.target.value)}
            />
          </Grid>
        )}
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Button
            variant="contained"
            color="error"
            onClick={cancelar}
            sx={{ marginX: 0.5 }}
          >
            Cancelar
          </Button>
          <Button variant="contained" onClick={crear} sx={{ marginX: 0.5 }}>
            Guardar
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};
