import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const columns = [
  {
    id: "ruc_emisor",
    label: "RUC",
    minWidth: 50,
  },
  {
    id: "razon_social_emisor",
    label: "Razon social",
    minWidth: 50,
  },
  {
    id: "registro_nombre_plan",
    label: "Plan",
    minWidth: 50,
  },
  {
    id: "cantidad_documentos",
    label: "N. documentos",
    minWidth: 30,
    align: "center",
  },
  {
    id: "precio",
    label: "Precio",
    minWidth: 30,
    align: "center",
  },
  {
    id: "fecha_inicio_plan",
    label: "Fecha de inicio",
    minWidth: 50,
    align: "center",
  },
  {
    id: "fecha_fin_plan",
    label: "Fecha de vencimiento",
    minWidth: 50,
    align: "center",
  },
  {
    id: "fecha_registro_plan",
    label: "Fecha de registro",
    minWidth: 50,
    align: "center",
  },
];

export const TablaReporteRegistros = ({ data = [] }) => {
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: "60vh" }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    key={row.id_registro_plan}
                    style={{
                      backgroundColor:
                        row.necesita_renovar && "rgba(255, 0, 0, 0.05)",
                    }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];

                      if (column.id == "necesita_renovar") {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value ? "SI" : "NO"}
                          </TableCell>
                        );
                      }

                      if (column.id == "cantidad_documentos") {
                        if (value == null) {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              ILIMITADO
                            </TableCell>
                          );
                        }

                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      }

                      if (column.id == "fecha_inicio_plan") {
                        const date = dayjs(new Date(value)).format(
                          "DD/MM/YYYY"
                        );

                        return (
                          <TableCell key={column.id} align={column.align}>
                            {date}
                          </TableCell>
                        );
                      }

                      if (column.id == "fecha_fin_plan") {
                        if (value == null) {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              NO APLICA
                            </TableCell>
                          );
                        }

                        const date = dayjs(new Date(value)).format(
                          "DD/MM/YYYY"
                        );

                        return (
                          <TableCell key={column.id} align={column.align}>
                            {date}
                          </TableCell>
                        );
                      }

                      if (column.id == "fecha_registro_plan") {
                        const date = dayjs(new Date(value)).format(
                          "DD/MM/YYYY"
                        );

                        return (
                          <TableCell key={column.id} align={column.align}>
                            {date}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
