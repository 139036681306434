import { ArrowBack, UploadFile } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { AppLayout } from "../../../layouts/AppLayout";
import { subirCompraRequest } from "../../../services/api/ComprasApi";
import { DocumentosInput } from "./components/DocumentosInput";
import { SeleccionDocumentos } from "./components/SeleccionDocumentos";

export const SubirComprasPage = () => {
  const { establecimiento } = useContext(AuthContext);

  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
  };

  const subirDocumentos = async () => {
    setLoading(true);

    const subida_documentos = fileList.map(async (file, i) =>
      subirDocumento(file)
    );

    await Promise.all(subida_documentos)
      .then((res) => {
        res.forEach((f, i) => {
          let updatedList = [...fileList];
          updatedList[i].upload = true;
          updatedList[i].result = f.respuesta.result;
          updatedList[i].message = f.respuesta.message;
          setFileList(updatedList);
        });
      })
      .catch((error) => {
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const subirDocumento = async (file) => {
    const formData = new FormData();
    const random = parseInt(Math.random() * 100000);
    formData.append("file", file);
    formData.append("id_establecimiento", establecimiento.id_establecimiento);
    return {
      respuesta: await subirCompraRequest(formData),
      file: file,
    };
  };

  return (
    <AppLayout>
      <Grid container xs={12} position="relative">
        <Grid container flexDirection={"row"} alignItems={"center"}>
          <Tooltip title={"Regresar"} placement={"left"}>
            <IconButton
              color="primary"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack />
            </IconButton>
          </Tooltip>
          <Typography fontSize={16} fontWeight={"500"}>
            {" "}
            Cargar Facturas
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
            },
          }}
        >
          <Grid
            mt={1}
            item
            flexDirection={"row"}
            flex={1}
            xs={12}
            sm={12}
            md={7}
          >
            <SeleccionDocumentos
              fileList={fileList}
              setFileList={setFileList}
            />
          </Grid>
          <Grid item flexDirection={"row"} xs={12} sm={12} md={5} flex={1}>
            <DocumentosInput fileList={fileList} fileRemove={fileRemove} loading={loading}/>
          </Grid>
        </Grid>
        <Grid mt={1}>
          <Button
            onClick={() => subirDocumentos()}
            variant="contained"
            size="small"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : <></>}
            endIcon={<UploadFile />}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </AppLayout>
  );
};
