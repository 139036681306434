import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ModalLayout } from "../../../../layouts/ModalLayout";
import { ArrowBack } from "@mui/icons-material";
import { usuarioUpdateRequest } from "../../../../services/api/UsuariosApi";
import { emailRegexGlobal } from "../../../../helpers/email";

const UsuarioModal = ({ usuario, visible, close, reload = () => {} }) => {

  const [data, setData] = useState({
    id_usuario: usuario.id_usuario,
    email_usuario: usuario.email_usuario,
    nombre_usuario: usuario.nombre_usuario,
    password_usuario: "",
    change_password: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState({
    text: "",
    show: false,
  });

  const handleSave = () => {
    setError({
      text: "",
      show: false,
    });

    const confirm = window.confirm(
      "¿Esta seguro de editar los datos del usuario ?"
    );

    if (!confirm) return;
    //VALIDAR DATOS
    const validaciones = validarDatos();
    const errores = Object.keys(validaciones);
    if (errores.length > 0) {
      let alert = "";
      errores.forEach((key) => {
        alert += `${validaciones[key]}\n`;
      });
      window.alert(alert);
      return;
    }

    const dataRequest = {
      id_usuario: data.id_usuario,
      email_usuario: data.email_usuario,
      nombre_usuario: data.nombre_usuario,
      password_usuario: data.password_usuario,
      change_password: data.change_password,
    };

    setIsLoading(true);
    usuarioUpdateRequest(dataRequest)
      .then((res) => {
        if (res.result) {
            reload();
            close();
        } else {
          setError({
            text: res.message,
            show: true,
          });
        }
      })
      .catch()
      .finally(() => setIsLoading(false));
  };

  const validarDatos = () => {
    let errores = {};

    // Validar datos de usuario
    if (data.email_usuario.trim() === "") {
      errores.email_usuario = "Email es obligatorio";
    }
    const emailRegex = emailRegexGlobal;
    if (!emailRegex.test(data.email_usuario)) {
      errores.email_usuario_formato = "Revise el formato del email";
    }

    if (data.change_password && data.password_usuario.trim() === "") {
      errores.password_usuario = "Contraseña es obligatoria";
    }

    if (data.change_password && data.password_usuario.trim().length < 8) {
      errores.password_usuario_formato =
        "Contraseña debe tener almenos 8 caracteres";
    }

    if (data.nombre_usuario.trim() === "") {
      errores.nombre_usuario = "Nombre es obligatorio";
    }

    return errores;
  };

  return (
    <Modal
      open={visible}
      onClose={() => {
        close();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout>
        <Grid container alignItems={"center"} flexDirection={"column"}>
          {/* Header */}
          <Grid
            sx={{
              height: 40,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
              }}
            >
              Editar usuario
            </Typography>
            <IconButton
              onClick={close}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <ArrowBack />
            </IconButton>
          </Grid>
          {/* Body */}
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid>
              <TextField
                value={data.email_usuario}
                onChange={(e) =>
                  setData({
                    ...data,
                    email_usuario: e.target.value,
                  })
                }
                label={"Correo Electronico"}
                size={"small"}
                fullWidth
              />
            </Grid>
            <Grid mt={1}>
              <FormControlLabel
                sx={{
                  width: 230,
                }}
                control={
                  <Checkbox
                    checked={data.change_password}
                    onChange={(e) => {
                      setData({
                        ...data,
                        change_password: e.target.checked,
                        password_usuario: "",
                      });
                    }}
                  />
                }
                label="Cambiar contraseña"
                required
              />
            </Grid>
            {data.change_password && (
              <Grid mt={1}>
                <TextField
                  value={data.password_usuario}
                  onChange={(e) =>
                    setData({
                      ...data,
                      password_usuario: e.target.value,
                    })
                  }
                  label={"Nueva contraseña"}
                  size={"small"}
                  fullWidth
                />
              </Grid>
            )}
            <Grid mt={1}>
              <TextField
                value={data.nombre_usuario}
                onChange={(e) =>
                  setData({
                    ...data,
                    nombre_usuario: e.target.value.toUpperCase(),
                  })
                }
                label={"Nombre Usuario"}
                size={"small"}
                fullWidth
              />
            </Grid>
            <Box
              mt={3}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {isLoading && <CircularProgress size={25} />}
              {error.show && <Alert severity="error">{error.text}</Alert>}
              <Button
                size="small"
                onClick={handleSave}
                variant="contained"
                disabled={isLoading}
                style={{
                  borderRadius: 100,
                  px: 4,
                  backgroundColor: isLoading ? "GrayText" : "#000590",
                  width: "200px",
                }}
              >
                Guardar cambios
              </Button>
            </Box>
          </Grid>
        </Grid>
      </ModalLayout>
    </Modal>
  );
};

export default UsuarioModal;
