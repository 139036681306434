import React from "react";
import { Route, Routes } from "react-router-dom";
import { PlanesPage } from "../../../pages/app/planes/PlanesPage";
import { PlanPage } from "../../../pages/app/planes/PlanPage";

export const PlanesRoute = () => {
  return (
    <Routes>
      <Route path="/*" element={<PlanesPage />} />
      <Route path="/:id" element={<PlanPage />} />
    </Routes>
  );
};
