import {
  Alert,
  CircularProgress,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { AccionesContext } from "../../../../context/AccionesContext";
import { AuthContext } from "../../../../context/AuthContext";
import { useForm } from "../../../../hooks/useForm";
import { ModalLayout } from "../../../../layouts/ModalLayout";
import { FormPlan } from "./FormPlan";
import { actualizarPlanRequest } from "../../../../services/api/PlanesApi";

export const ModalEditarPlan = ({ modal, close, reload, openToast, plan }) => {
  const { user } = useContext(AuthContext);
  const { accionPlanes } = useContext(AccionesContext);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState({
    text: "",
    show: false,
  });

  const [values, onChange, reset, initValues, setValues] = useForm({
    id_plan: plan.id_plan,
    nombre_plan: plan.nombre_plan,
    id_tipo_plan: plan.id_tipo_plan,
    cantidad_documentos: plan.cantidad_documentos ?? "",
    meses_habilitados: plan.meses_habilitados,
    precio: plan.precio,
  });

  const editar = () => {
    setError({
      text: "",
      show: false,
    });

    //Validaciones
    if (
      values.nombre_plan.trim().length == 0 ||
      values.id_tipo_plan == "" ||
      (values.id_tipo_plan == 1 && values.cantidad_documentos.length == 0) ||
      values.meses_habilitados <= 0 ||
      values.precio.trim().length == 0
    ) {
      setError({
        text: "Complete los campos solicitados",
        show: true,
      });
      return;
    }

    const dataRequest = {
      id_plan: values.id_plan,
      id_usuario_admin: user.usuario?.id_usuario,
      nombre_plan: values.nombre_plan,
      id_tipo_plan: values.id_tipo_plan,
      cantidad_documentos: values.cantidad_documentos,
      precio: values.precio,
      meses_habilitados: values.meses_habilitados,
    };

    setLoading(true);

    actualizarPlanRequest(dataRequest)
      .then((res) => {
        if (res.result) {
          close();
          reset();
          reload();
          accionPlanes();
          openToast("Plan actualizado correctamente !");
        } else {
          setError({
            text: res.message,
            show: true,
          });
        }
      })
      .catch((error) => {
        setError({
          text: "Ocurrio un error",
          show: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      open={modal}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout>
        <Grid container alignItems={"center"} flexDirection={"column"}>
          {/* Header */}
          <Grid
            sx={{
              height: 40,
            }}
            alignItems={"center"}
          >
            <Typography
              sx={{
                fontWeight: "600",
              }}
            >
              Editar Plan
            </Typography>
          </Grid>
          {/* Form */}
          <Grid>
            <FormPlan
              values={values}
              setValues={setValues}
              onChange={onChange}
              crear={editar}
              loading={loading}
              edit={true}
              cancelar={() => {
                reset();
                close();
              }}
            />
          </Grid>
          {error.show && <Alert severity="error">{error.text}</Alert>}
          {loading && <CircularProgress size={25} />}
        </Grid>
      </ModalLayout>
    </Modal>
  );
};
