import {
  CheckBox,
  CheckBoxOutlineBlank,
  Delete,
  Edit,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import EstablecimientoModal from "./EstablecimientoModal";
import { establecimientoDeleteRequest } from "../../../../services/api/UsuariosApi";
import { AuthContext } from "../../../../context/AuthContext";

const EstablecimientoCard = ({
  establecimiento,
  handleUpdateEstablecimiento = (i) => {},
  handleDeleteEstablecimiento = (i) => {},
  index,
  reload = () => {},
}) => {
  const { user } = useContext(AuthContext);

  const [modal, setModal] = useState(false);

  const handleOpen = () => setModal(true);
  const handleClose = () => setModal(false);

  const update = (index) => {
    handleUpdateEstablecimiento(index);
    if (establecimiento.id_establecimiento) {
      handleOpen();
    }
  };

  const deleteEs = (index) => {
    handleDeleteEstablecimiento(index);
    if (establecimiento.id_establecimiento) {
      eliminarEstablecimiento();
    }
  };

  const eliminarEstablecimiento = () => {
    const confirm = window.confirm(
      "¿Esta seguro de eliminar este establecimiento?, Si el emisor genero documentos ya no los podra visualizar"
    );
    if (!confirm) return;

    const dataRequest = {
      id_establecimiento: establecimiento.id_establecimiento,
      id_usuario: user.usuario?.id_usuario,
    };

    establecimientoDeleteRequest(dataRequest)
      .then((res) => {
        if (res.result) {
          reload();
        } else {
          window.alert(res.message);
        }
      })
      .catch();
  };

  return (
    <Card
      style={{
        marginTop: 10,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        minWidth: "100%",
        maxHeight: "200px",
        minHeight: "100px",
      }}
    >
      {establecimiento.url_logo.length > 0 && (
        <CardMedia
          component="img"
          height={"70px"}
          width={"70px"}
          image={establecimiento.url_logo}
          alt={establecimiento.nombre_comercial}
          style={{ flex: 1 }}
        />
      )}
      <CardContent style={{ flex: "2", position: "relative" }}>
        {establecimiento.nombre_comercial.length > 0 && (
          <Typography variant="body2" color="textSecondary" component="p">
            <strong> {establecimiento.nombre_comercial} </strong>
          </Typography>
        )}
        <Typography variant="body2" color="textSecondary" component="p">
          <strong>Dirección:</strong>{" "}
          {establecimiento.direccion_establecimiento}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          <strong>Código de establecimiento:</strong>{" "}
          {establecimiento.codigo_establecimiento}
        </Typography>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color="textSecondary" component="p">
            <strong>Matriz:</strong>{" "}
          </Typography>
          {establecimiento.matriz ? (
            <CheckBox color="primary" style={{ height: 16 }} />
          ) : (
            <CheckBoxOutlineBlank color="primary" style={{ height: 16 }} />
          )}
        </Box>
        <Typography variant="body2" color="textSecondary" component="p">
          <strong>Punto de emisión:</strong>{" "}
          {establecimiento.punto_emision?.codigo_punto_emision}
        </Typography>
        {/* BOTONES */}
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <IconButton size="small" color="info" onClick={() => update(index)}>
            <Edit style={{ height: 19, width: 19 }} />
          </IconButton>

          <IconButton
            size="small"
            color="error"
            onClick={() => deleteEs(index)}
          >
            <Delete style={{ height: 19, width: 19 }} />
          </IconButton>
        </Box>
      </CardContent>

      {/* MODAL EDIT */}
      {establecimiento.id_establecimiento && (
        <EstablecimientoModal
          establecimiento={establecimiento}
          visible={modal}
          close={handleClose}
          reload={reload}
        />
      )}
    </Card>
  );
};

export default EstablecimientoCard;
