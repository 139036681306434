import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { InicioPage } from "../../pages/app/inicio/InicioPage";
import { UsuariosRoute } from "./admin/UsuariosRoute";
import { FacturasRoute } from "./usuario/FacturasRoute";
import { PlanesRoute } from "./admin/PlanesRoutes";
import { RegistrosPlanesRoute } from "./admin/RegistrosPlanesRoutes";


export const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="inicio" element={<InicioPage />} />
      <Route path="/usuarios/*" element={<UsuariosRoute />} />
      <Route path="/planes/*" element={<PlanesRoute/>} />
      <Route path="/registro_planes/*" element={<RegistrosPlanesRoute/>} />
      <Route path="/*" element={<Navigate to="/inicio" />} />
    </Routes>
  );
};
