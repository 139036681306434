import { ArrowBack, Close } from "@mui/icons-material";
import { Box, Divider, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import { DocumentoDetalle } from "./DocumentoDetalle";
import { DocumentoEmision } from "./DocumentoEmision";
import { DocumentoNotaCredito } from "./DocumentoNotaCredito";
import { DocumentoValores } from "./DocumentoValores";

export const CreditoDetalleModal = ({ visible, close, documento}) => {
  return (
    <Modal
      open={visible}
      onClose={() => {
        close();
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          width: { xs: "100%", sm: "80%", md: "900px" },
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: 2,
          borderRadius: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <IconButton
            onClick={() => {
              close();
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography fontWeight={"bold"} fontSize={16} ml={2}>
            Información de Nota de Crédito
          </Typography>
        </Box>
        <Box
          sx={{
            minHeight: 499,
            maxHeight: 500,
            overflow: 'auto'
          }}
        >
          {/* DOCUMENTO EMISION */}
          <DocumentoEmision 
            documento={documento}
          />
          {/* DOCUMENTO NOTA DE CREDITO */}
          <DocumentoNotaCredito 
            documento={documento}
          />
          {/* DOCUMENTO DETALLE */}
          <DocumentoDetalle 
            documento={documento}
          />
          {/* DOCUMENTO VALORES */}
          <DocumentoValores 
            documento={documento}
          />
        </Box>
      </Box>
    </Modal>
  );
};
