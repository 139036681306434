export const FormatoNumero = (num) => {
  return Number(num).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
};

export const BToMb = (bytes) => {
  const cal = bytes / (1024 * 1024);
  return cal.toFixed(2)
}