import {
  Alert,
  CircularProgress,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AccionesContext } from "../../../../context/AccionesContext";
import { AuthContext } from "../../../../context/AuthContext";
import { useForm } from "../../../../hooks/useForm";
import { ModalLayout } from "../../../../layouts/ModalLayout";
import { crearClienteRequest } from "../../../../services/api/ClientesApi";
import { FormCliente } from "./FormCliente";
import { emailRegexGlobal } from "../../../../helpers/email";

export const ModalCliente = ({ modal, close, openToast }) => {
  const { establecimiento } = useContext(AuthContext);
  const { accionClientes } = useContext(AccionesContext);

  const [identificacion, setIdentificacion] = useState("tipo");
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState({
    text: "",
    show: false,
  });

  const [values, onChange, reset, initValues, setValues] = useForm({
    empresa: false,
    tipo_identificacion: {},
    nombre_cliente: "",
    apellido_cliente: "",
    numero_documento_cliente: "",
    telefono_cliente: "",
    email_cliente: "",
    direccion_cliente: "",
    razon_social_cliente: "",
  });

  const [contactos, setContactos] = useState([]);

  const [emails, setEmails] = useState([]);

  const crear = () => {
    setError({
      text: "",
      show: false,
    });


    //Validaciones
    if (
      (values.empresa
        ? values.razon_social_cliente.length === 0
        : values.nombre_cliente.length === 0 ||
        values.apellido_cliente.length === 0) ||
      !values.tipo_identificacion.id_tipo_identificacion ||
      values.telefono_cliente.length === 0 ||
      values.direccion_cliente.length === 0 ||
      values.numero_documento_cliente.length === 0
    ) {
      setError({
        text: "Complete los campos solicitados",
        show: true,
      });
      return;
    }

    //Validar emails
    const regex_email = emailRegexGlobal;
    if (emails.length === 0) {
      if (values.email_cliente.trim().length === 0) {
        setError({
          text: "Email requerido",
          show: true,
        });
        return;
      }
      if (!regex_email.test(values.email_cliente)) {
        setError({
          text: "Ingrese un correo válido.",
          show: true,
        });
        return;
      }
    } else if (values.email_cliente.length > 0) {
      if (!regex_email.test(values.email_cliente)) {
        setError({
          text: "Ingrese un correo válido.",
          show: true,
        });
        return;
      }
    }


    const em = values.email_cliente != "" ? [values.email_cliente] : [];

    const eArr = [...emails, ...em];

    let c = [];

    c = [
      {
        telefono_cliente: values.telefono_cliente,
        email_cliente: eArr[0],
        direccion_cliente: values.direccion_cliente,
        emails_contacto: JSON.stringify(eArr),
      },
    ];

    setLoading(true);

    const dataRequest = {
      id_tipo_identificacion: values.tipo_identificacion.id_tipo_identificacion,
      id_establecimiento: establecimiento.id_establecimiento,
      nombre_cliente: values.nombre_cliente,
      apellido_cliente: values.apellido_cliente,
      email_cliente: values.email_cliente,
      telefono_cliente: values.telefono_cliente,
      numero_documento_cliente: values.numero_documento_cliente,
      direccion_cliente: values.direccion_cliente,
      razon_social_cliente: values.razon_social_cliente,
      id_tipo_cliente: values.empresa ? 2 : 1,
      contactos: c,
    };

    crearClienteRequest(dataRequest)
      .then((res) => {
        if (res.result) {
          close();
          setContactos([]);
          setEmails([]);
          reset();
          accionClientes();
          openToast("Cliente creado correctamente !");
        } else {
          setError({
            text: res.message,
            show: true,
          });
        }
      })
      .catch((error) => {
        setError({
          text: "Ocurrio un error",
          show: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addContacto = () => {
    if (
      values.telefono_cliente.length === 0 ||
      values.email_cliente.length === 0 ||
      values.direccion_cliente.length === 0
    ) {
      setError({
        text: "Complete los campos de contacto !",
        show: true,
      });
      return;
    }

    setContactos((c) => [
      ...c,
      {
        telefono_cliente: values.telefono_cliente,
        email_cliente: values.email_cliente,
        direccion_cliente: values.direccion_cliente,
      },
    ]);

    setValues({
      ...values,
      telefono_cliente: "",
      email_cliente: "",
      direccion_cliente: "",
    });
  };

  const removeContacto = (index) => {
    const cf = contactos.filter((c, i) => i != index);
    setContactos(cf);
  };

  //Agregar Email
  const addEmail = (t) => {
    
    const regex_email = emailRegexGlobal;
    if (!regex_email.test(t)) {
      setError({
        text: "Ingrese un correo válido.",
        show: true,
      });
      return;
    }
    setEmails((e) => [...e, t]);
    setValues({
      ...values,
      email_cliente: "",
    });
  };

  //Remove Email
  const removeEmail = (index) => {
    const e = emails.filter((e, i) => i != index);
    setEmails(e);
  };

  return (
    <Modal
      open={modal}
      onClose={() => {
        reset();
        setContactos([]);
        setEmails([]);
        close();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout>
        <Grid container alignItems={"center"} flexDirection={"column"}>
          {/* Header */}
          <Grid
            sx={{
              height: 40,
            }}
            alignItems={"center"}
          >
            <Typography
              sx={{
                fontWeight: "600",
              }}
            >
              Nuevo cliente
            </Typography>
          </Grid>
          {/* Form */}
          <Grid>
            <FormCliente
              values={values}
              setValues={setValues}
              onChange={onChange}
              cancelar={() => {
                reset();
                close();
              }}
              emails={emails}
              removeEmail={removeEmail}
              crear={crear}
              addEmail={addEmail}
              addContacto={addContacto}
              removeContacto={removeContacto}
              contactos={contactos}
            />
          </Grid>
          {error.show && <Alert severity="error">{error.text}</Alert>}
          {loading && <CircularProgress size={25} />}
        </Grid>
      </ModalLayout>
    </Modal>
  );
};
