import React from "react";
import { Route, Routes } from "react-router-dom";
import { CrearCreditoPage } from "../../../pages/app/credito/CrearCreditoPage";
import { CreditoPage } from "../../../pages/app/credito/CreditoPage";
import { EditarCreditoPage } from "../../../pages/app/credito/EditarCreditoPage";
import NotasCreditoPage from "../../../pages/app/credito/NotasCreditoPage";

export const NotasCreditoRoute = () => {
  return (
    <Routes>
      <Route path="/*" element={<NotasCreditoPage />} />
      <Route path="/:id" element={<CreditoPage />} />
      <Route path="/crearNotaCredito" element={<CrearCreditoPage />} />
      <Route path="editar/:id" element={<EditarCreditoPage />} />
    </Routes>
  );
};
