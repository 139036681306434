import {
  RemoveCircleOutline,
  Search,
  SettingsOverscan,
} from "@mui/icons-material";
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Snackbar,
  Typography,
  Card,
  CardContent,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccionesContext } from "../../../context/AccionesContext";
import { AuthContext } from "../../../context/AuthContext";

import { AppLayout } from "../../../layouts/AppLayout";
import { identificacionesRequest } from "../../../services/api/ClientesApi";
import {
  calculosRequest,
  consultarFormasPagoRequest,
} from "../../../services/api/DocumentoApi";
import { crearGuiaRemisionRequest } from "../../../services/api/GuiaRemisionApi";
import { registrarNotaCreditoRequest } from "../../../services/api/NotaCreditoApi";
import { crearTransportistaRequest } from "../../../services/api/TransportistasApi";
import { FacturaDetalleModal } from "../credito/components/FacturaDetalleModal";
import { FacturasModal } from "../credito/components/FacturasModal";
import { CrearFacturaEmision } from "../facturas/components/CrearFacturaEmision";
import { CrearGuiaMercaderia } from "./components/CrearGuiaMercaderia";
import { GuiaDestinatarioForm } from "./components/GuiaDestinatarioForm";
import { GuiaTransporteForm } from "./components/GuiaTransporteForm";
import { emailRegexGlobal } from "../../../helpers/email";

export const CrearGuiaPage = () => {
  const { establecimiento, user } = useContext(AuthContext);
  const { accionClientes } = useContext(AccionesContext);

  const navigate = useNavigate();

  const [tipos_identificaciones, setTipos_identificaciones] = useState([]);

  //FACTURA
  const [factura, setFactura] = useState({});

  const [formasPago, setFormasPago] = useState([]);
  const [pago, setPago] = useState("");
  const [formaPago, setFormaPago] = useState(null);
  const [codigoPago, setCodigoPago] = useState(null);
  const [idFormaPago, setIdFormaPago] = useState(null);
  const [observaciones, setObservaciones] = useState("Sin Observaciones");
  //MOTIVO
  const [motivo, setMotivo] = useState("");

  const handleFormaPago = (e, pago_find) => {
    setPago(e.target.value);
    setFormaPago(pago_find.descripcion_forma_pago);
    setCodigoPago(pago_find.codigo_forma_pago);
    setIdFormaPago(pago_find.id_forma_pago);
  };

  const handleObservaciones = (e) => {
    setObservaciones(e);
  };

  const [loading, setLoading] = useState(false);

  const [cliente, setCliente] = useState({
    id_cliente: null,
    empresa: false,
    tipo_identificacion: {},
    nombre_cliente: "",
    apellido_cliente: "",
    email_cliente: "",
    telefono_cliente: "",
    numero_documento_cliente: "",
    direccion_cliente: "",
    razon_social_cliente: "",
    cliente_nuevo: true,
  });

  const [guiaState, setGuiaState] = useState({
    guia_direccion_partida: "",
    guia_razon_social_transportista: "",
    guia_tipo_identificacion_transportista: "",
    guia_ruc_transportista: "",
    guia_fecha_inicio_transporte: "",
    guia_fecha_fin_transporte: "",
    guia_placa: "",
    guia_direccion_destinatario: "",
    guia_identificacion_destinatario: "",
    guia_razon_social_destinatario: "",
    guia_motivo_traslado: "",
    guia_ruta: "",
    transportista_new: true,

    guia_documento_aduanero_unico: "",
    guia_codigo_establecimiento_destino: "",
    guia_codigo_documento_sustento: "",
    guia_numero_documento_sustento: "",
    guia_numero_autorizacion_documento_sustento: "",
    guia_fecha_emision_documento_sustento: " ",
  });

  const [emails, setEmails] = useState([]);

  const [productosFactura, setProductosFactura] = useState([]);
  const [change, setChange] = useState(false);

  const [detalle_calculos, setDetalle_calculos] = useState([]);

  const [fechaEmision, setFechaEmision] = useState("");

  const [error, setError] = useState({
    text: "",
    show: false,
  });

  const [errorRequired, setErrorRequired] = useState(false);

  const [cabecera_calculos, setCabecera_calculos] = useState({
    impuesto: 0,
    subtotal_iva: 0,
    subtotal_cero: 0,
    subtotal: 0,
    value_iva: 0,
    total_factura: 0,
  });

  const [toast, setToast] = useState({
    open: false,
    text: "",
  });

  const [modalFactura, setModalFactura] = useState(false);
  const [modalFacturaDetalle, setModalFacturaDetalle] = useState(false);

  //Tipos identificacion
  useEffect(() => {
    identificacionesRequest()
      .then((res) => {
        if (res.identificaciones) {
          setTipos_identificaciones(res.identificaciones);
        }
      })
      .catch((err) => {
      })
      .finally(() => {});
  }, []);

  //Formas de Pago
  useEffect(() => {
    consultarFormasPagoRequest({})
      .then((res) => {
        if (res.pagos) {
          setFormasPago(res.pagos.rows);
        }
      })
      .catch((err) => {
      })
      .finally(() => {});
  }, []);

  //Calculos
  useEffect(() => {

    const pc = productosFactura.map((p) => {
      return {
        codigo_producto: p.condigo_producto,
        descripcion: p.nombre_producto,
        tiene_iva: p.precio_iva != 0 ? "1" : "0",
        cantidad: p.cantidad,
        valor_unitario: p.precio_unitario,
        precio_iva: p.precio_iva,
      };
    });

    const dataRequest = {
      productos: pc,
    };

    calculosRequest(dataRequest)
      .then((res) => {

        if (res.result && res.cabecera_calculos.length > 0) {
          setCabecera_calculos(res.cabecera_calculos[0]);
        }
        if (res.result && res.detalles_calculos.length > 0) {
          const productoCalculos = productosFactura.map((pf, index) => {
            const index1 = index + 1;

            const producto_calculo = res.detalles_calculos.find(
              (dc) => dc.indice == index1
            );

            return {
              ...pf,
              ...producto_calculo,
            };
          });
          setProductosFactura(productoCalculos);
        }
      })
      .catch((err) => {
      })
      .finally(() => {});
  }, [change]);

  //Fecha emision
  useEffect(() => {
    const date = new Date();
    const dateFormat = dayjs(date).format("YYYY-MM-DD HH:mm:ss");

    onChangeFecha(dateFormat);
  }, []);

  useEffect(() => {
    const date = new Date(fechaEmision);
    setGuiaState({
      ...guiaState,
      guia_fecha_inicio_transporte: dayjs(date).format("YYYY-MM-DD"),
    });
  }, [fechaEmision]);
  //Fechas Inicio
  useEffect(() => {
    const date = new Date();
    setGuiaState({
      ...guiaState,
      guia_fecha_inicio_transporte: dayjs(date).format("YYYY-MM-DD"),
      guia_fecha_fin_transporte: dayjs(date).format("YYYY-MM-DD"),
    });
  }, []);


  const onChangeFecha = (value) => {
    setFechaEmision(value);
  };

  //CLIENTE
  const onChangeCliente = (name, value) => {
    setCliente({
      ...cliente,
      [name]: value,
    });
  };

  const seleccionarCliente = (e) => {
    setEmails([]);

    const empresa = e.id_tipo_cliente == 2;

    setCliente({
      id_cliente: e.id_cliente,
      tipo_identificacion: {
        descripcion_tipo: tipos_identificaciones.find(
          (e) => e.id_tipo_identificacion
        )?.descripcion_tipo,
        id_tipo_identificacion: e.id_tipo_identificacion,
      },
      nombre_cliente: e.nombre_cliente,
      apellido_cliente: e.apellido_cliente,
      razon_social_cliente: e.razon_social_cliente,
      empresa: e.id_tipo_identificacion == 2,
      email_cliente: e.email_cliente,
      telefono_cliente: e.telefono_cliente,
      numero_documento_cliente: empresa
        ? e.numero_ruc_cliente
        : e.numero_documento_cliente,
      empresa: empresa,
      direccion_cliente: e.direccion_cliente,
      cliente_nuevo: false,
    });

    const emailsArr = JSON.parse(e.contactos[0].emails_contacto);

    const em = emailsArr.filter((ema, i) => i != 0);

    setEmails((e) => [...e, ...em]);
  };

  const quitarCliente = () => {
    setCliente({
      id_cliente: null,
      tipo_identificacion: {},
      nombre_cliente: "",
      apellido_cliente: "",
      email_cliente: "",
      telefono_cliente: "",
      numero_documento_cliente: "",
      direccion_cliente: "",
      cliente_nuevo: true,
    });
    setEmails([]);
  };

  const quitarProducto = (index) => {
    const pfd = productosFactura.filter((p, i) => i != index);
    setProductosFactura(pfd);
    setChange(!change);
    if (productosFactura.length === 1) {
      setCabecera_calculos({
        subtotal_iva: 0,
        subtotal_cero: 0,
        subtotal: 0,
        value_iva: 0,
        total_factura: 0,
      });
    }
  };

  const seleccionarProducto = (e) => {
    const p = productosFactura.find((p) => p.id_producto == e.id_producto);

    if (p?.id_producto) {
      const pff = productosFactura.filter(
        (p) => p.id_producto != e.id_producto
      );
      setProductosFactura([...pff, { ...p, cantidad: Number(p.cantidad) + 1 }]);
    } else {
      setProductosFactura((m) => [...m, { ...e, cantidad: 1 }]);
    }
    setChange(!change);
  };

  const onChangeCantidad = (index, cantidad) => {
    if (cantidad < 0) {
      return;
    }

    const pfc = productosFactura.map((pf, i) => {
      if (i == index) {
        return {
          ...pf,
          cantidad: cantidad,
        };
      }
      return pf;
    });
    setChange(!change);
    setProductosFactura(pfc);
  };

  const onChangePrecio = (index, precio) => {
    if (precio < 0) {
      return;
    }

    const pfc = productosFactura.map((pf, i) => {
      if (i == index) {
        return {
          ...pf,
          precio_unitario: precio,
        };
      }
      return pf;
    });
    setChange(!change);
    setProductosFactura(pfc);
  };

  const crearGuiaRemision = async () => {
    setErrorRequired(false);
    setError({
      text: "",
      show: false,
    });

    //Productos
    if (productosFactura.length === 0) {
      setError({
        text: "Seleccione almenos un item en la mercaderia",
        show: true,
      });
      return;
    }

    let valid = true;
    productosFactura.forEach((pf) => {
      if (pf.cantidad == 0) {
        valid = false;
      }
    });

    if (!valid) {
      setError({
        text: "Revise la cantidad de los productos",
        show: true,
      });
      return;
    }

    //VALIDAR EMISION
    if (!dayjs(fechaEmision).isValid()) {
      setError({
        text: "Ingrese una fecha valida de emision",
        show: true,
      });
      return;
    }

    //CAMPOS OBLIGATORIOS GUIA

    if (
      guiaState.guia_direccion_partida.trim().length == 0 ||
      guiaState.guia_razon_social_transportista.trim().length == 0 ||
      guiaState.guia_tipo_identificacion_transportista.trim().length == 0 ||
      guiaState.guia_ruc_transportista.trim().length == 0 ||
      guiaState.guia_fecha_inicio_transporte.trim().length == 0 ||
      guiaState.guia_fecha_fin_transporte.trim().length == 0 ||
      guiaState.guia_placa.trim().length == 0 ||
      guiaState.guia_identificacion_destinatario.trim().length == 0 ||
      guiaState.guia_razon_social_destinatario.trim().length == 0 ||
      guiaState.guia_direccion_destinatario.trim().length == 0 ||
      guiaState.guia_motivo_traslado.trim().length == 0
    ) {
      setError({
        text: "Revise los campos obligatorios",
        show: true,
      });
      return;
    }


    if(guiaState.transportista_new){
      const dataRequestTrans = {
        razon_social_transportista: guiaState.guia_razon_social_transportista,
        identificacion_transportista: guiaState.guia_ruc_transportista,
        tipo_identificacion_transportista:
          guiaState.guia_tipo_identificacion_transportista,
        placa_transportista: guiaState.guia_placa,
        id_tipo_identificacion: tipos_identificaciones.map(
          (t) =>
            t.codigo_identificacion == guiaState.guia_tipo_identificacion_transportista
        )?.id_tipo_identificacion,
        id_establecimiento: establecimiento.id_establecimiento,
      };
  
      await crearTransportistaRequest(dataRequestTrans)
        .then((res) => {
          if (res.result) {
            openToast("Transportista creado correctamente !");
          }else{
            setError({
              text: res.message,
              show: true,
            });
          }
        })
        .catch((error) => {
          setError({
            text: "Ocurrio un error",
            show: true,
          });
        })
    };
    

    const ejemplo = {
      id_documento: 891,
      total_sin_impuestos: 20,
      subtotal_neto: 20,
      total_base_imponible_cero: 20,
      total_base_imponible_doce: 0,
      valor_total_iva: 0,
      valor_total: 20,
      porcentaje_iva: 0,
      motivo_nota_credito: "DEVOLUCION",

      guia_direccion_partida: "",
      guia_razon_social_transportista: "",
      guia_tipo_identificacion_transportista: "",
      guia_ruc_transportista: "",
      guia_fecha_inicio_transporte: "",
      guia_fecha_fin_transporte: "",
      guia_placa: "",
      guia_identificacion_destinatario: "",
      guia_direccion_destinatario: "",
      guia_razon_social_destinatario: "",
      guia_motivo_traslado: "",
      guia_ruta: "",

      guia_documento_aduanero_unico: "",
      guia_codigo_establecimiento_destino: "",
      guia_codigo_documento_sustento: "",
      guia_numero_documento_sustento: "",
      guia_numero_autorizacion_documento_sustento: "",
      guia_fecha_emision_documento_sustento: " ",

      productos: [
        {
          id_establecimiento: 10,
          codigo_producto: "001",
          descripcion: "PrOducto 1",
          tiene_iva: "0",
          cantidad: 1,
          valor_unitario: 1.0,
          indice: 1,
          valor_total: 1.0,
          valor_total_sin_impuesto: 1.0,
          porcentaje_iva: 0,
          valor_iva: 0,
          valor_total_imponible_doce: 0,
          valor_total_imponible_cero: 1.0,
        },
      ],
    };

    const establecimiento_matriz = user.establecimientos.find(
      (e) => e.estado_matriz_establecimiento == true
    );
    const punto_emision = user.puntos_emision.find(
      (p) => p.id_establecimiento == establecimiento.id_establecimiento
    );

    const dataRequestDocumento = {
      id_documento: factura.id_documento,

      id_establecimiento: establecimiento.id_establecimiento,
      ruc: user.usuario?.ruc_emisor,
      razon_social: user.usuario?.razon_social_emisor,
      nombre_comercial: user.usuario?.nombre_comercial_emisor,
      direccion_matriz: establecimiento_matriz.direccion_establecimiento,
      direccion_establecimiento: establecimiento.direccion_establecimiento,
      obligado_contabilidad: establecimiento.obligado_contabilidad ?? "SI",
      codigo_establecimiento: establecimiento.codigo_establecimiento,
      codigo_punto_emision: punto_emision.codigo_punto_emision,

      total_sin_impuestos: cabecera_calculos.subtotal,
      subtotal_neto: cabecera_calculos.subtotal,
      total_base_imponible_cero: cabecera_calculos.subtotal_cero,
      total_base_imponible_doce: cabecera_calculos.subtotal_iva,
      valor_total_iva: cabecera_calculos.subtotal_iva,
      valor_total: cabecera_calculos.total_factura,
      porcentaje_iva: cabecera_calculos.value_iva,
      motivo_nota_credito: motivo,
      fecha_emision_documento: fechaEmision,

      guia_direccion_partida: guiaState.guia_direccion_partida,
      guia_razon_social_transportista:
        guiaState.guia_razon_social_transportista,
      guia_tipo_identificacion_transportista:
        guiaState.guia_tipo_identificacion_transportista,
      guia_ruc_transportista: guiaState.guia_ruc_transportista,
      guia_fecha_inicio_transporte: guiaState.guia_fecha_inicio_transporte,
      guia_fecha_fin_transporte: guiaState.guia_fecha_fin_transporte,
      guia_placa: guiaState.guia_placa,
      guia_identificacion_destinatario:
        guiaState.guia_identificacion_destinatario,
      guia_razon_social_destinatario: guiaState.guia_razon_social_destinatario,
      guia_direccion_destinatario: guiaState.guia_direccion_destinatario,
      guia_motivo_traslado: guiaState.guia_motivo_traslado,
      guia_ruta: guiaState.guia_ruta,
      guia_documento_aduanero_unico: guiaState.guia_documento_aduanero_unico,
      guia_codigo_establecimiento_destino:
        guiaState.guia_codigo_establecimiento_destino,
      guia_codigo_documento_sustento: guiaState.guia_codigo_documento_sustento,
      guia_numero_documento_sustento: guiaState.guia_numero_documento_sustento,
      guia_numero_autorizacion_documento_sustento:
        guiaState.guia_numero_autorizacion_documento_sustento,
      guia_fecha_emision_documento_sustento:
        guiaState.guia_fecha_emision_documento_sustento,

      productos: productosFactura.map((pf) => {
        return {
          ...pf,
          descripcion: pf.nombre_producto,
          valor_unitario: pf.precio_unitario,
          tiene_iva: pf.precio_iva != 0 ? "1" : "0",
        };
      }),
    };

    setLoading(true);

    crearGuiaRemisionRequest(dataRequestDocumento)
      .then((res) => {
        if (res.result) {
          openToast("Guía de remisión creada correctamente !");
          setCabecera_calculos({
            subtotal_iva: 0,
            subtotal_cero: 0,
            subtotal: 0,
            value_iva: 0,
            total_factura: 0,
          });
          setProductosFactura([]);
          navigate(-1);
        } else {
          setError({
            text: res.message,
            show: true,
          });
        }
      })
      .catch((err) => {
        setError({
          text: "Ocurrio un error al crear la guia de remision",
          show: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const closeToast = () => {
    setToast({
      open: false,
      text: "",
    });
  };

  const openToast = (text = "") => {
    setToast({
      open: true,
      text: text,
    });
  };

  //Agregar Email
  const addEmail = (t) => {
    const regex = emailRegexGlobal;
    if (!regex.test(t)) {
      setError({
        text: "Ingrese un correo válido.",
        show: true,
      });
      return;
    }
    setEmails((e) => [...e, t]);
    setCliente({
      ...cliente,
      email_cliente: "",
    });
  };

  //Remove Email
  const removeEmail = (index) => {
    const e = emails.filter((e, i) => i != index);
    setEmails(e);
  };

  //SELECCIONAR FACTURA
  const seleccionarFactura = (factura) => {
    setFactura(factura);
    //Productos
    const productos = factura.detalles?.map((item) => {
      return {
        ...item,
        nombre_producto: item.descripcion,
        precio_unitario: item.valor_unitario,
        precio_iva: item.precio_iva,
      };
    });


    setProductosFactura(productos);
    setChange(!change);

    const d = new Date(factura.fecha_emision_documento);
    const df = dayjs(d).format("YYYY-MM-DD");

    setGuiaState({
      ...guiaState,
      guia_direccion_partida: factura.direccion_establecimiento,
      guia_razon_social_destinatario: factura.razon_social_cliente,
      guia_identificacion_destinatario: factura.num_identificacion_cliente,
      guia_direccion_destinatario: factura.cliente?.direccion_cliente,

      guia_codigo_documento_sustento: factura.codigo_tipo_documento,
      guia_numero_documento_sustento: factura.numero_documento,
      guia_fecha_emision_documento_sustento: df,
      guia_numero_autorizacion_documento_sustento: factura.clave_acceso,
    });
  };

  const addAnexo = (index, anexo) => {
    if (anexo.trim().length <= 2) {
      alert("El anexo debe tener almenos 3 caracteres");
      return;
    }

    const pA = productosFactura[index];

    if (pA.anexos?.length >= 3) {
      alert("Solo se puede agregar 3 anexos por detalle");
      return;
    }

    const asInit = [
      ...(pA.anexos ?? []),

      {
        id_detalle_anexo: null,
        anexo: anexo,
      },
    ];

    const pfc = productosFactura.map((pf, i) => {
      if (i == index) {
        return {
          ...pf,
          anexos: asInit,
        };
      }
      return pf;
    });
    setChange(!change);
    setProductosFactura(pfc);
  };

  const removeAnexo = (index, iAnexo) => {
    const pA = productosFactura[index];

    const aChange = pA.anexos.filter((a, i) => i != iAnexo);

    const pfc = productosFactura.map((pf, i) => {
      if (i == index) {
        return {
          ...pf,
          anexos: aChange,
        };
      }
      return pf;
    });
    setChange(!change);
    setProductosFactura(pfc);
  };

  const onChangeGuia = (name, value) => {
    const v = value;
    setGuiaState({
      ...guiaState,
      [name]: v,
    });
  };

  const openFactura = () => setModalFactura(true);
  const closeFactura = () => setModalFactura(false);

  const openFacturaDetalle = () => setModalFacturaDetalle(true);
  const closeFacturaDetalle = () => setModalFacturaDetalle(false);

  const quitarFactura = () => {
    setFactura({});
    setProductosFactura([]);
    setChange(!change);
    setGuiaState({
      ...guiaState,
      guia_razon_social_destinatario: "",
      guia_identificacion_destinatario: "",
      guia_direccion_destinatario: "",

      guia_codigo_documento_sustento: "",
      guia_numero_documento_sustento: "",
      guia_fecha_emision_documento_sustento: " ",
      guia_numero_autorizacion_documento_sustento: "",
    });
  };

  return (
    <AppLayout>
      <Grid
        container
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          // backgroundColor: 'red',
          p: 1,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "#d4d4ff",
          borderRadius: 3,
        }}
      >
        <Grid xs={12} sm={5.5} px={1}>
          <Grid
            mb={1}
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontSize={16} fontWeight={"bold"} color={"darkgray"}>
              GENERAR GUÍA REMISIÓN
            </Typography>
            <Button
              variant="contained"
              size="small"
              onClick={openFactura}
              endIcon={<Search />}
            >
              Buscar factura
            </Button>
          </Grid>
          {/* Emision */}
          <Grid>
            <CrearFacturaEmision
              fechaEmision={fechaEmision}
              onChangeFecha={onChangeFecha}
              editar
            />
          </Grid>
          {factura.id_documento && (
            <Grid my={0.5}>
              <Card>
                <CardContent>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography
                      fontWeight={"bold"}
                      color={"darkgray"}
                      gutterBottom
                    >
                      FACTURA
                    </Typography>
                    <Grid>
                      <Tooltip title={"Quitar"} placement="top">
                        <IconButton onClick={quitarFactura} size="small">
                          <RemoveCircleOutline color="error" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={"Ver detalles"} placement="top">
                        <IconButton onClick={openFacturaDetalle} size="small">
                          <SettingsOverscan color="primary" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography fontWeight={"bold"}>Documento N.º</Typography>
                    <Typography>{factura.numero_documento}</Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography fontWeight={"bold"}>Fecha</Typography>
                    <Typography>
                      {factura.fecha_emision_documento?.substring(0, 10)}
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography fontWeight={"bold"}>Cliente</Typography>
                    <Typography>{factura.razon_social_cliente}</Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography fontWeight={"bold"}>
                      Identificación cliente
                    </Typography>
                    <Typography>
                      {factura.num_identificacion_cliente}
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography fontWeight={"bold"}>Total</Typography>
                    <Typography fontWeight={"bold"}>
                      ${" "}
                      {Number(factura.valor_total)?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )}
          {/* GUIA REMISION TRANSPORTE*/}
          <Grid mt={1}>
            <GuiaTransporteForm
              tipos_identificaciones={tipos_identificaciones}
              form={guiaState}
              onChangeForm={onChangeGuia}
              errorRequired={errorRequired}
              setForm={setGuiaState}
            />
          </Grid>
          <Divider />
          {/* GUIA DESTINARARIO*/}
          <Grid mt={1}>
            <GuiaDestinatarioForm
              form={guiaState}
              onChangeForm={onChangeGuia}
            />
          </Grid>
          <Grid my={1} minHeight={10}></Grid>
        </Grid>
        <Divider sx={{ display: { xs: "block", sm: "none" } }} />
        <Grid mb={1} xs={12} sm={6.5} px={1}>
          {/* Mercaderia */}
          <Grid>
            <CrearGuiaMercaderia
              seleccionarProducto={seleccionarProducto}
              productosFactura={productosFactura}
              onChangeCantidad={onChangeCantidad}
              quitarProducto={quitarProducto}
              onChangePrecio={onChangePrecio}
              addAnexo={addAnexo}
              removeAnexo={removeAnexo}
            />
          </Grid>

          <Divider />
          {loading && <CircularProgress size={25} />}

          {error.show && <Alert severity="error">{error.text}</Alert>}
          {!loading && (
            <Grid
              component={"div"}
              display={"flex"}
              justifyContent={"end"}
              mt={2}
            >
              <Button
                onClick={crearGuiaRemision}
                variant="contained"
                size="small"
                sx={{
                  borderRadius: 100,
                  px: 4,
                  backgroundColor: "#000590",
                }}
              >
                Crear guía de remisión
              </Button>
            </Grid>
          )}
        </Grid>

        <Snackbar
          open={toast.open}
          autoHideDuration={6000}
          onClose={closeToast}
        >
          <Alert onClose={closeToast} severity="success" sx={{ width: "100%" }}>
            {toast.text}
          </Alert>
        </Snackbar>
        {/* MODAL BUSQUEDA FACTURA */}
        <FacturasModal
          visible={modalFactura}
          close={closeFactura}
          seleccionarFactura={seleccionarFactura}
        />
        {/* MODAL FACTURA DETALLE */}
        <FacturaDetalleModal
          visible={modalFacturaDetalle}
          close={closeFacturaDetalle}
          documento={factura}
        />
      </Grid>
    </AppLayout>
  );
};
