import {
  DateRange,
  Search,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  MenuItem,
  Select,
  styled,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState, useRef } from "react";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    // backgroundColor: theme.palette.background.paper,
    // border: '1px solid #ced4da',
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // borderRadius: 4,
      // borderColor: '#80bdff',
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

export const PageSearchDocumento = ({
  title = "",
  searchText = "",
  textButtton = "Crear ",
  actionButton = () => {},
  onFilter = (id, value) => {},
  dates = { show: false },
  setDates = () => {},
}) => {
  const [option, setOption] = useState("");
  const [select, setSelect] = useState("");
  const [search, setSearch] = useState("");

  const [datesFormat, setDatesFormat] = useState({
    init: '',
    end: ''
  });

  const inputRef = useRef();

  const optionsFilter = [
    {
      id: "numero_documento",
      type: "input",
      label: "Número de documento",
      input_placeholder: "Ej. 001-001-000000001",
    },
    {
      id: "num_identificacion_cliente",
      type: "input",
      label: "Identificación cliente",
      input_placeholder: "Ej. 0504375353",
    },
    {
      id: "razon_social_cliente",
      type: "input",
      label: "Razon social cliente",
      input_placeholder: "Ej. NOMBRES APELLIDOS",
    },
    {
      id: "estado_documento_electronico",
      type: "select",
      label: "Estado de documento",
      select_default: "Seleccione",
      options: [
        { id: 0, label: "ANULADO" },
        { id: 1, label: "CREADO" },
        { id: 2, label: "DEVUELTO" },
        { id: 3, label: "AUTORIZADO" },
        { id: 4, label: "EN PROCESO" },
        { id: 5, label: "RECHAZADO" },
        { id: 6, label: "NO AUTORIZADO" },
      ],
    },
  ];

  const inputSearchChange = (e) => {
    const text = e.target.value;
    setSearch(text);
  };

  const handleChange = (e) => {
    setSearch("");
    setSelect("");
    setOption(e.target.value);
  };

  const handleChangeSelect = (e) => {
    const value = e.target.value;
    setSelect(value);
    onFilter(option, value, dates.show);
  };

  useEffect(() => {
    if (option != "") {
      const type = optionsFilter.find((o) => o.id == option).type;
      if (type == "input") {
        inputRef.current.focus();
      }
    } else {
      onFilter(option, "", dates.show);
    }
  }, [option]);

  useEffect(() => {
    const date = new Date();
    const init = dayjs(date).format("YYYY-MM-DD");
    const end = dayjs(date).add(1, 'day').format("YYYY-MM-DD");
    setDatesFormat({
      init: init,
      end: end,
    })
  }, [])

  const submitFilter = (e) => {
    e.preventDefault();
    onFilter(option, search, dates.show);
  };

  return (
    <Grid>
      <Grid>
        <Grid container flexDirection={"row"} alignItems={"center"} xs={12}>
          {/* Filtro */}
          <Grid
            item
            xs={2}
            sm={2}
            sx={{
              backgroundColor: "#e3e2ff",
              borderRadius: 100,
              height: 35,
            }}
          >
            {/* SELECT */}
            <FormControl
              fullWidth
              sx={{
                height: 35,
              }}
            >
              <Select
                sx={{
                  height: 35,
                  minWidth: "100%",
                  textAlign: "center",
                }}
                input={<BootstrapInput />}
                size="small"
                displayEmpty
                value={option}
                onChange={handleChange}
              >
                <MenuItem value={""}>Todos</MenuItem>
                {optionsFilter.map((o) => {
                  return (
                    <MenuItem key={o.id} value={o.id}>
                      {" "}
                      {o.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          {/* Input */}
          {option !== "" &&
            optionsFilter.find((o) => o.id == option).type === "input" && (
              <Grid
                item
                xs={2}
                sm={2}
                sx={{
                  backgroundColor: "#e3e2ff",
                  borderRadius: 100,
                  height: 35,
                }}
              >
                <form className={"form_search"} onSubmit={submitFilter}>
                  <InputBase
                    inputRef={inputRef}
                    placeholder={
                      optionsFilter.find((o) => o.id == option)
                        .input_placeholder
                    }
                    value={search}
                    sx={{
                      height: "100%",
                      width: "100%",
                      pl: 2,
                    }}
                    onChange={inputSearchChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          color="primary"
                          disabled={search.trim().length == 0}
                          type="submit"
                        >
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </form>
              </Grid>
            )}
          {/* Select */}
          {option !== "" &&
            optionsFilter.find((o) => o.id == option).type === "select" && (
              <Grid
                item
                xs={2}
                sm={2}
                sx={{
                  backgroundColor: "#e3e2ff",
                  borderRadius: 100,
                  height: 35,
                }}
              >
                {/* SELECT */}
                <FormControl fullWidth>
                  <Select
                    sx={{
                      height: 35,
                      minWidth: "100%",
                      textAlign: "center",
                    }}
                    input={<BootstrapInput />}
                    size="small"
                    displayEmpty
                    value={select}
                    onChange={handleChangeSelect}
                  >
                    <MenuItem value={""}>
                      {optionsFilter.find((o) => o.id == option).select_default}
                    </MenuItem>
                    {optionsFilter
                      .find((o) => o.id == option)
                      .options.map((o) => {
                        return (
                          <MenuItem key={o.id} value={o.id}>
                            {" "}
                            {o.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            )}
          <IconButton
            color={dates.show ? "primary" : "default"}
            onClick={() => {

              if(dates.show){
                onFilter('', '', '');
              }

              const date = new Date();

              const dateFormatEnd = dayjs(date).format("YYYY-MM-DD");
              const dateFormatInit = dayjs(date)
                .subtract(1, "M")
                .format("YYYY-MM-DD");

              setDates({
                ...dates,
                show: !dates.show,
                date_init: dateFormatInit,
                date_end: dateFormatEnd,
              });
            }}
          >
            <DateRange />
          </IconButton>
          {/* DATES */}
          {dates.show && (
            <>
              {/* Fecha Inicio */}
              <Grid
                item
                xs={2}
                sm={2}
                sx={{
                  backgroundColor: "#e3e2ff",
                  borderRadius: 100,
                  height: 35,
                  px: 1,
                  position: "relative",
                }}
              >
                <Typography
                  sx={{
                    position: "absolute",
                    top: "-12px",
                    left: "10px",
                    fontSize: 11,
                    // backgroundColor: '#fff',
                    borderRadius: 100,
                    px: 1,
                  }}
                >
                  Fecha inicio
                </Typography>
                <InputBase
                  type={"date"}
                  value={dates.date_init}
                  sx={{
                    height: "100%",
                    width: "100%",
                    pl: 1,
                  }}
                  inputProps={{
                      min: '2020-01-01',
                      max: datesFormat.init,
                  }}
                  onChange={(e) => {
                    setDates({
                      ...dates,
                      date_init: e.target.value,
                    });
                  }}
                />
              </Grid>
              {/* Fecha Final */}
              <Grid
                item
                xs={2}
                sm={2}
                sx={{
                  backgroundColor: "#e3e2ff",
                  borderRadius: 100,
                  height: 35,
                  px: 1,
                  position: "relative",
                }}
              >
                <Typography
                  sx={{
                    position: "absolute",
                    top: "-12px",
                    left: "10px",
                    fontSize: 11,
                    // backgroundColor: '#fff',
                    borderRadius: 100,
                    px: 1,
                  }}
                >
                  Fecha Fin
                </Typography>
                <InputBase
                  type={"date"}
                  value={dates.date_end}
                  sx={{
                    height: "100%",
                    width: "100%",
                    pl: 1,
                  }}
                  inputProps={{
                    min: dates.date_init,
                    max: datesFormat.end,
                }}
                  onChange={(e) => {
                    setDates({
                      ...dates,
                      date_end: e.target.value,
                    });
                  }}
                />
              </Grid>
              {/* BUSCAR */}
              <IconButton
                color="primary"
                onClick={() => {
                  onFilter("", "", true);
                }}
              >
                <Search />
              </IconButton>
            </>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          height: 40,
          px: 2,
        }}
      >
        <Grid
          item
          xs={0}
          sm={0}
          md={3}
          lg={5}
          sx={{
            display: { xs: "none", sm: "none", md: "block", lg: "block" },
          }}
        >
          <Typography fontWeight={"bold"} fontSize={16} component={"h6"}>
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
            alignItems: "center",
            justifyContent: "end",
          }}
          xs={0}
          sm={5}
          md={5}
          lg={3}
          xl={3}
        >
          <Button
            variant="contained"
            onClick={actionButton}
            sx={{
              borderRadius: 100,
              px: 3,
              fontSize: 13,
            }}
          >
            {textButtton}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
