import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ModalLayout } from "../../../../layouts/ModalLayout";
import { ArrowBack } from "@mui/icons-material";
import { emisorUpdateRequest } from "../../../../services/api/UsuariosApi";
import { tiposContribuyenteRequest } from "../../../../services/api/EstablecimientosApi";

const EmisorModal = ({ emisor, visible, close, reload = () => {} }) => {
  const [data, setData] = useState({
    id_emisor: emisor.id_emisor,
    ruc_emisor: emisor.ruc_emisor,
    razon_social_emisor: emisor.razon_social_emisor,
    obligado_contabilidad: emisor.obligado_contabilidad,
    tipo_contribuyente: emisor.tipo_contribuyente,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState({
    text: "",
    show: false,
  });

  const [tiposContribuyentes, setTiposContribuyentes] = useState([]);

  useEffect(() => {
    tiposContribuyenteRequest({})
      .then((res) => {
        if (res.contribuyente) {
          const t = res.contribuyente;
          setTiposContribuyentes(t);
        }
      })
      .finally(() => {});
  }, []);

  const handleSave = () => {
    setError({
      text: "",
      show: false,
    });

    const confirm = window.confirm(
      "¿Esta seguro de editar los datos del emisor?"
    );

    if (!confirm) return;
    //VALIDAR DATOS
    const validaciones = validarDatos();
    const errores = Object.keys(validaciones);
    if (errores.length > 0) {
      let alert = "";
      errores.forEach((key) => {
        alert += `${validaciones[key]}\n`;
      });
      window.alert(alert);
      return;
    }

    const dataRequest = {
      id_emisor: data.id_emisor,
      razon_social_emisor: data.razon_social_emisor,
      ruc_emisor: data.ruc_emisor,
      obligado_contabilidad: data.obligado_contabilidad,
      id_tipo_contribuyente: data.tipo_contribuyente?.id_tipo_contribuyente,
    };


    setIsLoading(true);
    emisorUpdateRequest(dataRequest)
      .then((res) => {
        if (res.result) {
          reload();
          close();
        } else {
          setError({
            text: res.message,
            show: true,
          });
        }
      })
      .catch()
      .finally(() => setIsLoading(false));
  };

  const validarDatos = () => {
    let errores = {};

    if (data.ruc_emisor.trim() === "") {
      errores.ruc_emisor = "RUC es obligatorio";
    }

    if (data.ruc_emisor.length < 13 || data.ruc_emisor.length > 13) {
      errores.ruc_emisor_formato = "El RUC debe tener 13 digitos";
    }

    if (data.razon_social_emisor.trim() === "") {
      errores.razon_social_emisor = "Razón social es obligatoria";
    }

    if (data.obligado_contabilidad.trim() === "") {
      errores.obligado_contabilidad =
        "Obligado a llevar contabilidad es obligatorio";
    }

    if (Object.keys(data.tipo_contribuyente).length === 0) {
      errores.tipo_contribuyente = "Tipo de contribuyente es obligatorio";
    }

    return errores;
  };

  return (
    <Modal
      open={visible}
      onClose={() => {
        close();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout>
        <Grid container alignItems={"center"} flexDirection={"column"}>
          {/* Header */}
          <Grid
            sx={{
              height: 40,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
              }}
            >
              Editar emisor
            </Typography>
            <IconButton
              onClick={close}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <ArrowBack />
            </IconButton>
          </Grid>
          {/* Body */}
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid mt={2}>
              <TextField
                value={data.razon_social_emisor}
                onChange={(e) =>
                  setData({
                    ...data,
                    razon_social_emisor: e.target.value.toUpperCase(),
                  })
                }
                label={"Nombre / Razón social"}
                size={"small"}
                fullWidth
              />
            </Grid>
            <Grid mt={1}>
              <TextField
                value={data.ruc_emisor}
                onChange={(e) =>
                  setData({
                    ...data,
                    ruc_emisor: e.target.value.toUpperCase(),
                  })
                }
                label={"RUC"}
                size={"small"}
                fullWidth
              />
            </Grid>
            <Grid mt={1}>
              <Grid width={230}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Obligado a llevar contabilidad
                  </InputLabel>
                  <Select
                    id="demo-simple-select"
                    value={data.obligado_contabilidad}
                    label="Obligado a llevar contabilidad"
                    onChange={(e) => {
                      const v = e.target.value;
                      setData({
                        ...data,
                        obligado_contabilidad: v,
                      });
                    }}
                  >
                    <MenuItem key={"NO"} value={"NO"}>
                      NO
                    </MenuItem>
                    <MenuItem key={"SI"} value={"SI"}>
                      SI
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid mt={1}>
              <Grid width={230}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Contribuyente
                  </InputLabel>
                  <Select
                    id="demo-simple-select"
                    value={
                      data.tipo_contribuyente?.descripcion_contribuyente ?? ""
                    }
                    label="Contribuyente"
                    onChange={(e) => {
                      const v = e.target.value;

                      const c = tiposContribuyentes.find(
                        (tc) => tc.descripcion_contribuyente == v
                      );

                      setData({
                        ...data,
                        tipo_contribuyente: c,
                      });
                    }}
                  >
                    {tiposContribuyentes?.map((tc) => {
                      return (
                        <MenuItem
                          key={tc.descripcion_contribuyente}
                          value={tc.descripcion_contribuyente}
                        >
                          {tc.descripcion_contribuyente}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Box
              mt={3}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {isLoading && <CircularProgress size={25} />}
              {error.show && <Alert severity="error">{error.text}</Alert>}
              <Button
                size="small"
                onClick={handleSave}
                variant="contained"
                disabled={isLoading}
                style={{
                  borderRadius: 100,
                  px: 4,
                  backgroundColor: isLoading ? "GrayText" : "#000590",
                  width: "200px",
                }}
              >
                Guardar cambios
              </Button>
            </Box>
          </Grid>
        </Grid>
      </ModalLayout>
    </Modal>
  );
};

export default EmisorModal;
