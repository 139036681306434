import API from "./ApiConfig"

const URL_IVA = '/iva';


export const ivasRequest = async () => {
    const request = await API.get(URL_IVA);
    const response = request.data;
    return response;
}



