import React, { Fragment, useContext, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import { Close, StyleOutlined } from "@mui/icons-material";
import { ModalLayout } from "../../layouts/ModalLayout";
import { AuthContext } from "../../context/AuthContext";
import { planesRequest } from "../../services/api/PlanesApi";
import { AccionesContext } from "../../context/AccionesContext";

export const Planes = ({ onPlanSelect, customButton, disable = false }) => {
  const { user } = useContext(AuthContext);
  const { recargarPlanes } = useContext(AccionesContext);

  const [planes, setPlanes] = useState([]);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});

  const handleOpen = () => {
    if (disable) return;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePlanSelect = (plan) => {
    const f = onPlanSelect(plan);
    if (!f) return;
    setSelectedPlan(plan);
    handleClose();
  };

  useEffect(() => {
    setPlanes([]);
    setLoading(true);
    const dataRequest = {
      id_usuario: user.usuario?.id_usuario,
    };
    planesRequest(dataRequest)
      .then((res) => {
        if (res.result && res.planes) {
          const planes = res.planes;
          setPlanes(planes);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [recargarPlanes]);

  return (
    <div>
      <Grid container justifyContent={"center"} alignItems={"center"}>
        {selectedPlan.id_plan ? (
          customButton ? (
            <Grid>{customButton}</Grid>
          ) : (
            <Box
              onClick={() => handleOpen()}
              component={"div"}
              style={{
                minHeight: 60,
                width: 230,
                borderRadius: 10,
                backgroundColor: "#1b37aa",
                boxShadow: "3px 5px 7px rgba(40, 83, 255, 0.4)",
                cursor: "pointer",
                padding: 10,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <Grid>
                <Typography color={"white"} fontSize={12}>
                  {selectedPlan.nombre_plan}
                </Typography>
                <Typography color={"white"} fontSize={10}>
                  <b>Nº documentos: </b>{" "}
                  {selectedPlan.cantidad_documentos ?? "ILIMITADO"}
                </Typography>
                <Typography color={"white"} fontSize={8}>
                  <b>Meses habilitados: </b> {selectedPlan.meses_habilitados}
                </Typography>
              </Grid>
              <Typography color={"white"} fontSize={14}>
                $ {selectedPlan.precio}
              </Typography>
              <StyleOutlined
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  height: 25,
                  width: 25,
                  color: "rgba(255,255,255, 0.2)",
                }}
              />
            </Box>
          )
        ) : customButton ? (
          <Grid onClick={handleOpen} component={"div"}>
            {customButton}
          </Grid>
        ) : (
          <Tooltip title={"Seleccionar un plan"}>
            <Box
              component={"div"}
              onClick={handleOpen}
              style={{
                height: 45,
                width: 230,
                borderRadius: 10,
                backgroundColor: "#2853ff",
                boxShadow: "3px 5px 7px rgba(40, 83, 255, 0.3)",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <StyleOutlined color="secondary" />
                <Typography
                  ml={2}
                  mr={4}
                  color={"white"}
                  fontWeight={"bold"}
                  fontSize={16}
                >
                  PLAN
                </Typography>
              </Grid>
            </Box>
          </Tooltip>
        )}
      </Grid>

      <Modal open={open} onClose={handleClose}>
        <ModalLayout>
          <Grid
            container
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            position={"relative"}
          >
            <Grid position="absolute" left={5}>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Grid>
            <Typography fontSize={16} fontWeight={"bold"}>
              Planes
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            pt={3}
            maxHeight={"70vh"}
            paddingBottom={3}
            overflow={"auto"}
          >
            {planes.map((plan, index) => {
              return (
                <Box
                  key={plan.id_plan}
                  component={"div"}
                  onClick={() => handlePlanSelect(plan)}
                  mt={2}
                  style={{
                    minHeight: 80,
                    width: 300,
                    borderRadius: 10,
                    backgroundColor:
                      plan.id_plan == selectedPlan.id_plan
                        ? "#1b37aa"
                        : "#244ae3",
                    boxShadow: "3px 5px 7px rgba(40, 83, 255, 0.4)",
                    transform:
                      plan.id_plan == selectedPlan.id_plan && "scale(1.1)",
                    cursor: "pointer",
                    padding: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    position: "relative",
                  }}
                >
                  <Grid>
                    <Typography color={"white"} fontSize={16}>
                      {plan.nombre_plan} 
                    </Typography>
                    <Typography color={"white"} fontSize={13}>
                      <b>Nº documentos: </b>{""}
                      {plan.cantidad_documentos ?? "ILIMITADO"}
                    </Typography>
                    <Typography color={"white"} fontSize={10}>
                      <b>Meses habilitados: </b>{" "}
                      {plan.meses_habilitados}
                    </Typography>
                  </Grid>
                  <Typography color={"white"} fontSize={16}>
                    ${plan.precio}
                  </Typography>
                  <StyleOutlined
                    style={{
                      position: "absolute",
                      right: 0,
                      bottom: 0,
                      height: 40,
                      width: 40,
                      color: "rgba(255,255,255, 0.2)",
                    }}
                  />
                </Box>
              );
            })}
          </Grid>
        </ModalLayout>
      </Modal>
    </div>
  );
};
