import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  Button,
  CircularProgress,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useForm } from "../../../hooks/useForm";
import { AuthLayout } from "../../../layouts/AuthLayout";
import { loginRequest } from "../../../services/api/AuthApi";

export const LoginPage = () => {

  const { login } = useContext(AuthContext);

  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    text: "",
    show: false,
  });

  const [values, onChange, reset, initValues] = useForm({
    user: "",
    password: "",
    show: false,
  });

  const loginUser = () => {

    if(values.password.trim().length === 0 || values.user.trim().length === 0){
      setError({
        show: 'true',
        text: 'Ingrese el usuario y contraseña'
      })
      return;
    }

    const dataRequest = {
      email_usuario: values.user,
      password_usuario: values.password,
    };

    setLoading(true);

    loginRequest(dataRequest)
      .then((res) => {
        if (res.result) {

          const usuario = res.data_sesion?.login;
          const establecimientos = res.data_establecimiento;
          const puntos_emision = res.data_punto_emision;

          //usuario, establecimientos, puntos_emision
          login(usuario, establecimientos, puntos_emision);

        } else {
          setError({
            show: 'true',
            text: res.message
          })
        }
      })
      .catch((err) => {
      })
      .finally( () => {
        setLoading(false);
      })
  };

  const onChangeValues = (name, e) => {
    onChange(name, e.target.value);
  };

  return (
    <AuthLayout title="Globito">
      <form>
        <Grid
          container
          rowSpacing={2}
          mt={1}
          mb={1}
          direction={"column"}
          alignItems={"center"}
        >
          <Grid item>
            <TextField
              label="Email"
              size="small"
              fullWidth
              sx={{
                width: 200,
              }}
              value={values.user}
              onChange={(e) => onChangeValues("user", e)}
            />
          </Grid>
          <Grid item>
            <FormControl sx={{
                width: 200,
              }}
               variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
                Contraseña
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={values.show ? "text" : "password"}
                value={values.password}
                onChange={(e) => onChangeValues("password", e)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => onChange("show", !values.show)}
                    >
                      {values.show ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container justifyContent={"center"} mt={2}>
          <Grid item xs={6}>
            <Button
              onClick={loginUser}
              variant="contained"
              sx={{
                borderRadius: 100,
              }}
              xs={1}
              fullWidth
            >
              Iniciar
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent={"end"} mt={2}>
          {/* <Link
            component={ RouterLink }
            to={'/auth/register'}
            >
            Crear una cuenta
          </Link> */}
        </Grid>
      </form>
          {
            loading && (
              <CircularProgress size={20} />
            )
          }
      {
      error.show && (
        <Alert severity="error">{error.text}</Alert>
      )
      }

    </AuthLayout>
  );
};
