import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import React from 'react'
import { blueTheme } from './themes';

export const Theme = ({ children }) => {
    return (
        <ThemeProvider
            theme={blueTheme}
        >
            <CssBaseline />
            {children}
        </ThemeProvider>

    )
}
