import { Add, AddCircle } from "@mui/icons-material";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AccionesContext } from "../../../../context/AccionesContext";
import { AuthContext } from "../../../../context/AuthContext";
import { identificacionesRequest } from "../../../../services/api/ClientesApi";
import { ClienteContactoForm } from "./ClienteContactoForm";
import { ClienteEmailsForm } from "./ClienteEmailsForm";

export const FormCliente = ({
  values,
  onChange,
  cancelar,
  crear,
  setValues,
  addContacto,
  removeContacto,
  removeEmail = (i) => {},
  emails = [],
  contactos = [],
  addEmail = (t) => {},
  edit = false,
  editar = (i) => {},
}) => {
  const [tipos_identificaciones, setTipos_identificaciones] = useState([]);

  useEffect(() => {
    identificacionesRequest()
      .then((res) => {
        if (res.identificaciones) {
          setTipos_identificaciones(res.identificaciones);
        }
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    if (values.empresa) {
      const tipo = tipos_identificaciones.find(
        (t) => t.id_tipo_identificacion == 2
      );
      onChange("tipo_identificacion", tipo);
    }
  }, [values.empresa, tipos_identificaciones]);

  return (
    <Grid>
      <form>
        <Grid item mt={1}>
          <Grid container justifyContent={"center"}>
            {!edit && (
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    size="medium"
                    checked={values.empresa}
                    onChange={(e) => {
                      onChange("empresa", e.target.checked);
                    }}
                  />
                }
                label="Cliente con RUC"
                labelPlacement="start"
              />
            )}
          </Grid>
          {values.empresa ? (
            <Grid item mt={1}>
              <TextField
                label="Razon social"
                size="small"
                fullWidth
                value={values.razon_social_cliente}
                type="text"
                onChange={(e) =>
                  onChange("razon_social_cliente", e.target.value.toUpperCase())
                }
              />
            </Grid>
          ) : (
            <>
              <Grid item mt={1}>
                <TextField
                  label="Nombres"
                  size="small"
                  fullWidth
                  value={values.nombre_cliente}
                  type="text"
                  onChange={(e) =>
                    onChange("nombre_cliente", e.target.value.toUpperCase())
                  }
                />
              </Grid>
              <Grid item mt={1}>
                <TextField
                  label="Apellidos"
                  size="small"
                  fullWidth
                  value={values.apellido_cliente}
                  onChange={(e) =>
                    onChange(
                      "apellido_cliente",
                      e.target.value.toUpperCase()
                    )
                  }
                />
              </Grid>
            </>
          )}
          {tipos_identificaciones.length > 0 && !values.empresa && (
            <Grid item mt={1}>
              {edit ? (
                <Typography
                  textAlign={"center"}
                  fontWeight={"bold"}
                  marginY={1}
                >
                  {values.tipo_identificacion?.descripcion_tipo}
                </Typography>
              ) : (
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Tipo identificación
                  </InputLabel>
                  <Select
                    id="demo-simple-select"
                    value={values.tipo_identificacion?.descripcion_tipo}
                    label="Tipo identificación"
                    onChange={(e) => {
                      const tipo = tipos_identificaciones.find(
                        (t) => t.descripcion_tipo == e.target.value
                      );

                      onChange("tipo_identificacion", tipo);
                    }}
                  >
                    {tipos_identificaciones.map((tipo) => {
                      return (
                        <MenuItem
                          key={tipo.id_tipo_identificacion}
                          value={tipo.descripcion_tipo}
                          disabled={
                            tipo.descripcion_tipo == "RUC" && !values.empresa
                          }
                        >
                          {tipo.descripcion_tipo}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </Grid>
          )}
          <Grid item mt={1}>
            <TextField
              label={values.empresa ? "RUC empresa" : "Identificacion"}
              size="small"
              fullWidth
              value={values.numero_documento_cliente}
              onChange={(e) =>
                onChange("numero_documento_cliente", e.target.value)
              }
            />
          </Grid>
          <Grid container></Grid>

          <Grid item mt={1}>
            <TextField
              label="Telefono"
              size="small"
              fullWidth
              value={values.telefono_cliente}
              onChange={(e) => onChange("telefono_cliente", e.target.value)}
            />
          </Grid>
          <Grid item mt={1}>
            <TextField
              label="Email"
              size="small"
              fullWidth
              value={values.email_cliente}
              onChange={(e) => {
                if (e.target.value.includes(" ")) {
                  return;
                }
                onChange("email_cliente", e.target.value);
              }}
            />
            <IconButton
              color={"success"}
              onClick={() => addEmail(values.email_cliente)}
              sx={{
                position: "absolute",
              }}
            >
              <AddCircle />
            </IconButton>
          </Grid>
          <Grid item mt={1}>
            <ClienteEmailsForm emails={emails} removeEmail={removeEmail} />
          </Grid>
          <Grid item mt={1}>
            <TextField
              label="Dirección"
              size="small"
              fullWidth
              multiline
              maxRows={3}
              value={values.direccion_cliente}
              onChange={(e) => onChange("direccion_cliente", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Button variant="contained" color="error" onClick={cancelar}>
            Cancelar
          </Button>
          <Button variant="contained" onClick={crear}>
            Guardar
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};
