import { Alert, AlertTitle, Grid, Typography } from "@mui/material";
import React from "react";
import { isNullOrUndefined } from "../../helpers/functions/is-null-or-undefined";

export const ErroresXML = ({ errores }) => {
  return (
    <Grid container>
      {!isNullOrUndefined(errores?.recepcion) && (
        <Grid item>
          <ErrorXML title={"Recepción"} mensaje={errores.recepcion?.mensaje} />
        </Grid>
      )}
      {!isNullOrUndefined(errores?.autorizacion) && (
        <Grid item>
          <ErrorXML
            title={"Autorización"}
            mensaje={errores.autorizacion?.mensaje}
          />
        </Grid>
      )}
    </Grid>
  );
};

export const ErrorXML = ({ title, mensaje }) => {

  return (
    <Alert severity="error">
      <AlertTitle>{title}</AlertTitle>
      {Array.isArray(mensaje) &&
        mensaje.map(({ mensaje, identificador, informacionAdicional }) => {
          return (
            <Typography>{`${mensaje} (${identificador}) : ${informacionAdicional}`}</Typography>
          );
        })}
      {!Array.isArray(mensaje) && (
        <Typography>{`${mensaje?.mensaje} (${mensaje?.identificador}) : ${mensaje?.informacionAdicional}`}</Typography>
      )}
    </Alert>
  );
};
