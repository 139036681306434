import API from "./ApiConfig"

const URL_NOTA_CREDITO = '/notaCredito';

export const notaCreditoIdRequest = async (data) => {
    const request = await API.post(URL_NOTA_CREDITO + '/nota_credito_id', data);
    const response = request.data;
    return response;
}

export const datosNotaCreditoRequest = async (data) => {
    const request = await API.post(URL_NOTA_CREDITO + '/datos_nota_credito_id', data);
    const response = request.data;
    return response;
}

export const busquedaDocumentoCreditoRequest = async (data) => {
    const request = await API.post(URL_NOTA_CREDITO + '/busqueda_documento_credito', data);
    const response = request.data;
    return response;
}

export const disponibilidadRequest = async (data) => {
    const request = await API.post(URL_NOTA_CREDITO + '/disponibilidad_documento', data);
    const response = request.data;
    return response;
}

export const disponibilidadEditarRequest = async (data) => {
    const request = await API.post(URL_NOTA_CREDITO + '/disponibilidad_editar_documento', data);
    const response = request.data;
    return response;
}

export const listaNotasCreditoRequest = async (data) => {
    const request = await API.post(URL_NOTA_CREDITO + '/listar_notas_credito_inicial', data);
    const response = request.data;
    return response;
}

export const buscarNotasCreditoRequest = async (data) => {
    const request = await API.post(URL_NOTA_CREDITO + '/buscar_notas_credito', data);
    const response = request.data;
    return response;
}

export const registrarNotaCreditoRequest = async (data) => {
    const request = await API.post(URL_NOTA_CREDITO + '/registrar_nota_credito', data);
    const response = request.data;
    return response;
}

export const editarNotaCreditoRequest = async (data) => {
    const request = await API.post(URL_NOTA_CREDITO + '/editar_nota_credito', data);
    const response = request.data;
    return response;
}


export const listaFacturasRequest = async (data) => {
    const request = await API.post(URL_NOTA_CREDITO + '/lista_facturas', data);
    const response = request.data;
    return response;
}

export const buscarFacturasRequest = async (data) => {
    const request = await API.post(URL_NOTA_CREDITO + '/buscar_facturas', data);
    const response = request.data;
    return response;
}

