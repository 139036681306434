import React, { createContext, useContext, useEffect, useState } from "react";
import {
  authStorageGet,
  authStorageRemove,
  authStorageSave,
} from "../helpers/AuthStorage";
import { validarPlanUsuarioRequest } from "../services/api/PlanesApi";
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Snackbar,
  Typography,
} from "@mui/material";
import { AuthContext } from "./AuthContext";
import { ModalLayout } from "../layouts/ModalLayout";
import { Close, StyleOutlined } from "@mui/icons-material";

export const PlanContext = createContext();

export const PlanProvider = ({ children }) => {
  const { user, authState } = useContext(AuthContext);

  const [datosPlan, setDatosPlan] = useState({});
  const [validando, setValidando] = useState(false);

  const [init, setInit] = useState(true);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (
      user.usuario &&
      !user.usuario.admin_registro &&
      authState == "authenticated"
    ) {
      validarPlan();
    } else {
      setDatosPlan({});
    }
  }, [user, authState]);

  const openModal = () => setModal(true);
  const closeModal = () => setModal(false);

  const validarPlan = async () => {
    let valido = true;
    const dataRequest = {
      id_usuario: user.usuario?.id_usuario,
    };
    setValidando(true);
    await validarPlanUsuarioRequest(dataRequest)
      .then((res) => {
        if (res.result && res.validacion) {
          if (res.validacion.necesita_renovar) {
            if (!init) openModal();
            valido = false;
          }
          setDatosPlan(res.validacion);
        }
      })
      .finally(() => {
        setInit(false);
        setValidando(false);
      });

    return valido;
  };

  return (
    <PlanContext.Provider
      value={{
        datosPlan,
        validarPlan,
      }}
    >
      {children}
      {datosPlan.tiene_plan && (
        <>
          {/* Alert bottom */}
          <Snackbar open={datosPlan.necesita_renovar} autoHideDuration={5000}>
            <Alert severity="error" sx={{ width: "100%" }}>
              {
                "Necesita renovar el plan, contactese con el proveedor del sistema"
              }
            </Alert>
          </Snackbar>
          {/* Modal Loading Verify */}
          <Modal open={validando} onClose={() => setValidando(false)}>
            <Box
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "rgba(0,0,0, 0.01)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="secondary" size={25} />
              <Typography ml={2} color={"white"}>
                Verificando datos de plan...
              </Typography>
            </Box>
          </Modal>
          {/* Modal Renovar*/}
          <Modal open={modal} onClose={closeModal}>
            <ModalLayout>
              <Grid>
                <Grid>
                  <IconButton onClick={closeModal}>
                    <Close />
                  </IconButton>
                </Grid>
                <Grid
                  container
                  style={{
                    minHeight: 250,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography fontSize={20} textAlign={'center'} color={'error'}>
                    Necesita renovar el plan, contactese con el proveedor del
                    sistema.
                  </Typography>
                  <StyleOutlined
                    style={{
                      marginTop: 20,
                      height: 80,
                      width: 80,
                      color: 'rgba(0,0,0,0.4)'
                    }}
                  />
                </Grid>
              </Grid>
            </ModalLayout>
          </Modal>
        </>
      )}
    </PlanContext.Provider>
  );
};
