import { Add } from "@mui/icons-material";
import {
  Alert,
  CircularProgress,
  Divider,
  Fab,
  Grid,
  Snackbar,
  Box,
} from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import { PageSearch } from "../../../global/components/PageSearch";
import { AppLayout } from "../../../layouts/AppLayout";
import { TablaTransportista } from "./components/TablaTransportista";
import { ModalTransportista } from "./components/ModalTransportista";
import { AuthContext } from "../../../context/AuthContext";
import {
  productosBusquedaRequest,
  productosRequest,
} from "../../../services/api/ProductosApi";
import { AccionesContext } from "../../../context/AccionesContext";
import { transportistasRequest } from "../../../services/api/TransportistasApi";

export const TransportistasPage = () => {
  const { establecimiento } = useContext(AuthContext);
  const { recargarTransportistas } = useContext(AccionesContext);
  const [modalCreate, setModalCreate] = useState(false);
  const [transportistas, setTransportistas] = useState([]);
  const [transportistasSearch, setTransportistasSearch] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const [toast, setToast] = useState({
    open: false,
    text: "",
  });

  useEffect(() => {
    setTransportistas([]);
    setLoading(true);
    const dataRequest = {
      id_establecimiento: establecimiento.id_establecimiento,
    };
    transportistasRequest(dataRequest)
      .then((res) => {
        if (res.transportistas) {
          setTransportistas(res.transportistas);
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        setLoading(false);
      });
  }, [establecimiento, recargarTransportistas]);

  useEffect(() => {
    if (search.length > 1) {
      setLoading(true);
      const dataRequest = {
        search_producto: search,
        id_establecimiento: establecimiento.id_establecimiento,
      };
      productosBusquedaRequest(dataRequest)
        .then((res) => {
          if (res.productos) {
            setTransportistasSearch(res.productos);
          }
        })
        .catch((err) => {
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [search, establecimiento]);

  const openModal = () => setModalCreate(true);
  const closeModal = () => setModalCreate(false);

  const onChangeSearch = (text) => {
    setSearch(text);
  };

  const closeToast = () => {
    setToast({
      open: false,
      text: "",
    });
  };

  const openToast = (text = "") => {
    setToast({
      open: true,
      text: text,
    });
  };

  return (
    <AppLayout>
      <Grid>
        {/* Header */}
        <PageSearch
          title="Transportistas"
          searchText="Buscar Transportista"
          textButtton="Crear Transportista"
          actionButton={openModal}
          onChangeSearch={onChangeSearch}
          search={search}
        />
        <Divider />
        {/* Body */}
        <Grid
          sx={{
            minHeight: 100,
          }}
        >
          {search.length > 1 ? (
            <TablaTransportista data={transportistasSearch} />
          ) : (
            <TablaTransportista data={transportistas} />
          )}
          {loading && <CircularProgress size={25} />}
          <Snackbar
            open={toast.open}
            autoHideDuration={6000}
            onClose={closeToast}
          >
            <Alert
              onClose={closeToast}
              severity="success"
              sx={{ width: "100%" }}
            >
              {toast.text}
            </Alert>
          </Snackbar>
        </Grid>
        <Box
          sx={{
            position: "absolute",
            bottom: 10,
            right: 10,
            display: {
              xs: "block",
              sm: "none",
            },
          }}
        >
          <Fab onClick={openModal} color="primary" aria-label="add">
            <Add />
          </Fab>
        </Box>
        <ModalTransportista
          modal={modalCreate}
          close={closeModal}
          openToast={openToast}
        />
      </Grid>
    </AppLayout>
  );
};
