import { AppBar, Box, Grid, Toolbar } from "@mui/material";
import React, { useState } from "react";
import Navbar from "./components/Navbar";
import { Sidebar } from "./components/Sidebar";

const drawerWidth = 250;

export const AppLayout = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Nabvar */}
      <Navbar drawerWidth={drawerWidth} openDrawer={openDrawer} />
      {/* Sidevar */}
      <Sidebar
        drawerWidth={drawerWidth}
        drawerOpen={drawerOpen}
        closeDrawer={closeDrawer}
      />
      {/* Page */}
      <Box component="main" sx={{ minHeight: '100vh', flexGrow: 1, p: { sm: 1, md: 1, }, pt: { xs: 0.5 }}}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};
