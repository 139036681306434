import { Box, Typography } from '@mui/material'
import React from 'react'
import { Cliente } from './Cliente'

export const Clientes = ({ clientes = [], seleccionarCliente}) => {
  return (
    <Box
        mt={1}
        sx={{
            maxHeight: 500,
            overflow: 'auto'
          }}
    >
        {
            clientes.map(cliente => {
                return(
                    <Cliente cliente={cliente} seleccionarCliente={seleccionarCliente}/>
                )
            })
        }
    </Box>
  )
}
