import { Home } from "@mui/icons-material";
import React, { useContext } from "react";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { AdminRoutes } from "./app/AdminRoutes";
import { UsuarioRoutes } from "./app/UsuarioRoutes";
import { AuthRoute } from "./auth/AuthRoute";

export const RoutesApp = () => {
  const { authState, user } = useContext(AuthContext);

  return (
    <BrowserRouter>
      <Routes>
        {authState === "not-authenticated" ? (
          <Route path="/auth/*" element={<AuthRoute />} />
        ) : (
          <>
            {user?.usuario?.admin_registro && (
              <Route path="/*" element={<AdminRoutes />} />
            )}
            {!user?.usuario?.admin_registro && (
              <Route path="/*" element={<UsuarioRoutes />} />
            )}
          </>
        )}
        <Route path="/*" element={<Navigate to="/auth/login" />} />
      </Routes>
    </BrowserRouter>
  );
};
