import {
  AssessmentOutlined,
  DescriptionOutlined,
  DifferenceOutlined,
  FormatListBulleted,
  InsertDriveFileOutlined,
  LocalShippingOutlined,
  ManageAccountsOutlined,
  PeopleOutline,
  PostAdd,
  ShoppingCartOutlined,
  StyleOutlined,
  WidgetsOutlined,
} from "@mui/icons-material";
import { Grid, Typography, Box } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { AppLayout } from "../../../layouts/AppLayout";
import { PlanContext } from "../../../context/PlanContext";

export const InicioPage = () => {
  const { user } = useContext(AuthContext);
  const { validarPlan } = useContext(PlanContext);
  const navigate = useNavigate();

  const tools_usuario = [
    {
      id: 0,
      label: "Crear Factura",
      icon: (
        <PostAdd
          color="secondary"
          sx={{
            height: "30px",
            width: "30px",
          }}
        />
      ),
      action: async () => {
        const valido = await validarPlan();
        if (!valido) return;

        navigate("/facturas/crearFactura");
      },
    },
    {
      id: 1,
      label: "Facturas",
      icon: (
        <DescriptionOutlined
          color="secondary"
          sx={{
            height: "30px",
            width: "30px",
          }}
        />
      ),
      action: () => {
        navigate("/facturas");
      },
    },

    {
      id: 2,
      label: "Productos",
      icon: (
        <WidgetsOutlined
          color="secondary"
          sx={{
            height: "30px",
            width: "30px",
          }}
        />
      ),
      action: () => {
        navigate("/productos");
      },
    },
    {
      id: 3,
      label: "Clientes",
      icon: (
        <PeopleOutline
          color="secondary"
          sx={{
            height: "30px",
            width: "30px",
          }}
        />
      ),
      action: () => {
        navigate("/clientes");
      },
    },
    {
      id: 4,
      label: "Notas de Crédito",
      icon: (
        <DifferenceOutlined
          color="secondary"
          sx={{
            height: "30px",
            width: "30px",
          }}
        />
      ),
      action: () => {
        navigate("/creditos");
      },
    },
    {
      id: 5,
      label: "Guías de remisión",
      icon: (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <InsertDriveFileOutlined
            color="secondary"
            sx={{
              height: "35px",
              width: "35px",
            }}
          />
          <LocalShippingOutlined
            color="secondary"
            style={{
              position: "absolute",
              marginTop: 6,
              width: 14,
              height: 14,
            }}
          />
        </span>
      ),
      action: () => {
        navigate("/guias");
      },
    },
    {
      id: 6,
      label: "Compras",
      icon: (
        <ShoppingCartOutlined
          color="secondary"
          sx={{
            height: "30px",
            width: "30px",
          }}
        />
      ),
      action: () => {
        navigate("/compras");
      },
    },
    {
      id: 7,
      label: "Reportes",
      icon: (
        <AssessmentOutlined
          color="secondary"
          sx={{
            height: "30px",
            width: "30px",
          }}
        />
      ),
      action: () => {
        navigate("/reportes");
      },
    },
  ];

  const tools_admin = [
    {
      id: 0,
      label: "Usuarios",
      icon: (
        <ManageAccountsOutlined
          color="secondary"
          sx={{
            height: "30px",
            width: "30px",
          }}
        />
      ),
      action: () => {
        navigate("/usuarios");
      },
    },
    {
      id: 1,
      label: "Planes",
      icon: (
        <StyleOutlined
          color="secondary"
          sx={{
            height: "30px",
            width: "30px",
          }}
        />
      ),
      action: () => {
        navigate("/planes");
      },
    },
    {
      id: 2,
      label: "Registros Planes",
      icon: (
        <FormatListBulleted
          color="secondary"
          sx={{
            height: "30px",
            width: "30px",
          }}
        />
      ),
      action: () => {
        navigate("/registro_planes");
      },
    },
  ];

  return (
    <AppLayout>
      <Grid container flexDirection={"row"}>
        {/* Bienvenido */}
        <Grid
          container
          sx={{
            // backgroundColor: 'red',
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{ mt: { xs: 2 }, textAlign: { xs: "center", sm: "justify" } }}
          >
            Bienvenido {user.usuario?.nombre_comercial_emisor}!
          </Typography>
        </Grid>
        {/* Secciones */}
        <Grid
          container
          sx={{
            pt: 1,
            mt: 1,
            justifyContent: "center",
          }}
        >
          {(user.usuario.admin_registro ? tools_admin : tools_usuario).map(
            (tool) => {
              return (
                <Grid key={tool.id} item xs={6} sm={4} md={3}>
                  <Box
                    variant={"div"}
                    onClick={tool.action}
                    className={"card-shadow"}
                    sx={{
                      backgroundColor: "primary.main",
                      borderRadius: 3,
                      margin: 1,
                      height: 100,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 1,
                    }}
                  >
                    {tool.icon}
                    <Typography
                      mt={1}
                      sx={{
                        color: "#fff",
                      }}
                    >
                      {tool.label}
                    </Typography>
                  </Box>
                </Grid>
              );
            }
          )}
        </Grid>
        {/* Accesos directos */}
        <Grid></Grid>
      </Grid>
    </AppLayout>
  );
};
