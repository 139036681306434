import API from "./ApiConfig"

const URL_USUARIO = '/usuario';

export const usuariosRequest = async (data) => {
    const request = await API.post(URL_USUARIO + '/usuarios', data);
    const response = request.data;
    return response;
}

export const busquedaUsuariosRequest = async (data) => {
    const request = await API.post(URL_USUARIO + '/busqueda_usuarios', data);
    const response = request.data;
    return response;
}


export const usuarioIdRequest = async (data) => {
    const request = await API.post(URL_USUARIO + '/usuario_id', data);
    const response = request.data;
    return response;
}


export const crearUsuarioRequest = async (data) => {
    const request = await API.post(URL_USUARIO + '/crear_usuario', data, { headers: { 'Content-Type': 'multipart/form-data' } });
    const response = request.data;
    return response;
}


export const usuarioUpdateRequest = async (data) => {
    const request = await API.post(URL_USUARIO + '/usuario_update', data);
    const response = request.data;
    return response;
}

export const emisorUpdateRequest = async (data) => {
    const request = await API.post(URL_USUARIO + '/emisor_update', data);
    const response = request.data;
    return response;
}

export const firmaUpdateRequest = async (data) => {
    const request = await API.post(URL_USUARIO + '/firma_update', data, { headers: { 'Content-Type': 'multipart/form-data' } });
    const response = request.data;
    return response;
}

export const establecimientoUpdateValidRequest = async (data) => {
    const request = await API.post(URL_USUARIO + '/establecimiento_update_valid', data, { headers: { 'Content-Type': 'multipart/form-data' } });
    const response = request.data;
    return response;
}


export const nuevoEstablecimientoRequest = async (data) => {
    const request = await API.post(URL_USUARIO + '/nuevo_establecimiento', data, { headers: { 'Content-Type': 'multipart/form-data' } });
    const response = request.data;
    return response;
}

export const establecimientoUpdateRequest = async (data) => {
    const request = await API.post(URL_USUARIO + '/establecimiento_update', data, { headers: { 'Content-Type': 'multipart/form-data' } });
    const response = request.data;
    return response;
}

export const establecimientoDeleteRequest = async (data) => {
    const request = await API.post(URL_USUARIO + '/establecimiento_delete', data);
    const response = request.data;
    return response;
}