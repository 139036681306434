import {
  CheckBox,
  CheckBoxOutlineBlank,
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
  Description,
  DescriptionRounded,
  Email,
  EmailRounded,
  MoreVertOutlined,
  PictureAsPdf,
  RemoveCircle,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  TableCell,
  TableRow,
  Link,
  IconButton,
  Snackbar,
  Alert,
  Tooltip,
  Typography,
  Grid,
  InputBase,
  styled,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import dayjs from "dayjs";
import fileDownload from "js-file-download";
import jsPDF from "jspdf";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ESTADO_COBRO,
  ESTADO_COBRO_MAP,
  ESTADO_DOCUMENTO,
} from "../../../../configs/configs";
import { AccionesContext } from "../../../../context/AccionesContext";
import {
  anularDocumentoRequest,
  autorizarDocumentoRequest,
  cambiarEstadoCobroRequest,
  descargarDocumentoPDFRequest,
  enviarCorreoRequest,
  generarDocumentoRequest,
  generarDocumentoXMLRequest,
} from "../../../../services/api/DocumentoApi";
import { AnularAlerta } from "./AnularAlerta";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    // backgroundColor: theme.palette.background.paper,
    // border: '1px solid #ced4da',
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // borderRadius: 4,
      // borderColor: '#80bdff',
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

export const TableRowFactura = ({
  row,
  columns,
  isSelected,
  selectedList,
  index,
  handleClickRow,
}) => {
  const navigate = useNavigate();

  const { accionFacturas } = useContext(AccionesContext);

  const [autorizando, setAutorizando] = useState(false);
  const [enviandoEmail, setEnviandoEmail] = useState(false);
  const [anulado, setAnulando] = useState(false);
  const [estadoCobro, setEstadoCobro] = useState(false);

  const [estadoCobroSelect, setEstadoCobroSelect] = useState(row.estado_cobro);

  const [openAlert, setOpenAlert] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [openAnulando, setOpenAnulando] = React.useState(false);
  const [toast, setToast] = useState({
    open: false,
    text: "",
  });

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  //ANULACION LOADIN
  const handleClickAnulando = () => {
    setOpenAnulando(true);
  };

  const handleCloseAnulando = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAnulando(false);
  };

  //ALERT
  const handleClickOpen = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleGenerarPDF = (id_doc, num_doc) => {

    generarDocumentoRequest({ id_documento: id_doc })
      .then((resp) => {

        const doc = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: "A4",

        });

        doc.html(
          resp.html_string,
          {
            async callback(doc) {
              doc.save("pdf_name_" + num_doc);
            },
            autoPaging: "text",
            // margin: [4, 0, 10, 0],
            // html2canvas: {
            //     scale: 0.51
            // } 
          },
        );

      })
      .catch((error) => {
      });
      
  };

  const handleGenerarXML = (id_doc) => {
    generarDocumentoXMLRequest({ id_documento: id_doc })
      .then((resp) => {

        const anchorTag = document.createElement("a");
        anchorTag.href = resp;
        anchorTag.download = "FacturaXML.xml";
        anchorTag.click();
      })
      .catch((error) => {
      });
  };

  const descargarXML = (id, num_doc) => {
    generarDocumentoXMLRequest({ id_documento: id })
      .then((res) => {
        if (res.result === false) {
          alert("No existe el documento !");
          return;
        }
        fileDownload(res, `FACTURA ${num_doc}.xml`);
      })
      .catch((err) => {
        alert("Ocurrio un error al descargar el documento !");
      });
  };

  const descargarPDF = (id, num_doc) => {
    descargarDocumentoPDFRequest({ id_documento: id })
      .then((res) => {
        if (res.result === false) {
          alert("No existe el documento !");
          return;
        }
        fileDownload(res, `FACTURA ${num_doc}.pdf`);
      })
      .catch((err) => {
        alert("Ocurrio un error al descargar el documento !");
      });
  };

  const autorizar = (id) => {
    setAutorizando(true);
    autorizarDocumentoRequest({ id_documento: id }).finally(() => {
      setAutorizando(false);
      accionFacturas();
    });
  };

  const enviarEmail = (id) => {
    handleClick();
    setEnviandoEmail(true);
    enviarCorreoRequest({ id_documento: id })
      .then((res) => {
        if (res.result) {
          openToast("Correo enviado correctamente !");
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        setEnviandoEmail(false);
      }); 
  };

  const anularDocumento = (id) => {
    handleCloseAlert();
    handleClickAnulando(handleClickAnulando);
    setAnulando(true);
    anularDocumentoRequest({ id_documento: id })
      .then((res) => {
        if (res.result) {
        } else {
          alert(res.message);
        }
        accionFacturas();
      })
      .catch((err) => {
      })
      .finally(() => {
        setAnulando(false);
        handleCloseAnulando();
      });
  };

  const cambiarEstadoCobroDocumento = (value, id) => {
    const estado = Number(value);

    const dataRequest = {
      id_documento: id,
      estado_cobro: estado,
    };

    cambiarEstadoCobroRequest(dataRequest)
      .then((res) => {})
      .catch((error) => {})
      .finally(() => {
      });
  };

  const closeToast = () => {
    setToast({
      open: false,
      text: "",
    });
  };

  const openToast = (text = "") => {
    setToast({
      open: true,
      text: text,
    });
  };

  const handleChangeSelect = (e, id) => {
    const v = e.target.value;
    setEstadoCobroSelect(v);
    cambiarEstadoCobroDocumento(v, id, row.id_documento);
  };

  const isItemSelected = isSelected(row.id_documento);

  const labelId = `enhanced-table-checkbox-${index}`;

  return (
    <TableRow
      hover
      role="checkbox"
      // component={'div'}
      tabIndex={-1}
      key={row.numero_documento}
      selected={isItemSelected}
      onClick={(event) => {
        if (!selectedList.show) {
          return;
        }
        handleClickRow(event, row.id_documento);
      }}
    >
      {selectedList.show && (
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </TableCell>
      )}

      {columns.map((column) => {
        const value = row[column.id];

        if (column.id === "estado_cobro") {
          return (
            <TableCell key={column.id} align={column.align} component={"div"}>
              <Grid container flexDirection={"row"} alignItems={"center"}>
                {/* SELECT */}
                <FormControl fullWidth>
                  <Select
                    sx={{
                      height: 35,
                      minWidth: "100%",
                      textAlign: "center",
                    }}
                    input={<BootstrapInput />}
                    size="small"
                    value={estadoCobroSelect}
                    onChange={(e) => handleChangeSelect(e, row.id_documento)}
                  >
                    {ESTADO_COBRO_MAP.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                {/* <IconButton
                    size={'small'}
                    color={value?'primary':'default'}
                    onClick={() => cambiarEstadoCobroDocumento(!value, row.id_documento, row.numero_documento)}
                  >
                    {value === 0 && <CheckBoxOutlineBlankOutlined />}
                    {value === 1 && <CheckBox />}
                  </IconButton>
                  <Typography fontSize={12}>{ESTADO_COBRO[value]}</Typography> */}
              </Grid>
            </TableCell>
          );
        }

        if (column.id === "estado_documento_electronico") {
          return (
            <TableCell key={column.id} align={column.align} component={"div"}>
              {ESTADO_DOCUMENTO[value] === "EN PROCESO" ||
              ESTADO_DOCUMENTO[value] === "CREADO" ||
              ESTADO_DOCUMENTO[value] === "NO AUTORIZADO" ||
              ESTADO_DOCUMENTO[value] === "RECHAZADO" ||
              ESTADO_DOCUMENTO[value] === "DEVUELTO" ? (
                <Button
                  variant={"contained"}
                  disabled={autorizando}
                  sx={{
                    height: ESTADO_DOCUMENTO[value] == "CREADO" ? 28 : 45,
                    backgroundColor: "#00a23e",
                    fontSize: ESTADO_DOCUMENTO[value] == "CREADO" ? 14 : 12,
                  }}
                  onClick={() => {
                    if (value == 2) {
                      const confirm = window.confirm(
                        "Su documento fue DEVUELTO por el SRI, asegúrese de revisar los datos antes de volver a enviar."
                      );
                      if (!confirm) {
                        return;
                      }
                    }
                    if (value == 4) {
                      const confirm = window.confirm(
                        "El SRI devolvió el estado EN PROCESO, esperar un par de horas para volver enviar, si ya espero un tiempo prudente darle en Aceptar."
                      );
                      if (!confirm) {
                        return;
                      }
                    }
                    autorizar(row.id_documento);
                  }}
                >
                  {autorizando ? (
                    <CircularProgress size={20} color={"secondary"} />
                  ) : ESTADO_DOCUMENTO[value] == "CREADO" ? (
                    "AUTORIZAR"
                  ) : (
                    `AUTORIZAR - ${ESTADO_DOCUMENTO[value]}`
                  )}
                </Button>
              ) : (
                ESTADO_DOCUMENTO[value]
              )}
            </TableCell>
          );
        }

        if (column.id == "numero_documento") {
          return (
            <TableCell
              component={"div"}
              onClick={() => navigate(`/facturas/${row.id_documento}`)}
              key={column.id}
              align={column.align}
            >
              <Link>{value}</Link>
            </TableCell>
          );
        }

        if (column.id == "fecha_emision_documento") {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              component={"div"}
              onClick={() => navigate(`/facturas/${row.id_documento}`)}
            >
              {dayjs(new Date(value)).format("YYYY-MM-DD")}
            </TableCell>
          );
        }

        if (column.id == "valor_total") {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              component={"div"}
              onClick={() => navigate(`/facturas/${row.id_documento}`)}
            >
              {Number(value).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) ?? ""}
            </TableCell>
          );
        }

        if (column.id == "PDF") {
          return (
            <TableCell key={column.id} align={column.align}>
              <Tooltip
                title={`Descargar ${row.numero_documento}.pdf`}
                placement={"top"}
              >
                <IconButton
                  onClick={() => {
                    // handleGenerarPDF(row.id_documento, row.numero_documento);
                    descargarPDF(row.id_documento, row.numero_documento);
                  }}
                  color={"primary"}
                >
                  <PictureAsPdf />
                </IconButton>
              </Tooltip>
            </TableCell>
          );
        }

        if (column.id == "XML") {
          return (
            <TableCell key={column.id} align={column.align}>
              <Tooltip
                title={`Descargar ${row.numero_documento}.xml`}
                placement="top"
              >
                <IconButton
                  color="primary"
                  disabled={
                    !(
                      ESTADO_DOCUMENTO[row.estado_documento_electronico] ==
                      "AUTORIZADO"
                    )
                  }
                  onClick={() =>
                    descargarXML(row.id_documento, row.numero_documento)
                  }
                >
                  <DescriptionRounded />
                </IconButton>
              </Tooltip>
            </TableCell>
          );
        }
        if (column.id == "ANULAR") {
          return (
            <TableCell key={column.id} align={column.align}>
              <Tooltip placement="top" title={"Anular documento"}>
                <IconButton
                  color="error"
                  disabled={row.estado_documento_electronico == 0}
                  onClick={() => handleClickOpen()}
                >
                  <RemoveCircle />
                </IconButton>
              </Tooltip>
            </TableCell>
          );
        }

        if (column.id == "opciones") {
          return (
            <TableCell
              key={column.id}
              sx={{
                width: "5px",
              }}
            >
              {enviandoEmail ? (
                <CircularProgress size={20} color={"primary"} />
              ) : (
                <Tooltip
                  title={`Reenviar a ${row.cliente?.emails_contacto
                    ?.replaceAll("[", "")
                    .replaceAll("]", "")
                    .replaceAll(/['"]+/g, "")
                    .replaceAll(",", ", ")}`}
                  placement={"top"}
                >
                  <IconButton
                    color="primary"
                    disabled={
                      !(
                        ESTADO_DOCUMENTO[row.estado_documento_electronico] ==
                        "AUTORIZADO"
                      )
                    }
                    onClick={() => enviarEmail(row.id_documento)}
                  >
                    <EmailRounded />
                  </IconButton>
                </Tooltip>
              )}
            </TableCell>
          );
        }

        return (
          <TableCell
            key={column.id}
            align={column.align}
            component={"div"}
            onClick={() => navigate(`/facturas/${row.id_documento}`)}
          >
            {column.format && typeof value === "number"
              ? column.format(value)
              : value}
          </TableCell>
        );
      })}
      {/* LOADING */}
      <Snackbar
        TransitionComponent={Slide}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          everity="info"
          variant="standard"
          color="info"
          icon={false}
          sx={{ width: "100%", pr: 15 }}
        >
          <CircularProgress size={18} sx={{ mr: 2 }} />
          Enviando correo...
        </Alert>
      </Snackbar>
      {/* LOADING ANULADO*/}
      <Snackbar
        TransitionComponent={Slide}
        open={openAnulando}
        autoHideDuration={6000}
        onClose={handleCloseAnulando}
      >
        <Alert
          everity="info"
          variant="standard"
          color="info"
          icon={false}
          sx={{ width: "100%", pr: 15 }}
        >
          <CircularProgress size={18} sx={{ mr: 2 }} />
          Anulando documento...
        </Alert>
      </Snackbar>

      {/* TOAST */}
      <Snackbar open={toast.open} autoHideDuration={6000} onClose={closeToast}>
        <Alert onClose={closeToast} severity="success" sx={{ width: "100%" }}>
          {toast.text}
        </Alert>
      </Snackbar>
      {/* ALERTA ANULACION */}
      <AnularAlerta
        open={openAlert}
        handleClose={handleCloseAlert}
        anular={anularDocumento}
        documento={row}
      />
    </TableRow>
  );
};
