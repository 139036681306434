import { Box, Typography } from '@mui/material'
import React from 'react'
import { Cliente } from './Cliente'
import { Producto } from './Producto'

export const Productos = ({ productos = [], seleccionarProducto}) => {
  return (
    <Box
        mt={1}
        sx={{
            maxHeight: 500,
            overflow: 'auto'
          }}
    >
        {
            productos.map(producto => {
                return(
                    <Producto key={producto.id_producto} producto={producto} seleccionarProducto={seleccionarProducto}/>
                )
            })
        }
    </Box>
  )
}
