import { Edit } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import UsuarioModal from "./UsuarioModal";

const UsuarioInfo = ({ usuario, reload = () => {} }) => {
  const [modal, setModal] = useState(false);

  const handleOpen = () => setModal(true);
  const handleClose = () => setModal(false);

  
  return (
    <Grid>
      <Grid
        height={40}
        flexDirection={"row"}
        display={"flex"}
        alignItems={"center"}
        position={"relative"}
      >
        <Typography fontWeight={"bold"} color={"darkgrey"}>
          USUARIO
        </Typography>
        {/* Acciones */}
        <Grid position={"absolute"} right={0}>
          <Tooltip placement={"left-start"} title={"Editar datos de usuario"}>
            <IconButton onClick={handleOpen} color="info">
              <Edit style={{ height: 20, width: 20 }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container flexDirection={"row"} justifyContent={"space-between"}>
        <Typography fontWeight={"bold"}>Email</Typography>
        <Typography>{usuario.email_usuario}</Typography>
      </Grid>
      <Grid
        container
        flexDirection={"row"}
        justifyContent={"space-between"}
        mt={1}
      >
        <Typography fontWeight={"bold"}>Usuario</Typography>
        <Typography>{usuario.nombre_usuario}</Typography>
      </Grid>
      <Grid
        container
        flexDirection={"row"}
        justifyContent={"space-between"}
        mt={1}
      >
        <Typography fontWeight={"bold"}>Contraseña</Typography>
        <Typography>********</Typography>
      </Grid>
      <Grid
        container
        flexDirection={"row"}
        justifyContent={"space-between"}
        mt={1}
      >
        <Typography fontWeight={"bold"}>Fecha registro</Typography>
        {usuario.fecha_creacion.length > 0 && (
          <Typography>
            {dayjs(usuario.fecha_creacion)
              .subtract(5, "hour")
              .format("YYYY-MM-DD HH:mm:ss")}
          </Typography>
        )}
      </Grid>

      {/* MODAL EDIT */}
      {usuario.id_usuario && (
        <UsuarioModal
          usuario={usuario}
          visible={modal}
          close={handleClose}
          reload={reload}
        />
      )}
    </Grid>
  );
};

export default UsuarioInfo;
