import { Edit, StyleOutlined } from "@mui/icons-material";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import UsuarioModal from "./UsuarioModal";
import { Planes } from "../../../../global/components/Planes";
import { registrarPlanRequest } from "../../../../services/api/PlanesApi";
import { useNavigate } from "react-router-dom";

const PlanInfo = ({ id_usuario, plan, reload = () => {} }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const registrarPlan = (id_plan) => {
    const dataRequest = {
      id_plan: id_plan,
      id_usuario: id_usuario,
    };
    setLoading(true);
    registrarPlanRequest(dataRequest)
      .then((resp) => {
        if (resp.result) {
          reload();
        }
      })
      .catch()
      .finally(() => {
        setLoading(false);
      });
    return true;
  };

  return (
    <Grid>
      <Grid
        minHeight={50}
        flexDirection={"row"}
        display={"flex"}
        alignItems={"center"}
        position={"relative"}
      >
        <Typography fontWeight={"bold"} color={"darkgrey"}>
          PLAN
        </Typography>
        {/* Acciones */}
        <Grid position={"absolute"} right={0}>
          {plan.id_plan != null ? (
            <Box
              onClick={() => {
                navigate(`../../registro_planes/${id_usuario}`);
              }}
              component={"div"}
              style={{
                minHeight: 50,
                width: 270,
                borderRadius: 10,
                backgroundColor: "#1b37aa",
                boxShadow: "3px 5px 7px rgba(40, 83, 255, 0.4)",
                cursor: "pointer",
                padding: 10,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <Grid>
                <Typography color={"white"} fontSize={12}>
                  {plan.registro_nombre_plan}
                </Typography>
                <Typography color={"white"} fontSize={10}>
                  <b>Nº documentos: </b>{" "}
                  {plan.cantidad_documentos ?? "ILIMITADO"}
                </Typography>
                <Typography color={"white"} fontSize={8}>
                  <b>Meses habilitados: </b> {plan.meses_habilitados}
                </Typography>
              </Grid>
              <Typography color={"white"} fontSize={14}>
                $ {plan.precio}
              </Typography>
              <StyleOutlined
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  height: 25,
                  width: 25,
                  color: "rgba(255,255,255, 0.2)",
                }}
              />
            </Box>
          ) : (
            !loading && (
              <Planes onPlanSelect={(plan) => registrarPlan(plan.id_plan)} />
            )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PlanInfo;
