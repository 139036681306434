import API from "./ApiConfig"

const URL_REPORTE = '/reporte';

export const documentosListaRequest = async (data) => {
    const request = await API.post(URL_REPORTE + '/documentos_lista', data );
    const response = request.data;
    return response;
}

export const filtroDocumentosRequest = async (data) => {
    const request = await API.post(URL_REPORTE + '/filtro_documentos_lista', data );
    const response = request.data;
    return response;
}


export const calculoTotalesDocumentos = async (data) => {
    const request = await API.post(URL_REPORTE + '/totales_documentos', data );
    const response = request.data;
    return response;
}

export const generarReportePDFRequest = async (data) => {
    const request = await API.post(URL_REPORTE + '/generar_reporte_pdf', data, { responseType: 'arraybuffer'} );
    const response = request.data;
    return response;
}

export const generarReporteXLSXRequest = async (data) => {
    const request = await API.post(URL_REPORTE + '/generar_reporte_xlsx', data, { responseType: 'arraybuffer'} );
    const response = request.data;
    return response;
}

//COMPRAS

export const comprasListaRequest = async (data) => {
    const request = await API.post(URL_REPORTE + '/compras_lista', data );
    const response = request.data;
    return response;
}

export const generarReporteComprasPDFRequest = async (data) => {
    const request = await API.post(URL_REPORTE + '/generar_reporte_compras_pdf', data, { responseType: 'arraybuffer'} );
    const response = request.data;
    return response;
}

export const generarReporteComprasXLSXRequest = async (data) => {
    const request = await API.post(URL_REPORTE + '/generar_reporte_compras_xlsx', data, { responseType: 'arraybuffer'} );
    const response = request.data;
    return response;
}