import {
  TableCell,
  TableRow,
  Typography,
  Grid,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import React from "react";
import { FormatoNumero } from "../../../../helpers/FormatNumber";

export const TotalesReporte = ({ option = 1, columns, totales }) => {
  return (
    <Grid xs={12}>
      <Grid>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                if (column.id == "estado_cobro") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography fontWeight={"bold"}></Typography>
                    </TableCell>
                  );
                }
                if (column.id == "numero_documento") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography fontWeight={"bold"}>
                        Total de documentos
                      </Typography>
                    </TableCell>
                  );
                }

                if (
                  [
                    "estado_documento_electronico",
                    "num_identificacion_cliente",
                    "razon_social_cliente",
                    "fecha_emision_documento",
                  ].includes(column.id)
                ) {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography width={"100%"}></Typography>
                    </TableCell>
                  );
                }

                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography fontWeight={"bold"}>{column.label}</Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* POR COBRAR */}
            <TableRow>
              {columns.map((column) => {
                if (column.id == "estado_cobro") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography>POR COBRAR</Typography>
                    </TableCell>
                  );
                }
                if (column.id == "numero_documento") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography>
                        {totales.documentos_0?.numero_documentos}
                      </Typography>
                    </TableCell>
                  );
                }

                if (
                  [
                    "estado_documento_electronico",
                    "num_identificacion_cliente",
                    "razon_social_cliente",
                    "fecha_emision_documento",
                  ].includes(column.id)
                ) {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography width={"100%"}></Typography>
                    </TableCell>
                  );
                }

                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography>
                      {FormatoNumero(totales.documentos_0[column.id])}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
            {/* PAGADOS */}
            <TableRow>
              {columns.map((column) => {
                if (column.id == "estado_cobro") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography>PAGADOS</Typography>
                    </TableCell>
                  );
                }
                if (column.id == "numero_documento") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography>
                        {totales.documentos_1?.numero_documentos}
                      </Typography>
                    </TableCell>
                  );
                }

                if (
                  [
                    "estado_documento_electronico",
                    "num_identificacion_cliente",
                    "razon_social_cliente",
                    "fecha_emision_documento",
                  ].includes(column.id)
                ) {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography width={"100%"}></Typography>
                    </TableCell>
                  );
                }

                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography>
                      {FormatoNumero(totales.documentos_1[column.id])}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
            {/* TOTAL */}
            <TableRow>
              {columns.map((column) => {
                if (column.id == "estado_cobro") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography>TODOS</Typography>
                    </TableCell>
                  );
                }
                if (column.id == "numero_documento") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography>
                        {totales.documentos_total?.numero_documentos}
                      </Typography>
                    </TableCell>
                  );
                }

                if (
                  [
                    "estado_documento_electronico",
                    "num_identificacion_cliente",
                    "razon_social_cliente",
                    "fecha_emision_documento",
                  ].includes(column.id)
                ) {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography width={"100%"}></Typography>
                    </TableCell>
                  );
                }

                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography>
                      {FormatoNumero(totales.documentos_total[column.id])}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};
