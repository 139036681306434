import { CloudUpload } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import React, { useRef } from "react";

import "./drop-file-input.css";

export const SeleccionDocumentos = ({ fileList, setFileList, fileRemove }) => {
  
  const wrapperRef = useRef(null);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const newFiles = e.target.files;
    if (newFiles.length > 0) {
      const updatedList = [...fileList, ...newFiles];
      setFileList(updatedList);
    }
  };

  return (
    <Grid
      component={"div"}
      className={"drop-file-input"}
      ref={wrapperRef}
      sx={{
        minHeight: "35vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        borderStyle: "dashed",
        borderColor: "darkgray",
        borderRadius: 5,
        borderWidth: 2,
      }}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <Grid display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <CloudUpload
          color="inherit"
          sx={{ height: 50, width: 50, color: "darkgray" }}
        />
        <Typography fontWeight={"bold"} fontSize={18} color="darkgray">
          Arrastre o seleccione los documentos
        </Typography>

        <Typography fontSize={16} color="darkgray">
          Documentos seleccionados ({fileList.length})
        </Typography>
      </Grid>
      <input
        className="input"
        type="file"
        value=""
        onChange={onFileDrop}
        multiple
      />
    </Grid>
  );
};
