import { Alert, CircularProgress, Grid, Modal, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AccionesContext } from "../../../../context/AccionesContext";
import { AuthContext } from "../../../../context/AuthContext";
import { useForm } from "../../../../hooks/useForm";
import { ModalLayout } from "../../../../layouts/ModalLayout";
import { identificacionesRequest } from "../../../../services/api/ClientesApi";
import { editarTransportistaRequest } from "../../../../services/api/TransportistasApi";
import { FormTransportista } from "./FormTransportista";

export const ModalEditarTransportista = ({ modal, close, transportista, openToast, reload = () => {} }) => {

  const { establecimiento } = useContext(AuthContext);
  const { accionTransportistas } = useContext(AccionesContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    text: "",
    show: false,
  });

  const [values, onChange, reset, initValues] = useForm({
    razon_social_transportista: transportista.razon_social_transportista,
    identificacion_transportista: transportista.identificacion_transportista,
    tipo_identificacion_transportista: transportista.tipo_identificacion_transportista,
    placa_transportista: transportista.placa_transportista,
    id_tipo_identificacion: transportista.id_tipo_identificacion 
  });

  const [tipos_identificaciones, setTipos_identificaciones] = useState([]);

  useEffect(() => {
    identificacionesRequest()
      .then((res) => {
        if (res.identificaciones) {
          setTipos_identificaciones(res.identificaciones);
        }
      })
      .catch((err) => {
      })
      .finally(() => {});
  }, []);


  const guardar = () => {

    setError({
      text: '',
      show: false,
    })

    //Validaciones
    if (
      values.razon_social_transportista?.length == 0 ||
      values.identificacion_transportista?.length == 0 ||
      values.tipo_identificacion_transportista?.length == 0 ||
      values.placa_transportista?.length == 0
    ) {
      setError({
        text: "Ingrese todos los datos",
        show: true,
      });
      return;
    }

    setLoading(true);
    
    const dataRequest = {
      id_transportista: transportista.id_transportista,
      razon_social_transportista: values.razon_social_transportista,
      identificacion_transportista: values.identificacion_transportista,
      tipo_identificacion_transportista:
        values.tipo_identificacion_transportista,
      placa_transportista: values.placa_transportista,
      id_tipo_identificacion: tipos_identificaciones.map(
        (t) =>
          t.codigo_identificacion == values.tipo_identificacion_transportista
      )?.id_tipo_identificacion,
    };

    editarTransportistaRequest(dataRequest)
      .then( res => {
        if(res.result){
          close();
          reset();
          accionTransportistas();
          reload();
          openToast('Transportista actualizado correctamente !');
        }
      })
      .catch( error => {
        setError({
          text: 'Ocurrio un error',
          show: true,
        })
      })
      .finally(() => {
        setLoading(false)
      })

  };

  return (
    <Modal
      open={modal}
      onClose={() => {
        close();
        reset();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout>
        <Grid container alignItems={"center"} flexDirection={"column"}>
          {/* Header */}
          <Grid
            sx={{
              height: 40,
            }}
            alignItems={"center"}
          >
            <Typography
              sx={{
                fontWeight: "600",
              }}
            >
              Editar producto
            </Typography>
          </Grid>
          {/* Form */}
          <Grid>
            <FormTransportista 
              values={values}
              tipos_identificaciones={tipos_identificaciones}
              onChange={onChange}
              cancelar={() => {
                reset();
                close();
              }}
              crear={guardar}
            />
          </Grid>
          {error.show && <Alert severity="error">{error.text}</Alert>}
          {loading && <CircularProgress size={25} />}

        </Grid>
      
      </ModalLayout>
    </Modal>
  );
};
