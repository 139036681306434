import { AccountCircle } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import React from "react";

export const FormTransportista = ({
  values,
  onChange,
  cancelar,
  crear,
  tipos_identificaciones,
}) => {
  return (
    <Grid>
      <form>
        <Grid item mt={1}>
          <TextField
            label="Nombre Transportisa"
            size="small"
            fullWidth
            value={values.razon_social_transportista}
            onChange={(e) =>
              onChange("razon_social_transportista", e.target.value)
            }
          />
        </Grid>
        <Grid item mt={1}>
          <FormControl size="small">
            <InputLabel id="demo-simple-select-label">
              Tipo identficación
            </InputLabel>
            <Select
              id="demo-simple-select"
              value={values.tipo_identificacion_transportista}
              label="Tipo identificación"
              sx={{
                width: 230,
              }}
              size="small"
              onChange={(e) => {
                onChange(
                  "tipo_identificacion_transportista",
                  e.target.value
                );
              }}
            >
              {tipos_identificaciones.map((tipo) => {
                return (
                  <MenuItem
                    key={tipo.id_tipo_identificacion}
                    value={tipo.codigo_identificacion}
                    disabled={tipo.codigo_identificacion === "07"}
                  >
                    {tipo.descripcion_tipo}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item mt={1}>
          <TextField
            label="Identificacón"
            size="small"
            fullWidth
            value={values.identificacion_transportista}
            onChange={(e) =>
              onChange("identificacion_transportista", e.target.value)
            }
            
          />
        </Grid>
        <Grid item mt={1}>
          <TextField
            label="Placa"
            size="small"
            fullWidth
            value={values.placa_transportista}
            onChange={(e) => onChange("placa_transportista", e.target.value?.toUpperCase())}
          />
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Button
            variant="contained"
            color="error"
            onClick={cancelar}
            sx={{ marginX: 0.5 }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={crear}
            mx={0.5}
            sx={{ marginX: 0.5 }}
          >
            Guardar
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};
