import {
  Alert,
  CircularProgress,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { AccionesContext } from "../../../../context/AccionesContext";
import { AuthContext } from "../../../../context/AuthContext";
import { useForm } from "../../../../hooks/useForm";
import { ModalLayout } from "../../../../layouts/ModalLayout";
import { FormPlan } from "./FormPlan";
import { crearPlanRequest } from "../../../../services/api/PlanesApi";

export const ModalPlan = ({ modal, close, openToast }) => {

  const { user } = useContext(AuthContext);
  const { accionPlanes } = useContext(AccionesContext);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState({
    text: "",
    show: false,
  });

  const [values, onChange, reset, initValues, setValues] = useForm({
    nombre_plan: "",
    id_tipo_plan: "",
    cantidad_documentos: "",
    precio: "",
    meses_habilitados: 12
  });

  const crear = () => {
    setError({
      text: "",
      show: false,
    });

    //Validaciones
    if (
      values.nombre_plan.trim().length == 0 ||
      values.id_tipo_plan == "" ||
      (values.id_tipo_plan == 1 &&
        values.cantidad_documentos.trim().length == 0) ||
        values.meses_habilitados == '' ||
        values.precio.trim().length == 0
    ) {
      setError({
        text: "Complete los campos solicitados",
        show: true,
      });
      return;
    }

    const dataRequest = {
      id_usuario_admin: user.usuario?.id_usuario,
      nombre_plan: values.nombre_plan,
      id_tipo_plan: values.id_tipo_plan,
      cantidad_documentos: values.cantidad_documentos,
      meses_habilitados: values.meses_habilitados,
      precio: values.precio,
    };


    setLoading(true);
    crearPlanRequest(dataRequest)
      .then((res) => {
        if (res.result) {
          close();
          reset();
          accionPlanes();
          openToast("Plan creado correctamente !");
        } else {
          setError({
            text: res.message,
            show: true,
          });
        }
      })
      .catch((error) => {
        setError({
          text: "Ocurrio un error",
          show: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      open={modal}
      onClose={() => {
        reset();
        close();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout>
        <Grid container alignItems={"center"} flexDirection={"column"}>
          {/* Header */}
          <Grid
            sx={{
              height: 40,
            }}
            alignItems={"center"}
          >
            <Typography
              sx={{
                fontWeight: "600",
              }}
            >
              Nuevo plan
            </Typography>
          </Grid>
          {/* Form */}
          <FormPlan
            values={values}
            setValues={setValues}
            onChange={onChange}
            crear={crear}
            loading={loading}
            cancelar={() => {
              reset();
              close();
            }}
          />
          {error.show && (
            <Alert mt={1} severity="error">
              {error.text}
            </Alert>
          )}
          {loading && <CircularProgress size={25} />}
        </Grid>
      </ModalLayout>
    </Modal>
  );
};
