import * as React from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {
  autorizarDocumentoRequest,
  cambiarEstadoCobroRequest,
  generarDocumentoRequest,
  generarDocumentoXMLRequest,
} from "../../../../services/api/DocumentoApi";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import {
  alpha,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  Link,
  MenuItem,
  Select,
  styled,
  TableFooter,
  Toolbar,
  Typography,
} from "@mui/material";
import fileDownload from "js-file-download";
import { AccionesContext } from "../../../../context/AccionesContext";
import { useState } from "react";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { CheckBoxOutlineBlank } from "@mui/icons-material";
import { ESTADO_COBRO, ESTADO_COBRO_MAP } from "../../../../configs/configs";
import { useContext } from "react";
import { ReportesRow } from "./ReportesRow";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    // backgroundColor: theme.palette.background.paper,
    // border: '1px solid #ced4da',
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // borderRadius: 4,
      // borderColor: '#80bdff',
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const columns = [
  {
    id: "estado_cobro",
    label: "E cobro",
    minWidth: 100,
  },
  {
    id: "estado_documento_electronico",
    label: "Estado documento",
    minWidth: 100,
  },
  {
    id: "numero_documento",
    label: "Documento Nº  ",
    minWidth: 100,
  },

  {
    id: "num_identificacion_cliente",
    label: "Identificación cliente",
    minWidth: 60,
  },

  {
    id: "razon_social_cliente",
    label: "Cliente",
    minWidth: 80,
  },

  {
    id: "fecha_emision_documento",
    label: "Fecha emision",
    minWidth: 80,
  },
  {
    id: "total_sin_impuestos",
    label: "Subtotal",
    minWidth: 20,
    align: "right",
  },
  {
    id: "total_descuento",
    label: "Descuento",
    minWidth: 10,
    align: "right",
  },
  {
    id: "total_base_imponible_cero",
    label: "Subtotal 0",
    minWidth: 30,
    align: "right",
  },
  {
    id: "total_base_imponible_doce",
    label: "Subtotal 12",
    minWidth: 30,
    align: "right",
  },
  {
    id: "porcentaje_iva",
    label: "IVA",
    minWidth: 20,
    align: "right",
  },
  {
    id: "valor_total",
    label: "Total",
    minWidth: 20,
    align: "right",
  },
];

export const TablaReportes = ({
  data = [],
  numberDefault = 100,
  navigateActive = true,
  vhValue = 70,
  reporte = false,
}) => {
  const { accionFacturas } = useContext(AccionesContext);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(numberDefault);

  const [selectedList, setSelectedList] = useState({
    show: false,
    id: "",
  });

  const [estadoCobroSelect, setEstadoCobroSelect] = useState("");

  const [selected, setSelected] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleShowSelectedList = (value, id) => {
    const event = {
      target: {
        checked: value,
      },
    };
    handleSelectAllClick(event);
    setSelectedList({
      show: value,
      id: value ? id : "",
    });
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.id_documento);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClickRow = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeSelect = (e, id) => {
    const v = e.target.value;
    const confirm = window.confirm(
      `Esta seguro de modificar el estado de ${selected.length} documentos a ${ESTADO_COBRO[v]}`
    );
    if (!confirm) {
      return;
    }

    const dataRequest = {
      array: true,
      ids_documentos: selected,
      estado_cobro: v,
    };

    cambiarEstadoCobroRequest(dataRequest)
      .then((res) => {})
      .catch((error) => {})
      .finally(() => {
        accionFacturas();
        setSelectedList({
          show: false,
          id: "",
        });
        setSelected([]);
      });
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {selected.length > 0 && (
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(selected.length > 0 && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity
                ),
            }),
          }}
        >
          <Grid container xs={3}>
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {selected.length} documentos seleccionados
            </Typography>
          </Grid>

          {selectedList.id == "estado_cobro" && (
            <Grid
              container
              sx={{
                alignItems: "center",
                justifyContent: {
                  xs: "flex-start",
                  md: "flex-end",
                },
              }}
            >
              <Grid mx={1}>
                <Typography fontSize={16} fontWeight={"bold"}>
                  ESTADO COBRO
                </Typography>
              </Grid>
              <Grid
                sx={{
                  width: 150,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Select
                  sx={{
                    height: 35,
                    width: "100%",
                    textAlign: "center",
                  }}
                  input={<BootstrapInput />}
                  size="small"
                  displayEmpty
                  value={estadoCobroSelect}
                  onChange={handleChangeSelect}
                >
                  <MenuItem key={"121"} value={""}>
                    SELECCIONE
                  </MenuItem>
                  {ESTADO_COBRO_MAP.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
          )}
        </Toolbar>
      )}
      <TableContainer sx={{ maxHeight: `${vhValue}vh` }}>
        <Table 
          stickyHeader
         aria-label="sticky table" size={"small"}
         >
          <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography>{column.label}</Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
          </TableHead>

          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <ReportesRow
                    handleClickRow={handleClickRow}
                    key={row.id_documento}
                    index={index}
                    row={row}
                    columns={columns}
                    isSelected={isSelected}
                    selectedList={selectedList}
                    navigateActive={navigateActive}
                  />
                );
              })}
          
          </TableBody>

        </Table>
      </TableContainer>
      
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

    </Paper>
  );
};
