import { Add, AddCircle } from "@mui/icons-material";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { tiposPlanRequest } from "../../../../services/api/PlanesApi";

export const FormPlan = ({
  values,
  onChange,
  cancelar,
  crear,
  loading,
  setValues,
  edit = false,
  editar = (i) => {},
}) => {
  const [tiposPlan, setTiposPlan] = useState([]);

  useEffect(() => {
    tiposPlanRequest({}).then((res) => {
      if (res.result && res.tipos) {
        setTiposPlan(res.tipos);
      }
    });
  }, []);

  return (
    <Grid>
      <form>
        <Grid item mt={1}>
          <Grid
            container
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item mt={1}>
              <TextField
                label="Nombre de plan"
                size="small"
                fullWidth
                value={values.nombre_plan}
                onChange={(e) => onChange("nombre_plan", e.target.value)}
              />
            </Grid>
            <Grid item mt={1}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  Tipo de plan
                </InputLabel>
                <Select
                  disabled={edit}
                  style={{
                    width: 230,
                  }}
                  id="demo-simple-select"
                  value={values.id_tipo_plan}
                  label="Tipo identificación"
                  onChange={(e) => {
                    onChange("id_tipo_plan", e.target.value);
                  }}
                >
                  {tiposPlan.map((tipo) => {
                    return (
                      <MenuItem
                        key={tipo.id_tipo_plan}
                        value={tipo.id_tipo_plan}
                      >
                        {tipo.nombre_tipo_plan}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            {values.id_tipo_plan == 1 && (
              <Grid item mt={1}>
                <TextField
                  label="Cantidad de documentos"
                  size="small"
                  fullWidth
                  value={values.cantidad_documentos}
                  onChange={(e) =>
                    onChange("cantidad_documentos", e.target.value)
                  }
                />
              </Grid>
            )}

            <Grid item mt={1}>
              <TextField
                label="Meses habilitados"
                size="small"
                fullWidth
                value={values.meses_habilitados}
                type="number"
                onChange={(e) => onChange("meses_habilitados", e.target.value)}
              />
            </Grid>
            <Grid item mt={1}>
              <TextField
                label="Precio"
                size="small"
                fullWidth
                value={values.precio}
                type="number"
                onChange={(e) => onChange("precio", e.target.value)}
              />
            </Grid>
            {!loading && (
              <Grid
                container
                flexDirection={"row"}
                justifyContent={"space-between"}
                mt={2}
              >
                <Button
                  disabled={loading}
                  variant="contained"
                  color="error"
                  onClick={cancelar}
                >
                  Cancelar
                </Button>
                <Button disabled={loading} variant="contained" onClick={crear}>
                  Guardar
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};
