import { Box, CircularProgress, Modal, Typography } from '@mui/material';
import React from 'react';

export const LoadingLayout = ({
    isLoading = false,
    text = "",
}) => {

    if (!isLoading) return;

    return (
        <Modal open={isLoading} >
        <Box
            style={{
                height: "100%",
                width: "100%",
                backgroundColor: "rgba(0,0,0, 0.01)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute"
            }}
        >
            <CircularProgress color="secondary" size={25} />
            <Typography ml={2} color={"white"}>
                { text }...
            </Typography>
        </Box>
        </Modal>
    )
}



