import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AccionesContext } from "../../../context/AccionesContext";
import { AuthContext } from "../../../context/AuthContext";

import { AppLayout } from "../../../layouts/AppLayout";
import {
  crearClienteRequest,
  identificacionesRequest,
} from "../../../services/api/ClientesApi";
import {
  calculosRequest,
  consultarDocumentoIDRequest,
  consultarFormasPagoRequest,
  crearDocumentoRequest,
  editarDocumentoRequest,
} from "../../../services/api/DocumentoApi";
import { CrearFacturaCliente } from "./components/CrearFacturaCliente";
import { CrearFacturaEmision } from "./components/CrearFacturaEmision";
import { CrearFacturaProductos } from "./components/CrearFacturaProductos";
import { CrearFacturaValores } from "./components/CrearFacturaValores";
import { emailRegexGlobal } from "../../../helpers/email";
import { ivasRequest } from "../../../services/api/IvasApi";

export const EditFacturaPage = () => {
  const { establecimiento, user } = useContext(AuthContext);
  const { accionClientes } = useContext(AccionesContext);

  const navigate = useNavigate();
  const { id } = useParams();

  const [tipos_identificaciones, setTipos_identificaciones] = useState([]);
  const [formasPago, setFormasPago] = useState([]);
  const [pago, setPago] = useState("");
  const [formaPago, setFormaPago] = useState("");
  const [codigoPago, setCodigoPago] = useState("");
  const [idFormaPago, setIdFormaPago] = useState("");
  const [observaciones, setObservaciones] = useState("");

  const handleFormaPago = (e, pago_find) => {
    setPago(pago_find);
    setFormaPago(pago_find.descripcion_forma_pago);
    setCodigoPago(pago_find.codigo_forma_pago);
    setIdFormaPago(pago_find.id_forma_pago);
  };

  const handleObservaciones = (e) => {
    setObservaciones(e);
  };

  const [loadingData, setLoadingData] = useState(false);

  const [emails, setEmails] = useState([]);

  const [loading, setLoading] = useState(false);

  const [fechaEmision, setFechaEmision] = useState("");

  const [cliente, setCliente] = useState({
    id_cliente: null,
    empresa: false,
    tipo_identificacion: {},
    nombre_cliente: "",
    apellido_cliente: "",
    email_cliente: "",
    telefono_cliente: "",
    numero_documento_cliente: "",
    direccion_cliente: "",
    razon_social_cliente: "",
    cliente_nuevo: true,
  });

  const [productosFactura, setProductosFactura] = useState([]);
  const [change, setChange] = useState(false);

  const [detalle_calculos, setDetalle_calculos] = useState([]);

  const [error, setError] = useState({
    text: "",
    show: false,
  });

  const [cabecera_calculos, setCabecera_calculos] = useState({
    impuesto: 0,
    subtotal_iva: 0,
    subtotal_cero: 0,
    subtotal: 0,
    value_iva: 0,
    total_factura: 0,
  });

  const [toast, setToast] = useState({
    open: false,
    text: "",
  });

  const [ivas, setIvas] = useState([]);

  //Tipos identificacion
  useEffect(() => {
    identificacionesRequest()
      .then((res) => {
        if (res.identificaciones) {
          setTipos_identificaciones(res.identificaciones);
        }
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  //Formas de Pago
  useEffect(() => {
    consultarFormasPagoRequest({})
      .then((res) => {
        if (res.pagos) {
          setFormasPago(res.pagos.rows);
        }
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  //Cargar datos para editar
  useEffect(() => {
    setError({
      text: "",
      show: false,
    });
    setLoadingData(true);
    const dataRequest = {
      id_documento: id,
    };
    consultarDocumentoIDRequest(dataRequest)
      .then((res) => {
        if (res.result) {
          //Cliente
          seleccionarCliente({
            ...res.cliente,
            ...res.cliente?.contactos[0],
          });

          //FECHA
          const fecha = res.cabecera.fecha_emision_documento;
          const date = new Date(fecha);
          const date_format = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
          onChangeFecha(date_format);

          //Productos
          const productos = res.detalles?.map((item) => {
            return {
              ...item,
              nombre_producto: item.descripcion,
              precio_unitario: item.valor_unitario,
            };
          });

          setObservaciones(res.cabecera?.observaciones_adicionales);

          const pagores = res.cabecera?.pagos?.[0];

          if (pagores) {
            const pago_find = formasPago.find(
              (p) => p.descripcion_forma_pago == pagores.descripcion_forma_pago
            );

            setPago(pago_find);
            setFormaPago(pagores.descripcion_forma_pago);
            setCodigoPago(pagores.codigo_forma_pago);
            setIdFormaPago(pagores.id_forma_pago);
          }

          setProductosFactura(productos);
          setChange(!change);
        } else {
          setError({
            text: res.message,
            show: true,
          });
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoadingData(false);
      });
  }, [formasPago]);

  //Calculos
  useEffect(() => {
    const pc = productosFactura.map((p) => {
      return {
        codigo_producto: p.condigo_producto,
        descripcion: p.nombre_producto,
        tiene_iva: p.precio_iva != 0 ? "1" : "0",
        cantidad: p.cantidad,
        valor_unitario: p.precio_unitario,
        precio_iva: p.precio_iva,
      };
    });

    const dataRequest = {
      productos: pc,
    };

    calculosRequest(dataRequest)
      .then((res) => {
        if (res.result && res.cabecera_calculos.length > 0) {
          setCabecera_calculos(res.cabecera_calculos[0]);
        }
        if (res.result && res.detalles_calculos.length > 0) {
          const productoCalculos = productosFactura.map((pf, index) => {
            const index1 = index + 1;

            const producto_calculo = res.detalles_calculos.find(
              (dc) => dc.indice == index1
            );

            return {
              ...pf,
              ...producto_calculo,
            };
          });
          setProductosFactura(productoCalculos);
        }
      })
      .catch((err) => {})
      .finally(() => {});
  }, [change]);

  useEffect(() => {
    ivasRequest().then((response) => {
      if (response.ivas && response.ivas?.length > 0) {
        setIvas(response.ivas);
      }
    });
  }, []);

  const onChangeFecha = (value) => {
    setFechaEmision(value);
  };

  //CLIENTE
  const onChangeCliente = (name, value) => {
    setCliente({
      ...cliente,
      [name]: value,
    });
  };

  const seleccionarCliente = (e) => {
    setEmails([]);

    const empresa = e.id_tipo_cliente == 2;

    setCliente({
      id_cliente: e.id_cliente,
      tipo_identificacion: {
        descripcion_tipo: tipos_identificaciones.find(
          (e) => e.id_tipo_identificacion
        )?.descripcion_tipo,
        id_tipo_identificacion: e.id_tipo_identificacion,
      },
      nombre_cliente: e.nombre_cliente,
      apellido_cliente: e.apellido_cliente,
      razon_social_cliente: e.razon_social_cliente,
      empresa: e.id_tipo_identificacion == 2,
      email_cliente: e.email_cliente,
      telefono_cliente: e.telefono_cliente,
      numero_documento_cliente: empresa
        ? e.numero_ruc_cliente
        : e.numero_documento_cliente,
      empresa: empresa,
      direccion_cliente: e.direccion_cliente,
      cliente_nuevo: false,
    });

    const emailsArr = JSON.parse(e.contactos[0].emails_contacto);

    const em = emailsArr.filter((ema, i) => i != 0);

    setEmails((e) => [...e, ...em]);
  };

  const quitarCliente = () => {
    setCliente({
      id_cliente: null,
      tipo_identificacion: {},
      nombre_cliente: "",
      apellido_cliente: "",
      email_cliente: "",
      telefono_cliente: "",
      numero_documento_cliente: "",
      direccion_cliente: "",
      cliente_nuevo: true,
    });
    setEmails([]);
  };

  const quitarProducto = (index) => {
    const pfd = productosFactura.filter((p, i) => i != index);
    setProductosFactura(pfd);
    setChange(!change);
    if (productosFactura.length === 1) {
      setCabecera_calculos({
        subtotal_iva: 0,
        subtotal_cero: 0,
        subtotal: 0,
        value_iva: 0,
        total_factura: 0,
      });
    }
  };

  const seleccionarProducto = (e) => {
    const p = productosFactura.find((p) => p.id_producto == e.id_producto);

    if (p?.id_producto) {
      const pff = productosFactura.filter(
        (p) => p.id_producto != e.id_producto
      );
      setProductosFactura([...pff, { ...p, cantidad: Number(p.cantidad) + 1 }]);
    } else {
      setProductosFactura((m) => [...m, { ...e, cantidad: 1 }]);
    }
    setChange(!change);
  };

  const onChangeCantidad = (index, cantidad) => {
    if (cantidad < 0) {
      return;
    }

    const pfc = productosFactura.map((pf, i) => {
      if (i == index) {
        return {
          ...pf,
          cantidad: cantidad,
        };
      }
      return pf;
    });
    setChange(!change);
    setProductosFactura(pfc);
  };

  const onChangePrecio = (index, precio) => {
    if (precio < 0) {
      return;
    }

    const pfc = productosFactura.map((pf, i) => {
      if (i == index) {
        return {
          ...pf,
          precio_unitario: precio,
        };
      }
      return pf;
    });
    setChange(!change);
    setProductosFactura(pfc);
  };

  const onChangeIva = (index, id_iva) => {


    const iva = ivas.find(iva => iva.id_iva === id_iva);

    const pfc = productosFactura.map((pf, i) => {
      if (i == index) {
        return {
          ...pf,
          precio_iva: iva.valor, 
          id_iva: id_iva,
        };
      }
      return pf;
    });
    setChange(!change);
    setProductosFactura(pfc);
  };


  const crearFactura = async () => {
    setError({
      text: "",
      show: false,
    });

    //Validar cliente
    if (
      (cliente.empresa
        ? cliente.razon_social_cliente.length === 0
        : cliente.nombre_cliente.length === 0) ||
      !cliente.tipo_identificacion.id_tipo_identificacion ||
      cliente.telefono_cliente.length === 0 ||
      cliente.numero_documento_cliente.length === 0 ||
      cliente.direccion_cliente.length === 0
    ) {
      setError({
        text: "Complete los campos del cliente solicitados",
        show: true,
      });
      return;
    }
    //Productos
    if (productosFactura.length === 0) {
      setError({
        text: "Seleccione almenos un producto",
        show: true,
      });
      return;
    }

    let valid = true;
    productosFactura.forEach((pf) => {
      if (pf.cantidad == 0) {
        valid = false;
      }
    });

    if (!valid) {
      setError({
        text: "Revise la cantidad de los prodcutos",
        show: true,
      });
      return;
    }

    //VALIDACION DE TOTAL CONSUMIDOR FINAL
    if (cliente.tipo_identificacion.id_tipo_identificacion == 4) {
      if (cabecera_calculos.total_factura > 50) {
        setError({
          text: "El consumidor final no puede superar los $50 en el total de la factura.",
          show: true,
        });
        return;
      }
    }

    const result = window.confirm(
      `La factura se creara con el código de establecimiento: ${establecimiento.codigo_establecimiento}`
    );

    if (!result) {
      return;
    }

    let id_cliente = cliente.id_cliente;

    //Validar emails
    const regex_email = emailRegexGlobal;
    if (emails.length === 0) {
      if (cliente.email_cliente.trim().length === 0) {
        setError({
          text: "Email requerido",
          show: true,
        });
        return;
      }
      if (!regex_email.test(cliente.email_cliente)) {
        setError({
          text: "Ingrese un correo válido.",
          show: true,
        });
        return;
      }
    } else if (cliente.email_cliente.length > 0) {
      if (!regex_email.test(cliente.email_cliente)) {
        setError({
          text: "Ingrese un correo válido.",
          show: true,
        });
        return;
      }
    }

    //VALIDAR EMISION
    if (!dayjs(fechaEmision).isValid()) {
      setError({
        text: "Ingrese una fecha valida",
        show: true,
      });
      return;
    }

    const em = cliente.email_cliente != "" ? [cliente.email_cliente] : [];

    const eArr = [...em, ...emails];

    if (cliente.cliente_nuevo) {
      const dataRequestCliente = {
        id_tipo_identificacion:
          cliente.tipo_identificacion.id_tipo_identificacion,
        id_establecimiento: establecimiento.id_establecimiento,
        nombre_cliente: cliente.nombre_cliente,
        apellido_cliente: cliente.apellido_cliente,
        email_cliente: cliente.email_cliente,
        telefono_cliente: cliente.telefono_cliente,
        numero_documento_cliente: cliente.numero_documento_cliente,
        direccion_cliente: cliente.direccion_cliente,
        id_tipo_cliente: cliente.empresa ? 2 : 1,
        razon_social_cliente: cliente.razon_social_cliente,
        contactos: [
          {
            email_cliente: cliente.email_cliente,
            telefono_cliente: cliente.telefono_cliente,
            direccion_cliente: cliente.direccion_cliente,
            emails_contacto: JSON.stringify(eArr),
          },
        ],
      };

      const valid = await crearClienteRequest(dataRequestCliente)
        .then((res) => {
          if (res.result) {
            id_cliente = res.id_cliente;

            accionClientes();
            openToast("Cliente creado correctamente !");
            return true;
          } else {
            setError({
              text: res.message,
              show: true,
            });
            return false;
          }
        })
        .catch((error) => {
          setError({
            text: "Ocurrio un error",
            show: true,
          });
          return false;
        })
        .finally(() => {
          setLoading(false);
        });

      if (!valid) {
        return;
      }
    }

    const establecimiento_matriz = user.establecimientos.find(
      (e) => e.estado_matriz_establecimiento == true
    );
    const punto_emision = user.puntos_emision.find(
      (p) => p.id_establecimiento == establecimiento.id_establecimiento
    );

    const razon_social_cliente = cliente.empresa
      ? cliente.razon_social_cliente
      : `${cliente.nombre_cliente} ${cliente.apellido_cliente}`;

    const codigo_identificacion = tipos_identificaciones.find(
      (ti) =>
        ti.id_tipo_identificacion ===
        cliente.tipo_identificacion.id_tipo_identificacion
    ).codigo_identificacion;

    const dataRequestDocumento = {
      id_documento: id,
      id_cliente: id_cliente,
      tipo_identificacion_cliente: codigo_identificacion,
      num_identificacion_cliente: cliente.numero_documento_cliente,
      razon_social_cliente: razon_social_cliente,
      total_sin_impuestos: cabecera_calculos.subtotal,
      subtotal_neto: cabecera_calculos.subtotal,
      total_base_imponible_cero: cabecera_calculos.subtotal_cero,
      total_base_imponible_doce: cabecera_calculos.subtotal_iva,
      valor_total_iva: cabecera_calculos.subtotal_iva,
      valor_total: cabecera_calculos.total_factura,
      porcentaje_iva: cabecera_calculos.value_iva,
      fecha_emision_documento: fechaEmision,
      emails_contacto: JSON.stringify(eArr),
      codigo_forma_pago: codigoPago,
      id_forma_pago: idFormaPago,
      observaciones_adicionales: observaciones,
      productos: productosFactura.map((pf) => {
        return {
          ...pf,
          descripcion: pf.nombre_producto,
          valor_unitario: pf.precio_unitario,
          tiene_iva: pf.precio_iva != 0 ? "1" : "0",
        };
      }),
    };

    setLoading(true);

    editarDocumentoRequest(dataRequestDocumento)
      .then((res) => {
        if (res.result) {
          openToast("Factura editada correctamente !");
          navigate(-1);
        } else {
          setError({
            text: res.message,
            show: true,
          });
        }
      })
      .catch((err) => {
        setError({
          text: "Ocurrio un error al crear el documento",
          show: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const closeToast = () => {
    setToast({
      open: false,
      text: "",
    });
  };

  const openToast = (text = "") => {
    setToast({
      open: true,
      text: text,
    });
  };

  //Agregar Email
  const addEmail = (t) => {
    const regex = emailRegexGlobal;
    if (!regex.test(t)) {
      setError({
        text: "Ingrese un correo válido.",
        show: true,
      });
      return;
    }
    setEmails((e) => [...e, t]);
    setCliente({
      ...cliente,
      email_cliente: "",
    });
  };

  //Remove Email
  const removeEmail = (index) => {
    const e = emails.filter((e, i) => i != index);
    setEmails(e);
  };

  const addAnexo = (index, anexo) => {
    if (anexo.trim().length <= 2) {
      alert("El anexo debe tener almenos 3 caracteres");
      return;
    }

    const pA = productosFactura[index];

    if (pA.anexos?.length >= 3) {
      alert("Solo se puede agregar 3 anexos por detalle");
      return;
    }

    const asInit = [
      ...(pA.anexos ?? []),

      {
        id_detalle_anexo: null,
        anexo: anexo,
      },
    ];

    const pfc = productosFactura.map((pf, i) => {
      if (i == index) {
        return {
          ...pf,
          anexos: asInit,
        };
      }
      return pf;
    });
    setChange(!change);
    setProductosFactura(pfc);
  };

  const removeAnexo = (index, iAnexo) => {
    const pA = productosFactura[index];

    const aChange = pA.anexos.filter((a, i) => i != iAnexo);

    const pfc = productosFactura.map((pf, i) => {
      if (i == index) {
        return {
          ...pf,
          anexos: aChange,
        };
      }
      return pf;
    });
    setChange(!change);
    setProductosFactura(pfc);
  };

  return (
    <AppLayout>
      <Grid
        container
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          // backgroundColor: 'red',
          p: 0.5,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "#d4d4ff",
          borderRadius: 3,
        }}
      >
        <Grid xs={12} sm={5} px={1}>
          {loadingData && <CircularProgress size={20} sx={{ marginLeft: 2 }} />}

          {/* Emision */}
          <Grid my={1}>
            <CrearFacturaEmision
              editar={true}
              fechaEmision={fechaEmision}
              onChangeFecha={onChangeFecha}
            />
          </Grid>
          <Divider />
          {/* Cliente */}
          <Grid my={1}>
            <CrearFacturaCliente
              tipos_identificaciones={tipos_identificaciones}
              values={cliente}
              onChange={onChangeCliente}
              seleccionarCliente={seleccionarCliente}
              quitarCliente={quitarCliente}
              emails={emails}
              addEmail={addEmail}
              removeEmail={removeEmail}
            />
          </Grid>
        </Grid>
        <Divider sx={{ display: { xs: "block", sm: "none" } }} />
        <Grid my={1} xs={12} sm={7} px={1}>
          {/*  Productos */}
          <Grid>
            <CrearFacturaProductos
              seleccionarProducto={seleccionarProducto}
              productosFactura={productosFactura}
              onChangeCantidad={onChangeCantidad}
              quitarProducto={quitarProducto}
              onChangePrecio={onChangePrecio}
              addAnexo={addAnexo}
              removeAnexo={removeAnexo}
              onChangeIva={onChangeIva}
              ivas={ivas}
            />
          </Grid>
          <Divider />
          {/* Valores */}
          <Grid my={1}>
            <CrearFacturaValores cabecera_calculos={cabecera_calculos} />
          </Grid>

          {/* Boton Formas de Pago*/}
          <Grid
            container
            mt={1}
            flexDirection={"row"}
            sx={{ marginTop: 0, justifyContent: "right" }}
          >
            <Typography
              fontWeight={"bold"}
              sx={{ marginRight: 8, marginTop: 1 }}
            >
              {" "}
              Forma de Pago{" "}
            </Typography>

            <Grid>
              <FormControl
                fullWidth
                size="small"
                sx={{
                  width: 230,
                }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ textAlign: "center" }}
                >
                  Forma de Pago
                </InputLabel>
                <Select
                  id="demo-simple-select"
                  value={idFormaPago}
                  label="Forma de Pago"
                  onChange={(e) => {
                    const pago_find = formasPago.find(
                      (p) => p.id_forma_pago == e.target.value
                    );

                    handleFormaPago(e, pago_find);
                  }}
                >
                  {formasPago.map((item) => {
                    return (
                      <MenuItem
                        key={item.id_forma_pago}
                        value={item.id_forma_pago}
                      >
                        {item.descripcion_forma_pago}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            mt={1}
            flexDirection={"row"}
            justifyContent={"right"}
            sx={{ marginRight: 20 }}
          >
            <Typography sx={{ fontWeight: "bold", marginRight: 26 }}>
              {" "}
              Observacion Adicional:{" "}
            </Typography>
          </Grid>

          <Grid container mt={1} flexDirection={"row"} justifyContent={"end"}>
            <TextareaAutosize
              aria-label="Observaciones Adicionales"
              placeholder="Escribe algo.."
              value={observaciones}
              style={{
                width: 400,
                height: 200,
                fontFamily: "sans-serif",
                fontSize: 14,
                padding: 10,
              }}
              onChange={(e) => {
                handleObservaciones(e.target.value);
              }}
            />
          </Grid>

          {!loading && (
            <Grid
              component={"div"}
              display={"flex"}
              justifyContent={"end"}
              mt={2}
            >
              <Button
                onClick={crearFactura}
                variant="contained"
                sx={{
                  borderRadius: 100,
                  px: 4,
                  backgroundColor: "#000590",
                }}
              >
                Guardar Factura
              </Button>
            </Grid>
          )}
        </Grid>
        {loading && <CircularProgress size={25} />}
        {error.show && <Alert severity="error">{error.text}</Alert>}
        <Snackbar
          open={toast.open}
          autoHideDuration={6000}
          onClose={closeToast}
        >
          <Alert onClose={closeToast} severity="success" sx={{ width: "100%" }}>
            {toast.text}
          </Alert>
        </Snackbar>
      </Grid>
    </AppLayout>
  );
};
