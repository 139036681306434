import {
  AddCircle,
  CloudQueueSharp,
  PersonRemove,
  Search,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { ClienteEmailsForm } from "../../clientes/components/ClienteEmailsForm";
import { ModalBuscarCliente } from "./ModalBuscarCliente";

export const CrearFacturaCliente = ({
  tipos_identificaciones,
  values,
  onChange,
  seleccionarCliente,
  quitarCliente,
  addEmail = (e) => {},
  removeEmail = (i) => {},
  emails = [],
}) => {
  const [modal, setModal] = useState(false);
  const [observaciones, setObservaciones] = useState("");

  const openModal = () => setModal(true);
  const closeModal = () => setModal(false);
  useEffect(() => {
    if (values.empresa) {
      const tipo = tipos_identificaciones.find(
        (t) => t.id_tipo_identificacion == 2
      );
      onChange("tipo_identificacion", tipo);
    }
  }, [values.empresa]);

  const handleObservaciones = (e) => {
    setObservaciones(e);
  };

  return (
    <Grid container flexDirection={"column"}>
      {/* TITULO */}
      <Grid
        container
        flexDirection={"row"}
        justifyContent={"space-between"}
        sx={{ alignItems: "center" }}
      >
        <Typography sx={{ fontWeight: "bold" }}>Datos Cliente</Typography>
        <Button
          variant="contained"
          onClick={openModal}
          endIcon={<Search color="secondary" />}
        >
          Buscar Cliente
        </Button>
      </Grid>
      {values.cliente_nuevo && (
        <Grid container justifyContent={"center"}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                size="medium"
                checked={values.empresa}
                onChange={(e) => {
                  onChange("empresa", e.target.checked);
                }}
              />
            }
            label="Cliente con RUC"
            labelPlacement="start"
          />
        </Grid>
      )}
      {values.cliente_nuevo ? (
        <>
          {values.empresa ? (
            <Grid
              container
              mt={1}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Typography fontWeight={"bold"}>Razon Social</Typography>
              <Grid item>
                <TextField
                  label="Razon social"
                  size="small"
                  fullWidth
                  value={values.razon_social_cliente}
                  onChange={(e) =>
                    onChange(
                      "razon_social_cliente",
                      e.target.value.toUpperCase()
                    )
                  }
                />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid
                container
                mt={2}
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Typography fontWeight={"bold"}>Nombre</Typography>

                <Grid>
                  {values.cliente_nuevo ? (
                    <TextField
                      label="Nombres"
                      size="small"
                      fullWidth
                      value={values.nombre_cliente}
                      sx={{
                        minWidth: 200,
                        maxWidth: 230,
                      }}
                      onChange={(e) =>
                        onChange("nombre_cliente", e.target.value.toUpperCase())
                      }
                    />
                  ) : (
                    <Typography>{values.nombre_cliente}</Typography>
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                mt={1}
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Typography fontWeight={"bold"}>Apellido</Typography>
                <Grid>
                  {values.cliente_nuevo ? (
                    <TextField
                      label="Apellidos"
                      size="small"
                      fullWidth
                      sx={{
                        minWidth: 200,
                        maxWidth: 230,
                      }}
                      value={values.apellido_cliente}
                      onChange={(e) =>
                        onChange("apellido_cliente", e.target.value.toUpperCase())
                      }
                    />
                  ) : (
                    <Typography>{values.apellido_cliente}</Typography>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </>
      ) : (
        <>
          <Grid
            container
            mt={2}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Typography fontWeight={"bold"}>Cliente</Typography>

            <Grid>
              {values.empresa ? (
                <Typography>{values.razon_social_cliente}</Typography>
              ) : (
                <Typography>
                  {values.nombre_cliente} {values.apellido_cliente}
                </Typography>
              )}
            </Grid>
          </Grid>
        </>
      )}
      {/* TIPO IDENTIFICACION */}
      {tipos_identificaciones.length > 0 &&
        !values.empresa &&
        values.cliente_nuevo && (
          <Grid
            container
            mt={1}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Typography fontWeight={"bold"}>Tipo identificación</Typography>
            <Grid>
              {values.cliente_nuevo ? (
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    width: 230,
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Tipo identificación
                  </InputLabel>
                  <Select
                    id="demo-simple-select"
                    value={values.tipo_identificacion.descripcion_tipo}
                    label="Tipo identificación"
                    onChange={(e) => {
                      const tipo = tipos_identificaciones.find(
                        (t) => t.descripcion_tipo == e.target.value
                      );

                      onChange("tipo_identificacion", tipo);
                    }}
                  >
                    {tipos_identificaciones.map((tipo) => {
                      return (
                        <MenuItem
                          key={tipo.id_tipo_identificacion}
                          value={tipo.descripcion_tipo}
                          disabled={
                            tipo.descripcion_tipo == "RUC" && !values.empresa
                          }
                        >
                          {tipo.descripcion_tipo}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : (
                <Typography>
                  {values.tipo_identificacion.descripcion_tipo}
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      {/* IDENTIFICACION */}
      <Grid
        container
        mt={1}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Typography fontWeight={"bold"}>
          {" "}
          {values.empresa ? "RUC empresa" : "Identificacion"}{" "}
        </Typography>
        <Grid>
          {values.cliente_nuevo ? (
            <TextField
              label={values.empresa ? "RUC empresa" : "Identificacion"}
              size="small"
              fullWidth
              sx={{
                minWidth: 200,
                maxWidth: 230,
              }}
              value={values.numero_documento_cliente}
              onChange={(e) =>
                onChange("numero_documento_cliente", e.target.value)
              }
            />
          ) : (
            <Typography>{values.numero_documento_cliente}</Typography>
          )}
        </Grid>
      </Grid>
      {/* TELEFONO  */}
      <Grid
        container
        mt={1}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Typography fontWeight={"bold"}>Telefono</Typography>
        <Grid>
          {/* {values.cliente_nuevo ? ( */}
          {true ? (
            <TextField
              label="Telefono"
              size="small"
              fullWidth
              sx={{
                minWidth: 200,
                maxWidth: 230,
              }}
              value={values.telefono_cliente}
              onChange={(e) => onChange("telefono_cliente", e.target.value)}
            />
          ) : (
            <Typography>{values.telefono_cliente}</Typography>
          )}
        </Grid>
      </Grid>
      {/* EMAIL  */}
      <Grid
        container
        mt={1}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Typography fontWeight={"bold"}>Email</Typography>
        <Grid
          sx={{
            minWidth: 200,
            maxWidth: 230,
            position: "relative",
          }}
        >
          {/* {values.cliente_nuevo ? ( */}

          <TextField
            label="Email"
            size="small"
            fullWidth
            value={values.email_cliente}
            sx={{
              minWidth: 200,
              maxWidth: 230,
            }}
            onChange={(e) => {
              if (e.target.value.includes(" ")) {
                return;
              }
              onChange("email_cliente", e.target.value);
            }}
          />
          <IconButton
            color={"success"}
            onClick={() => addEmail(values.email_cliente)}
            sx={{
              position: "absolute",
              right: -30,
              // backgroundColor: 'red'
            }}
          >
            <AddCircle />
          </IconButton>
          <Grid item mt={1}>
            <ClienteEmailsForm emails={emails} removeEmail={removeEmail} />
          </Grid>
        </Grid>
      </Grid>

      {/* DIRECCION  */}
      <Grid
        container
        mt={1}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Typography fontWeight={"bold"}>Dirección</Typography>
        <Grid>
          {/* {values.cliente_nuevo ? ( */}
          {true ? (
            <TextField
              label="Dirección"
              size="small"
              multiline
              maxRows={4}
              value={values.direccion_cliente}
              sx={{
                minWidth: 200,
                maxWidth: 230,
              }}
              onChange={(e) => onChange("direccion_cliente", e.target.value)}
              fullWidth
            />
          ) : (
            <Typography>{values.direccion_cliente}</Typography>
          )}
        </Grid>
      </Grid>

      {/* QUITAR CLIENTE */}
      <Grid container mt={1} flexDirection={"row"} justifyContent={"flex-end"}>
        <Button
          endIcon={<PersonRemove color="error" />}
          color={"error"}
          onClick={quitarCliente}
        >
          Quitar cliente
        </Button>
      </Grid>

      {/* MODAL CLIENTE */}
      <ModalBuscarCliente
        modal={modal}
        close={closeModal}
        seleccionarCliente={(e) => {
          seleccionarCliente(e);
          closeModal();
        }}
      />
    </Grid>
  );
};
