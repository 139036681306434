import API from "./ApiConfig"

const URL_DOCUMENTO = '/documento';


export const calculosRequest = async (data) => {
    const request = await API.post(URL_DOCUMENTO + '/calcular_valores', data, { timeout: 10000 });
    const response = request.data;
    return response;
}

export const crearDocumentoRequest = async (data) => {
    const request = await API.post(URL_DOCUMENTO + '/registrar_documento', data);
    const response = request.data;
    return response;
}

export const editarDocumentoRequest = async (data) => {
    const request = await API.post(URL_DOCUMENTO + '/editar_documento', data);
    const response = request.data;
    return response;
}


export const listaDocumentosRequest = async (data) => {
    const request = await API.post(URL_DOCUMENTO + '/consultar_documentos', data);
    const response = request.data;
    return response;
}


export const consultarDocumentoIDRequest = async (data) => {
    const request = await API.post(URL_DOCUMENTO + '/consultar_documento_id', data);
    const response = request.data;
    return response;
}

export const descargarDocumentoPDFRequest = async (data) => {
    const request = await API.post(URL_DOCUMENTO + '/descargar_documento_pdf', data, { responseType: 'arraybuffer' });
    const response = request.data;
    return response;
}

export const descargarFacturaRolloPDFRequest = async (data) => {
    const request = await API.post(URL_DOCUMENTO + '/descargar_factura_rollo_pdf', data, { responseType: 'blob' });
    const response = request.data;
    return response;
}

export const generarRideRequest = async (data) => {
    const request = await API.post(URL_DOCUMENTO + '/generar-ride', data, { responseType: 'blob' });
    const response = request.data;
    return response;
}

export const buscarDocumentoRequest = async (data) => {
    const request = await API.post(URL_DOCUMENTO + '/', data);
    const response = request.data;
    return response;
}

export const generarDocumentoRequest = async (data) => {
    const request = await API.post(URL_DOCUMENTO + '/generar_documento', data);
    const response = request.data;
    return response;
}

export const busquedaDocumentoRequest = async (data) => {
    const request = await API.post(URL_DOCUMENTO + '/busqueda_documento', data);
    const response = request.data;
    return response;
}

export const generarDocumentoXMLRequest = async (data) => {
    const response = await API.post(URL_DOCUMENTO + '/generar_documento_xml', data, {
        responseType: 'arraybuffer',
      });
      const decoder = new TextDecoder('utf-8');
      const decodedData = decoder.decode(response.data);
      return decodedData;
}

export const cambiarEstadoCobroRequest = async (data) => {
    const request = await API.post(URL_DOCUMENTO + '/cambiar_estado_cobro', data);
    const response = request.data;
    return response;
}

export const autorizarDocumentoRequest = async (data) => {
    const request = await API.post(URL_DOCUMENTO + '/autorizar_documento', data);
    const response = request.data;
    return response;
}

export const consultarFormasPagoRequest = async (data) => {
    const request = await API.post(URL_DOCUMENTO + '/consultar_formas_pago', data);
    const response = request.data;
    return response;
}

export const enviarCorreoRequest = async (data) => {
    const request = await API.post(URL_DOCUMENTO + '/enviar_email_documento', data);
    const response = request.data;
    return response;
}

export const anularDocumentoRequest = async (data) => {
    const request = await API.post(URL_DOCUMENTO + '/anular_factura', data);
    const response = request.data;
    return response;
}

export const erroresRecepcionAutorizacionRequest = async (data) => {
    const request = await API.post(URL_DOCUMENTO + '/errores_recepcion_autorizacion', data);
    const response = request.data;
    return response;
}