import { Divider, Grid, Typography } from "@mui/material";
import React from "react";

export const DocumentoNotaCredito = ({ documento }) => {

    const emails = documento?.cliente?.emails_contacto;

  return (
    <Grid
      container
      my={2}
      xs={12}
      sx={{
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: { sm: "space-between" },
        backgroundColor: 'rgba(0, 50, 212, 0.07)',
        px: 2,
        py: 1,
        borderRadius: 2
      }}
    >
      <Grid xs={12} sm={5.8}>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={1}
        >
          <Typography fontWeight={"bold"}>Documento modifica Nº.</Typography>
          <Typography>{documento.num_doc_modificado}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={1}
        >
          <Typography fontWeight={"bold"}>Fecha emision</Typography>
          <Typography>
            {documento.fecha_emision_doc_sustento?.substring(0, 10)}
          </Typography>
        </Grid>
       
      </Grid>
      <Grid xs={12} sm={5.8}>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={1}
        >
          <Typography fontWeight={"bold"}>Motivo</Typography>
          <Typography>{documento.motivo_nota_credito}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
