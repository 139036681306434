import {
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { tiposContribuyenteRequest } from "../../../../services/api/EstablecimientosApi";

export const EmisorForm = ({ data, setData }) => {

  const [tiposContribuyentes, setTiposContribuyentes] = useState([]);

  useEffect(() => {
    tiposContribuyenteRequest({})
      .then((res) => {
        if (res.contribuyente) {
          const t = res.contribuyente;
          setTiposContribuyentes(t);
        }
      })
      .finally(() => {});
  }, []);

  return (
    <Grid container flexDirection={"column"} alignItems={"center"}>
      <Grid>
        <Typography fontWeight={"bold"} color={"darkgrey"} my={1}>
          EMISOR
        </Typography>
      </Grid>
      <Grid>
        <TextField
          value={data.razon_social_emisor}
          onChange={(e) =>
            setData({
              ...data,
              razon_social_emisor: e.target.value.toUpperCase(),
            })
          }
          label={"Nombre / Razón social"}
          size={"small"}
          fullWidth
        />
      </Grid>
      <Grid mt={1}>
        <TextField
          value={data.ruc_emisor}
          onChange={(e) =>
            setData({
              ...data,
              ruc_emisor: e.target.value.toUpperCase(),
            })
          }
          label={"RUC"}
          size={"small"}
          fullWidth
        />
      </Grid>
      <Grid mt={1}>
        <Grid width={230}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">
              Obligado a llevar contabilidad
            </InputLabel>
            <Select
              id="demo-simple-select"
              value={data.obligado_llevar_contabilidad}
              label="Obligado a llevar contabilidad"
              onChange={(e) => {
                const v = e.target.value;
                setData({
                  ...data,
                  obligado_llevar_contabilidad: v,
                });
              }}
            >
              <MenuItem key={"NO"} value={"NO"}>
                NO
              </MenuItem>
              <MenuItem key={"SI"} value={"SI"}>
                SI
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid mt={1}>
        <Grid width={230}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Contribuyente</InputLabel>
            <Select
              id="demo-simple-select"
              value={data.tipo_contribuyente?.descripcion_contribuyente ?? ""}
              label="Contribuyente"
              onChange={(e) => {
                const v = e.target.value;

                const c = tiposContribuyentes.find(
                  (tc) => tc.descripcion_contribuyente == v
                );

                setData({
                  ...data,
                  tipo_contribuyente: c,
                });
              }}
            >
              {tiposContribuyentes?.map((tc) => {
                return (
                  <MenuItem
                    key={tc.descripcion_contribuyente}
                    value={tc.descripcion_contribuyente}
                  >
                    {tc.descripcion_contribuyente}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};
