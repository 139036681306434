import {
  TableCell,
  TableRow,
  Link,
  InputBase,
  styled,
  Checkbox,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ESTADO_COBRO, ESTADO_DOCUMENTO } from "../../../../configs/configs";
import { FormatoNumero } from "../../../../helpers/FormatNumber";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    // backgroundColor: theme.palette.background.paper,
    // border: '1px solid #ced4da',
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // borderRadius: 4,
      // borderColor: '#80bdff',
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

export const ReportesComprasRow = ({
  row,
  columns,
  isSelected,
  selectedList,
  index,
  handleClickRow,
  navigateActive,
}) => {
  const navigate = useNavigate();

  const isItemSelected = isSelected(row.id_documento);

  const labelId = `enhanced-table-checkbox-${index}`;

  return (
    <TableRow
      hover
      role="checkbox"
      // component={'div'}
      tabIndex={-1}
      key={row.numero_documento}
      selected={isItemSelected}
      onClick={(event) => {
        if (!selectedList.show) {
          return;
        }
        handleClickRow(event, row.id_documento);
      }}
    >
      {selectedList.show && (
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </TableCell>
      )}

      {columns.map((column) => {
        const value = row[column.id];

        //FECHA REGISTRO
        if (column.id == "fecha_registro") {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              component={"div"}
              onClick={() => navigateActive && navigate(`/compras/${row.id_documento}`)}
            >
              {dayjs(new Date(value)).format("YYYY-MM-DD")}
            </TableCell>
          );
        }

        //ESTADO COBRO
        if (column.id === "estado_cobro") {
          return (
            <TableCell key={column.id} align={column.align} component={"div"}>
              {ESTADO_COBRO[value]}
            </TableCell>
          );
        }

        //ESTADO DOCUMENTO ELECTRONICO
        if (column.id === "estado_documento_electronico") {
          return (
            <TableCell key={column.id} align={column.align} component={"div"}>
              {ESTADO_DOCUMENTO[value]}
            </TableCell>
          );
        }

        if (column.id == "numero_documento") {
          return (
            <TableCell
              component={"div"}
              onClick={() => navigateActive && navigate(`/compras/${row.id_documento}`)}
              key={column.id}
              align={column.align}
            >
              <Link>{value}</Link>
            </TableCell>
          );
        }

        if (column.id == "fecha_emision_documento") {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              component={"div"}
              onClick={() => navigateActive && navigate(`/compras/${row.id_documento}`)}
            >
              {dayjs(new Date(value)).format("YYYY-MM-DD")}
            </TableCell>
          );
        }

        if (column.id == "total_sin_impuestos") {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              component={"div"}
              onClick={() => navigateActive && navigate(`/compras/${row.id_documento}`)}
            >
              {FormatoNumero(value)}
            </TableCell>
          );
        }

        if (column.id == "total_descuento") {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              component={"div"}
              onClick={() => navigateActive && navigate(`/compras/${row.id_documento}`)}
            >
              {FormatoNumero(value)}
            </TableCell>
          );
        }

        if (column.id == "total_base_imponible_cero") {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              component={"div"}
              onClick={() => navigateActive && navigate(`/compras/${row.id_documento}`)}
              backgroundColor={'red'}
            >
              {FormatoNumero(value)}
            </TableCell>
          );
        }

        if (column.id == "total_base_imponible_doce") {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              component={"div"}
              onClick={() => navigateActive && navigate(`/compras/${row.id_documento}`)}
            >
              {FormatoNumero(value)}
            </TableCell>
          );
        }

        if (column.id == "porcentaje_iva") {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              component={"div"}
              onClick={() => navigateActive && navigate(`/compras/${row.id_documento}`)}
            >
              {FormatoNumero(value)}
            </TableCell>
          );
        }

        if (column.id == "valor_total") {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              component={"div"}
              onClick={() => navigateActive && navigate(`/compras/${row.id_documento}`)}
            >
              {FormatoNumero(value)}
            </TableCell>
          );
        }

        return (
          <TableCell
            key={column.id}
            align={column.align}
            component={"div"}
            onClick={() => navigateActive && navigate(`/compras/${row.id_documento}`)}
          >
            {column.format && typeof value === "number"
              ? column.format(value)
              : value}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
