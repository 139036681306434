import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const columns = [
  {
    id: "nombre_plan",
    label: "Plan",
    minWidth: 80,
  },
  {
    id: "nombre_tipo_plan",
    label: "Tipo",
    minWidth: 50,
  },
  {
    id: "cantidad_documentos",
    label: "Cantidad de Documentos",
    minWidth: 50,
  },
  {
    id: "meses_habilitados",
    label: "Meses habilitados",
    minWidth: 30,
  },
  {
    id: "precio",
    label: "Precio",
    minWidth: 50,
  },
  {
    id: "fecha_registro",
    label: "Fecha registro",
    minWidth: 30,
  },
];

export const TablaPlanes = ({ data = [] }) => {
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: "65vh" }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    component={"div"}
                    onClick={() => navigate(`/planes/${row.id_plan}`)}
                    key={row.code}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];

                      if (column.id == "fecha_registro") {
                        const date = dayjs(new Date(value)).format(
                          "DD/MM/YYYY HH:mm"
                        );

                        return (
                          <TableCell key={column.id} align={column.align}>
                            {date}
                          </TableCell>
                        );
                      }

                      if (column.id == "cantidad_documentos") {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value ?? "ILIMITADO"}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
