import { Add } from "@mui/icons-material";

import {
  Alert,
  CircularProgress,
  Divider,
  Fab,
  Grid,
  Snackbar,
  Box,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AccionesContext } from "../../../context/AccionesContext";
import { AuthContext } from "../../../context/AuthContext";
import { PageSearch } from "../../../global/components/PageSearch";
import { AppLayout } from "../../../layouts/AppLayout";

import { TablaRegistrosPlanes } from "./components/TablaRegistrosPlanes";
import {
  busquedaRegistrosPlanesRequest,
  planesBusquedaRequest,
  registrosPlanesRequest,
} from "../../../services/api/PlanesApi";
import ModalReporteRegistros from "./components/ModalReporteRegistros";

export const RegistrosPlanesPage = () => {
  const { user } = useContext(AuthContext);
  const { recargarPlanes } = useContext(AccionesContext);
  const [modalCreate, setModalCreate] = useState(false);
  const [registros, setRegistros] = useState([]);
  const [registrosSearch, setRegistrosSearch] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const [toast, setToast] = useState({
    open: false,
    text: "",
  });

  useEffect(() => {
    setRegistros([]);
    setLoading(true);
    const dataRequest = {
      id_usuario: user.usuario?.id_usuario,
    };
    registrosPlanesRequest(dataRequest)
      .then((res) => {
        if (res.result && res.registros) {
          const registros = res.registros;
          setRegistros(registros);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [recargarPlanes]);

  useEffect(() => {
    if (search.length > 1) {
      setLoading(true);
      const dataRequest = {
        id_usuario: user.usuario?.id_usuario,
        busqueda: search,
      };
      busquedaRegistrosPlanesRequest(dataRequest)
        .then((res) => {
          if (res.result && res.registros) {
            setRegistrosSearch(res.registros);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [search]);

  const openModal = () => setModalCreate(true);
  const closeModal = () => setModalCreate(false);

  const onChangeSearch = (text) => {
    setSearch(text);
  };

  const closeToast = () => {
    setToast({
      open: false,
      text: "",
    });
  };

  const openToast = (text = "") => {
    setToast({
      open: true,
      text: text,
    });
  };

  return (
    <AppLayout>
      <Grid>
        {/* Header */}
        <PageSearch
          title="Registros de planes"
          searchText="Buscar"
          textButtton="Reporte"
          actionButton={openModal}
          onChangeSearch={onChangeSearch}
          search={search}
        />
        <Divider />
        {/* Body */}
        <Grid
          sx={{
            minHeight: 100,
          }}
        >
          {search.length > 1 ? (
            <TablaRegistrosPlanes data={registrosSearch} />
          ) : (
            <TablaRegistrosPlanes data={registros} />
          )}
          {loading && <CircularProgress size={25} />}
          <Snackbar
            open={toast.open}
            autoHideDuration={6000}
            onClose={closeToast}
          >
            <Alert
              onClose={closeToast}
              severity="success"
              sx={{ width: "100%" }}
            >
              {toast.text}
            </Alert>
          </Snackbar>
        </Grid>
        <Box
          sx={{
            position: "absolute",
            bottom: 10,
            right: 10,
            display: {
              xs: "block",
              sm: "none",
            },
          }}
        >
          <Fab onClick={openModal} color="primary" aria-label="add">
            <Add />
          </Fab>
        </Box>
        <ModalReporteRegistros visible={modalCreate} close={closeModal} />
      </Grid>
    </AppLayout>
  );
};
