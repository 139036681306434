import { Grid, TextField, Typography } from "@mui/material";
import React from "react";

export const UsuarioForm = ({ data, setData }) => {
  return (
    <Grid container flexDirection={"column"} alignItems={"center"}>
      <Grid>
        <Typography fontWeight={"bold"} color={"darkgrey"} my={1}>
          USUARIO
        </Typography>
      </Grid>
      <Grid>
        <TextField
          value={data.email_usuario}
          onChange={(e) => setData({
            ...data,
            email_usuario: e.target.value,
          })}
          label={"Correo Electronico"}
          size={"small"}
          fullWidth
        />
      </Grid>
      <Grid mt={1}>
        <TextField
          value={data.password_usuario}
          onChange={(e) => setData({
            ...data,
            password_usuario: e.target.value,
          })}
          label={"Contraseña"}
          size={"small"}
          fullWidth
        />
      </Grid>
      <Grid mt={1}>
        <TextField
          value={data.nombre_usuario}
          onChange={(e) => setData({
            ...data,
            nombre_usuario: e.target.value.toUpperCase(),
          })}
          label={"Nombre Usuario"}
          size={"small"}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
