import { RestartAlt, Search } from "@mui/icons-material";
import { Box, Grid, IconButton, TextField } from "@mui/material";
import dayjs from "dayjs";
import React from "react";

export const Filters = ({
  filters = [],
  onChangeFilters = (f) => {},
  onSubmit = () => {},
  onReset = () => {},
}) => {
  const onChangeFilter = (id, value) => {
    let filtersChange = [...filters];
    filtersChange = filtersChange.map((f) =>
      f.id === id ? { ...f, value: value } : f
    );

    onChangeFilters(filtersChange);
  };

  const onSubmitFilters = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <Grid
      container
      style={{
        height: 40,
        // backgroundColor: "red",
        display: "flex",
      }}
    >
      <form
        onSubmit={onSubmitFilters}
        style={{
          //   backgroundColor: "blue",
          display: "flex",
          alignItems: "center",
        }}
      >
        {filters.map((filter) => {
          return (
            <Box
              key={filter.id}
              style={{
                marginInline: 3,
                paddingLeft: 3,
                paddingRight: 3,
              }}
            >
              {filter.type === "input" && (
                <TextField
                  label={filter.label}
                  size="small"
                  fullWidth
                  value={filter.value}
                  style={{
                    width: 200,
                  }}
                  onChange={(e) => onChangeFilter(filter.id, e.target.value)}
                />
              )}
              {filter.type === "date" && (
                <Grid container>
                  <TextField
                    label={`${filter.label} (Desde)`}
                    size="small"
                    type={"date"}
                    value={filter.value[0]}
                    onChange={(e) => {
                      const date = e.target.value;
                      if (!dayjs(date).isValid()) return;
                      onChangeFilter(filter.id, [date, filter.value[1]]);
                    }}
                    inputProps={{
                      max: filter.value[1],
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Grid minWidth={5} />
                  <TextField
                    label={`${filter.label} (Hasta)`}
                    size="small"
                    type={"date"}
                    value={filter.value[1]}
                    onChange={(e) => {
                      const date = e.target.value;
                      if (!dayjs(date).isValid()) return;
                      onChangeFilter(filter.id, [filter.value[0], date]);
                    }}
                    inputProps={{
                      min: filter.value[0],
                      max: dayjs(new Date()).format("YYYY-MM-DD"),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              )}
            </Box>
          );
        })}
        <IconButton color="primary" type={"submit"}>
          <Search />
        </IconButton>
        <IconButton onClick={onReset} color="info">
          <RestartAlt />
        </IconButton>
      </form>
    </Grid>
  );
};
