import { Add, Edit } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import EstablecimientoCard from "./EstablecimientoCard";
import NuevoEstablecimientoModal from "./NuevoEstablecimientoModal";

const EstablecimientosInfo = ({id_emisor, establecimientos, reload = () => {} }) => {

  const [modal, setModal] = useState(false);

  const handleOpen = () => setModal(true);
  const handleClose = () => setModal(false);

  return (
    <Grid>
      <Grid
        height={40}
        flexDirection={"row"}
        display={"flex"}
        alignItems={"center"}
        position={"relative"}
      >
        <Typography fontWeight={"bold"} color={"darkgrey"}>
          ESTABLECIMIENTOS ({establecimientos.length})
        </Typography>
        <Tooltip title={"Crear establecimiento"} placement="left-start">
          <IconButton
            onClick={handleOpen}
            color="primary"
            style={{
              position: "absolute",
              right: 0,
            }}
          >
            <Add />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid display={"flex"} flexDirection={"column"} justifyContent={"center"}>
        {establecimientos.map((e, index) => {
          return (
            <EstablecimientoCard
              key={index}
              establecimiento={e}
              index={index}
              reload={reload}
            />
          );
        })}
      </Grid>
      {/* MODAL */}
      {
        id_emisor &&
        <NuevoEstablecimientoModal
          visible={modal}
          close={handleClose}
          reload={reload}
          id_emisor={id_emisor}
        />
      }
    </Grid>
  );
};

export default EstablecimientosInfo;
