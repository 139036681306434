import { Edit, FileDownload } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import FirmaModal from "./FirmaModal";

const FirmaInfo = ({ firma, reload = () => {} }) => {

  const [modal, setModal] = useState(false);

  const handleOpen = () => setModal(true);
  const handleClose = () => setModal(false);

  return (
    <Grid>
      <Grid
        height={40}
        flexDirection={"row"}
        display={"flex"}
        alignItems={"center"}
        position={"relative"}
      >
        <Typography fontWeight={"bold"} color={"darkgrey"}>
          FIRMA
        </Typography>
        {/* Acciones */}
        <Grid position={"absolute"} right={0}>
          <Tooltip placement={"left-start"} title={"Editar datos de firma"}>
            <IconButton
              onClick={handleOpen}
              color="info"
            >
              <Edit style={{ height: 20, width: 20 }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container flexDirection={"row"} justifyContent={"space-between"}>
        <Typography fontWeight={"bold"}>Archivo</Typography>

        <a href={firma.firma_url} target="_blank" rel="noopener noreferrer">
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <FileDownload color="primary" style={{ height: 18 }} />
            <Typography color={"primary"}> Descargar firma</Typography>
          </Grid>
        </a>
      </Grid>
      <Grid
        container
        flexDirection={"row"}
        justifyContent={"space-between"}
        mt={1}
      >
        <Typography fontWeight={"bold"}>Clave</Typography>
        <Typography>{firma.password_firma}</Typography>
      </Grid>
      <Grid
        container
        flexDirection={"row"}
        justifyContent={"space-between"}
        mt={1}
      >
        <Typography fontWeight={"bold"}>Fecha vigencia</Typography>
        <Typography>{firma.fecha_valida}</Typography>
      </Grid>

      {/* MODAL EDIT */}
      {firma.id_firma && (
        <FirmaModal
          firma={firma}
          visible={modal}
          close={handleClose}
          reload={reload}
        />
      )}

    </Grid>
  );
};

export default FirmaInfo;
