import React from "react";
import { Route, Routes } from "react-router-dom";
import { CrearFacturaPage } from "../../../pages/app/facturas/CrearFacturaPage";
import { EditFacturaPage } from "../../../pages/app/facturas/EditFacturaPage";
import { FacturaPage } from "../../../pages/app/facturas/FacturaPage";
import { FacturasPage } from "../../../pages/app/facturas/FacturasPage";

export const FacturasRoute = () => {
  return (
    <Routes>
      <Route path="/*" element={<FacturasPage />} />
      <Route path="/:id" element={<FacturaPage />} />
      <Route path="crearFactura" element={<CrearFacturaPage />} />
      <Route path="editar/:id" element={<EditFacturaPage />} />
    </Routes>
  );
};
