import { Grid, Paper, TableContainer, TablePagination, Typography } from "@mui/material";
import React from "react";
import { ReporteCobroRow } from "./ReporteCobroRow";

export const TablaReportesCobro = ({ data = [] }) => {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: `50vh` }}>
        {/* Header */}
        <Grid
          container
          justifyContent={"space-around"}
          xs={12}
          sx={{
            backgroundColor: "#fff",
            height: 30,
            alignItems: "center",
            borderBottom: "1px solid #e1e1e1",
            position: "sticky",
            top: 0,
          }}
          >
          <Grid xs={1.5}>
            <Typography>Documento</Typography>
          </Grid>
          {/* <Grid xs={1}>
            <Typography>Tipo</Typography>
          </Grid> */}
          <Grid xs={1}>
            <Typography>Emision</Typography>
          </Grid>
          {/* <Grid xs={1}>
            <Typography>Vence</Typography>
          </Grid> */}
          <Grid xs={3}>
            <Typography>Concepto</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>Debito</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>Credito</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>Saldo</Typography>
          </Grid>
        </Grid>
        {/* Data */}
        <Grid xs={12}>
          {data
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((documento, index) => {
            return (
              <ReporteCobroRow
                key={documento.id_documento}
                documento={documento}
                />
                );
              })}
        </Grid>
      </TableContainer>
      
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </Paper>
  );
};
