import { Add } from "@mui/icons-material";

import {
  Alert,
  CircularProgress,
  Divider,
  Fab,
  Grid,
  Snackbar,
  Box,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccionesContext } from "../../../context/AccionesContext";
import { AuthContext } from "../../../context/AuthContext";
import { PageSearch } from "../../../global/components/PageSearch";
import { AppLayout } from "../../../layouts/AppLayout";
import {
  clientesBusquedaRequest,
  clientesRequest,
} from "../../../services/api/ClientesApi";
import {
  busquedaUsuariosRequest,
  usuariosRequest,
} from "../../../services/api/UsuariosApi";
import { TablaUsuarios } from "./components/TablaUsuarios";

export const UsuariosPage = () => {

  const { user } = useContext(AuthContext);
  const { recargarUsuarios } = useContext(AccionesContext);

  const navigate = useNavigate();

  const [modalCreate, setModalCreate] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosSearch, setUsuariosSearch] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);


  const [toast, setToast] = useState({
    open: false,
    text: "",
  });

  useEffect(() => {
    setUsuarios([]);
    setLoading(true);
    const dataRequest = {
      id_usuario: user?.usuario?.id_usuario,
    };

    usuariosRequest(dataRequest)
      .then((res) => {
        if (res.usuarios) {
          const u = res.usuarios;
          setUsuarios(u);
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        setLoading(false);
      });
  }, [ recargarUsuarios]);

  useEffect(() => {
    if (search.length > 1) {
      setLoading(true);
      const dataRequest = {
        id_usuario: user?.usuario?.id_usuario,
        busqueda: search,
      };
      busquedaUsuariosRequest(dataRequest)
        .then((res) => {
          if (res.result && res.usuarios) {
            setUsuariosSearch(res.usuarios);
          }
        })
        .catch((err) => {
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [search]);

  const openModal = () => setModalCreate(true);
  const closeModal = () => setModalCreate(false);

  const onChangeSearch = (text) => {
    setSearch(text);
  };

  const closeToast = () => {
    setToast({
      open: false,
      text: "",
    });
  };

  const openToast = (text = "") => {
    setToast({
      open: true,
      text: text,
    });
  };

  return (
    <AppLayout>
      <Grid>
        {/* Header */}
        <PageSearch
          title="Usuarios"
          searchText="Buscar usuario"
          textButtton="Crear usuario"
          actionButton={() => navigate("/usuarios/crearUsuario")}
          onChangeSearch={onChangeSearch}
          search={search}
        />
        <Divider />
        {/* Body */}
        <Grid
          sx={{
            minHeight: 100,
          }}
        >
          {search.length > 1 ? (
            <TablaUsuarios data={usuariosSearch} />
          ) : (
            <TablaUsuarios data={usuarios} />
          )}
          {loading && <CircularProgress size={25} />}
          <Snackbar
            open={toast.open}
            autoHideDuration={6000}
            onClose={closeToast}
          >
            <Alert
              onClose={closeToast}
              severity="success"
              sx={{ width: "100%" }}
            >
              {toast.text}
            </Alert>
          </Snackbar>
        </Grid>
        <Box
          sx={{
            position: "absolute",
            bottom: 10,
            right: 10,
            display: {
              xs: "block",
              sm: "none",
            },
          }}
        >
          <Fab
            onClick={() => navigate("/usuarios/crearUsuario")}
            color="primary"
            aria-label="add"
          >
            <Add />
          </Fab>
        </Box>
      </Grid>
    </AppLayout>
  );
};
