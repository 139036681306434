import React, { useState } from "react";

import { Print } from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { FORMATO_RIDE } from "../../../../helpers/constants/formato-ride";
import { generarRideRequest } from "../../../../services/api/DocumentoApi";

export const ImpresionFactura = ({ documento = {} }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const imprimirFactura = (formato) => {
    handleClose();
    setIsLoading(true);
    const dataRequest = {
      id_documento: documento.id_documento,
      formato: formato,
    };
    generarRideRequest(dataRequest)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res], { type: "application/pdf" })
        );
        const newTab = window.open(url, "_blank");
        if (newTab) {
          newTab.focus();
        } else {
          alert(
            "El bloqueo de ventanas emergentes del navegador puede estar impidiendo que se abra una nueva pestaña. Asegúrate de habilitar las ventanas emergentes."
          );
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress size={20} />
      ) : (
        <Tooltip
          title={`Imprimir Factura ${documento.numero_documento}.pdf`}
          placement={"top"}
        >
          <IconButton color="primary" onClick={handleClick}>
            <Print />
          </IconButton>
        </Tooltip>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => imprimirFactura(FORMATO_RIDE.A4)}>
          Formato A4
        </MenuItem>
        <MenuItem onClick={() => imprimirFactura(FORMATO_RIDE.ROLLO_70MM)}>
          Formato Rollo 7cm
        </MenuItem>
        <MenuItem onClick={() => imprimirFactura(FORMATO_RIDE.ROLLO_75MM)}>
          Formato Rollo 7.5cm
        </MenuItem>
        <MenuItem onClick={() => imprimirFactura(FORMATO_RIDE.ROLLO_80MM)}>
          Formato Rollo 8cm
        </MenuItem>
      </Menu>
    </>
  );
};
