import { Grid, Typography } from "@mui/material";
import React from "react";

export const DocumentoValores = ({documento}) => {
  return (
    <Grid
      container
      xs={12}
      sx={{
        justifyContent: "flex-end",
      }}
    >
      <Grid container xs={12} sm={6}>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={1}
        >
          <Typography fontWeight={"bold"}>Subtotal IVA%</Typography>
          <Typography>{Number(documento.total_base_imponible_doce).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={1}
        >
          <Typography fontWeight={"bold"}>Subtotal 0%</Typography>
          <Typography>{Number(documento.total_base_imponible_cero).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={1}
        >
          <Typography fontWeight={"bold"}>Subtotal Sin Impuestos</Typography>
          <Typography>{Number(documento.total_sin_impuestos).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={1}
        >
          <Typography fontWeight={"bold"}>IVA</Typography>
          <Typography>{Number(documento.porcentaje_iva).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={1}
        >
          <Typography fontWeight={"bold"} fontSize={16}>TOTAL</Typography>
          <Typography fontWeight={"bold"} fontSize={16}>{Number(documento.valor_total).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
