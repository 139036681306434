import { Logout, LogoutOutlined, MenuOutlined } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Modal,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { PlanContext } from "../../context/PlanContext";
import { ModalPlanInfo } from "./ModalPlanInfo";

const Navbar = ({ drawerWidth = 230, openDrawer }) => {
  const { user, establecimiento } = useContext(AuthContext);
  const { datosPlan } = useContext(PlanContext);

  const [modal, setModal] = useState(false);

  const handleOpenModal = () => setModal(true);

  const handleCloseModal = () => setModal(false);

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { md: `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` },
      }}
    >
      <Toolbar>
        <IconButton
          color={"inherit"}
          edge={"start"}
          sx={{ ml: 0.5, mr: 2, display: { md: "none" } }}
          onClick={openDrawer}
        >
          <MenuOutlined />
        </IconButton>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          position={"relative"}
        >
          <Typography fontSize={17}>
            Facturacion {establecimiento.codigo_establecimiento} -{" "}
            {
              user.puntos_emision.find(
                (p) =>
                  p.id_establecimiento == establecimiento.id_establecimiento
              )?.codigo_punto_emision
            }
            {user?.usuario?.admin_registro && "Admin"}
          </Typography>
          {datosPlan.tiene_plan && (
            <>
              <Tooltip placement="bottom" title="Información de plan">
                <Box
                  onClick={handleOpenModal}
                  className="item_hover"
                  component={"div"}
                  style={{
                    position: "absolute",
                    right: 0,
                    backgroundColor: datosPlan.necesita_renovar
                      ? "#ff8e8e"
                      : "white",
                    height: 30,
                    width: 100,
                    borderRadius: 15,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {datosPlan.cantidad_documentos == null ? (
                    <Typography color={"primary"}>ILIMITADO</Typography>
                  ) : (
                    <Typography color={"primary"}>
                      {datosPlan.cantidad_documentos -
                        datosPlan.documentos_faltantes}{" "}
                      / {datosPlan.cantidad_documentos}
                    </Typography>
                  )}
                </Box>
              </Tooltip>

              {/* MODAL */}
              <ModalPlanInfo
                id_usuario={datosPlan.id_usuario}
                visible={modal}
                close={handleCloseModal}
              />
            </>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
