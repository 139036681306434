import styled from "@emotion/styled";
import {
  ArrowBack,
  CalendarViewDay,
  CalendarViewMonth,
  PictureAsPdf,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import fileDownload from "js-file-download";
import React, { useContext, useEffect, useState } from "react";
import { AccionesContext } from "../../../../context/AccionesContext";
import { AuthContext } from "../../../../context/AuthContext";
import {
  calculoTotalesDocumentos,
  documentosListaRequest,
  filtroDocumentosRequest,
  generarReportePDFRequest,
  generarReporteXLSXRequest,
} from "../../../../services/api/ReporteApi";
import { FiltrosReportes } from "./FiltrosReporte";
import { TablaReportes } from "./TablaReportes";
import { TablaReportesCobro } from "./TablaReportesCobro";
import { TotalesReporte } from "./TotalesReporte";
import { TotalesReporteCobro } from "./TotalesReporteCobro";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    // backgroundColor: theme.palette.background.paper,
    // border: '1px solid #ced4da',
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // borderRadius: 4,
      // borderColor: '#80bdff',
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const columns = [
  {
    id: "estado_cobro",
    label: "E cobro",
    minWidth: 100,
  },
  {
    id: "estado_documento_electronico",
    label: "Estado documento",
    minWidth: 100,
  },
  {
    id: "numero_documento",
    label: "Documento Nº  ",
    minWidth: 100,
  },

  {
    id: "num_identificacion_cliente",
    label: "Identificación cliente",
    minWidth: 60,
  },

  {
    id: "razon_social_cliente",
    label: "Cliente",
    minWidth: 80,
  },

  {
    id: "fecha_emision_documento",
    label: "Fecha emision",
    minWidth: 80,
  },
  {
    id: "total_sin_impuestos",
    label: "Subtotal",
    minWidth: 20,
    align: "right",
  },
  {
    id: "total_descuento",
    label: "Descuento",
    minWidth: 10,
    align: "right",
  },
  {
    id: "total_base_imponible_cero",
    label: "Subtotal 0",
    minWidth: 30,
    align: "right",
  },
  {
    id: "total_base_imponible_doce",
    label: "Subtotal 12",
    minWidth: 30,
    align: "right",
  },
  {
    id: "porcentaje_iva",
    label: "IVA",
    minWidth: 20,
    align: "right",
  },
  {
    id: "valor_total",
    label: "Total",
    minWidth: 20,
    align: "right",
  },
];

const ModalCrearReporte = ({ close, visible }) => {
  
  const { establecimiento } = useContext(AuthContext);
  const { recargarFacturas } = useContext(AccionesContext);

  const [dates, setDates] = useState({
    show: false,
    date_init: "",
    date_end: "",
  });

  const [documentos, setDocumentos] = useState([]);
  const [documentosFilter, setDocumentosFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchState, setSearchState] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [emptySearch, setEmptySearch] = useState({ show: false, text: "" });
  const [totalesDocumentos, setTotalDocumentos] = useState({});
  const [change, setChange] = useState({});

  const [optionReporte, setOptionReporte] = useState("TOTALES");

  const [loadingPDF, setLoadingPDF] = useState(false);
  const [loadingXLSX, setLoadingXLSX] = useState(false);

  useEffect(() => {
    const date = new Date();
    const dateFormatEnd = dayjs(date).format("YYYY-MM-DD");
    const dateFormatInit = dayjs(date).subtract(1, "M").format("YYYY-MM-DD");
    setDates({
      ...date,
      date_init: dateFormatInit,
      date_end: dateFormatEnd,
    });
  }, []);

  useEffect(() => {
    setDocumentos([]);
    setLoading(true);
    const dataRequest = {
      id_establecimiento: establecimiento.id_establecimiento,
    };
    documentosListaRequest(dataRequest)
      .then((res) => {
        if (res.documentos) {
          const d = res.documentos;
          setDocumentos(d);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [establecimiento, recargarFacturas]);

  useEffect(() => {
    const dataRequest = {
      documentos: searchState ? documentosFilter : documentos,
    };
    calculoTotalesDocumentos(dataRequest)
      .then((res) => {
        if (res.totales) {
          setTotalDocumentos(res.totales);
        }
      })
      .catch()
      .finally();
  }, [documentos, documentosFilter, change]);

  const onFilter = (id, value, date) => {
    if (id == "" && !date) {
      setSearchState(false);
      setLoadingSearch(false);
      setChange(!change);
      return;
    }
    setEmptySearch({ show: false, text: "" });
    setSearchState(true);
    setLoadingSearch(true);
    setDocumentosFilter([]);

    const date_init = new Date(dates.date_init);
    const date_end = new Date(dates.date_end);

    const dateAdd = dayjs(date_end).add(2, "days").format("YYYY-MM-DD");

    const dataRequest = {
      id_establecimiento: establecimiento.id_establecimiento,
      filter: id,
      filter_value: value,
      date_show: dates.show,
      date_init: dates.date_init,
      date_end: dates.date_init === dates.date_end ? dateAdd : dates.date_end,
    };

    filtroDocumentosRequest(dataRequest)
      .then((res) => {
        if (res.result && res.documentos.length > 0) {
          setDocumentosFilter(res.documentos);
        } else if (res.result && res.documentos.length === 0) {
          setEmptySearch({
            show: true,
            text: id != "estado_documento_electronico" ? value : "",
          });
        }
      })
      .catch()
      .finally(() => {
        setLoadingSearch(false);
      });
  };

  const optionsReporte = ["TOTALES", "COBROS"];

  const generarPDF = () => {
    setLoadingPDF(true);
    const dataRequest = {
      documentos: searchState ? documentosFilter : documentos,
      id_establecimiento: establecimiento.id_establecimiento,
      totales: totalesDocumentos,
      tipo: optionReporte,
    };
    generarReportePDFRequest(dataRequest)
      .then((res) => {
        const date = new Date();
        const date_reporte = dayjs(date).format("YYYYMMDDD-HHmmss");
        fileDownload(res, `REPORTE-${date_reporte}.pdf`);
      })
      .catch((err) => {
        alert("Ocurrio un error al descargar el documento !");
      })
      .finally(() => {
        setLoadingPDF(false);
      });
  };

  const generarXLSX = () => {
    setLoadingXLSX(true);
    const dataRequest = {
      documentos: searchState ? documentosFilter : documentos,
      id_establecimiento: establecimiento.id_establecimiento,
      totales: totalesDocumentos,
      tipo: optionReporte,
    };
    generarReporteXLSXRequest(dataRequest)
      .then((res) => {
        const date = new Date();
        const date_reporte = dayjs(date).format("YYYYMMDDD-HHmmss");
        fileDownload(res, `REPORTE-${date_reporte}.xlsx`);
      })
      .catch((err) => {
        alert("Ocurrio un error al descargar el documento !");
      })
      .finally(() => {
        setLoadingXLSX(false);
      });
  };

  return (
    <Modal open={visible} onClose={close} fullScreen>
      <Grid
        sx={{
          minHeight: "100vh",
          backgroundColor: "#fff",
        }}
      >
        {/* Header Go Back */}
        <Grid
          container
          flexDirection={"row"}
          alignItems={"center"}
          position={"relative"}
          height={45}
        >
          <IconButton
            onClick={close}
            sx={{
              position: "absolute ",
            }}
          >
            <ArrowBack />
          </IconButton>
          <Grid xs={12}>
            <Typography fontWeight={"bold"} fontSize={16} textAlign={"center"}>
              Nuevo reporte
            </Typography>
          </Grid>
        </Grid>
        {/* TIPO REPORTE */}
        <Grid container alignItems={"center"} my={1}>
          <Typography fontSize={16} marginX={2} fontWeight={"bold"}>
            TIPO DE REPORTE
          </Typography>
          <Grid
            xs={1}
            sx={{
              backgroundColor: "#e3e2ff",
              borderRadius: 100,
              height: 35,
            }}
          >
            <FormControl fullWidth>
              <Select
                sx={{
                  height: 20,
                  minWidth: "100%",
                  textAlign: "center",
                }}
                input={<BootstrapInput />}
                size="small"
                displayEmpty
                value={optionReporte}
                onChange={(e) => setOptionReporte(e.target.value)}
              >
                {optionsReporte.map((o) => {
                  return (
                    <MenuItem key={o} value={o}>
                      {" "}
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* REPORTE TOTALES */}
        {optionReporte === "TOTALES" && (
          <>
            {/* Filters */}
            <Grid container>
              <FiltrosReportes
                dates={dates}
                setDates={setDates}
                onFilter={onFilter}
              />
            </Grid>
            {/* Tabla documentos */}
            <Grid mt={1}>
              {searchState ? (
                <>
                  <TablaReportes
                    data={documentosFilter}
                    vhValue={40}
                    navigateActive={false}
                    reporte={true}
                  />
                  {emptySearch.show && (
                    <Grid
                      container
                      sx={{
                        paddingX: 1,
                        justifyContent: {
                          sx: "flex-start",
                          md: "center",
                        },
                      }}
                      justifyContent={"center"}
                    >
                      <Typography mr={2}>
                        No se encontro documentos "{emptySearch.text}".
                      </Typography>
                    </Grid>
                  )}
                </>
              ) : (
                <TablaReportes
                  data={documentos}
                  vhValue={40}
                  navigateActive={false}
                  reporte={true}
                />
              )}
            </Grid>
            {/* Totales */}
            <Grid>
              <TotalesReporte columns={columns} totales={totalesDocumentos} />
            </Grid>
          </>
        )}
        {/* REPORTE COBRO */}
        {optionReporte === "COBROS" && (
          <>
            {/* Filters */}
            <Grid container>
              <FiltrosReportes
                dates={dates}
                setDates={setDates}
                onFilter={onFilter}
              />
            </Grid>
            <Grid mt={1}>
              {searchState ? (
                <>
                  <TablaReportesCobro data={documentosFilter} />
                  {emptySearch.show && (
                    <Grid
                      container
                      sx={{
                        paddingX: 1,
                        justifyContent: {
                          sx: "flex-start",
                          md: "center",
                        },
                      }}
                      justifyContent={"center"}
                    >
                      <Typography mr={2}>
                        No se encontro documentos "{emptySearch.text}".
                      </Typography>
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  <TablaReportesCobro data={documentos} />
                </>
              )}
            </Grid>
            {/* Totales */}
            <Grid>
              <TotalesReporteCobro
                columns={columns}
                totales={totalesDocumentos}
              />
            </Grid>
          </>
        )}
        {/*  */}
        <Grid container mt={1} px={1} flexDirection={"row"}>
          <Button
            variant={"contained"}
            color={"error"}
            endIcon={<PictureAsPdf />}
            disabled={loadingPDF}
            onClick={generarPDF}
          >
            {loadingPDF && <CircularProgress mr={3} size={20} />}
            Descargar PDF
          </Button>
          <Grid mx={1} />
          <Button
            variant={"contained"}
            color={"success"}
            endIcon={<CalendarViewMonth />}
            disabled={loadingXLSX}
            onClick={generarXLSX}
          >
            {loadingXLSX && <CircularProgress mr={3} size={20} />}
            Descargar XLSX
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ModalCrearReporte;
