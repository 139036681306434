import { Close, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputBase,
  Modal,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AccionesContext } from "../../../../context/AccionesContext";
import { AuthContext } from "../../../../context/AuthContext";
import { ModalLayout } from "../../../../layouts/ModalLayout";
import {
  clientesBusquedaRequest,
  clientesRequest,
} from "../../../../services/api/ClientesApi";
import { Clientes } from "./Clientes";
import { usePagination } from "../../../../hooks/usePagination";

export const ModalBuscarCliente = ({ modal, close, seleccionarCliente }) => {
  const { user, establecimiento } = useContext(AuthContext);
  const { recargarClientes } = useContext(AccionesContext);

  const [clientes, setClientes] = useState([]);
  const [clientesSearch, setClientesSearch] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const { page, limit, loadMore, reset, onChangeTablePagination } = usePagination(100);

  useEffect(() => {
    reset();
    setSearch('');
    setClientes([]);
  }, [recargarClientes]);

  useEffect(() => {
    reset();
    setLoading(true);
    const pagination = {
      limit,
      page: 1,
    }
    clientesPaginationRequest(pagination)
      .then((res) => {
        if (res.data) {
          const c = res.data.map(cl => {
            return {
              ...cl.cliente,
              ...cl.contactos[0],
              contactos: cl.contactos,

            }
          })

          setClientes(c);
        }
      })
      .finally(() => setLoading(false));
  }, [recargarClientes, search]);

  useEffect(() => {
    if (page === 1) return;
    setLoading(true);
    const pagination = {
      limit,
      page,
    }
    clientesPaginationRequest(pagination)
      .then((res) => {
        if (res.data) {
          const c = res.data.map(cl => {
            return {
              ...cl.cliente,
              ...cl.contactos[0],
              contactos: cl.contactos,

            }
          })

          setClientes((prev) => [...prev, ...c]);
        }
      })
      .finally(() => setLoading(false));

  }, [page]);

  const clientesPaginationRequest = async (pagination) => {
    if (!search) {
      return await clientesRequest({ id_establecimiento: establecimiento.id_establecimiento }, pagination)
    }
    if (!!search) {
      return await clientesBusquedaRequest({ id_establecimiento: establecimiento.id_establecimiento, search_cliente: search }, pagination)
    }
  }

  const onChangeSearch = (text) => {
    setSearch(text);
  };

  return (
    <Modal
      open={modal}
      onClose={() => {
        close();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout>
        <Box>
          {/* BUSQUEDA */}
          <Box
            component={"div"}
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box>
              <IconButton
                onClick={() => {
                  setSearch("");
                  close();
                }}
              >
                <Close />
              </IconButton>
            </Box>
            <Box
              sx={{
                backgroundColor: "#e3e2ff",
                borderRadius: 3,
                width: "100%",
              }}
            >
              <InputBase
                placeholder={"Buscar cliente"}
                value={search}
                sx={{
                  height: "100%",
                  width: "100%",
                  px: 2,
                }}
                onChange={(e) => onChangeSearch(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                }
              />
            </Box>
          </Box>
          {/* CLIENTES */}
          
            <Clientes
              clientes={clientes}
              seleccionarCliente={seleccionarCliente}
            />
          
        </Box>
      </ModalLayout>
    </Modal>
  );
};
