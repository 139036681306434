import {
  DateRange,
  DateRangeOutlined,
  DateRangeRounded,
  DateRangeSharp,
  Search,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  ListSubheader,
  MenuItem,
  Select,
  styled,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState, useRef } from "react";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    // backgroundColor: theme.palette.background.paper,
    // border: '1px solid #ced4da',
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // borderRadius: 4,
      // borderColor: '#80bdff',
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

export const FiltrosReportesCompras = ({
  onFilter = (id, value) => {},
  dates = { show: false },
  setDates = () => {},
}) => {
  const [option, setOption] = useState("");
  const [select, setSelect] = useState("");
  const [search, setSearch] = useState("");

  const [datesFormat, setDatesFormat] = useState({
    init: "",
    end: "",
  });

  const inputRef = useRef();

  const optionsFilter = [
    {
      id: "ruc",
      type: "input",
      label: "RUC",
      input_placeholder: "Ej. 0504375353001",
    },
    {
      id: "razon_social",
      type: "input",
      label: "Emisor",
      input_placeholder: "Ej. NOMBRES APELLIDOS",
    }
  ];

  const inputSearchChange = (e) => {
    const text = e.target.value;
    setSearch(text);
  };

  const handleChange = (e) => {
    setSearch("");
    setSelect("");
    setOption(e.target.value);
  };

  const handleChangeSelect = (e) => {
    const value = e.target.value;
    setSelect(value);
    onFilter(option, value, dates.show);
  };

  useEffect(() => {
    if (option != "") {
      const type = optionsFilter.find((o) => o.id == option).type;
      if (type == "input") {
        inputRef.current.focus();
      }
    } else {
      onFilter(option, "", dates.show);
    }
  }, [option]);

  useEffect(() => {
    const date = new Date();
    const init = dayjs(date).format("YYYY-MM-DD");
    const end = dayjs(date).add(1, "day").format("YYYY-MM-DD");
    setDatesFormat({
      init: init,
      end: end,
    });
  }, []);

  const submitFilter = (e) => {
    e.preventDefault();
    onFilter(option, search, dates.show);
  };

  return (
    <Grid container flexDirection={"row"} alignItems={"center"} xs={12}>
      {/* Filtro */}
      <Grid
        item
        xs={2}
        sm={2}
        sx={{
          backgroundColor: "#e3e2ff",
          borderRadius: 100,
          height: 35,
        }}
      >
        {/* SELECT */}
        <FormControl
          fullWidth
          sx={{
            height: 35,
          }}
        >
          <Select
            sx={{
              height: 35,
              minWidth: "100%",
              textAlign: "center",
            }}
            input={<BootstrapInput />}
            size="small"
            displayEmpty
            value={option}
            onChange={handleChange}
          >
            <MenuItem value={""}>Todos</MenuItem>
            {optionsFilter.map((o) => {
              return (
                <MenuItem key={o.id} value={o.id}>
                  {" "}
                  {o.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      {/* Input */}
      {option !== "" &&
        optionsFilter.find((o) => o.id == option).type === "input" && (
          <Grid
            item
            xs={2}
            sm={2}
            sx={{
              backgroundColor: "#e3e2ff",
              borderRadius: 100,
              height: 35,
            }}
          >
            <form className={"form_search"} onSubmit={submitFilter}>
              <InputBase
                inputRef={inputRef}
                placeholder={
                  optionsFilter.find((o) => o.id == option).input_placeholder
                }
                value={search}
                sx={{
                  height: "100%",
                  width: "100%",
                  pl: 2,
                }}
                onChange={inputSearchChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      disabled={search.trim().length == 0}
                      type="submit"
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </form>
          </Grid>
        )}
      {/* Select */}
      {option !== "" &&
        optionsFilter.find((o) => o.id == option).type === "select" && (
          <Grid
            item
            xs={2}
            sm={2}
            sx={{
              backgroundColor: "#e3e2ff",
              borderRadius: 100,
              height: 35,
            }}
          >
            {/* SELECT */}
            <FormControl fullWidth>
              <Select
                sx={{
                  height: 35,
                  minWidth: "100%",
                  textAlign: "center",
                }}
                input={<BootstrapInput />}
                size="small"
                displayEmpty
                value={select}
                onChange={handleChangeSelect}
              >
                <MenuItem value={""}>
                  {optionsFilter.find((o) => o.id == option).select_default}
                </MenuItem>
                {optionsFilter
                  .find((o) => o.id == option)
                  .options.map((o) => {
                    return (
                      <MenuItem key={o.id} value={o.id}>
                        {" "}
                        {o.label}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        )}
      <IconButton
        color={dates.show ? "primary" : "default"}
        onClick={() => {
          if (dates.show) {
            onFilter("", "", "");
          }

          const date = new Date();

          const dateFormatEnd = dayjs(date).format("YYYY-MM-DD");
          const dateFormatInit = dayjs(date)
            .subtract(1, "M")
            .format("YYYY-MM-DD");

          setDates({
            ...dates,
            show: !dates.show,
            date_init: dateFormatInit,
            date_end: dateFormatEnd,
          });
        }}
      >
        <DateRange />
      </IconButton>
      {/* DATES */}
      {dates.show && (
        <>
          {/* Fecha Inicio */}
          <Grid
            item
            xs={2}
            sm={2}
            sx={{
              backgroundColor: "#e3e2ff",
              borderRadius: 100,
              height: 35,
              px: 1,
              position: "relative",
            }}
          >
            <Typography
              sx={{
                position: "absolute",
                top: "-12px",
                left: "10px",
                fontSize: 11,
                // backgroundColor: '#fff',
                borderRadius: 100,
                px: 1,
              }}
            >
              Fecha inicio
            </Typography>
            <InputBase
              type={"date"}
              value={dates.date_init}
              sx={{
                height: "100%",
                width: "100%",
                pl: 1,
              }}
              inputProps={{
                min: "2020-01-01",
                max: datesFormat.init,
              }}
              onChange={(e) => {
                setDates({
                  ...dates,
                  date_init: e.target.value,
                });
              }}
            />
          </Grid>
          {/* Fecha Final */}
          <Grid
            item
            xs={2}
            sm={2}
            sx={{
              backgroundColor: "#e3e2ff",
              borderRadius: 100,
              height: 35,
              px: 1,
              position: "relative",
            }}
          >
            <Typography
              sx={{
                position: "absolute",
                top: "-12px",
                left: "10px",
                fontSize: 11,
                // backgroundColor: '#fff',
                borderRadius: 100,
                px: 1,
              }}
            >
              Fecha Fin
            </Typography>
            <InputBase
              type={"date"}
              value={dates.date_end}
              sx={{
                height: "100%",
                width: "100%",
                pl: 1,
              }}
              inputProps={{
                min: dates.date_init,
                max: datesFormat.end,
              }}
              onChange={(e) => {
                setDates({
                  ...dates,
                  date_end: e.target.value,
                });
              }}
            />
          </Grid>
          {/* BUSCAR */}
          <IconButton
            color="primary"
            onClick={() => {
              onFilter(option, search, true);
            }}
          >
            <Search />
          </IconButton>
        </>
      )}
    </Grid>
  );
};
