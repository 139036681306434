import { Divider, Grid, Typography } from "@mui/material";
import React from "react";

export const DocumentoGuiaEmision = ({ documento }) => {
  const emails = documento?.cliente?.emails_contacto;

  return (
    <Grid
      container
      xs={12}
      sx={{
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: { sm: "space-between" },
      }}
    >
      <Grid xs={12} sm={5.8}>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Typography fontWeight={"bold"}>Documento Nº.</Typography>
          <Typography>{documento.numero_documento}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Typography fontWeight={"bold"}>Fecha</Typography>
          <Typography>
            {documento.fecha_emision_documento?.substring(0, 10)}
          </Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Typography fontWeight={"bold"}>RUC</Typography>
          <Typography>{documento.ruc}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Typography fontWeight={"bold"}>Establecimiento</Typography>
          <Typography>{documento.direccion_establecimiento}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Typography fontWeight={"bold"}>Matriz</Typography>
          <Typography>{documento.direccion_matriz}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Typography fontWeight={"bold"}>
            Obligado a llevar contabilidad
          </Typography>
          <Typography>{documento.obligado_contabilidad}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"column"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Typography fontWeight={"bold"}>Clave de acceso</Typography>
          <Typography
            textAlign={"center"}
            sx={{
              wordWrap: "break-word",
              maxWidth: "100%",
            }}
          >
            {documento.clave_acceso}
          </Typography>
        </Grid>
      </Grid>
      <Divider mt={2} mb={2} />
      <Grid xs={12} sm={5.8}>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={1}
        >
          <Typography fontWeight={"bold"}>Transportista</Typography>
          <Typography>{documento.guia_razon_social_transportista}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={1}
        >
          <Typography fontWeight={"bold"}>
            Identificación Transportista
          </Typography>
          <Typography>{documento.guia_ruc_transportista}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={1}
          mb={1}
        >
          <Typography fontWeight={"bold"}>Placa</Typography>
          <Typography>{documento.guia_placa}</Typography>
        </Grid>
        <Divider />
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={1}
        >
          <Typography fontWeight={"bold"}>Razon social destinatario</Typography>
          <Typography>{documento.guia_razon_social_destinatario}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={1}
        >
          <Typography fontWeight={"bold"}>
            Identificiación destinatario
          </Typography>
          <Typography>{documento.guia_identificacion_destinatario}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={1}
          mb={1}
        >
          <Typography fontWeight={"bold"}>Direccion destinatario</Typography>
          <Typography>{documento.guia_direccion_destinatario}</Typography>
        </Grid>
        <Divider />
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={1}
          mb={1}
        >
          <Typography fontWeight={"bold"}>Dirección partida</Typography>
          <Typography>{documento.guia_direccion_partida}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={1}
          mb={1}
        >
          <Typography fontWeight={"bold"}>Ruta</Typography>
          <Typography>{documento.guia_ruta}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={1}
          mb={1}
        >
          <Typography fontWeight={"bold"}>Documento aduanero único</Typography>
          <Typography>{documento.guia_documento_aduanero_unico}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={1}
          mb={1}
        >
          <Typography fontWeight={"bold"}>Fecha inicio transporte</Typography>
          <Typography>
            {documento.guia_fecha_inicio_transporte?.substring(0, 10)}
          </Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={1}
          mb={1}
        >
          <Typography fontWeight={"bold"}>Fecha fin transporte</Typography>
          <Typography>
            {documento.guia_fecha_fin_transporte?.substring(0, 10)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
