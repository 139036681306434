import {
  Alert,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AccionesContext } from "../../../context/AccionesContext";
import { AppLayout } from "../../../layouts/AppLayout";
import {
  clienteRequest,
  eliminarClienteRequest,
  identificacionesRequest,
} from "../../../services/api/ClientesApi";
import {
  ModalEditarCliente,
  ModalEditarPlan,
} from "./components/ModalEditarPlan";
import {
  eliminarPlanRequest,
  planIdRequest,
  registroPlanIdRequest,
  registrosPlanUsuarioIdRequest,
} from "../../../services/api/PlanesApi";
import dayjs from "dayjs";
import { usuarioIdRequest } from "../../../services/api/UsuariosApi";
import { ArrowBack } from "@mui/icons-material";
import UsuarioInfo from "./components/UsuarioInfo";
import EmisorInfo from "./components/EmisorInfo";
import PlanInfo from "./components/PlanInfo";
import { RegistrosInfo } from "./components/RegistrosInfo";

export const RegistroPlanPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [usuario, setUsuario] = useState({
    id_usuario: null,
    email_usuario: "",
    nombre_usuario: "",
    fecha_creacion: "",
  });

  const [emisor, setEmisor] = useState({
    ruc_emisor: "",
    razon_social_emisor: "",
    obligado_contabilidad: "",
    tipo_contribuyente: {},
  });

  const [firma, setFirma] = useState({
    firma_url: "",
    firma: {},
    password_firma: "",
    fecha_valida: "",
  });

  const [establecimientos, setEstablecimientos] = useState([]);

  const [plan, setPlan] = useState({});
  const [registros, setRegistros] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    usuarioIdRequest({ id_usuario: parseInt(id ?? 0) })
      .then((resp) => {
        if (resp.result && resp.usuario) {
          const u = resp.usuario;
          setUsuario({
            id_usuario: u.id_usuario,
            email_usuario: u.email_usuario,
            nombre_usuario: u.nombre_usuario,
            fecha_creacion: u.fecha_creacion_usuario,
          });
          const e = u.emisor;

          const eM = u.establecimientos.find(
            (es) => es.estado_matriz_establecimiento
          );

          setEmisor({
            id_emisor: e.id_emisor,
            ruc_emisor: e.ruc_emisor,
            razon_social_emisor: e.razon_social_emisor,
            obligado_contabilidad: eM.obligado_contabilidad,
            tipo_contribuyente: {
              id_tipo_contribuyente: eM.id_tipo_contribuyente,
              descripcion_contribuyente: eM.descripcion_contribuyente,
            },
          });

          const f = u.firma;
          const date = new Date(f.fecha_valida);

          setFirma({
            id_firma: f.id_firma,
            firma_url: f.url_firma,
            password_firma: f.password_firma,
            fecha_valida: dayjs(date).format("YYYY-MM-DD"),
            firma: {},
          });

          const establecimientosFormat = u.establecimientos.map((es) => {
            return {
              id_establecimiento: es.id_establecimiento,
              nombre_comercial: es.nombre_comercial_establecimiento ?? "",
              url_logo: es.url_logo_establecimiento ?? "",
              direccion_establecimiento: es.direccion_establecimiento,
              codigo_establecimiento: es.codigo_establecimiento,
              matriz: es.estado_matriz_establecimiento,
              punto_emision: {
                id_punto_emision: es.id_punto_emision,
                codigo_punto_emision: es.codigo_punto_emision,
              },
            };
          });

          const compararEstablecimientos = (a, b) => {
            if (a.matriz !== b.matriz) {
              if (a.matriz === true) {
                return -1;
              }
              if (b.matriz === false) {
                return 1;
              }
            }

            return a.codigo_establecimiento.localeCompare(
              b.codigo_establecimiento
            );
          };

          establecimientosFormat.sort(compararEstablecimientos);

          setEstablecimientos(establecimientosFormat);
        }
      })
      .catch()
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
    registroPlanIdRequest({ id_usuario: parseInt(id ?? 0) }).then((res) => {
      if (res.result && res.registro) {
        setPlan(res.registro);
      }
    });
  }, [reload]);

  useEffect(() => {
    const dataRequest = {
      id_usuario: parseInt(id ?? 0),
    };
    registroPlanIdRequest(dataRequest).then((res) => {
      if (res.result && res.registro) {
        setPlan(res.registro);
      }
    });
  }, [reload]);

  useEffect(() => {
    const dataRequest = {
      id_usuario: parseInt(id ?? 0),
    };
    registrosPlanUsuarioIdRequest(dataRequest).then((res) => {
      if (res.result && res.registros) {
        setRegistros(res.registros);
      }
    });
  }, [reload]);

  const reloadInfo = () => setReload(!reload);

  return (
    <AppLayout>
      <Box
        component={"div"}
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "flex-start" },
        }}
      >
        <Card
          xs={12}
          sx={{
            borderRadius: 3,
            p: 2,
            minWidth: "100%",
          }}
        >
          <Grid display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Tooltip title={"Regresar"}>
              <IconButton
                size="small"
                onClick={() => navigate(-1)}
                color="primary"
              >
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <Typography ml={1} fontWeight={"bold"} color={"GrayText"}>
              REGISTRO DE PLANES
            </Typography>
          </Grid>
          <Grid
            minWidth={"500px"}
            container
            xs={12}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-around"}
          >
          
            <Grid xs={12} sm={6} md={6} lg={5.5}>
              {/* Informacion de plan */}
              <Grid xs={12} p={0.5}>
                <PlanInfo plan={plan} reload={reloadInfo}/>
              </Grid>
              <Divider />
              {/* Informacion de usuario */}
              <Grid xs={12} p={0.5}>
                <UsuarioInfo usuario={usuario} reload={reloadInfo} />
              </Grid>
              {/* Informacion de emisor */}
              <Grid xs={12} p={0.5}>
                <EmisorInfo emisor={emisor} reload={reloadInfo} />
              </Grid>
              <Divider />
              <Grid
                mt={1}
                container
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Typography fontWeight={"bold"}>
                  T. documentos generados
                </Typography>
                <Typography>{plan.total_documentos}</Typography>
              </Grid>
            </Grid>
            <Divider />
            {/* Informacion de Establecimientos */}
            <Grid xs={12} sm={6} md={6} lg={5.5}>
              <Grid xs={12} p={0.5}>
                <RegistrosInfo registros={registros} />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </AppLayout>
  );
};
