import React from "react";
import { AccionesProvider } from "./context/AccionesContext";
import { AuthContext, AuthProvider } from "./context/AuthContext";

import { RoutesApp } from "./routes/RoutesApp";
import { Theme } from "./theme/Theme";
import { PlanProvider } from "./context/PlanContext";

export const FacturacionApp = () => {
  return (
    <Theme>
      <AuthProvider>
        <PlanProvider>
          <AccionesProvider>
            <RoutesApp />
          </AccionesProvider>
        </PlanProvider>
      </AuthProvider>
    </Theme>
  );
};
