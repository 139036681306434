import {
  Card,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Modal,
} from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { usuarioIdRequest } from "../../services/api/UsuariosApi";
import {
  registroPlanIdRequest,
  registrosPlanUsuarioIdRequest,
} from "../../services/api/PlanesApi";
import { ArrowBack } from "@mui/icons-material";
import PlanInfo from "../../pages/app/registros_planes/components/PlanInfo";
import UsuarioInfo from "../../pages/app/registros_planes/components/UsuarioInfo";
import EmisorInfo from "../../pages/app/registros_planes/components/EmisorInfo";
import { RegistrosInfo } from "../../pages/app/registros_planes/components/RegistrosInfo";

export const ModalPlanInfo = ({ id_usuario, visible, close }) => {
  const id = id_usuario;

  const [usuario, setUsuario] = useState({
    id_usuario: null,
    email_usuario: "",
    nombre_usuario: "",
    fecha_creacion: "",
  });

  const [emisor, setEmisor] = useState({
    ruc_emisor: "",
    razon_social_emisor: "",
    obligado_contabilidad: "",
    tipo_contribuyente: {},
  });

  const [firma, setFirma] = useState({
    firma_url: "",
    firma: {},
    password_firma: "",
    fecha_valida: "",
  });

  const [establecimientos, setEstablecimientos] = useState([]);

  const [plan, setPlan] = useState({});
  const [registros, setRegistros] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    usuarioIdRequest({ id_usuario: parseInt(id ?? 0) })
      .then((resp) => {
        if (resp.result && resp.usuario) {
          const u = resp.usuario;
          setUsuario({
            id_usuario: u.id_usuario,
            email_usuario: u.email_usuario,
            nombre_usuario: u.nombre_usuario,
            fecha_creacion: u.fecha_creacion_usuario,
          });
          const e = u.emisor;

          const eM = u.establecimientos.find(
            (es) => es.estado_matriz_establecimiento
          );

          setEmisor({
            id_emisor: e.id_emisor,
            ruc_emisor: e.ruc_emisor,
            razon_social_emisor: e.razon_social_emisor,
            obligado_contabilidad: eM.obligado_contabilidad,
            tipo_contribuyente: {
              id_tipo_contribuyente: eM.id_tipo_contribuyente,
              descripcion_contribuyente: eM.descripcion_contribuyente,
            },
          });

          const f = u.firma;
          const date = new Date(f.fecha_valida);

          setFirma({
            id_firma: f.id_firma,
            firma_url: f.url_firma,
            password_firma: f.password_firma,
            fecha_valida: dayjs(date).format("YYYY-MM-DD"),
            firma: {},
          });

          const establecimientosFormat = u.establecimientos.map((es) => {
            return {
              id_establecimiento: es.id_establecimiento,
              nombre_comercial: es.nombre_comercial_establecimiento ?? "",
              url_logo: es.url_logo_establecimiento ?? "",
              direccion_establecimiento: es.direccion_establecimiento,
              codigo_establecimiento: es.codigo_establecimiento,
              matriz: es.estado_matriz_establecimiento,
              punto_emision: {
                id_punto_emision: es.id_punto_emision,
                codigo_punto_emision: es.codigo_punto_emision,
              },
            };
          });

          const compararEstablecimientos = (a, b) => {
            if (a.matriz !== b.matriz) {
              if (a.matriz === true) {
                return -1;
              }
              if (b.matriz === false) {
                return 1;
              }
            }

            return a.codigo_establecimiento.localeCompare(
              b.codigo_establecimiento
            );
          };

          establecimientosFormat.sort(compararEstablecimientos);

          setEstablecimientos(establecimientosFormat);
        }
      })
      .catch()
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
    registroPlanIdRequest({ id_usuario: parseInt(id ?? 0) }).then((res) => {
      if (res.result && res.registro) {
        setPlan(res.registro);
      }
    });
  }, [reload]);

  useEffect(() => {
    const dataRequest = {
      id_usuario: parseInt(id ?? 0),
    };
    registroPlanIdRequest(dataRequest).then((res) => {
      if (res.result && res.registro) {
        setPlan(res.registro);
      }
    });
  }, [reload]);

  useEffect(() => {
    const dataRequest = {
      id_usuario: parseInt(id ?? 0),
    };
    registrosPlanUsuarioIdRequest(dataRequest).then((res) => {
      if (res.result && res.registros) {
        setRegistros(res.registros);
      }
    });
  }, [reload]);

  const reloadInfo = () => setReload(!reload);

  return (
    <Modal open={visible} onClose={close}>
      <Grid
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          xs={12}
          style={{
            backgroundColor: "#f8f6fe",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Grid
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
          >
            <Tooltip title={"Regresar"}>
              <IconButton onClick={close} color="primary">
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <Typography ml={1} fontWeight={"bold"} color={"GrayText"}>
              REGISTRO DE PLANES
            </Typography>
          </Grid>
          <Grid
            container
            xs={11}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-around"}
          >
            <Grid xs={12} sm={6} md={6} lg={5.5}>
              {/* Informacion de plan */}
              <Grid xs={12} p={0.5}>
                <PlanInfo plan={plan} reload={reloadInfo} />
              </Grid>
              <Divider />
              {/* Informacion de usuario */}
              <Grid xs={12} p={0.5}>
                <UsuarioInfo usuario={usuario} reload={reloadInfo} />
              </Grid>
              {/* Informacion de emisor */}
              <Grid xs={12} p={0.5}>
                <EmisorInfo emisor={emisor} reload={reloadInfo} />
              </Grid>
              <Divider />
              <Grid
                mt={1}
                container
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Typography fontWeight={"bold"}>
                  T. documentos generados
                </Typography>
                <Typography>{plan.total_documentos}</Typography>
              </Grid>
            </Grid>
            <Divider />
            {/* Informacion de registros */}
            <Grid xs={12} sm={6} md={6} lg={5.5}>
              <Grid xs={12} p={0.5}>
                <RegistrosInfo registros={registros} />
              </Grid>
              <Grid height={100}>
                <Typography>-</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};
