import { Add } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  Fab,
  Grid,
  Typography,
} from "@mui/material";
import usePagination from "@mui/material/usePagination/usePagination";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccionesContext } from "../../../context/AccionesContext";
import { AuthContext } from "../../../context/AuthContext";
import { PageSearch } from "../../../global/components/PageSearch";
import { PageSearchDocumento } from "../../../global/components/PageSearchDocumento";
import { AppLayout } from "../../../layouts/AppLayout";
import {
  buscarNotasCreditoRequest,
  busquedaDocumentoCreditoRequest,
  listaNotasCreditoRequest,
} from "../../../services/api/NotaCreditoApi";
import { TablaNotasCredito } from "./components/TablaNotasCredito";
import { PlanContext } from "../../../context/PlanContext";

const NotasCreditoPage = () => {
  const navigate = useNavigate();

  const { establecimiento } = useContext(AuthContext);
  const { recargarCreditos } = useContext(AccionesContext);
  const { validarPlan } = useContext(PlanContext);

  const [creditos, setCreditos] = useState([]);
  const [creditosSearch, setCreditosSearch] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchState, setSearchState] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [emptySearch, setEmptySearch] = useState({ show: false, text: "" });

  const [toast, setToast] = useState({
    open: false,
    text: "",
  });

  const [dates, setDates] = useState({
    show: false,
    date_init: "",
    date_end: "",
  });

  const { limit, offset } = usePagination(5);

  useEffect(() => {
    setCreditos([]);
    setLoading(true);
    const dataRequest = {
      id_establecimiento: establecimiento.id_establecimiento,
    };
    listaNotasCreditoRequest(dataRequest)
      .then((res) => {
        if (res.notas_credito) {
          setCreditos(res.notas_credito);
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        setLoading(false);
      });
  }, [establecimiento, recargarCreditos]);

  useEffect(() => {
    if (search.length > 1) {
      setLoading(true);
      const dataRequest = {
        busqueda: search,
        id_establecimiento: establecimiento.id_establecimiento,
      };
      buscarNotasCreditoRequest(dataRequest)
        .then((res) => {
          if (res.notas_credito_autorizadas) {
            setCreditosSearch(res.notas_credito_autorizadas);
          }
        })
        .catch((err) => {
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [search]);

  useEffect(() => {
    const date = new Date();
    const dateFormatEnd = dayjs(date).format("YYYY-MM-DD");
    const dateFormatInit = dayjs(date).subtract(1, "M").format("YYYY-MM-DD");
    setDates({
      ...date,
      date_init: dateFormatInit,
      date_end: dateFormatEnd,
    });
  }, []);

  const onChangeSearch = (text) => {
    setSearch(text);
  };

  const closeToast = () => {
    setToast({
      open: false,
      text: "",
    });
  };

  const openToast = (text = "") => {
    setToast({
      open: true,
      text: text,
    });
  };

  const onFilter = (id, value, date) => {
    if (id == "" && !date) {
      setSearchState(false);
      setLoadingSearch(false);
      return;
    }
    setEmptySearch({ show: false, text: "" });
    setSearchState(true);
    setLoadingSearch(true);
    setCreditosSearch([]);
    const dataRequest = {
      id_establecimiento: establecimiento.id_establecimiento,
      filter: id,
      filter_value: value,
      date_show: dates.show,
      date_init: dates.date_init,
      date_end: dates.date_end,
    };

    busquedaDocumentoCreditoRequest(dataRequest)
      .then((res) => {
        if (res.result && res.documentos.length > 0) {
          setCreditosSearch(res.documentos);
        } else if (res.result && res.documentos.length === 0) {
          setEmptySearch({
            show: true,
            text: id != "estado_documento_electronico" ? value : "",
          });
        }
      })
      .catch()
      .finally(() => {
        setLoadingSearch(false);
      });
  };

  const navegarCrearNotaCredito = async () => {
    const valido = await validarPlan();
    if (!valido) return;
    navigate("/creditos/crearNotaCredito");
  };

  return (
    <AppLayout>
      <Grid>
        {/* Header */}

        <PageSearchDocumento
          title="Notas de crédito"
          searchText="Buscar nota de crédito"
          textButtton="Crear nota de crédito"
          actionButton={navegarCrearNotaCredito}
          onChangeSearch={onChangeSearch}
          search={search}
          onFilter={onFilter}
          dates={dates}
          setDates={setDates}
        />

        <Divider />
        {/* Body */}
        <Grid
          sx={{
            minHeight: 100,
          }}
        >
          {searchState ? (
            <>
              <TablaNotasCredito data={creditosSearch} />
              {emptySearch.show && (
                <Grid
                  container
                  sx={{
                    paddingX: 1,
                    justifyContent: {
                      sx: "flex-start",
                      md: "center",
                    },
                  }}
                  justifyContent={"center"}
                >
                  <Typography mr={2}>
                    No se encontro documentos "{emptySearch.text}".
                  </Typography>
                </Grid>
              )}
            </>
          ) : (
            <TablaNotasCredito data={creditos} />
          )}
          {loadingSearch && (
            <Grid
              container
              sx={{
                paddingX: 1,
                justifyContent: {
                  sx: "flex-start",
                  md: "center",
                },
              }}
              justifyContent={"center"}
            >
              <Typography mr={2}>Buscando documento {""}...</Typography>
              <CircularProgress size={25} />
            </Grid>
          )}
        </Grid>
        {loading && <CircularProgress size={25} />}
      </Grid>
      <Box
        sx={{
          position: "absolute",
          bottom: 10,
          right: 10,
          display: {
            xs: "block",
            sm: "none",
          },
        }}
      >
        <Fab
          onClick={navegarCrearNotaCredito}
          color="primary"
          aria-label="add"
        >
          <Add />
        </Fab>
      </Box>
    </AppLayout>
  );
};

export default NotasCreditoPage;
