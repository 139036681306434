import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";

export const FacturaItem = ({ factura, seleccionarFactura }) => {
  return (
    <Box
      component={"div"}
      onClick={() => {
        seleccionarFactura(factura);
      }}
    >
      <Grid
        container
        sx={{
          minHeight: 35,
          alignItems: "center",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
          },
        }}
      >
        <Grid container flexDirection={"row"} xs={12} sm={12} md={6}>
          <Grid item xs={6}>
            <Typography
              sx={{ textAlign: { xs: "left", md: "left" }, fontWeight: "bold" }}
            >
              {factura.numero_documento}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                textAlign: { xs: "right", md: "left" },
                fontWeight: "bold",
              }}
            >
              ${" "}
              {Number(factura.valor_total)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Grid>
        </Grid>
        <Grid container flexDirection={"row"} xs={12} sm={12} md={6}>
          <Grid item xs={8}>
            <Typography
              sx={{ textAlign: { xs: "left", md: "left" }}}
            >
              {factura.razon_social_cliente}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              sx={{
                textAlign: { xs: "right", md: "left" },
              }}
            >
              {factura.num_identificacion_cliente}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
};
