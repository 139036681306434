import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Select,
  Snackbar,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AccionesContext } from "../../../context/AccionesContext";
import { AuthContext } from "../../../context/AuthContext";

import { AppLayout } from "../../../layouts/AppLayout";
import {
  crearClienteRequest,
  identificacionesRequest,
} from "../../../services/api/ClientesApi";
import {
  calculosRequest,
  consultarFormasPagoRequest,
} from "../../../services/api/DocumentoApi";
import {
  disponibilidadEditarRequest,
  editarNotaCreditoRequest,
  notaCreditoIdRequest,
  registrarNotaCreditoRequest,
} from "../../../services/api/NotaCreditoApi";
import { CrearFacturaEmision } from "../facturas/components/CrearFacturaEmision";
import { CrearFacturaProductos } from "../facturas/components/CrearFacturaProductos";
import { CrearFacturaValores } from "../facturas/components/CrearFacturaValores";
import { CrearCreditoFactura } from "./components/CrearCreditoFactura";
import { emailRegexGlobal } from "../../../helpers/email";
import { ivasRequest } from "../../../services/api/IvasApi";

export const EditarCreditoPage = () => {
  const { establecimiento, user } = useContext(AuthContext);
  const { accionClientes } = useContext(AccionesContext);

  const navigate = useNavigate();

  const { id } = useParams();

  const [tipos_identificaciones, setTipos_identificaciones] = useState([]);

  //FACTURA
  const [factura, setFactura] = useState({});

  const [formasPago, setFormasPago] = useState([]);
  const [pago, setPago] = useState("");
  const [formaPago, setFormaPago] = useState(null);
  const [codigoPago, setCodigoPago] = useState(null);
  const [idFormaPago, setIdFormaPago] = useState(null);
  const [observaciones, setObservaciones] = useState("Sin Observaciones");
  //MOTIVO
  const [motivo, setMotivo] = useState("");

  const handleFormaPago = (e, pago_find) => {
    setPago(e.target.value);
    setFormaPago(pago_find.descripcion_forma_pago);
    setCodigoPago(pago_find.codigo_forma_pago);
    setIdFormaPago(pago_find.id_forma_pago);
  };

  const handleObservaciones = (e) => {
    setObservaciones(e);
  };

  const [loading, setLoading] = useState(false);

  const [loadingData, setLoadingData] = useState(false);

  const [cliente, setCliente] = useState({
    id_cliente: null,
    empresa: false,
    tipo_identificacion: {},
    nombre_cliente: "",
    apellido_cliente: "",
    email_cliente: "",
    telefono_cliente: "",
    numero_documento_cliente: "",
    direccion_cliente: "",
    razon_social_cliente: "",
    cliente_nuevo: true,
  });

  const [emails, setEmails] = useState([]);

  const [productosFactura, setProductosFactura] = useState([]);
  const [change, setChange] = useState(false);

  const [detalle_calculos, setDetalle_calculos] = useState([]);

  const [fechaEmision, setFechaEmision] = useState("");

  const [disponibilidad, setDisponibilidad] = useState(0);

  const [error, setError] = useState({
    text: "",
    show: false,
  });

  const [cabecera_calculos, setCabecera_calculos] = useState({
    impuesto: 0,
    subtotal_iva: 0,
    subtotal_cero: 0,
    subtotal: 0,
    value_iva: 0,
    total_factura: 0,
  });

  const [toast, setToast] = useState({
    open: false,
    text: "",
  });

  const [ivas, setIvas] = useState([]);

  useEffect(() => {
    setError({
      text: "",
      show: false,
    });
    setLoadingData(true);
    const dataRequest = {
      id_documento: id,
    };
    notaCreditoIdRequest(dataRequest)
      .then((res) => {
        if (res.nota_credito?.length > 0) {
          const nota_credito = res.nota_credito[0];
          seleccionarFactura(nota_credito);
          //BUSCAR NOTA DE CREDITO
          const nota_busqueda = nota_credito.notas_credito.find(
            (n) => n.id_documento == id
          );
          if (nota_busqueda) {
            //FECHA
            const date = new Date(nota_busqueda.fecha_emision_documento);
            const dateFormat = dayjs(date).format("YYYY-MM-DD HH:mm:ss");

            onChangeFecha(dateFormat);

            //Productos
            const productos = nota_busqueda.detalles?.map((item) => {
              return {
                ...item,
                nombre_producto: item.descripcion,
                precio_unitario: item.valor_unitario,
                precio_iva: item.precio_iva,
              };
            });

            setProductosFactura(productos);
            setChange(!change);

            //MOTIVOS
            setMotivo(nota_busqueda.motivo_nota_credito);
          }
        } else {
          setError({
            text: "No existe una nota de credito",
            show: true,
          });
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        setLoadingData(false);
      });
  }, []);

  //Tipos identificacion
  useEffect(() => {
    identificacionesRequest()
      .then((res) => {
        if (res.identificaciones) {
          setTipos_identificaciones(res.identificaciones);
        }
      })
      .catch((err) => {
      })
      .finally(() => {});
  }, []);

  //Formas de Pago
  useEffect(() => {
    consultarFormasPagoRequest({})
      .then((res) => {
        if (res.pagos) {
          setFormasPago(res.pagos.rows);
        }
      })
      .catch((err) => {
      })
      .finally(() => {});
  }, []);

  //Calculos
  useEffect(() => {

    const pc = productosFactura.map((p) => {
      return {
        codigo_producto: p.condigo_producto,
        descripcion: p.nombre_producto,
        tiene_iva: p.precio_iva != 0 ? "1" : "0",
        cantidad: p.cantidad,
        valor_unitario: p.precio_unitario,
        precio_iva: p.precio_iva,
      };
    });

    const dataRequest = {
      productos: pc,
    };

    calculosRequest(dataRequest)
      .then((res) => {

        if (res.result && res.cabecera_calculos.length > 0) {
          setCabecera_calculos(res.cabecera_calculos[0]);
        }
        if (res.result && res.detalles_calculos.length > 0) {
          const productoCalculos = productosFactura.map((pf, index) => {
            const index1 = index + 1;

            const producto_calculo = res.detalles_calculos.find(
              (dc) => dc.indice == index1
            );

            return {
              ...pf,
              ...producto_calculo,
            };
          });
          setProductosFactura(productoCalculos);
        }
      })
      .catch((err) => {
      })
      .finally(() => {});
  }, [change]);

  //CALCULAR DISPONIBILIDAD
  useEffect(() => {
    if (factura.id_documento) {
      const dataRequest = {
        id_nota_credito: id,
        id_documento: factura.id_documento,
      };
      disponibilidadEditarRequest(dataRequest)
        .then((res) => {
          setDisponibilidad(res.total_disponible);
        })
        .catch((err) => {})
        .finally(() => {});
    }
  }, [factura.id_documento]);

  //Fecha emision
  useEffect(() => {
    const date = new Date();
    const dateFormat = dayjs(date).format("YYYY-MM-DD HH:mm:ss");

    onChangeFecha(dateFormat);
  }, []);

  useEffect(() => {
    ivasRequest().then((response) => {
      if (response.ivas && response.ivas?.length > 0) {
        setIvas(response.ivas);
      }
    });
  }, []);

  const onChangeFecha = (value) => {
    setFechaEmision(value);
  };

  //CLIENTE
  const onChangeCliente = (name, value) => {
    setCliente({
      ...cliente,
      [name]: value,
    });
  };

  const seleccionarCliente = (e) => {
    setEmails([]);

    const empresa = e.id_tipo_cliente == 2;

    setCliente({
      id_cliente: e.id_cliente,
      tipo_identificacion: {
        descripcion_tipo: tipos_identificaciones.find(
          (e) => e.id_tipo_identificacion
        )?.descripcion_tipo,
        id_tipo_identificacion: e.id_tipo_identificacion,
      },
      nombre_cliente: e.nombre_cliente,
      apellido_cliente: e.apellido_cliente,
      razon_social_cliente: e.razon_social_cliente,
      empresa: e.id_tipo_identificacion == 2,
      email_cliente: e.email_cliente,
      telefono_cliente: e.telefono_cliente,
      numero_documento_cliente: empresa
        ? e.numero_ruc_cliente
        : e.numero_documento_cliente,
      empresa: empresa,
      direccion_cliente: e.direccion_cliente,
      cliente_nuevo: false,
    });

    const emailsArr = JSON.parse(e.contactos[0].emails_contacto);

    const em = emailsArr.filter((ema, i) => i != 0);

    setEmails((e) => [...e, ...em]);
  };

  const quitarCliente = () => {
    setCliente({
      id_cliente: null,
      tipo_identificacion: {},
      nombre_cliente: "",
      apellido_cliente: "",
      email_cliente: "",
      telefono_cliente: "",
      numero_documento_cliente: "",
      direccion_cliente: "",
      cliente_nuevo: true,
    });
    setEmails([]);
  };

  const quitarProducto = (index) => {
    const pfd = productosFactura.filter((p, i) => i != index);
    setProductosFactura(pfd);
    setChange(!change);
    if (productosFactura.length === 1) {
      setCabecera_calculos({
        subtotal_iva: 0,
        subtotal_cero: 0,
        subtotal: 0,
        value_iva: 0,
        total_factura: 0,
      });
    }
  };

  const seleccionarProducto = (e) => {
    const p = productosFactura.find((p) => p.id_producto == e.id_producto);

    if (p?.id_producto) {
      const pff = productosFactura.filter(
        (p) => p.id_producto != e.id_producto
      );
      setProductosFactura([...pff, { ...p, cantidad: Number(p.cantidad) + 1 }]);
    } else {
      setProductosFactura((m) => [...m, { ...e, cantidad: 1 }]);
    }
    setChange(!change);
  };

  const onChangeCantidad = (index, cantidad) => {
    if (cantidad < 0) {
      return;
    }

    const pfc = productosFactura.map((pf, i) => {
      if (i == index) {
        return {
          ...pf,
          cantidad: cantidad,
        };
      }
      return pf;
    });
    setChange(!change);
    setProductosFactura(pfc);
  };

  const onChangePrecio = (index, precio) => {
    if (precio < 0) {
      return;
    }

    const pfc = productosFactura.map((pf, i) => {
      if (i == index) {
        return {
          ...pf,
          precio_unitario: precio,
        };
      }
      return pf;
    });
    setChange(!change);
    setProductosFactura(pfc);
  };

  const onChangeIva = (index, id_iva) => {


    const iva = ivas.find(iva => iva.id_iva === id_iva);

    const pfc = productosFactura.map((pf, i) => {
      if (i == index) {
        return {
          ...pf,
          precio_iva: iva.valor, 
          id_iva: id_iva,
        };
      }
      return pf;
    });
    setChange(!change);
    setProductosFactura(pfc);
  };

  const editarNotaCredito = async () => {
    setError({
      text: "",
      show: false,
    });

    //Factura
    if (!factura.id_documento) {
      setError({
        text: "Seleccione una factura",
        show: true,
      });
      return;
    }

    //Productos
    if (productosFactura.length === 0) {
      setError({
        text: "Seleccione almenos un producto",
        show: true,
      });
      return;
    }

    let valid = true;
    productosFactura.forEach((pf) => {
      if (pf.cantidad == 0) {
        valid = false;
      }
    });

    if (!valid) {
      setError({
        text: "Revise la cantidad de los productos",
        show: true,
      });
      return;
    }

    //MOTIVO
    if (motivo.trim().length === 0) {
      setError({
        text: "Escriba el motivo de la creación de la nota de crédito.",
        show: true,
      });
      return;
    }

    //VALIDAR EMISION
    if (!dayjs(fechaEmision).isValid()) {
      setError({
        text: "Ingrese una fecha valida",
        show: true,
      });
      return;
    }

    const ejemplo = {
      id_documento: 891,
      total_sin_impuestos: 20,
      subtotal_neto: 20,
      total_base_imponible_cero: 20,
      total_base_imponible_doce: 0,
      valor_total_iva: 0,
      valor_total: 20,
      porcentaje_iva: 0,
      motivo_nota_credito: "DEVOLUCION",
      productos: [
        {
          id_establecimiento: 10,
          codigo_producto: "001",
          descripcion: "PrOducto 1",
          tiene_iva: "0",
          cantidad: 1,
          valor_unitario: 1.0,
          indice: 1,
          valor_total: 1.0,
          valor_total_sin_impuesto: 1.0,
          porcentaje_iva: 0,
          valor_iva: 0,
          valor_total_imponible_doce: 0,
          valor_total_imponible_cero: 1.0,
        },
      ],
    };

    const dataRequestDocumento = {
      id_nota_credito: id,
      id_documento: factura.id_documento,
      total_sin_impuestos: cabecera_calculos.subtotal,
      subtotal_neto: cabecera_calculos.subtotal,
      total_base_imponible_cero: cabecera_calculos.subtotal_cero,
      total_base_imponible_doce: cabecera_calculos.subtotal_iva,
      valor_total_iva: cabecera_calculos.subtotal_iva,
      valor_total: cabecera_calculos.total_factura,
      porcentaje_iva: cabecera_calculos.value_iva,
      motivo_nota_credito: motivo,
      fecha_emision_documento: fechaEmision,
      productos: productosFactura.map((pf) => {
        return {
          ...pf,
          descripcion: pf.nombre_producto,
          valor_unitario: pf.precio_unitario,
          tiene_iva: pf.precio_iva != 0 ? "1" : "0",
        };
      }),
    };

    setLoading(true);

    editarNotaCreditoRequest(dataRequestDocumento)
      .then((res) => {
        if (res.result) {
          openToast("Nota de crédito fue editada correctamente !");
          setCabecera_calculos({
            subtotal_iva: 0,
            subtotal_cero: 0,
            subtotal: 0,
            value_iva: 0,
            total_factura: 0,
          });
          setProductosFactura([]);
          navigate(-1);
        } else {
          setError({
            text: res.message,
            show: true,
          });
        }
      })
      .catch((err) => {
        setError({
          text: "Ocurrio un error al editar la nota de crédito",
          show: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const closeToast = () => {
    setToast({
      open: false,
      text: "",
    });
  };

  const openToast = (text = "") => {
    setToast({
      open: true,
      text: text,
    });
  };

  //Agregar Email
  const addEmail = (t) => {
    const regex = emailRegexGlobal;
    if (!regex.test(t)) {
      setError({
        text: "Ingrese un correo válido.",
        show: true,
      });
      return;
    }
    setEmails((e) => [...e, t]);
    setCliente({
      ...cliente,
      email_cliente: "",
    });
  };

  //Remove Email
  const removeEmail = (index) => {
    const e = emails.filter((e, i) => i != index);
    setEmails(e);
  };

  //SELECCIONAR FACTURA
  const seleccionarFactura = (factura) => {
    setFactura(factura);
    // //Productos
    // const productos = factura.detalles?.map((item) => {
    //   return {
    //     ...item,
    //     nombre_producto: item.descripcion,
    //     precio_unitario: item.valor_unitario,
    //     precio_iva: item.precio_iva,
    //   };
    // });

    // setProductosFactura(productos);
    // setChange(!change);
  };

  const addAnexo = (index, anexo) => {
    if (anexo.trim().length <= 2) {
      alert("El anexo debe tener almenos 3 caracteres");
      return;
    }

    const pA = productosFactura[index];

    if (pA.anexos?.length >= 3) {
      alert("Solo se puede agregar 3 anexos por detalle");
      return;
    }

    const asInit = [
      ...(pA.anexos ?? []),

      {
        id_detalle_anexo: null,
        anexo: anexo,
      },
    ];

    const pfc = productosFactura.map((pf, i) => {
      if (i == index) {
        return {
          ...pf,
          anexos: asInit,
        };
      }
      return pf;
    });
    setChange(!change);
    setProductosFactura(pfc);
  };

  const removeAnexo = (index, iAnexo) => {
    const pA = productosFactura[index];

    const aChange = pA.anexos.filter((a, i) => i != iAnexo);

    const pfc = productosFactura.map((pf, i) => {
      if (i == index) {
        return {
          ...pf,
          anexos: aChange,
        };
      }
      return pf;
    });
    setChange(!change);
    setProductosFactura(pfc);
  };

  return (
    <AppLayout>
      <Grid
        container
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          // backgroundColor: 'red',
          p: 1,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "#d4d4ff",
          borderRadius: 3,
        }}
      >
        <Grid xs={12} sm={5} px={1}>
          {loadingData && <CircularProgress size={20} sx={{ marginLeft: 2 }} />}

          {/* Emision */}
          <Grid>
            <CrearFacturaEmision
              fechaEmision={fechaEmision}
              onChangeFecha={onChangeFecha}
              editar
            />
          </Grid>
          <Grid
            my={1}
            // backgroundColor={'red'}
            minHeight={10}
          >
            <CrearCreditoFactura
              seleccionarFactura={seleccionarFactura}
              factura={factura}
              editar
              id={id}
            />
          </Grid>
          <Divider />
        </Grid>
        <Divider sx={{ display: { xs: "block", sm: "none" } }} />
        <Grid my={1} xs={12} sm={7} px={1}>
          {/*  Productos */}
          <Grid>
            <CrearFacturaProductos
              seleccionarProducto={seleccionarProducto}
              productosFactura={productosFactura}
              onChangeCantidad={onChangeCantidad}
              quitarProducto={quitarProducto}
              onChangePrecio={onChangePrecio}
              addAnexo={addAnexo}
              removeAnexo={removeAnexo}
              onChangeIva={onChangeIva}
              ivas={ivas}
            />
          </Grid>
          <Divider />
          {/* Valores */}
          <Grid my={1}>
            <CrearFacturaValores
              component={() => {
                return (
                  <Grid container>
                    <TextField
                      label="Motivo de la nota de crédito"
                      fullWidth
                      multiline
                      maxRows={4}
                      value={motivo}
                      onChange={(v) => setMotivo(v.target.value)}
                      sx={{
                        maxWidth: "90%",
                      }}
                    />
                  </Grid>
                );
              }}
              cabecera_calculos={cabecera_calculos}
            />
          </Grid>
          {loading && <CircularProgress size={25} />}
          {<Alert severity="error">Total disponible: {Number(disponibilidad).toFixed(2)}</Alert>}
          {error.show && <Alert severity="error">{error.text}</Alert>}
          {!loading && factura.id_documento && (
            <Grid
              component={"div"}
              display={"flex"}
              justifyContent={"end"}
              mt={2}
            >
              <Button
                onClick={editarNotaCredito}
                variant="contained"
                sx={{
                  borderRadius: 100,
                  px: 4,
                  backgroundColor: "#000590",
                }}
              >
                Editar nota de crédito
              </Button>
            </Grid>
          )}
        </Grid>

        <Snackbar
          open={toast.open}
          autoHideDuration={6000}
          onClose={closeToast}
        >
          <Alert onClose={closeToast} severity="success" sx={{ width: "100%" }}>
            {toast.text}
          </Alert>
        </Snackbar>
      </Grid>
    </AppLayout>
  );
};
