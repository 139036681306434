import * as React from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { GuiaTableRow } from "./GuiaTableRow";
import { Typography } from "@mui/material";

const columns = [
  {
    id: "estado_documento_electronico",
    label: "Estado documento",
    minWidth: 150,
  },
  {
    id: "numero_documento",
    label: "Documento",
    minWidth: 100,
  },
  {
    id: "fecha_emision_documento",
    label: "Fecha emision",
    minWidth: 80,
  },
  {
    id: "guia_razon_social_transportista",
    label: "Transportista",
    minWidth: 80,
  },
  {
    id: "guia_ruc_transportista",
    label: "Nª transportista",
    minWidth: 60,
  },
  {
    id: "guia_placa",
    label: "Placa",
    minWidth: 30,
  },
  {
    id: "PDF",
    label: "G.PDF",
    minWidth: 50,
  },
  {
    id: "XML",
    label: "G.XML",
    minWidth: 30,
  },
  {
    id: "ANULAR",
    label: "Anular",
    minWidth: 30,
  },
  {
    id: "opciones",
    label: "Reenviar",
  },
];

export const TablaGuiaRemision = ({ data = [] }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  var generarPDF = (
    <Button variant="contained" onClick={() => {}}>
      PDF
    </Button>
  );

  var generarXML = <Button variant="contained">XML</Button>;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: "65vh" }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <Typography>{column.label}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return <GuiaTableRow row={row} columns={columns} />;
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
