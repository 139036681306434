import { RemoveCircle, RemoveRounded, Search } from "@mui/icons-material";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Checkbox,
  IconButton,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { AccionesContext } from "../../../../context/AccionesContext";
import { AuthContext } from "../../../../context/AuthContext";
import { CrearFacturaProductoItem } from "./CrearFacturaProductoItem";
import { ModalBuscarProducto } from "./ModalBuscaProducto";
import { ModalProducto } from "./ModalProducto";

export const CrearFacturaProductos = ({
  seleccionarProducto,
  productosFactura,
  onChangeCantidad,
  quitarProducto,
  onChangePrecio,
  addAnexo,
  removeAnexo,
  onChangeIva,
  ivas = [],
  
}) => {
  const { establecimiento } = useContext(AuthContext);
  const { recargarProductos } = useContext(AccionesContext);
  const [modalSearch, setModalSearch] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const [productos, setProductos] = useState([]);
  const [productosSearch, setProductosSearch] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const [toast, setToast] = useState({
    open: false,
    text: "",
  });

  const openModal = () => setModalCreate(true);
  const closeModal = () => setModalCreate(false);

  const openModalBuscar = () => setModalSearch(true);
  const closeModalBuscar = () => setModalSearch(false);

  const onChangeSearch = (text) => {
    setSearch(text);
  };

  const closeToast = () => {
    setToast({
      open: false,
      text: "",
    });
  };

  const openToast = (text = "") => {
    setToast({
      open: true,
      text: text,
    });
  };

  const [open, setOpen] = useState(false);

  /*
  const handleClick = () => {
    setOpen(true);
  };
  */

  /*
  const handleClose = (e) => {
    setOpen(false);
  };
  */

  return (
    <Grid container flexDirection={"column"}>
      {/* TITULO */}
      <Grid
        container
        flexDirection={"row"}
        justifyContent={"space-between"}
        sx={{ alignItems: "center" }}
        mb={1}
      >
        <Typography sx={{ fontWeight: "bold" }}>Productos</Typography>
        <Button
          variant="contained"
          onClick={openModalBuscar}
          endIcon={<Search color="secondary" />}
        >
          Buscar producto
        </Button>
      </Grid>

      <Divider />
      {/* Header */}
      <Box
        mt={1}
        component={"div"}
        sx={{
          backgroundColor: "#0032d4",
          height: "40px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        }}
      >
        <Box
          sx={{
            width: "20%",
            //backgroundColor: "green",
          }}
        >
          <Typography textAlign={"center"} color={"#fff"}>
            Cantidad
          </Typography>
        </Box>
        <Box
          sx={{
            width: "35%",
            //backgroundColor: "green",
          }}
        >
          <Typography color={"#fff"}>Descripción</Typography>
        </Box>
        <Box
          sx={{
            width: "20%",
            //backgroundColor: "green",
          }}
        >
          <Typography color={"#fff"}>Precio</Typography>
        </Box>
        <Box
          sx={{
            width: "5%",
            // backgroundColor: 'green',
          }}
        >
          <Typography color={"#fff"} textAlign={"center"}>
            IVA
          </Typography>
        </Box>
        <Box
          sx={{
            width: "15%",
            //backgroundColor: 'green',
          }}
        >
          <Typography color={"#fff"} textAlign={"center"}>
            Total
          </Typography>
        </Box>
        <Box
          sx={{
            //backgroundColor: 'green',
            width: "10%",
          }}
        >
          <Typography></Typography>
        </Box>
      </Box>
      {/* Productos */}
      <Box component={"div"}>
        {productosFactura.map((item, index) => {
          return (
            <CrearFacturaProductoItem
              key={index}
              index={index}
              item={item}
              onChangeCantidad={onChangeCantidad}
              onChangePrecio={onChangePrecio}
              quitarProducto={quitarProducto}
              addAnexo={addAnexo}
              removeAnexo={removeAnexo}
              onChangeIva={onChangeIva}
              ivas={ivas}
            />
          );
        })}
      </Box>
      {/* <Typography> {JSON.stringify(productosFactura, 0, 3)}</Typography> */}
      <Box
        mt={2}
        sx={{
          // backgroundColor: 'yellow',
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button sx={{}} onClick={openModal}>
          Crear producto
        </Button>
      </Box>
      <ModalBuscarProducto
        modal={modalSearch}
        close={closeModalBuscar}
        seleccionarProducto={(e) => {
          seleccionarProducto(e);
          closeModalBuscar();
        }}
      />

      <ModalProducto
        modal={modalCreate}
        close={closeModal}
        openToast={openToast}
        seleccionarProducto={seleccionarProducto}
      />

      {/* Validar Decimales Cantidad */}
      {/* <Snackbar 
        open={open} 
        autoHideDuration={3000} 
        onClose={handleClose}
      >
        <Alert 
          onClose={handleClose} 
          severity="error" 
          sx={{ width: '100%' }}
        >
          La cantidad solo acepta enteros
        </Alert>
      </Snackbar> */}
    </Grid>
  );
};
