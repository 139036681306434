import {
  Delete,
  DescriptionRounded,
  Email,
  EmailRounded,
  MoreVertOutlined,
  PictureAsPdf,
  RemoveCircle,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  TableCell,
  TableRow,
  Link,
  IconButton,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import dayjs from "dayjs";
import fileDownload from "js-file-download";
import jsPDF from "jspdf";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ESTADO_DOCUMENTO } from "../../../../configs/configs";
import { AccionesContext } from "../../../../context/AccionesContext";
import { eliminarCompraRequest } from "../../../../services/api/ComprasApi";
import {
  anularDocumentoRequest,
  autorizarDocumentoRequest,
  descargarDocumentoPDFRequest,
  enviarCorreoRequest,
  generarDocumentoRequest,
  generarDocumentoXMLRequest,
} from "../../../../services/api/DocumentoApi";
import { AnularAlerta } from "./AnularAlerta";

export const CompraTableRow = ({ row, columns }) => {
  const navigate = useNavigate();

  const { accionCompras } = useContext(AccionesContext);

  const [autorizando, setAutorizando] = useState(false);
  const [enviandoEmail, setEnviandoEmail] = useState(false);
  const [anulado, setAnulando] = useState(false);

  const [openAlert, setOpenAlert] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [openAnulando, setOpenAnulando] = React.useState(false);
  const [toast, setToast] = useState({
    open: false,
    text: "",
  });

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  //ANULACION LOADIN
  const handleClickAnulando = () => {
    setOpenAnulando(true);
  };

  const handleCloseAnulando = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAnulando(false);
  };

  //ALERT
  const handleClickOpen = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const descargarPDF = (id, num_doc) => {
    descargarDocumentoPDFRequest({ id_documento: id })
      .then((res) => {
        if (res.result === false) {
          alert("No existe el documento !");
          return;
        }
        fileDownload(res, `FACTURA COMPRA ${num_doc}.pdf`);
      })
      .catch((err) => {
        alert("Ocurrio un error al descargar el documento !");
      });
  };

  const descargarXML = (id, num_doc) => {
    generarDocumentoXMLRequest({ id_documento: id })
      .then((res) => {
        if (res.result === false) {
          alert("No existe el documento !");
          return;
        }
        fileDownload(res, `FACTURA COMPRA ${num_doc}.xml`);
      })
      .catch((err) => {
        alert("Ocurrio un error al descargar el documento !");
      });
  };

  const autorizar = (id) => {
    setAutorizando(true);
    autorizarDocumentoRequest({ id_documento: id }).finally(() => {
      setAutorizando(false);
    });
  };

  const anularDocumento = (id) => {
    handleCloseAlert();
    handleClickAnulando(handleClickAnulando);
    setAnulando(true);
    anularDocumentoRequest({ id_documento: id })
      .then((res) => {
        if (res.result) {
        } else {
          alert(res.message);
        }
      
      })
      .catch((err) => {
      })
      .finally(() => {
        setAnulando(false);
        handleCloseAnulando();
      });
  };

  const closeToast = () => {
    setToast({
      open: false,
      text: "",
    });
  };

  const openToast = (text = "") => {
    setToast({
      open: true,
      text: text,
    });
  };

  const eliminarDocumento = (id) => {

    const confirm = window.confirm('¿Estas seguro de eliminar esta compra?');

    if(!confirm){
      return;
    }

    const dataRequest = {
      id_documento: id,
    };

    eliminarCompraRequest(dataRequest)
      .then((res) => {
        if (res.result) {
          openToast("Compra eliminada correctamente");
          accionCompras();
        }
      })
      .catch();
  };

  return (
    <TableRow
      hover
      role="checkbox"
      // component={'div'}
      // onClick={() => navigate(`/facturas/${row.id_documento}`)}
      tabIndex={-1}
      key={row.numero_documento}
    >
      {columns.map((column) => {
        const value = row[column.id];

        if (column.id == "fecha_registro") {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              component={"div"}
              onClick={() => navigate(`/compras/${row.id_documento}`)}
            >
              {dayjs(new Date(value)).format("YYYY-MM-DD")}
            </TableCell>
          );
        }

        if (column.id === "estado_documento_electronico") {
          return (
            <TableCell key={column.id} align={column.align} component={"div"}>
              {ESTADO_DOCUMENTO[value] === "EN PROCESO" ||
              ESTADO_DOCUMENTO[value] === "CREADO" ||
              ESTADO_DOCUMENTO[value] === "DEVUELTO" ? (
                <Button
                  variant={"contained"}
                  disabled={autorizando}
                  sx={{
                    height: ESTADO_DOCUMENTO[value] == "CREADO" ? 28 : 45,
                    backgroundColor: "#00a23e",
                    fontSize: ESTADO_DOCUMENTO[value] == "CREADO" ? 14 : 12,
                  }}
                  onClick={() => {
                    if (value == 2) {
                      const confirm = window.confirm(
                        "Su documento fue devuelto por el SRI, asegúrese de revisar los datos antes de volver a enviar."
                      );
                      if (!confirm) {
                        return;
                      }
                    }
                    if (value == 4) {
                      const confirm = window.confirm(
                        "El SRI devolvió el estado EN PROCESO, esperar un par de horas para volver enviar, si ya espero un tiempo prudente darle en Aceptar."
                      );
                      if (!confirm) {
                        return;
                      }
                    }
                    autorizar(row.id_documento);
                  }}
                >
                  {autorizando ? (
                    <CircularProgress size={20} color={"secondary"} />
                  ) : ESTADO_DOCUMENTO[value] == "CREADO" ? (
                    "AUTORIZAR"
                  ) : (
                    `AUTORIZAR - ${ESTADO_DOCUMENTO[value]}`
                  )}
                </Button>
              ) : (
                ESTADO_DOCUMENTO[value]
              )}
            </TableCell>
          );
        }

        if (column.id == "numero_documento") {
          return (
            <TableCell
              component={"div"}
              onClick={() => navigate(`/compras/${row.id_documento}`)}
              key={column.id}
              align={column.align}
            >
              <Link>{value}</Link>
            </TableCell>
          );
        }

        if (column.id == "fecha_emision_documento") {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              component={"div"}
              onClick={() => navigate(`/compras/${row.id_documento}`)}
            >
              {dayjs(new Date(value)).format("YYYY-MM-DD")}
            </TableCell>
          );
        }

        if (column.id == "valor_total") {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              component={"div"}
              onClick={() => navigate(`/compras/${row.id_documento}`)}
            >
              {Number(value).toFixed(2) ?? ""}
            </TableCell>
          );
        }

        if (column.id == "PDF") {
          return (
            <TableCell key={column.id} align={column.align}>
              <Tooltip
                title={`Descargar ${row.numero_documento}.pdf`}
                placement={"top"}
              >
                <IconButton
                  onClick={() => {
                    // handleGenerarPDF(row.id_documento, row.numero_documento);
                    descargarPDF(row.id_documento, row.numero_documento);
                  }}
                  color={"primary"}
                >
                  <PictureAsPdf />
                </IconButton>
              </Tooltip>
            </TableCell>
          );
        }

        if (column.id == "XML") {
          return (
            <TableCell key={column.id} align={column.align}>
              <Tooltip
                title={`Descargar ${row.numero_documento}.xml`}
                placement="top"
              >
                <IconButton
                  color="primary"
                  disabled={
                    !(
                      ESTADO_DOCUMENTO[row.estado_documento_electronico] ==
                      "AUTORIZADO"
                    )
                  }
                  onClick={() =>
                    descargarXML(row.id_documento, row.numero_documento)
                  }
                >
                  <DescriptionRounded />
                </IconButton>
              </Tooltip>
            </TableCell>
          );
        }
        if (column.id == "eliminar") {
          return (
            <TableCell key={column.id} align={column.align}>
              <Tooltip placement="top" title={"Eliminar documento"}>
                <IconButton
                  color="error"
                  disabled={row.estado_documento_electronico == 0}
                  onClick={() => eliminarDocumento(row.id_documento)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </TableCell>
          );
        }

        return (
          <TableCell
            key={column.id}
            align={column.align}
            component={"div"}
            onClick={() => navigate(`/compras/${row.id_documento}`)}
          >
            {column.format && typeof value === "number"
              ? column.format(value)
              : value}
          </TableCell>
        );
      })}
      {/* LOADING */}
      <Snackbar
        TransitionComponent={Slide}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          everity="info"
          variant="standard"
          color="info"
          icon={false}
          sx={{ width: "100%", pr: 15 }}
        >
          <CircularProgress size={18} sx={{ mr: 2 }} />
          Enviando correo...
        </Alert>
      </Snackbar>
      {/* LOADING ANULADO*/}
      <Snackbar
        TransitionComponent={Slide}
        open={openAnulando}
        autoHideDuration={6000}
        onClose={handleCloseAnulando}
      >
        <Alert
          everity="info"
          variant="standard"
          color="info"
          icon={false}
          sx={{ width: "100%", pr: 15 }}
        >
          <CircularProgress size={18} sx={{ mr: 2 }} />
          Anulando documento...
        </Alert>
      </Snackbar>

      {/* TOAST */}
      <Snackbar open={toast.open} autoHideDuration={6000} onClose={closeToast}>
        <Alert onClose={closeToast} severity="success" sx={{ width: "100%" }}>
          {toast.text}
        </Alert>
      </Snackbar>
      {/* ALERTA ANULACION */}
      <AnularAlerta
        open={openAlert}
        handleClose={handleCloseAlert}
        anular={anularDocumento}
        documento={row}
      />
    </TableRow>
  );
};
