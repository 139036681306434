import { Edit } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";

const UsuarioInfo = ({ usuario, reload = () => {} }) => {
  const [modal, setModal] = useState(false);

  const handleOpen = () => setModal(true);
  const handleClose = () => setModal(false);

  
  return (
    <Grid>
      <Grid
        height={30}
        flexDirection={"row"}
        display={"flex"}
        alignItems={"center"}
        position={"relative"}
      >
        <Typography fontWeight={"bold"} color={"darkgrey"}>
          USUARIO
        </Typography>
        
      </Grid>
      <Grid container flexDirection={"row"} justifyContent={"space-between"}>
        <Typography fontWeight={"bold"}>Email</Typography>
        <Typography>{usuario.email_usuario}</Typography>
      </Grid>
      <Grid
        container
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Typography fontWeight={"bold"}>Usuario</Typography>
        <Typography>{usuario.nombre_usuario}</Typography>
      </Grid>
      <Grid
        container
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Typography fontWeight={"bold"}>Fecha registro</Typography>
        {usuario.fecha_creacion.length > 0 && (
          <Typography>
            {dayjs(usuario.fecha_creacion)
              .subtract(5, "hour")
              .format("YYYY-MM-DD HH:mm:ss")}
          </Typography>
        )}
      </Grid>

    
    </Grid>
  );
};

export default UsuarioInfo;
