import React, { useState } from 'react';

export const useForm = (initValues) => {

    const [form, setForm] = useState({ ...initValues });

    const onChangeForm = (name, value) => {
        setForm({
            ...form,
            [name]: value,
        })
    }

    const onReset = () => {
        setForm({ ...initValues });
    }


    return [
        form,
        onChangeForm,
        onReset,
        initValues,
        setForm,
    ]
}
