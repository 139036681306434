import { Divider, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";

export const RegistrosInfo = ({ registros }) => {
  return (
    <Grid>
      <Grid
        height={30}
        flexDirection={"row"}
        display={"flex"}
        alignItems={"center"}
        position={"relative"}
      >
        <Typography fontWeight={"bold"} color={"darkgrey"}>
          REGISTROS
        </Typography>
      </Grid>
      <Grid>
        {/*  */}
        <Grid
          display={"flex"}
          justifyContent={"space-between"}
          height={30}
          alignItems={"center"}
        >
          <Grid width={"30%"} display={"flex"} justifyContent={"start"}>
            <Typography>Plan</Typography>
          </Grid>
          <Grid width={"20%"} display={"flex"} justifyContent={"center"}>
            <Typography>C. doc</Typography>
          </Grid>
          <Grid width={"15%"} display={"flex"} justifyContent={"center"}>
            <Typography>F. inicio</Typography>
          </Grid>
          <Grid width={"15%"} display={"flex"} justifyContent={"center"}>
            <Typography>F. fin</Typography>
          </Grid>
          <Grid width={"15%"} display={"flex"} justifyContent={"center"}>
            <Typography>F. reg</Typography>
          </Grid>
        </Grid>
        <Divider />
        {/*  */}
        <Grid maxHeight={"60vh"} overflow={"auto"}>
          {registros.map((registro) => {
            return (
              <Grid
                onClick={() => {}}
                component={"div"}
                className="item_hover"
                display={"flex"}
                justifyContent={"space-between"}
                minHeight={30}
                alignItems={"center"}
              >
                <Grid width={"30%"} display={"flex"} justifyContent={"start"}>
                  <Typography fontSize={13}>
                    {registro.registro_nombre_plan}
                  </Typography>
                </Grid>
                <Grid width={"20%"} display={"flex"} justifyContent={"center"}>
                  <Typography fontSize={13}>
                    {registro.cantidad_documentos ?? "ILIMITADO"}
                  </Typography>
                </Grid>

                <Grid width={"15%"} display={"flex"} justifyContent={"center"}>
                  <Typography fontSize={13}>
                    {dayjs(registro.fecha_inicio_plan).format("DD-MM-YYYY")}
                  </Typography>
                </Grid>
                <Grid width={"15%"} display={"flex"} justifyContent={"center"}>
                  <Typography fontSize={13}>
                    {registro.fecha_fin_plan
                      ? dayjs(registro.fecha_fin_plan).format("DD-MM-YYYY")
                      : "NO APLICA"}
                  </Typography>
                </Grid>
                <Grid width={"15%"} display={"flex"} justifyContent={"center"}>
                  <Typography fontSize={13}>
                    {dayjs(registro.fecha_registro_plan).format("DD-MM-YYYY")}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};
