import API from "./ApiConfig"

const URL_PLAN = '/plan';

export const planesRequest = async (data) => {
    const request = await API.post(URL_PLAN + '/planes_usuario_admin', data);
    const response = request.data;
    return response;
}

export const busquedaPlanesRequest = async (data) => {
    const request = await API.post(URL_PLAN + '/busqueda_planes_usuario_admin', data);
    const response = request.data;
    return response;
}

export const planesBusquedaRequest = async (data) => {
    const request = await API.post(URL_PLAN + '/planes', data);
    const response = request.data;
    return response;
}

export const tiposPlanRequest = async (data) => {
    const request = await API.post(URL_PLAN + '/tipos_plan', data);
    const response = request.data;
    return response;
}

export const crearPlanRequest = async (data) => {
    const request = await API.post(URL_PLAN + '/crear_plan', data);
    const response = request.data;
    return response;
}

export const planIdRequest = async (data) => {
    const request = await API.post(URL_PLAN + '/plan_id', data);
    const response = request.data;
    return response;
}

export const actualizarPlanRequest = async (data) => {
    const request = await API.post(URL_PLAN + '/actualizar_plan', data);
    const response = request.data;
    return response;
}

export const eliminarPlanRequest = async (data) => {
    const request = await API.post(URL_PLAN + '/eliminar_plan', data);
    const response = request.data;
    return response;
}

export const registrarPlanRequest = async (data) => {
    const request = await API.post(URL_PLAN + '/registrar_plan_admin', data);
    const response = request.data;
    return response;
}

export const registrosPlanesRequest = async (data) => {
    const request = await API.post(URL_PLAN + '/registros_planes_admin', data);
    const response = request.data;
    return response;
}

export const busquedaRegistrosPlanesRequest = async (data) => {
    const request = await API.post(URL_PLAN + '/busqueda_registros_planes_admin', data);
    const response = request.data;
    return response;
}

export const registroPlanIdRequest = async (data) => {
    const request = await API.post(URL_PLAN + '/registro_plan_id', data);
    const response = request.data;
    return response;
}

export const registrosPlanUsuarioIdRequest = async (data) => {
    const request = await API.post(URL_PLAN + '/registros_plan_usuario_id', data);
    const response = request.data;
    return response;
}

export const renovarPlanRequest = async (data) => {
    const request = await API.post(URL_PLAN + '/renovar_plan', data);
    const response = request.data;
    return response;
}

export const renovarActualizarPlanRequest = async (data) => {
    const request = await API.post(URL_PLAN + '/renovar_actualizar_plan', data);
    const response = request.data;
    return response;
}

export const validarPlanUsuarioRequest = async (data) => {
    const request = await API.post(URL_PLAN + '/validar_usuario', data);
    const response = request.data;
    return response;
}

export const reporteRegistrosPlanRequest = async (data) => {
    const request = await API.post(URL_PLAN + '/reporte_registros_plan', data);
    const response = request.data;
    return response;
}

export const generarReporteRegistrosXLSXRequest = async (data) => {
    const request = await API.post(URL_PLAN + '/generar_reporte_registros_xlsx', data, { responseType: 'arraybuffer' });
    const response = request.data;
    return response;
}