import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useContext, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";

export const CrearFacturaEmision = ({
  editar = false,
  fechaEmision = "",
  onChangeFecha = () => {},
}) => {
  const { user, establecimiento, setEstablecimiento } = useContext(AuthContext);

  return (
    <Grid container flexDirection={"column"}>
      <Grid container alignItems={"center"} justifyContent={"space-between"}>
        {editar ? (
          <Fragment>
            <TextField
                label="Fecha emisión"
                size="small"
                type={"datetime-local"}
                onChange={(e) => {
                  onChangeFecha(e.target.value);
                }}
                value={fechaEmision}
              />
            <Typography mt={1}>
              Establecimiento: {establecimiento.direccion_establecimiento}
            </Typography>
          </Fragment>
        ) : (
          <Fragment>
            <Grid mb={1} xs={12}>
              <TextField
                label="Fecha emisión"
                size="small"
                type={"datetime-local"}
                onChange={(e) => {
                  onChangeFecha(e.target.value);
                }}
                value={fechaEmision}
              />
            </Grid>
            <Grid xs={12}>
              <FormControl size="small">
                <InputLabel id="demo-simple-select-label">
                  Establecimiento
                </InputLabel>
                <Select
                  id="demo-simple-select"
                  value={establecimiento.direccion_establecimiento}
                  label="Establecimiento"
                  onChange={(e) => {
                    const es = user.establecimientos.find(
                      (esta) => esta.direccion_establecimiento == e.target.value
                    );
                    setEstablecimiento(es);
                  }}
                >
                  {user.establecimientos.map((e) => {
                    return (
                      <MenuItem
                        key={e.id_establecimiento}
                        value={e.direccion_establecimiento}
                      >
                        {e.direccion_establecimiento}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Grid>
  );
};
