import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  AssessmentOutlined,
  DescriptionOutlined,
  DifferenceOutlined,
  ExpandLess,
  ExpandMore,
  Home,
  HomeOutlined,
  InsertDriveFile,
  InsertDriveFileOutlined,
  LocalShippingOutlined,
  Logout,
  PeopleOutline,
  ShoppingCart,
  ShoppingCartOutlined,
  StarBorder,
  WidgetsOutlined,
} from "@mui/icons-material";
import { AuthContext } from "../../context/AuthContext";
import { useState } from "react";

export const SideMenu = ({ closeDrawer }) => {
  
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const [guiasExpand, setGuiasExpand] = useState(false);
  const handleExpandGuias = () => setGuiasExpand(true);
  const handleCloseGuias = () => setGuiasExpand(false);

  const [reportesExpand, setReportesExpand] = useState(false);
  const handleExpandReportes = () => setReportesExpand(true);
  const handleCloseReportes = () => setReportesExpand(false);

  return (
    <List>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            navigate("/inicio");
            closeDrawer();
          }}
        >
          <ListItemIcon>
            <HomeOutlined />
          </ListItemIcon>
          <ListItemText primary="Inicio" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            navigate("/productos");
            closeDrawer();
          }}
        >
          <ListItemIcon>
            <WidgetsOutlined />
          </ListItemIcon>
          <ListItemText primary="Productos/Servicios" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            navigate("/clientes");
            closeDrawer();
          }}
        >
          <ListItemIcon>
            <PeopleOutline />
          </ListItemIcon>
          <ListItemText primary="Clientes" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            navigate("/facturas");
            closeDrawer();
          }}
        >
          <ListItemIcon>
            <DescriptionOutlined />
          </ListItemIcon>
          <ListItemText primary="Facturas" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            navigate("/compras");
            closeDrawer();
          }}
        >
          <ListItemIcon>
            <ShoppingCartOutlined />
          </ListItemIcon>
          <ListItemText primary="Compras" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            navigate("/creditos");
            closeDrawer();
          }}
        >
          <ListItemIcon>
            <DifferenceOutlined />
          </ListItemIcon>
          <ListItemText primary="Notas de crédito" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            guiasExpand ? handleCloseGuias() : handleExpandGuias();
          }}
        >
          <ListItemIcon>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <InsertDriveFileOutlined />
              <LocalShippingOutlined
                style={{
                  position: "absolute",
                  marginTop: 3,
                  width: 11,
                  height: 11,
                }}
              />
            </span>
          </ListItemIcon>
          <ListItemText primary="Guías de Remisión" />
          {guiasExpand ? (
            <ExpandLess sx={{ ml: 1 }} />
          ) : (
            <ExpandMore sx={{ ml: 1 }} />
          )}
        </ListItemButton>
      </ListItem>
      <Collapse in={guiasExpand} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              navigate("/guias");
              closeDrawer();
            }}
          >
            <ListItemIcon>
              <InsertDriveFileOutlined />
            </ListItemIcon>
            <ListItemText primary="Guías" />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              navigate("/transportistas");
              closeDrawer();
            }}
          >
            <ListItemIcon>
              <LocalShippingOutlined />
            </ListItemIcon>
            <ListItemText primary="Transportistas" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            reportesExpand ? handleCloseReportes() : handleExpandReportes();
          }}
        >
          <ListItemIcon>
            <AssessmentOutlined />
          </ListItemIcon>
          <ListItemText primary="Reportes" />
          {reportesExpand ? (
            <ExpandLess sx={{ ml: 1 }} />
          ) : (
            <ExpandMore sx={{ ml: 1 }} />
          )}
        </ListItemButton>
      </ListItem>
      <Collapse in={reportesExpand} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              navigate("/reportes/ventas");
              closeDrawer();
            }}
          >
            <ListItemIcon>
              <DescriptionOutlined />
            </ListItemIcon>
            <ListItemText primary="Ventas" />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              navigate("/reportes/compras");
              closeDrawer();
            }}
          >
            <ListItemIcon>
              <ShoppingCartOutlined />
            </ListItemIcon>
            <ListItemText primary="Compras" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            logout();
          }}
        >
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText primary="Cerrar Sesión" />
        </ListItemButton>
      </ListItem>
    </List>
  );
};
