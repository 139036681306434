import { Divider, Grid, Typography } from "@mui/material";
import React, { Component } from "react";

export const CrearFacturaValores = ({
  cabecera_calculos,
  component = () => {},
}) => {
  return (
    <Grid
      container
      display={"flex"}
      flexDirection={"row"}
    
    >
      {/* OTRO COMPONENTE */}
      <Grid
        container
        xs={6}
        mt={0.5}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        {component()}
      </Grid>
      {/* DETALLAES */}
      <Grid
        container
        xs={6}
        mt={0.5}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
      {/* SUBTOTAL */}
      <Grid
        container
        xs={12}
        mt={0.5}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Typography fontWeight={"bold"}>Subtotal</Typography>
        <Typography>
          {cabecera_calculos.subtotal?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </Grid>
      {/* SUBTOTAL IVA */}
      <Grid
        container
        xs={12}
        mt={0.5}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Typography fontWeight={"bold"}>Subtotal IVA</Typography>
        <Typography>
          {cabecera_calculos.subtotal_iva?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </Grid>
      {/* SUBTOTAL 0*/}
      <Grid
        container
        xs={12}
        mt={0.5}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Typography fontWeight={"bold"}>Subtotal 0</Typography>
        <Typography>
          {cabecera_calculos.subtotal_cero?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </Grid>
      <Grid
        container
        mt={0.5}
        xs={12}
        mb={0.5}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Typography fontWeight={"bold"}>IVA</Typography>
        <Typography>
          {cabecera_calculos.value_iva?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </Grid>
      <Divider />
      <Grid
        container
        mt={1}
        xs={12}
        flexDirection={"row"}
        justifyContent={"space-between"}
        sx={{
          alignItems: "center",
        }}
      >
        <Typography fontWeight={"bold"} fontSize={16}>
          TOTAL
        </Typography>
        <Typography fontWeight={"bold"} fontSize={17}>
          {cabecera_calculos.total_factura?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </Grid>
      </Grid>

      <Divider />
    </Grid>
  );
};
