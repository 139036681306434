import { Edit } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";

const EmisorInfo = ({ emisor, reload = () => {} }) => {


  return (
    <Grid>
      <Grid
        height={30}
        flexDirection={"row"}
        display={"flex"}
        alignItems={"center"}
        position={"relative"}
      >
        <Typography fontWeight={"bold"} color={"darkgrey"}>
          EMISOR
        </Typography>
      </Grid>
      <Grid container flexDirection={"row"} justifyContent={"space-between"}>
        <Typography fontWeight={"bold"}>Nombre / Razon social</Typography>
        <Typography>{emisor.razon_social_emisor}</Typography>
      </Grid>
      <Grid container flexDirection={"row"} justifyContent={"space-between"}>
        <Typography fontWeight={"bold"}>RUC</Typography>
        <Typography>{emisor.ruc_emisor}</Typography>
      </Grid>
      <Grid container flexDirection={"row"} justifyContent={"space-between"}>
        <Typography fontWeight={"bold"}>
          Obligado a llevar contabilidad
        </Typography>
        <Typography>{emisor.obligado_contabilidad}</Typography>
      </Grid>
      <Grid container flexDirection={"row"} justifyContent={"space-between"}>
        <Typography fontWeight={"bold"}>Tipo Contribuyente</Typography>
        <Typography>
          {emisor.tipo_contribuyente.descripcion_contribuyente}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EmisorInfo;
