import { Add } from "@mui/icons-material";

import {
  Alert,
  CircularProgress,
  Divider,
  Fab,
  Grid,
  Snackbar,
  Box,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AccionesContext } from "../../../context/AccionesContext";
import { AuthContext } from "../../../context/AuthContext";
import { PageSearch } from "../../../global/components/PageSearch";
import { AppLayout } from "../../../layouts/AppLayout";
import {
  clientesBusquedaRequest,
  clientesRequest,
} from "../../../services/api/ClientesApi";
import { ModalCliente } from "./components/ModalCliente";
import { TablaClientes } from "./components/TablaClientes";
import { usePagination } from "../../../hooks/usePagination";

export const ClientesPage = () => {

  const { establecimiento } = useContext(AuthContext);
  const { recargarClientes } = useContext(AccionesContext);
  const [modalCreate, setModalCreate] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const { page, limit, loadMore, reset, onChangeTablePagination } = usePagination(100);

  const [toast, setToast] = useState({
    open: false,
    text: "",
  });

  useEffect(() => {
    reset();
    setSearch('');
    setClientes([]);
  }, [recargarClientes]);

  useEffect(() => {
    reset();
    setLoading(true);
    const pagination = {
      limit,
      page: 1,
    }
    clientesPaginationRequest(pagination)
      .then((res) => {
        if (res.data) {
          const c = res.data.map(cl => {
            return {
              ...cl.cliente,
              ...cl.contactos[0],
              contactos: cl.contactos,

            }
          })

          setClientes(c);
        }
      })
      .finally(() => setLoading(false));
  }, [recargarClientes, search]);

  useEffect(() => {
    if (page === 1) return;
    setLoading(true);
    const pagination = {
      limit,
      page,
    }
    clientesPaginationRequest(pagination)
      .then((res) => {
        if (res.data) {
          const c = res.data.map(cl => {
            return {
              ...cl.cliente,
              ...cl.contactos[0],
              contactos: cl.contactos,

            }
          })

          setClientes((prev) => [...prev, ...c]);
        }
      })
      .finally(() => setLoading(false));

  }, [page]);

  const clientesPaginationRequest = async (pagination) => {
    if (!search) {
      return await clientesRequest({ id_establecimiento: establecimiento.id_establecimiento }, pagination)
    }
    if (!!search) {
      return await clientesBusquedaRequest({ id_establecimiento: establecimiento.id_establecimiento, search_cliente: search }, pagination)
    }
  }

  // useEffect(() => {
  //   setClientes([]);
  //   setLoading(true);
  //   const dataRequest = {
  //     id_establecimiento: establecimiento.id_establecimiento,
  //   };
  //   clientesRequest(dataRequest)
  //     .then((res) => {

  //       if (res.data) {
  //         const c = res.data.map(cl => {
  //           return {
  //             ...cl.cliente,
  //             ...cl.contactos[0],
  //             contactos: cl.contactos,

  //           }
  //         })

  //         setClientes(c);
  //       }
  //     })
  //     .catch((err) => {
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, [establecimiento, recargarClientes]);

  // useEffect(() => {
  //   if (search.length > 1) {
  //     setLoading(true);
  //     const dataRequest = {
  //       search_cliente: search,
  //       id_establecimiento: establecimiento.id_establecimiento,
  //     };
  //     clientesBusquedaRequest(dataRequest)
  //       .then((res) => {
  //         if (res.clientes) {
  //           const c = res.clientes.map(cl => {
  //             return {
  //               ...cl.cliente,
  //               ...cl.contactos[0],
  //               contactos: cl.contactos,

  //             }
  //           })

  //           setClientesSearch(c);
  //         }
  //       })
  //       .catch((err) => {
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // }, [search, establecimiento]);

  const openModal = () => setModalCreate(true);
  const closeModal = () => setModalCreate(false);

  const onChangeSearch = (text) => {
    setSearch(text);
  };

  const closeToast = () => {
    setToast({
      open: false,
      text: "",
    });
  };

  const openToast = (text = "") => {
    setToast({
      open: true,
      text: text,
    });
  };

  return (
    <AppLayout>
      <Grid>
        {/* Header */}
        <PageSearch
          title="Clientes"
          searchText="Buscar cliente"
          textButtton="Crear Cliente"
          actionButton={openModal}
          onChangeSearch={onChangeSearch}
          search={search}
        />
        <Divider />
        {/* Body */}
        <Grid
          sx={{
            minHeight: 100,
          }}
        >
          <TablaClientes data={clientes} onChangeTablePagination={onChangeTablePagination} />
          {loading && <CircularProgress size={25} />}
          <Snackbar
            open={toast.open}
            autoHideDuration={6000}
            onClose={closeToast}
          >
            <Alert
              onClose={closeToast}
              severity="success"
              sx={{ width: "100%" }}
            >
              {toast.text}
            </Alert>
          </Snackbar>
        </Grid>
        <Box
          sx={{
            position: "absolute",
            bottom: 10,
            right: 10,
            display: {
              xs: "block",
              sm: "none",
            },
          }}
        >
          <Fab onClick={openModal} color="primary" aria-label="add">
            <Add />
          </Fab>
        </Box>
        <ModalCliente
          modal={modalCreate}
          close={closeModal}
          openToast={openToast}
        />
      </Grid>
    </AppLayout>
  );
};
