import React, { useContext, useEffect, useState } from "react";
import { ArrowBack, CalendarViewMonth, Filter } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { Filters } from "../../../../global/components/Filters";
import dayjs from "dayjs";
import { AuthContext } from "../../../../context/AuthContext";
import {
  generarReporteRegistrosXLSXRequest,
  reporteRegistrosPlanRequest,
} from "../../../../services/api/PlanesApi";
import { TablaRegistrosPlanes } from "./TablaRegistrosPlanes";
import { TablaReporteRegistros } from "./TablaReporteRegistros";
import fileDownload from "js-file-download";

const ModalReporteRegistros = ({ visible, close }) => {
  const { user } = useContext(AuthContext);

  const [reporte, setReporte] = useState([]);
  const [totales, setTotales] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const [loadingXLSX, setLoadingXLSX] = useState(false);

  const filtersInit = [
    {
      id: "ruc_emisor",
      label: "RUC emisor",
      value: "",
      type: "input",
    },
    {
      id: "razon_social_emisor",
      label: "Razon social",
      value: "",
      type: "input",
    },
    {
      id: "fecha_registro_plan",
      label: "Fecha registro",
      value: [
        dayjs(new Date()).subtract(1, "years").format("YYYY-MM-DD"),
        dayjs(new Date()).format("YYYY-MM-DD"),
      ],
      type: "date",
    },
  ];

  const [filtros, setFiltros] = useState([...filtersInit]);

  useEffect(() => {
    const dataRequest = {
      id_usuario: user.usuario?.id_usuario,
      ruc_emisor: filtros.find((f) => f.id == "ruc_emisor").value,
      razon_social_emisor: filtros.find((f) => f.id == "razon_social_emisor")
        .value,
      fecha_registro_plan: filtros.find((f) => f.id == "fecha_registro_plan")
        .value,
    };

    setIsLoading(true);

    reporteRegistrosPlanRequest(dataRequest)
      .then((res) => {
        if (res.result && res.reporte) {
          setReporte(res.reporte);
        }
        if (res.result && res.totales) {
          setTotales(res.totales);
        }
      })
      .finally(() => setIsLoading(false));
  }, [reload]);

  const closeModal = () => {
    setFiltros(filtersInit);
    close();
  };

  const handleReload = () => setReload(!reload);
  const handleReset = () => {
    setFiltros(filtersInit);
    handleReload();
  };

  const generarXLSX = () => {
    setLoadingXLSX(true);
    const dataRequest = {
      reporte: reporte,
      totales: totales,
    };
    generarReporteRegistrosXLSXRequest(dataRequest)
      .then((res) => {
        const date = new Date();
        const date_reporte = dayjs(date).format("YYYYMMDDD-HHmmss");
        fileDownload(res, `REPORTE-PLANES-${date_reporte}.xlsx`);
      })
      .catch((err) => {
        alert("Ocurrio un error al descargar el reporte !");
      })
      .finally(() => {
        setLoadingXLSX(false);
      });
  };

  return (
    <Modal open={visible} onClose={closeModal}>
      <Grid
        sx={{
          minHeight: "100vh",
          backgroundColor: "#fff",
        }}
      >
        {/* Header Go Back */}
        <Grid
          container
          flexDirection={"row"}
          alignItems={"center"}
          position={"relative"}
          height={45}
        >
          <IconButton
            onClick={closeModal}
            sx={{
              position: "absolute ",
            }}
          >
            <ArrowBack />
          </IconButton>
          <Grid item xs={12}>
            <Typography fontWeight={"bold"} fontSize={16} textAlign={"center"}>
              Nuevo reporte
            </Typography>
          </Grid>
        </Grid>
        {/*  */}
        <Filters
          filters={filtros}
          onChangeFilters={(f) => setFiltros([...f])}
          onSubmit={handleReload}
          onReset={handleReset}
        />
        {/* Tabla reporte */}
        <Grid>
          {isLoading && (
            <Grid
              mt={1}
              container
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CircularProgress size={25} />
              <Typography ml={2}>Buscando registro...</Typography>
            </Grid>
          )}
          <TablaReporteRegistros data={reporte} />
          <Grid container justifyContent={"end"} p={1}>
            <Typography ml={5} fontSize={16}>
              <b>TOTAL: </b> $ {totales.suma_total}
            </Typography>
            <Typography ml={5} fontSize={16}>
              <b>Nº. REGISTROS: </b> {totales.cantidad_registros}
            </Typography>
          </Grid>
          {!isLoading && reporte.length === 0 && (
            <Grid
              mt={1}
              container
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography ml={2}>
                No se encontraron registros con los siguientes datos:
              </Typography>
              {filtros.map((f) => {
                if (f.value === "") {
                  return;
                }

                return (
                  <Typography mx={1} key={f.id}>
                    <b>{f.label.toLocaleUpperCase()}</b>{" "}
                    {f.type === "date" ? f.value.join("  /  ") : f.value}
                  </Typography>
                );
              })}
            </Grid>
          )}
        </Grid>
        {/* Botones reporte */}
        <Grid mt={2} px={1}>
          <Button
            variant={"contained"}
            color={"success"}
            endIcon={<CalendarViewMonth />}
            disabled={loadingXLSX}
            onClick={generarXLSX}
          >
            {loadingXLSX && <CircularProgress mr={3} size={20} />}
            Descargar XLSX
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ModalReporteRegistros;
