import React from "react";
import { Route, Routes } from "react-router-dom";
import { RegistrosPlanesPage } from "../../../pages/app/registros_planes/RegistrosPlanesPage";
import { RegistroPlanPage } from "../../../pages/app/registros_planes/RegistroPlanPage";

export const RegistrosPlanesRoute = () => {
  return (
    <Routes>
      <Route path="/*" element={<RegistrosPlanesPage />} />
      <Route path="/:id" element={<RegistroPlanPage />} />
    </Routes>
  );
};
