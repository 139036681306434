import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ModalLayout } from "../../../../layouts/ModalLayout";
import {
  ArrowBack,
  FileDownload,
  InsertDriveFile,
  UploadFile,
} from "@mui/icons-material";
import {
  firmaUpdateRequest,
  usuarioUpdateRequest,
} from "../../../../services/api/UsuariosApi";

const FirmaModal = ({ firma, visible, close, reload = () => {} }) => {

  const [data, setData] = useState({
    id_firma: firma.id_firma,
    firma_url: firma.firma_url,
    firma: {},
    password_firma: firma.password_firma,
    fecha_valida: firma.fecha_valida,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState({
    text: "",
    show: false,
  });

  const handleSave = () => {

    setError({
      text: "",
      show: false,
    });

    const confirm = window.confirm(
      "¿Esta seguro de editar los datos de la firma?"
    );

    if (!confirm) return;
    //VALIDAR DATOS
    const validaciones = validarDatos();
    const errores = Object.keys(validaciones);
    if (errores.length > 0) {
      let alert = "";
      errores.forEach((key) => {
        alert += `${validaciones[key]}\n`;
      });
      window.alert(alert);
      return;
    }

    const dataRequest = {
      id_firma: data.id_firma,
      firma_url: data.firma_url,
      password_firma: data.password_firma,
      fecha_valida: data.fecha_valida,
    };

    const formData = new FormData();
    formData.append("id_firma", dataRequest.id_firma);
    formData.append("firma_url", dataRequest.firma_url);
    formData.append("password_firma", dataRequest.password_firma);
    formData.append("fecha_valida", dataRequest.fecha_valida);
    if (data.firma instanceof File) {
      formData.append("firma", data.firma);
    }

    setIsLoading(true);
    firmaUpdateRequest(formData)
      .then((res) => {
        if (res.result) {
          reload();
          close();
        } else {
          setError({
            text: res.message,
            show: true,
          });
        }
      })
      .catch()
      .finally(() => setIsLoading(false));

  };

  const validarDatos = () => {
    let errores = {};

    if (data.password_firma.trim() === "") {
      errores.password_firma = "Clave de la firma es obligatoria";
    }

    if (data.fecha_valida.trim() === "") {
      errores.fecha_valida = "Fecha vigente es obligatoria";
    }

    return errores;
  };

  return (
    <Modal
      open={visible}
      onClose={() => {
        close();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout>
        <Grid container alignItems={"center"} flexDirection={"column"}>
          {/* Header */}
          <Grid
            sx={{
              height: 40,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
              }}
            >
              Editar firma
            </Typography>
            <IconButton
              onClick={close}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <ArrowBack />
            </IconButton>
          </Grid>
          {/* Body */}
          <Grid
            container
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {data.firma?.name == null && (
              <a
                href={firma.firma_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Grid
                  mt={1}
                  container
                  direction={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <InsertDriveFile
                    color="primary"
                    style={{
                      height: 35,
                      width: 35,
                    }}
                  />
                  <Grid
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <FileDownload color="primary" style={{ height: 18 }} />
                    <Typography color={"primary"}> Descargar firma</Typography>
                  </Grid>
                </Grid>
              </a>
            )}
            <Grid mt={2}>
              <input
                id="input-file"
                className={"input-file"}
                type="file"
                accept=".p12"
                onChange={(e) => {
                  if (!e.target.files[0]) return;
                  setData({
                    ...data,
                    firma: e.target.files[0],
                  });
                }}
              />
              <label htmlFor="input-file" className="input-file-label">
                <UploadFile sx={{ marginRight: 1, height: 20 }} />
                {data.firma?.name ?? "Subir otra firma"}
              </label>
            </Grid>
            <Grid mt={1}>
              <TextField
                value={data.password_firma}
                onChange={(e) => {
                  setData({
                    ...data,
                    password_firma: e.target.value,
                  });
                }}
                label={"Clave de firma"}
                size={"small"}
                fullWidth
              />
            </Grid>
            <Grid mt={1}>
              <TextField
                value={data.fecha_valida}
                onChange={(e) => {
                  setData({
                    ...data,
                    fecha_valida: e.target.value,
                  });
                }}
                label={"Fecha vigente"}
                type={"date"}
                size={"small"}
                fullWidth
              />
            </Grid>
            <Box
              mt={3}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {isLoading && <CircularProgress size={25} />}
              {error.show && <Alert severity="error">{error.text}</Alert>}
              <Button
                size="small"
                onClick={handleSave}
                variant="contained"
                disabled={isLoading}
                style={{
                  borderRadius: 100,
                  px: 4,
                  backgroundColor: isLoading ? "GrayText" : "#000590",
                  width: "200px",
                }}
              >
                Guardar cambios
              </Button>
            </Box>
          </Grid>
        </Grid>
      </ModalLayout>
    </Modal>
  );
};

export default FirmaModal;
