import { Grid, Typography } from "@mui/material";
import React from "react";

export const AuthLayout = ({ children, title = "" }) => {
  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"center"}
      spacing={0}
      sx={{
        backgroundColor: "primary.main",
        minHeight: "100vh",
        p: 1,
      }}
    >
      <Grid
        item
        className="card-shadow"
        xs={10}
        sm={6}
        md={4}
        lg={3}
        xl={2}
        sx={{
          backgroundColor: "white",
          borderRadius: 3,
          p: 2,
        }}
      >
        <Typography
          mt={2}
          variant="h5"
          sx={{
            textAlign: "center",
            color: "primary.main",
            fontWeight: "bold",
            fontSize: 18
          }}
        >
          {title}
        </Typography>
        {children}
      </Grid>
    </Grid>
  );
};
