import {
  Alert,
  Button,
  Card,
  CircularProgress,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AccionesContext } from "../../../context/AccionesContext";
import { AppLayout } from "../../../layouts/AppLayout";
import {
  eliminarProductoRequest,
  productoRequest,
} from "../../../services/api/ProductosApi";
import { ModalEditarProducto } from "./components/ModalEditarProducto";
import { isNullOrUndefined } from "../../../helpers/functions/is-null-or-undefined";

export const ProductoPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { accionProductos } = useContext(AccionesContext);

  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [producto, setProducto] = useState({});
  const [modalEdit, setModalEdit] = useState(false);

  const [toast, setToast] = useState({
    open: false,
    text: "",
  });

  useEffect(() => {
    setProducto({});
    setLoading(true);
    const dataRequest = {
      id_producto: id,
    };

    productoRequest(dataRequest)
      .then((res) => {
        if (res.result && res.producto.length > 0) {
          setProducto(res.producto[0]);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [reload]);

  const reloadPage = () => setReload(!reload);

  const openModal = () => setModalEdit(true);
  const closeModal = () => setModalEdit(false);

  const closeToast = () => {
    setToast({
      open: false,
      text: "",
    });
  };

  const openToast = (text = "") => {
    setToast({
      open: true,
      text: text,
    });
  };

  const eliminar = () => {
    const result = window.confirm("¿Estas seguro de eliminar este producto?");

    if (!result) {
      return;
    }
    const dataRequest = {
      id_producto: producto.id_producto,
    };

    eliminarProductoRequest(dataRequest)
      .then((res) => {
        accionProductos();
        navigate(-1);
      })
      .catch((err) => {});
  };

  return (
    <AppLayout>
      <Box
        component={"div"}
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "flex-start" },
        }}
      >
        <Card
          component={"div"}
          sx={{
            width: 300,
            padding: 1,
            mt: 1,
            borderRadius: 3,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
            }}
          >
            Detalle de producto
          </Typography>
          <Typography
            mt={2}
            sx={{
              textAlign: "center",
            }}
          >
            {producto.nombre_producto}
          </Typography>

          <Typography
            mt={1}
            sx={{
              textAlign: "center",
            }}
          >
            {producto.descripcion_producto}
          </Typography>
          <Typography
            mt={1}
            sx={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {producto.observacion_adicional}
          </Typography>
          <Typography
            mt={1.5}
            sx={{
              textAlign: "center",
            }}
          >
            $ {producto.precio_unitario}
          </Typography>
          <Typography
            mt={1.5}
            sx={{
              textAlign: "center",
            }}
          >
            IVA{" "}
            {isNullOrUndefined(producto?.iva)
              ? producto.precio_iva
              : producto.iva?.descripcion}
          </Typography>
          <Typography
            mt={1.5}
            sx={{
              textAlign: "center",
            }}
          >
            {producto.fecha_registro_producto?.substring(0, 10)}
          </Typography>
          <Typography
            mt={1.5}
            sx={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Código: {producto.codigo_producto}
          </Typography>
          <Typography
            mt={1.5}
            sx={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Código Referencia: {producto.codigo_referencia}
          </Typography>
          {loading && <CircularProgress size={25} />}
          <Grid
            mt={4}
            container
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-around"}
          >
            <Button variant="contained" color="error" onClick={eliminar}>
              Eliminar
            </Button>
            <Button variant="contained" color="info" onClick={openModal}>
              Editar
            </Button>
          </Grid>
        </Card>
        <Snackbar
          open={toast.open}
          autoHideDuration={6000}
          onClose={closeToast}
        >
          <Alert onClose={closeToast} severity="success" sx={{ width: "100%" }}>
            {toast.text}
          </Alert>
        </Snackbar>
        {producto.id_producto && (
          <ModalEditarProducto
            modal={modalEdit}
            close={closeModal}
            producto={producto}
            reload={reloadPage}
            openToast={openToast}
          />
        )}
      </Box>
    </AppLayout>
  );
};
