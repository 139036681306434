import { Edit } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useState } from "react";
import { Planes } from "../../../../global/components/Planes";
import {
  renovarActualizarPlanRequest,
  renovarPlanRequest,
} from "../../../../services/api/PlanesApi";
import { AuthContext } from "../../../../context/AuthContext";

const PlanInfo = ({ plan, reload = () => {} }) => {
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const renovarPlan = () => {
    const title = "¿Esta seguro de renovar el plan con los siguientes datos?";
    const datos = `Plan:  ${
      plan.registro_nombre_plan
    }\nCantidad de documentos:  ${
      plan.cantidad_documentos ?? "ILIMITADO"
    }\nMeses habilitados:  ${
      plan.meses_habilitados
    }\nPrecio:  $ ${plan.precio}`;

    const confirm = window.confirm(`${title}\n\n${datos}`);
    if (!confirm) return;

    const dataRequest = {
      id_usuario: plan.id_usuario,
      id_plan: plan.id_plan,
      registro_nombre_plan: plan.registro_nombre_plan,
      cantidad_documentos: plan.cantidad_documentos,
      precio: plan.precio,
      meses_habilitados: plan.meses_habilitados,
    };

    setLoading(true);
    renovarPlanRequest(dataRequest)
      .then((resp) => {
        reload();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const actualizarPlan = (p) => {
    let result = true;
    const title =
      "¿Esta seguro de actualizar el plan con los siguientes datos?";
    const datos = `Plan:  ${p.nombre_plan}\nCantidad de documentos:  ${
      p.cantidad_documentos ?? "ILIMITADO"
    }\nPrecio:  $ ${p.precio}`;

    const confirm = window.confirm(`${title}\n\n${datos}`);
    if (!confirm) {
      result = false;
      return result;
    }

    const dataRequest = {
      id_usuario: plan.id_usuario,
      id_plan: p.id_plan,
    };
    setLoading(true);
    renovarActualizarPlanRequest(dataRequest)
      .then((resp) => {
        reload();
      })
      .finally(() => {
        setLoading(false);
      });

    return result;
  };

  return (
    <Grid>
      <Grid
        height={30}
        flexDirection={"row"}
        display={"flex"}
        alignItems={"center"}
        position={"relative"}
      >
        <Typography fontWeight={"bold"} color={"darkgrey"}>
          PLAN ACTUAL
        </Typography>
      </Grid>
      <Grid container flexDirection={"row"} justifyContent={"space-between"}>
        <Typography fontWeight={"bold"}>Plan</Typography>
        <Typography>{plan.registro_nombre_plan}</Typography>
      </Grid>
      <Grid container flexDirection={"row"} justifyContent={"space-between"}>
        <Typography fontWeight={"bold"}>Cantidad de documentos</Typography>
        <Typography>{plan.cantidad_documentos ?? "ILIMITADO"}</Typography>
      </Grid>
      <Grid container flexDirection={"row"} justifyContent={"space-between"}>
        <Typography fontWeight={"bold"}>Meses habilitados</Typography>
        <Typography>{plan.meses_habilitados}</Typography>
      </Grid>
      <Grid container flexDirection={"row"} justifyContent={"space-between"}>
        <Typography fontWeight={"bold"}>Precio</Typography>
        <Typography>$ {plan.precio}</Typography>
      </Grid>


      <Grid container flexDirection={"row"} justifyContent={"space-between"}>
        <Typography fontWeight={"bold"}>Fecha de incio plan</Typography>
        <Typography>
          {dayjs(new Date(plan.fecha_inicio_plan)).format("DD-MM-YYYY")}
        </Typography>
      </Grid>
      <Grid container flexDirection={"row"} justifyContent={"space-between"}>
        <Typography fontWeight={"bold"}>Fecha de fin plan</Typography>
        <Typography>
          {plan.fecha_fin_plan == null
            ? "NO APLICA"
            : dayjs(new Date(plan.fecha_fin_plan)).format("DD-MM-YYYY")}
        </Typography>
      </Grid>
      <Grid
        mb={1}
        container
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Typography fontWeight={"bold"}>Fecha de registro</Typography>
        <Typography>
          {dayjs(new Date(plan.fecha_registro_plan)).format("DD-MM-YYYY")}
        </Typography>
      </Grid>
      {loading && <CircularProgress size={20} />}
      {user.usuario.admin_registro && (
        <Grid container justifyContent={"space-between"}>
          <Button
            onClick={renovarPlan}
            disabled={!plan.necesita_renovar || loading}
          >
            Renovar
          </Button>
          <Planes
            disable={!plan.necesita_renovar || loading}
            onPlanSelect={actualizarPlan}
            customButton={
              <Button disabled={!plan.necesita_renovar || loading}>
                Actualizacion de plan
              </Button>
            }
          />
        </Grid>
      )}
    </Grid>
  );
};

export default PlanInfo;
