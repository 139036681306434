import { Add, } from "@mui/icons-material";
import { Alert, CircularProgress, Divider, Fab, Grid, Snackbar, Box } from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import { PageSearch } from "../../../global/components/PageSearch";
import { AppLayout } from "../../../layouts/AppLayout";
import { TablaProductos } from "./components/TablaProductos";
import { ModalProducto } from "./components/ModalProducto";
import { AuthContext } from "../../../context/AuthContext";
import {
  productosBusquedaRequest,
  productosRequest,
} from "../../../services/api/ProductosApi";
import { AccionesContext } from "../../../context/AccionesContext";

export const ProductosPage = () => {

  const { establecimiento } = useContext(AuthContext);
  const { recargarProductos } = useContext(AccionesContext);
  const [modalCreate, setModalCreate] = useState(false);
  const [productos, setProductos] = useState([]);
  const [productosSearch, setProductosSearch] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const [toast, setToast] = useState({
    open: false,
    text: '',
  });

  
  useEffect(() => {
    setProductos([]);
    setLoading(true);
    const dataRequest = {
      id_establecimiento: establecimiento.id_establecimiento,
    };
    productosRequest(dataRequest)
      .then((res) => {
        if (res.producto) {
          setProductos(res.producto);
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        setLoading(false);
      });
  }, [establecimiento, recargarProductos]);

  useEffect(() => {
    if (search.length > 1) {
      setLoading(true);
      const dataRequest = {
        search_producto: search,
        id_establecimiento: establecimiento.id_establecimiento,
      };
      productosBusquedaRequest(dataRequest)
        .then((res) => {
          if (res.productos) {
            setProductosSearch(res.productos);
          }
        })
        .catch((err) => {
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [search, establecimiento]);

  const openModal = () => setModalCreate(true);
  const closeModal = () => setModalCreate(false);

  const onChangeSearch = (text) => {
    setSearch(text);
  };

  const closeToast = () => {
    setToast({
      open: false,
      text: ''
    })
  }

  const openToast = (text = '') => {
    setToast({
      open: true,
      text: text,
    })
  }


  return (
    <AppLayout>
      <Grid>
        {/* Header */}
        <PageSearch
          title="Productos / Servicios"
          searchText="Buscar producto / servicio"
          textButtton="Crear producto / servicio"
          actionButton={openModal}
          onChangeSearch={onChangeSearch}
          search={search}
        />
        <Divider />
        {/* Body */}
        <Grid
          sx={{
            minHeight: 100,
          }}
        >
          {search.length > 1 ? (
            <TablaProductos data={productosSearch} />
          ) : (
            <TablaProductos data={productos} />
          )}
          {loading && <CircularProgress size={25} />}
          <Snackbar open={toast.open} autoHideDuration={6000} onClose={closeToast}>
            <Alert onClose={closeToast} severity="success" sx={{ width: "100%" }}>
              { toast.text }
            </Alert>
          </Snackbar>
        </Grid>
        <Box
          sx={{
            position: "absolute",
            bottom: 10,
            right: 10,
            display: {
              xs: "block",
              sm: "none",
            },
          }}
        >
          <Fab onClick={openModal} color="primary" aria-label="add">
            <Add />
          </Fab>
        </Box>
        <ModalProducto 
          modal={modalCreate} 
          close={closeModal} 
          openToast={openToast}
        />
      </Grid>
    </AppLayout>
  );
};
