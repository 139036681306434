import {
  Alert,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AccionesContext } from "../../../context/AccionesContext";
import { AppLayout } from "../../../layouts/AppLayout";
import {
  clienteRequest,
  eliminarClienteRequest,
  identificacionesRequest,
} from "../../../services/api/ClientesApi";
import { ModalEditarCliente } from "./components/ModalEditarCliente";

export const ClientePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { accionClientes } = useContext(AccionesContext);

  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [cliente, setCliente] = useState({});
  const [emails, setEmails] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);

  const [toast, setToast] = useState({
    open: false,
    text: "",
  });

  const [tipos_identificaciones, setTipos_identificaciones] = useState([]);

  useEffect(() => {
    identificacionesRequest()
      .then((res) => {
        if (res.identificaciones) {
          setTipos_identificaciones(res.identificaciones);
        }
      })
      .catch((err) => {
      })
      .finally(() => {});
  }, []);

  useEffect(() => {
    if (tipos_identificaciones.length === 0) {
      return;
    }
    setCliente({});
    setLoading(true);
    const dataRequest = {
      id_cliente: id,
    };

    clienteRequest(dataRequest)
      .then((res) => {
        if (res.result && res.clientes.length > 0) {
          const c = {
            ...res.clientes[0].cliente,
            contactos: res.clientes[0].contactos,
          };

          const emailString = res.clientes[0]?.contactos[0]?.emails_contacto;
          setEmails(JSON.parse(emailString));
          setCliente(c);
        }
      })
      .catch((error) => {
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reload, tipos_identificaciones]);

  const reloadPage = () => setReload(!reload);

  const openModal = () => setModalEdit(true);
  const closeModal = () => setModalEdit(false);

  const closeToast = () => {
    setToast({
      open: false,
      text: "",
    });
  };

  const openToast = (text = "") => {
    setToast({
      open: true,
      text: text,
    });
  };

  const eliminar = () => {
    const result = window.confirm("¿Estas seguro de eliminar este cliente?");

    if (!result) {
      return;
    }
    const dataRequest = {
      id_cliente: cliente.id_cliente,
    };

    eliminarClienteRequest(dataRequest)
      .then((res) => {
        accionClientes();
        navigate(-1);
      })
      .catch((err) => {
      });
  };


  return (
    <AppLayout>
      <Box
        component={"div"}
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "flex-start" },
        }}
      >
        <Card
          component={"div"}
          sx={{
            width: 300,
            padding: 1,
            mt: 1,
            borderRadius: 3,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Datos de Cliente
          </Typography>
          <Typography
            mt={2}
            sx={{
              textAlign: "center",
            }}
          >
            {cliente.nombre_cliente} {cliente.apellido_cliente}
          </Typography>

          {cliente.id_tipo_cliente == 2 && (
            <Typography
              mt={2}
              sx={{
                textAlign: "center",
              }}
            >
              {cliente.razon_social_cliente}
            </Typography>
          )}

          <Typography
            mt={2}
            sx={{
              textAlign: "center",
            }}
          >
            {cliente.numero_documento_cliente}
          </Typography>

          {cliente.id_tipo_cliente == 2 && (
            <Typography
              mt={2}
              sx={{
                textAlign: "center",
              }}
            >
              {cliente.numero_ruc_cliente}
            </Typography>
          )}

          <Typography
            mt={1}
            sx={{
              textAlign: "center",
            }}
          >
            {
              tipos_identificaciones.find(
                (t) =>
                  t.id_tipo_identificacion == cliente.id_tipo_identificacion
              )?.descripcion_tipo
            }
          </Typography>
          <Typography
            mt={1}
            variant="h6"
            sx={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Información de contacto
          </Typography>
          {cliente.contactos?.map((c) => {
            return (
              <Fragment>
                {emails?.map((email, index) => {
                  return (
                    <Typography
                      mt={1.5}
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {email}
                    </Typography>
                  );
                })}
                <Typography
                  mt={1.5}
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {c.telefono_cliente}
                </Typography>
                <Typography
                  mt={1.5}
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {c.direccion_cliente}
                </Typography>
                <Divider />
              </Fragment>
            );
          })}
          {loading && <CircularProgress size={25} />}
          <Grid
            mt={5}
            container
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-around"}
          >
            <Button variant="contained" color="error" onClick={eliminar}>
              Eliminar
            </Button>
            <Button variant="contained" color="info" onClick={openModal}>
              Editar
            </Button>
          </Grid>
        </Card>
        <Snackbar
          open={toast.open}
          autoHideDuration={6000}
          onClose={closeToast}
        >
          <Alert onClose={closeToast} severity="success" sx={{ width: "100%" }}>
            {toast.text}
          </Alert>
        </Snackbar>
        {cliente.id_cliente && tipos_identificaciones.length > 0 && (
          <ModalEditarCliente
            modal={modalEdit}
            emailsProps={emails}
            close={closeModal}
            cliente={cliente}
            reload={reloadPage}
            openToast={openToast}
            tipos_identificaciones={tipos_identificaciones}
          />
        )}
      </Box>
    </AppLayout>
  );
};
