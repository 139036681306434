import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export const Cliente = ({ cliente, seleccionarCliente }) => {
  return (
    <Box mt={0.5}>
      <Box component={"div"} onClick={() => seleccionarCliente(cliente)}>
        <Box
          component={"div"}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 1,
          }}
        >
          {cliente.id_tipo_cliente == 1 ? (
            <Typography>
              {cliente.nombre_cliente} {cliente.apellido_cliente}
            </Typography>
          ) : (
            <Typography>{cliente.razon_social_cliente}</Typography>
          )}
          {cliente.id_tipo_cliente == 1 ? (
            <Typography>{cliente.numero_documento_cliente}</Typography>
          ) : (
            <Typography>{cliente.numero_ruc_cliente}</Typography>
          )}
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};
