// Ambientes: 1. local, 2. pruebas, 3. produccion, 4. hotfix //SUBIR GIT HUB
const ambiente = 'produccion';

const url_ambiente = {
    local:
    {
        host: 'http://localhost:3005',  
    },
    pruebas:
    {
        host: 'https://app-web-admin-bk-test.track4go.com',
    },
    produccion:
    {
        host: 'https://billing-4go-bk-pro-ssrfijlk6a-uc.a.run.app',

    },
    hotfix: {
        host: 'https://app-web-admin-bk-hotfix.track4go.com',
    }
};

export const CONFIGS = {
    API_URL: url_ambiente[ambiente].host,

}

export const ESTADO_DOCUMENTO = {
    0: "ANULADO",
    1: "CREADO",
    2: "DEVUELTO",
    3: "AUTORIZADO",
    4: "EN PROCESO",
    5: "RECHAZADO",
    6: "NO AUTORIZADO",
}

export const ESTADO_COBRO = {
    0: "POR COBRAR",
    1: "PAGADA",
}

export const ESTADO_COBRO_MAP = [
    { id: 0, value: "POR COBRAR" },
    { id: 1, value: "PAGADA" },
]