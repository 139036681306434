import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  Modal,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { AccionesContext } from "../../../../context/AccionesContext";
import { AuthContext } from "../../../../context/AuthContext";
import { useForm } from "../../../../hooks/useForm";
import { ModalLayout } from "../../../../layouts/ModalLayout";
import { crearProductoRequest } from "../../../../services/api/ProductosApi";
import { FormProducto } from "../../facturas/components/FormProducto";
import { useEffect } from "react";
import { messageDuplicate } from "../../../../helpers/constants/messages-api";
import { ivasRequest } from "../../../../services/api/IvasApi";

export const ModalProducto = ({
  modal,
  close,
  openToast,
  seleccionarProducto,
}) => {
  const { establecimiento } = useContext(AuthContext);
  const { accionProductos } = useContext(AccionesContext);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState({
    text: "",
    show: false,
  });

  const [values, onChange, reset, initValues, setForm] = useForm({
    codigo_referencia_activo: false,
    codigo_referencia: "",
    nombre_producto: "",
    precio_unitario: "",
    descripcion_producto: "",
    observacion_adicional: "",
    precio_iva: null,
    id_iva: null,
  });

  const [ivas, setIvas] = useState([]);


  useEffect(() => {
    if (!values.codigo_referencia_activo) {
      setForm((prev) => ({
        ...prev,
        codigo_referencia: "",
      }));
    }
  }, [values.codigo_referencia_activo]);

  useEffect(() => {
    ivasRequest().then((response) => {
      if (response.ivas && response.ivas?.length > 0) {
        setIvas(response.ivas);
      }
    });
  }, []);

  useEffect(() => {
    const id_iva = values.id_iva;
    const iva = ivas.find((iva) => iva.id_iva === id_iva);
    if (!iva) return;
    setForm((prev) => ({
      ...prev,
      precio_iva: iva.valor,
    }));
  }, [values.id_iva]);

  const crear = () => {
    setError({
      text: "",
      show: false,
    });

    //Validaciones
    if (
      values.nombre_producto.length === 0 ||
      Number(values.precio_unitario) == 0
    ) {
      setError({
        text: "Ingrese el nombre y el precio unitario del producto",
        show: true,
      });
      return;
    }

    if (values.codigo_referencia_activo && !values.codigo_referencia?.trim()) {
      setError({
        text: "Ingrese el Código de Referencia",
        show: true,
      });
      return;
    }

    setLoading(true);

    const dataRequest = {
      nombre_producto: values.nombre_producto,
      precio_unitario: values.precio_unitario,
      descripcion_producto: values.descripcion_producto,
      observacion_adicional: values.observacion_adicional,
      precio_iva: values.precio_iva,
      id_iva: values.id_iva,
      codigo_referencia: values.codigo_referencia.trim(),
      id_establecimiento: establecimiento.id_establecimiento,
    };

    crearProductoRequest(dataRequest)
      .then((res) => {
        if (res.result) {
          close();
          reset();
          accionProductos();
          seleccionarProducto({
            ...dataRequest,
            id_producto: res.id_producto,
            codigo_producto: res.secuencial_producto,
          });
          // openToast('Producto creado correctamente !');
        }
      })
      .catch((error) => {
        const message = error?.response?.data?.message;
        if (message?.includes(messageDuplicate)) {
          setError({
            text: `Ya se encuentra creado un producto con el nombre (${values.nombre_producto})`,
            show: true,
          });
        } else {
          setError({
            text: "Ocurrio un error",
            show: true,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      open={modal}
      onClose={() => {
        close();
        reset();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout>
        <Grid container alignItems={"center"} flexDirection={"column"}>
          {/* Header */}
          <Grid
            sx={{
              height: 40,
            }}
            alignItems={"center"}
          >
            <Typography
              sx={{
                fontWeight: "600",
              }}
            >
              Nuevo producto / servicio
            </Typography>
          </Grid>
          {/* Form */}
          <Grid>
            <FormProducto
              values={values}
              onChange={onChange}
              cancelar={() => {
                reset();
                close();
              }}
              crear={crear}
              ivas={ivas}
            />
          </Grid>
          {error.show && <Alert severity="error">{error.text}</Alert>}
          {loading && <CircularProgress size={25} />}
        </Grid>
      </ModalLayout>
    </Modal>
  );
};
