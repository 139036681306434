import API from "./ApiConfig"

const URL_CLIENTE = '/cliente';

export const clientesRequest = async (data, pagination) => {
    const request = await API.post(URL_CLIENTE + '/consultar_cliente', data, { params: pagination });
    const response = request.data;
    return response;
}

export const clienteRequest = async (data) => {
    const request = await API.post(URL_CLIENTE + '/consultar_cliente_id', data);
    const response = request.data;
    return response;
}

export const editarClienteRequest = async (data) => {
    const request = await API.post(URL_CLIENTE + '/editar_cliente', data);
    const response = request.data;
    return response;
}

export const eliminarClienteRequest = async (data) => {
    const request = await API.post(URL_CLIENTE + '/eliminar_cliente', data);
    const response = request.data;
    return response;
}

export const clientesBusquedaRequest = async (data, pagination) => {
    const request = await API.post(URL_CLIENTE + '/consultar_cliente_nombre', data, { params: pagination });
    const response = request.data;
    return response;
}

export const crearClienteRequest = async (data) => {
    const request = await API.post(URL_CLIENTE + '/registrar_cliente', data);
    const response = request.data;
    return response;
}

//Indentificaciones
export const identificacionesRequest = async (data) => {
    const request = await API.post(URL_CLIENTE + '/consultar_identificaciones', data);
    const response = request.data;
    return response;
}