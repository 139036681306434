import { Add } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  Fab,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccionesContext } from "../../../context/AccionesContext";
import { AuthContext } from "../../../context/AuthContext";
import { PageSearch } from "../../../global/components/PageSearch";
import { AppLayout } from "../../../layouts/AppLayout";
import {
  buscarDocumentoRequest,
  busquedaDocumentoRequest,
  listaDocumentosRequest,
} from "../../../services/api/DocumentoApi";
import { TablaFacturas } from "./components/TablaFacturas";
import { usePagination } from "../../../hooks/usePagination";
import { PageSearchDocumento } from "../../../global/components/PageSearchDocumento";
import dayjs from "dayjs";
import { PlanContext } from "../../../context/PlanContext";

export const FacturasPage = () => {
  const navigate = useNavigate();

  const { establecimiento } = useContext(AuthContext);
  const { validarPlan } = useContext(PlanContext);
  const { recargarFacturas } = useContext(AccionesContext);

  const [facturas, setFacturas] = useState([]);
  const [facturasSearch, setFacturasSearch] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchState, setSearchState] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [emptySearch, setEmptySearch] = useState({ show: false, text: "" });

  const [dates, setDates] = useState({
    show: false,
    date_init: "",
    date_end: "",
  });

  useEffect(() => {
    const date = new Date();
    const dateFormatEnd = dayjs(date).format("YYYY-MM-DD");
    const dateFormatInit = dayjs(date).subtract(1, "M").format("YYYY-MM-DD");
    setDates({
      ...date,
      date_init: dateFormatInit,
      date_end: dateFormatEnd,
    });
  }, []);

  const [toast, setToast] = useState({
    open: false,
    text: "",
  });

  const { limit, offset } = usePagination(5);

  useEffect(() => {
    setFacturas([]);
    setLoading(true);
    const dataRequest = {
      id_establecimiento: establecimiento.id_establecimiento,
    };
    listaDocumentosRequest(dataRequest)
      .then((res) => {
        if (res.facts) {
          const d = res.facts;
          setFacturas(d);
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        setLoading(false);
      });
  }, [establecimiento, recargarFacturas]);

  useEffect(() => {
    if (search.length > 1) {
      setLoading(true);
      const dataRequest = {
        search_docuemento: search,
        id_establecimiento: establecimiento.id_establecimiento,
      };
      buscarDocumentoRequest(dataRequest)
        .then((res) => {
          if (res.facts) {
            setFacturasSearch(res.facts);
          }
        })
        .catch((err) => {
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [search]);

  const onChangeSearch = (text) => {
    setSearch(text);
  };

  const closeToast = () => {
    setToast({
      open: false,
      text: "",
    });
  };

  const openToast = (text = "") => {
    setToast({
      open: true,
      text: text,
    });
  };

  const onFilter = (id, value, date) => {
    if (id == "" && !date) {
      setSearchState(false);
      setLoadingSearch(false);
      return;
    }
    setEmptySearch({ show: false, text: "" });
    setSearchState(true);
    setLoadingSearch(true);
    setFacturasSearch([]);

    const date_init = new Date(dates.date_init);
    const date_end = new Date(dates.date_end);

    const dateAdd = dayjs(date_end).add(2, "days").format("YYYY-MM-DD");

    const dataRequest = {
      id_establecimiento: establecimiento.id_establecimiento,
      filter: id,
      filter_value: value,
      date_show: dates.show,
      date_init: dates.date_init,
      date_end: dates.date_init === dates.date_end ? dateAdd : dates.date_end,
    };

    busquedaDocumentoRequest(dataRequest)
      .then((res) => {
        if (res.result && res.documentos.length > 0) {
          setFacturasSearch(res.documentos);
        } else if (res.result && res.documentos.length === 0) {
          setEmptySearch({
            show: true,
            text: id != "estado_documento_electronico" ? value : "",
          });
        }
      })
      .catch()
      .finally(() => {
        setLoadingSearch(false);
      });
  };

  const navegarCrearFactura = async () => {
    const valido = await validarPlan();
    if (!valido) return;
    navigate("/facturas/crearFactura");
  };

  return (
    <AppLayout>
      <Grid>
        {/* Header */}
        <PageSearchDocumento
          title="Facturas"
          searchText="Buscar factura"
          textButtton="Crear Factura"
          actionButton={navegarCrearFactura}
          onChangeSearch={onChangeSearch}
          search={search}
          onFilter={onFilter}
          dates={dates}
          setDates={setDates}
        />
        <Divider />
        {/* Body */}
        <Grid
          sx={{
            minHeight: 100,
          }}
        >
          {searchState ? (
            <>
              <TablaFacturas data={facturasSearch} />
              {emptySearch.show && (
                <Grid
                  container
                  sx={{
                    paddingX: 1,
                    justifyContent: {
                      sx: "flex-start",
                      md: "center",
                    },
                  }}
                  justifyContent={"center"}
                >
                  <Typography mr={2}>
                    No se encontro documentos "{emptySearch.text}".
                  </Typography>
                </Grid>
              )}
            </>
          ) : (
            <TablaFacturas data={facturas} />
          )}
          {loadingSearch && (
            <Grid
              container
              sx={{
                paddingX: 1,
                justifyContent: {
                  sx: "flex-start",
                  md: "center",
                },
              }}
              justifyContent={"center"}
            >
              <Typography mr={2}>Buscando documento {""}...</Typography>
              <CircularProgress size={25} />
            </Grid>
          )}
        </Grid>
        {loading && <CircularProgress size={25} />}
      </Grid>
      <Box
        sx={{
          position: "absolute",
          bottom: 10,
          right: 10,
          display: {
            xs: "block",
            sm: "none",
          },
        }}
      >
        <Fab onClick={navegarCrearFactura} color="primary" aria-label="add">
          <Add />
        </Fab>
      </Box>
    </AppLayout>
  );
};
