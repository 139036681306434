import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    id: "nombre_cliente",
    label: "Cliente",
    minWidth: 80,
  },
  // {
  //   id: "apellido_cliente",
  //   label: "Apellidos",
  //   minWidth: 80,
  // },
  {
    id: "numero_documento_cliente",
    label: "Identificacion",
    minWidth: 50,
  },
  {
    id: "telefono_cliente",
    label: "Telefono",
    minWidth: 50,
  },
  {
    id: "direccion_cliente",
    label: "Dirección",
    minWidth: 30,
  },
];

export const TablaClientes = ({ data = [], onChangeTablePagination = () => {} }) => {
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onChangeTablePagination(newPage + 1, rowsPerPage, data);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    onChangeTablePagination(1, +event.target.value, data);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    component={"div"}
                    onClick={() => navigate(`/clientes/${row.id_cliente}`)}
                    key={row.code}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];

                      if (
                        row.id_tipo_cliente == 1 &&
                        column.id == "nombre_cliente"
                      ) {

                        const nombre = `${row.nombre_cliente} ${row.apellido_cliente}` 

                        return (
                          <TableCell key={column.id} align={column.align}>
                              { nombre }
                          </TableCell>
                        );
                      }

                      if (
                        row.id_tipo_cliente == 2 &&
                        column.id == "nombre_cliente"
                      ) {


                        const nombre = row.razon_social_cliente; 

                        return (
                          <TableCell key={column.id} align={column.align}>
                              { nombre }
                          </TableCell>
                        );
                      }

                      if (
                        row.id_tipo_cliente == 2 &&
                        column.id == "numero_documento_cliente"
                      ) {

                        const nombre = row.numero_ruc_cliente

                        return (
                          <TableCell key={column.id} align={column.align}>
                              { nombre }
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        
      />
    </Paper>
  );
};
