import { Add } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  Fab,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccionesContext } from "../../../context/AccionesContext";
import { AuthContext } from "../../../context/AuthContext";
import { PageSearch } from "../../../global/components/PageSearch";
import { AppLayout } from "../../../layouts/AppLayout";
import {
  buscarDocumentoRequest,
  busquedaDocumentoRequest,
  listaDocumentosRequest,
} from "../../../services/api/DocumentoApi";
import { TablaFacturas, TablaReportes } from "./components/TablaReportes";
import { usePagination } from "../../../hooks/usePagination";
import { PageSearchDocumento } from "../../../global/components/PageSearchDocumento";
import dayjs from "dayjs";
import { documentosListaRequest } from "../../../services/api/ReporteApi";
import ModalCrearReporte from "./components/ModalCrearReporte";

export const ReportesVentasPage = () => {
  const navigate = useNavigate();

  const { establecimiento } = useContext(AuthContext);
  const { recargarFacturas } = useContext(AccionesContext);

  const [documentos, setDocumentos] = useState([]);
  const [documentosSearch, setDocumentosSearch] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchState, setSearchState] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [emptySearch, setEmptySearch] = useState({ show: false, text: "" });

  const [crearReporteModal, setCrearReporteModal] = useState(false);

  const [dates, setDates] = useState({
    show: false,
    date_init: "",
    date_end: "",
  });

  useEffect(() => {
    const date = new Date();
    const dateFormatEnd = dayjs(date).format("YYYY-MM-DD");
    const dateFormatInit = dayjs(date).subtract(1, "M").format("YYYY-MM-DD");
    setDates({
      ...date,
      date_init: dateFormatInit,
      date_end: dateFormatEnd,
    });
  }, []);

  const [toast, setToast] = useState({
    open: false,
    text: "",
  });

  const { limit, offset } = usePagination(5);

  useEffect(() => {
    setDocumentos([]);
    setLoading(true);
    const dataRequest = {
      id_establecimiento: establecimiento.id_establecimiento,
    };
    documentosListaRequest(dataRequest)
      .then((res) => {
        if (res.documentos) {
          const d = res.documentos;
          setDocumentos(d);
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        setLoading(false);
      });
  }, [recargarFacturas]);

  const onChangeSearch = (text) => {
    setSearch(text);
  };

  const closeToast = () => {
    setToast({
      open: false,
      text: "",
    });
  };

  const openToast = (text = "") => {
    setToast({
      open: true,
      text: text,
    });
  };

  const onFilter = (id, value, date) => {
    if (id == "" && !date) {
      setSearchState(false);
      setLoadingSearch(false);
      return;
    }
    setEmptySearch({ show: false, text: "" });
    setSearchState(true);
    setLoadingSearch(true);
    setDocumentosSearch([]);

    const date_init = new Date(dates.date_init);
    const date_end = new Date(dates.date_end);

    const dateAdd = dayjs(date_end).add(2, "days").format("YYYY-MM-DD");

    const dataRequest = {
      id_establecimiento: establecimiento.id_establecimiento,
      filter: id,
      filter_value: value,
      date_show: dates.show,
      date_init: dates.date_init,
      date_end: dates.date_init === dates.date_end ? dateAdd : dates.date_end,
    };

    busquedaDocumentoRequest(dataRequest)
      .then((res) => {
        if (res.result && res.documentos.length > 0) {
          setDocumentosSearch(res.documentos);
        } else if (res.result && res.documentos.length === 0) {
          setEmptySearch({
            show: true,
            text: id != "estado_documento_electronico" ? value : "",
          });
        }
      })
      .catch()
      .finally(() => {
        setLoadingSearch(false);
      });
  };

  const openCrearReporte = () => {
    setCrearReporteModal(true);
  };

  const closeCrearReporte = () => {
    setCrearReporteModal(false);
  };

  return (
    <AppLayout>
      <Grid>
        {/* Header */}
        <Grid
          container
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            height: 40,
            px: 2,
          }}
        >
          <Grid
            item
            xs={0}
            sm={0}
            md={3}
            lg={5}
            sx={{
              display: { xs: "none", sm: "none", md: "block", lg: "block" },
            }}
          >
            <Typography fontWeight={"bold"} fontSize={16} component={"h6"}>
              Ventas
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
              alignItems: "center",
              justifyContent: "end",
            }}
            xs={0}
            sm={5}
            md={5}
            lg={3}
            xl={3}
          >
            <Button
              variant="contained"
              onClick={openCrearReporte}
              sx={{
                borderRadius: 100,
                px: 3,
                fontSize: 13,
              }}
            >
              Generar Reporte
            </Button>
          </Grid>
        </Grid>
        <Divider />
        {/* Body */}
        <Grid
          sx={{
            minHeight: 100,
          }}
        >
          {searchState ? (
            <>
              <TablaReportes data={documentosSearch} />
              {emptySearch.show && (
                <Grid
                  container
                  sx={{
                    paddingX: 1,
                    justifyContent: {
                      sx: "flex-start",
                      md: "center",
                    },
                  }}
                  justifyContent={"center"}
                >
                  <Typography mr={2}>
                    No se encontro documentos "{emptySearch.text}".
                  </Typography>
                </Grid>
              )}
            </>
          ) : (
            <TablaReportes data={documentos} />
          )}
          {loadingSearch && (
            <Grid
              container
              sx={{
                paddingX: 1,
                justifyContent: {
                  sx: "flex-start",
                  md: "center",
                },
              }}
              justifyContent={"center"}
            >
              <Typography mr={2}>Buscando documento {""}...</Typography>
              <CircularProgress size={25} />
            </Grid>
          )}
        </Grid>
        {loading && <CircularProgress size={25} />}
      </Grid>
      <Box
        sx={{
          position: "absolute",
          bottom: 10,
          right: 10,
          display: {
            xs: "block",
            sm: "none",
          },
        }}
      >
        <Fab
          onClick={openCrearReporte}
          color="primary"
          aria-label="add"
        >
          <Add />
        </Fab>
      </Box>
      <ModalCrearReporte 
        visible={crearReporteModal}
        close={closeCrearReporte}
      />
    </AppLayout>
  );
};
