

export const authStorageSave = (data = { data: 'test'}) => {

    try {
        
        const stringfy = JSON.stringify(data);
        localStorage.setItem('@auth', stringfy);

        return true;
        
    } catch (error) {
        return false;        
    }

}

export const authStorageGet = () => {

    try {
        
        const stringfy = localStorage.getItem('@auth');

        return stringfy ? JSON.parse(stringfy) : null;
        
    } catch (error) {
        return null;        
    }

}

export const authStorageRemove = () => {
    try {
        localStorage.removeItem('@auth');
    } catch (error) {
    }

}