import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  FormatListBulleted,
  HomeOutlined,
  InsertDriveFileOutlined,
  LocalShippingOutlined,
  Logout,
  ManageAccountsOutlined,
  Style,
  StyleOutlined,
} from "@mui/icons-material";
import { AuthContext } from "../../context/AuthContext";
import { useState } from "react";

export const SideMenuAdmin = ({ closeDrawer }) => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const [planesExpand, setPlanesExpand] = useState(false);
  const handleExpandPlanes = () => setPlanesExpand(true);
  const handleClosePlanes = () => setPlanesExpand(false);

  return (
    <List>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            navigate("/inicio");
            closeDrawer();
          }}
        >
          <ListItemIcon>
            <HomeOutlined />
          </ListItemIcon>
          <ListItemText primary="Inicio" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            navigate("/usuarios");
            closeDrawer();
          }}
        >
          <ListItemIcon>
            <ManageAccountsOutlined />
          </ListItemIcon>
          <ListItemText primary="Usuarios" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            planesExpand ? handleClosePlanes() : handleExpandPlanes();
          }}
        >
          <ListItemIcon>
            <StyleOutlined />
          </ListItemIcon>
          <ListItemText primary="Planes" />
          {planesExpand ? (
            <ExpandLess sx={{ ml: 1 }} />
          ) : (
            <ExpandMore sx={{ ml: 1 }} />
          )}
        </ListItemButton>
      </ListItem>
      <Collapse in={planesExpand} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              navigate("/planes");
              closeDrawer();
            }}
          >
            <ListItemIcon>
              <StyleOutlined />
            </ListItemIcon>
            <ListItemText primary="Mis planes" />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              navigate("/registro_planes");
              closeDrawer();
            }}
          >
            <ListItemIcon>
              <FormatListBulleted />
            </ListItemIcon>
            <ListItemText primary="Registros de planes" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            logout();
          }}
        >
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText primary="Cerrar Sesión" />
        </ListItemButton>
      </ListItem>
    </List>
  );
};
