import React from "react";
import { Route, Routes } from "react-router-dom";
import { CrearGuiaPage } from "../../../pages/app/guias/CrearGuiaPage";
import { EditarGuiaPage } from "../../../pages/app/guias/EditarGuiaPage";
import { GuiaRemisionPage } from "../../../pages/app/guias/GuiaRemisionPage";
import GuiasRemisionPage from "../../../pages/app/guias/GuiasRemisionPage";

export const  GuiasRemisionRoute = () => {
  return (
    <Routes>
      <Route path="/*" element={<GuiasRemisionPage />} />
      <Route path="/:id" element={<GuiaRemisionPage />} />
      <Route path="/editar/:id" element={<EditarGuiaPage />} />
      <Route path="/crearGuiaRemision" element={<CrearGuiaPage />} />
    </Routes>
  );
};
