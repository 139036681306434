import {
  Alert,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AccionesContext } from "../../../context/AccionesContext";
import { AppLayout } from "../../../layouts/AppLayout";
import {
  clienteRequest,
  eliminarClienteRequest,
  identificacionesRequest,
} from "../../../services/api/ClientesApi";
import {
  ModalEditarCliente,
  ModalEditarPlan,
} from "./components/ModalEditarPlan";
import {
  eliminarPlanRequest,
  planIdRequest,
} from "../../../services/api/PlanesApi";
import dayjs from "dayjs";

export const PlanPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { accionPlanes } = useContext(AccionesContext);

  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [plan, setPlan] = useState({});
  const [modalEdit, setModalEdit] = useState(false);

  const [toast, setToast] = useState({
    open: false,
    text: "",
  });

  const reloadPage = () => setReload(!reload);

  const openModal = () => setModalEdit(true);
  const closeModal = () => setModalEdit(false);

  const closeToast = () => {
    setToast({
      open: false,
      text: "",
    });
  };

  const openToast = (text = "") => {
    setToast({
      open: true,
      text: text,
    });
  };

  useEffect(() => {
    setPlan({});
    planIdRequest({ id_plan: id }).then((res) => {
      if (res.result && res.plan && Object.keys(res.plan).length > 0) {
        setPlan(res.plan);
      }
    });
  }, [reload]);

  const eliminar = () => {
    const result = window.confirm("¿Estas seguro de eliminar este Plan?");

    if (!result) {
      return;
    }
    const dataRequest = {
      id_plan: plan.id_plan,
    };

    eliminarPlanRequest(dataRequest)
      .then((res) => {
        accionPlanes();
        navigate(-1);
      })
      .catch((err) => {});
  };

  return (
    <AppLayout>
      <Box
        component={"div"}
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "flex-start" },
        }}
      >
        <Card
          component={"div"}
          sx={{
            width: 300,
            padding: 1,
            mt: 1,
            borderRadius: 3,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Datos de Plan
          </Typography>
          <Typography
            mt={2}
            sx={{
              textAlign: "center",
            }}
          >
            {plan.nombre_plan}
          </Typography>

          <Typography
            mt={2}
            sx={{
              textAlign: "center",
            }}
          >
            {plan.nombre_tipo_plan}
          </Typography>

          <Typography
            mt={1}
            sx={{
              textAlign: "center",
            }}
          >
            <b>Cantidad:</b> {plan.cantidad_documentos}
          </Typography>
          <Typography
            mt={1}
            sx={{
              textAlign: "center",
            }}
          >
            <b>Meses habilitados:</b> {plan.meses_habilitados}
          </Typography>
          <Typography
            mt={1}
            sx={{
              textAlign: "center",
            }}
          >
            $ {plan.precio}
          </Typography>
          <Typography
            mt={1}
            sx={{
              textAlign: "center",
            }}
          >
            {dayjs(new Date(plan.fecha_registro)).format("DD/MM/YYYY HH:mm")}
          </Typography>

          {loading && <CircularProgress size={25} />}
          <Grid
            mt={5}
            container
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-around"}
          >
            <Button variant="contained" color="error" onClick={eliminar}>
              Eliminar
            </Button>
            <Button variant="contained" color="info" onClick={openModal}>
              Editar
            </Button>
          </Grid>
        </Card>
        <Snackbar
          open={toast.open}
          autoHideDuration={6000}
          onClose={closeToast}
        >
          <Alert onClose={closeToast} severity="success" sx={{ width: "100%" }}>
            {toast.text}
          </Alert>
        </Snackbar>
        {plan.id_plan && Object.keys(plan).length > 0 && (
          <ModalEditarPlan
            modal={modalEdit}
            close={closeModal}
            plan={plan}
            reload={reloadPage}
            openToast={openToast}
          />
        )}
      </Box>
    </AppLayout>
  );
};
