import {
  Add,
  CheckBox,
  CheckBoxOutlineBlank,
  Delete,
  Edit,
  Image,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { tiposContribuyenteRequest } from "../../../../services/api/EstablecimientosApi";
import EstablecimientoCard from "./EstablecimientoCard";

export const EstablecimientoForm = ({
  data,
  setData,
  establecimiento,
  setEstablecimiento,
  handleAddEstablecimiento,
  handleDeleteEstablecimiento,
  handleUpdateEstablecimiento,
  handleDeleteLogo,
}) => {
  return (
    <Grid container flexDirection={"column"} alignItems={"center"}>
      <Grid>
        <Typography fontWeight={"bold"} color={"darkgrey"} my={1}>
          ESTABLECIMIENTOS {data.length > 0 && `(${data.length})`}
        </Typography>
      </Grid>

      <Grid>
        <TextField
          value={establecimiento.direccion_establecimiento}
          onChange={(e) => {
            setEstablecimiento({
              ...establecimiento,
              direccion_establecimiento: e.target.value.toUpperCase(),
            });
          }}
          label={"Dirección"}
          size={"small"}
          fullWidth
          required
        />
      </Grid>
      <Grid mt={1}>
        <TextField
          value={establecimiento.codigo_establecimiento}
          onChange={(e) => {
            setEstablecimiento({
              ...establecimiento,
              codigo_establecimiento: e.target.value,
            });
          }}
          label={"Codigo establecimiento ej. 001"}
          size={"small"}
          fullWidth
          required
          inputProps={{
            maxLength: 3,
          }}
        />
      </Grid>
      <Grid mt={1}>
        <TextField
          value={establecimiento.punto_emision.codigo_punto_emision ?? ""}
          onChange={(e) => {
            setEstablecimiento({
              ...establecimiento,
              punto_emision: {
                ...establecimiento.punto_emision,
                codigo_punto_emision: e.target.value,
              },
            });
          }}
          label={"Punto emision ej. 001"}
          size={"small"}
          fullWidth
          required
          inputProps={{
            maxLength: 3,
          }}
        />
      </Grid>
      <Grid mt={1}>
        <FormControlLabel
          sx={{
            width: 230,
          }}
          control={
            <Checkbox
              checked={establecimiento.matriz}
              onChange={(e) => {
                setEstablecimiento({
                  ...establecimiento,
                  matriz: e.target.checked,
                });
              }}
            />
          }
          label="Matriz"
          required
        />
      </Grid>
      <Grid mt={1}>
        <TextField
          value={establecimiento.nombre_comercial}
          onChange={(e) => {
            setEstablecimiento({
              ...establecimiento,
              nombre_comercial: e.target.value.toUpperCase(),
            });
          }}
          label={"Nombre comercial"}
          size={"small"}
          fullWidth
        />
      </Grid>
      <Grid mt={1}>
        {establecimiento.url_logo.length > 0 && (
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            marginBottom={1}
            position={"relative"}
          >
            <img className="image_logo" src={establecimiento.url_logo} />
            <Tooltip title={"Quitar logo"}>
              <IconButton
                size="small"
                color="error"
                onClick={handleDeleteLogo}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              >
                <Delete style={{ height: 22, width: 22 }} />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        <input
          id="input-file-logo"
          className={"input-file"}
          type="file"
          accept="image/*"
          onChange={(e) => {
            if (!e.target.files[0]) return;

            setEstablecimiento({
              ...establecimiento,
              logo: e.target.files[0],
              url_logo: URL.createObjectURL(e.target.files[0]),
            });
          }}
        />
        <label htmlFor="input-file-logo" className="input-file-label">
          <Image sx={{ marginRight: 1, height: 20 }} />
          {establecimiento.logo?.name ?? "Logo"}
        </label>
      </Grid>
      <Grid mt={1}>
        <Tooltip title="Registrar otro establecimiento">
          <Button
            onClick={() => handleAddEstablecimiento()}
            variant="contained"
            size="small"
            startIcon={<Add />}
            sx={{
              width: 230,
            }}
          >
            Establecimiento
          </Button>
        </Tooltip>
      </Grid>
      {data.map((establecimiento, index) => (
        <EstablecimientoCard
          key={index}
          index={index}
          establecimiento={establecimiento}
          handleDeleteEstablecimiento={handleDeleteEstablecimiento}
          handleUpdateEstablecimiento={handleUpdateEstablecimiento}
        />
      ))}
    </Grid>
  );
};
