import API from "./ApiConfig"

const URL_GUIA = '/guiaRemision';


export const guiasRequest = async (data) => {
    const request = await API.post(URL_GUIA + '/guias_remision_inicial', data, { timeout: 10000 });
    const response = request.data;
    return response;
}

export const crearGuiaRemisionRequest = async (data) => {
    const request = await API.post(URL_GUIA + '/crear_guias_remision', data, { timeout: 10000 });
    const response = request.data;
    return response;
}

export const datosGuiaRemisionRequest = async (data) => {
    const request = await API.post(URL_GUIA + '/guia_remision_id', data, { timeout: 10000 });
    const response = request.data;
    return response;
}

export const editarGuiaRemisionRequest = async (data) => {
    const request = await API.post(URL_GUIA + '/editar_guia_remision', data, { timeout: 10000 });
    const response = request.data;
    return response;
}