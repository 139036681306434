import { Add, RemoveCircle } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ModalAnexos } from "./ModalAnexos";

export const CrearGuiaMercaderiaItem = ({
  index,
  item,
  onChangeCantidad,
  onChangePrecio,
  quitarProducto,
  addAnexo,
  removeAnexo,
}) => {
  const [modalAnexos, setModalAnexos] = useState(false);
  const openModalAnexos = () => setModalAnexos(true);
  const closeModalAnexos = () => setModalAnexos(false);
  return (
    <Box key={index}>
      <Box
        mt={2}
        component={"div"}
        sx={{
          minHeight: "40px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
        }}
      >
        <Box
          sx={{
            width: "20%",
            //backgroundColor: "green",
          }}
        >
          <TextField
            sx={{
              //backgroundColor: 'red',
              width: 85,
            }}
            // inputProps={{
            //   maxlength: 4
            // }}
            size="small"
            label="Cantidad"
            fullWidth
            value={item.cantidad}
            onChange={(e) => {
              if (e.target.value.includes(",")) {
                let cantidad_number = e.target.value;
                let fix_number = cantidad_number.replace(",", "");

                onChangeCantidad(index, fix_number);
              } else {
                onChangeCantidad(index, e.target.value);
              }
            }}
          />
        </Box>
        <Box
          sx={{
            width: "50%",
            //backgroundColor: "green",
            position: "relative",
          }}
        >
          <Typography>
            {item.nombre_producto}{" "} {item.anexos && ' - '}{" "}
            {item.anexos
              ?.map((a) => a.anexo)
              .toString()
              .replaceAll("[", " - ")
              .replaceAll("]", "")
              .replaceAll(",", " - ")}
          </Typography>
          {/* Anexos */}
          <Tooltip placement="top" title="Agregar anexo">
            <IconButton
              size="small"
              color="primary"
              onClick={openModalAnexos}
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
            >
              <Add />
            </IconButton>
          </Tooltip>
          <ModalAnexos
            item={item}
            index={index}
            visible={modalAnexos}
            close={closeModalAnexos}
            addAnexo={addAnexo}
            removeAnexo={removeAnexo}
          />
        </Box>
        <Box
          sx={{
            //backgroundColor: 'green',
            width: "20%",
          }}
        >
          <IconButton color={"error"} onClick={() => quitarProducto(index)}>
            <RemoveCircle />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
