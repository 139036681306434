import React, { Fragment } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ClientePage } from "../../pages/app/clientes/ClientePage";
import { ClientesPage } from "../../pages/app/clientes/ClientesPage";
import { InicioPage } from "../../pages/app/inicio/InicioPage";
import { ProductoPage } from "../../pages/app/productos/ProductoPage";
import { ProductosPage } from "../../pages/app/productos/ProductosPage";
import { ComprasRoute } from "./usuario/ComprasRoute";
import { FacturasRoute } from "./usuario/FacturasRoute";
import { GuiasRemisionRoute } from "./usuario/GuiasRemisionRoute";
import { NotasCreditoRoute } from "./usuario/NotasCreditoRoute";
import { ReportesRoute } from "./usuario/ReportesRoute";
import { TransportistasRoute } from "./usuario/TransportistasRoute";


export const UsuarioRoutes = () => {

  return (
    <Routes>
      <Route path="inicio" element={<InicioPage />} />
      <Route path="productos" element={<ProductosPage />} />
      <Route path="productos/:id" element={<ProductoPage />} />
      <Route path="clientes" element={<ClientesPage />} />
      <Route path="clientes/:id" element={<ClientePage />} />
      <Route path="/facturas/*" element={<FacturasRoute />} />
      <Route path="/creditos/*" element={<NotasCreditoRoute />} />
      <Route path="/guias/*" element={<GuiasRemisionRoute />} />
      <Route path="/compras/*" element={<ComprasRoute />} />
      <Route path="/transportistas/*" element={<TransportistasRoute />} />
      <Route path="/reportes/*" element={<ReportesRoute />} />
      <Route path="/*" element={<Navigate to="/inicio" />} />
    </Routes>
  );
};
