import React from "react";
import { Route, Routes } from "react-router-dom";
import { EditFacturaPage } from "../../../pages/app/facturas/EditFacturaPage";
import { FacturaPage } from "../../../pages/app/facturas/FacturaPage";
import { CrearUsuarioPage } from "../../../pages/app/usuarios/CrearUsuarioPage";
import { UsuariosPage } from "../../../pages/app/usuarios/UsuariosPage";
import { UsuarioPage } from "../../../pages/app/usuarios/UsuarioPage";

export const UsuariosRoute = () => {
  return (
    <Routes>
      <Route path="/*" element={<UsuariosPage />} />
      <Route path="/:id" element={<UsuarioPage />} />
      <Route path="crearUsuario" element={<CrearUsuarioPage />} />
      <Route path="editar/:id" element={<EditFacturaPage />} />
    </Routes>
  );
};
