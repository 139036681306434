import { Clear, InsertDriveFile } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { border } from "@mui/system";
import React from "react";
import { BToMb } from "../../../../helpers/FormatNumber";

export const DocumentosInput = ({ fileList, fileRemove, loading = false }) => {
  return (
    <Grid
      mt={1}
      padding={1}
      sx={{ backgroundColor: "#efebff", minHeight: "35vh", borderRadius: 2 }}
    >
      <Grid>
        {fileList.map((item) => {
          return (
            <Grid
              mt={0.3}
              className={"card-shadow"}
              sx={{ borderRadius: 2, backgroundColor: "#fff" }}
            >
              <Grid
                sx={{
                  position: "relative",
                  height: 55,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 55,
                  }}
                >
                  <InsertDriveFile color="primary" />
                </Grid>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Typography fontSize={11} fontWeight={"bold"}>
                      {item.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography fontSize={10}>{BToMb(item.size)} MB</Typography>
                  </Grid>
                  <Grid
                    item
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                  >
                    {loading && (
                      <>
                        <CircularProgress size={12} />
                        <Typography ml={1} fontSize={10}>Subiendo documento...</Typography>
                      </>
                    )}
                    {!loading && (
                      <Typography
                        fontSize={10}
                        color={item.result ? "green" : "red"}
                      >
                        {item.message}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                {/* REMOVER FILE*/}
                <Grid>
                  <IconButton size="small" onClick={() => fileRemove(item)}>
                    <Clear />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
