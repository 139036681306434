import {
  Avatar,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";

export const HeaderSideBar = () => {
  const { user, establecimiento, setEstablecimiento } = useContext(AuthContext);

  return (
    <Grid container flexDirection={"column"} alignItems={"center"}>
      <Grid
        item
        sx={{
          //backgroundColor: 'red',
          mt: 1,
          width: "90px",
          height: "90px",
        }}
      >
        <Avatar
          src={
            establecimiento?.url_logo_establecimiento ??
            "https://kubalubra.is/wp-content/uploads/2017/11/default-thumbnail.jpg"
          }
          sx={{
            width: "90px",
            height: "90px",
          }}
        />
      </Grid>
      <Grid mt={1} item>
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: 14,
            color: "#fff",
          }}
        >
          {establecimiento?.nombre_comercial_establecimiento}
        </Typography>
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: 12,
            color: "#fff",
          }}
        >
          {user?.usuario?.razon_social_emisor}
        </Typography>
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: 12,
            color: "#fff",
          }}
        >
          {user?.usuario?.ruc_emisor}
        </Typography>
      </Grid>
      <Grid
        mt={1}
        mb={1}
        sx={{
          width: "200px",
        }}
      >
        <FormControl fullWidth color="secondary" size="small">
          <InputLabel id="demo-simple-select-label" color="secondary">
            Establecimiento
          </InputLabel>
          <Select
            id="demo-simple-select"
            value={establecimiento?.id_establecimiento}
            label="Establecimiento"
            onChange={(e) => {
              const es = user?.establecimientos?.find(
                (esta) => esta.id_establecimiento == e.target.value
              );
              setEstablecimiento(es);
            }}
            color={"secondary"}
            sx={{
              color: "#fff",
            }}
          >
            {user?.establecimientos?.map((e) => {
              return (
                <MenuItem
                  key={e.id_establecimiento}
                  value={e.id_establecimiento}
                >
                  {e.direccion_establecimiento}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
