import React, { createContext, useState } from "react";

export const AccionesContext = createContext();

export const AccionesProvider = ({ children }) => {

  const [recargarProductos, setRecargarProductos] = useState(false);
  const [recargarClientes, setRecargarClientes] = useState(false);
  const [recargarFacturas, setRecargarFacturas] = useState(false);
  const [recargarCreditos, setRecargarCreditos] = useState(false);
  const [recargarGuias, setRecargarGuias] = useState(false);
  const [recargarTransportistas, setRecargarTransportistas] = useState(false);
  const [recargarCompras, setRecargarCompras] = useState(false);
  const [recargarUsuarios, setRecargarUsuarios] = useState(false);
  const [recargarPlanes, setRecargarPlanes] = useState(false);

  const accionProductos = () => {
    setRecargarProductos(!recargarProductos);
  };
  const accionClientes = () => {
    setRecargarClientes(!recargarClientes);
  };
  const accionFacturas = () => {
    setRecargarFacturas(!recargarFacturas);
  };
  const accionCreditos = () => {
    setRecargarCreditos(!recargarCreditos);
  };
  const accionGuias = () => {
    setRecargarGuias(!recargarGuias);
  };
  const accionTransportistas = () => {
    setRecargarTransportistas(!recargarTransportistas);
  };
  const accionCompras = () => {
    setRecargarCompras(!recargarCompras);
  };

  const accionUsuarios = () => {
    setRecargarUsuarios(!recargarUsuarios);
  };

  const accionPlanes = () => {
    setRecargarPlanes(!recargarPlanes);
  };

  return (
    <AccionesContext.Provider
      value={{
        recargarProductos,
        accionProductos,

        recargarClientes,
        accionClientes,

        recargarFacturas,
        accionFacturas,

        recargarCreditos,
        accionCreditos,

        recargarGuias,
        accionGuias,

        recargarTransportistas,
        accionTransportistas,

        recargarCompras,
        accionCompras,

        recargarUsuarios,
        accionUsuarios,

        recargarPlanes,
        accionPlanes,
      }}
    >
      {children}
    </AccionesContext.Provider>
  );
};
