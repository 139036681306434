import { Search } from "@mui/icons-material";
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";

export const PageSearch = ({
  title = "",
  searchText = "",
  textButtton = "Crear ",
  actionButton = () => {},
  onChangeSearch = () => {},
  search = "",
}) => {

  const [age, setAge] = React.useState('');

  const handleChange = (e) => {
    setAge(e.target.value);
  };

  return (
    <Grid
      container
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        height: 50,
        px: 1,
      }}
    >
      <Grid
        item
        xs={0}
        sm={0}
        md={3}
        lg={5}
        sx={{
          display: { xs: "none", sm: "none", md: "block", lg: "block" },
        }}
      >
        <Typography fontWeight={'bold'} fontSize={16} component={"h6"}>{title}</Typography>
      </Grid>
      <Grid
        item
        sx={{
          display: { xs: "none", sm: 'flex', md: "flex", lg: "flex" },
          alignItems: 'center',
          justifyContent: 'end'
        }}
        xs={0}
        sm={5}
        md={5}
        lg={3}
        xl={3}
      >
        <Button
          variant="contained"
          onClick={actionButton}
          sx={{
            borderRadius: 100,
            px: 3,
            fontSize: 13,
          }}
        >
          {textButtton}
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        sm={3}
        md={3}
        lg={3}
        sx={{
          backgroundColor: "#e3e2ff",
          borderRadius: 3,
          width: "100%",
        }}
      >
        
        <InputBase
          placeholder={searchText}
          value={search}
          sx={{
            height: "100%",
            width: "100%",
            px: 2,
          }}
          onChange={(e) => onChangeSearch(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          }
        />
      </Grid>
    </Grid>
  );
};
