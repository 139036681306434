import {
  CircularProgress,
  Divider,
  Grid,
  Alert,
  Button,
  Card,
  Box,
  Snackbar,
  Typography,
  Container,
  Tooltip,
  IconButton,
  Slide,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppLayout } from "../../../layouts/AppLayout";
import {
  anularDocumentoRequest,
  autorizarDocumentoRequest,
  consultarDocumentoIDRequest,
  descargarDocumentoPDFRequest,
  enviarCorreoRequest,
  erroresRecepcionAutorizacionRequest,
  generarDocumentoRequest,
  generarDocumentoXMLRequest,
} from "../../../services/api/DocumentoApi";

import { ESTADO_DOCUMENTO } from "../../../configs/configs";
import { DocumentoGuiaSustento } from "./components/DocumentoGuiaSustento";
import { DocumentoDetalle } from "./components/DocumentoDetalle";
import {
  DescriptionRounded,
  EmailRounded,
  PictureAsPdf,
  RemoveCircle,
} from "@mui/icons-material";
import { AccionesContext } from "../../../context/AccionesContext";
import jsPDF from "jspdf";
import fileDownload from "js-file-download";
import { AnularAlerta } from "./components/AnularAlerta";
import { datosGuiaRemisionRequest } from "../../../services/api/GuiaRemisionApi";
import { DocumentoGuiaEmision } from "./components/DocumentoGuiaEmision";
import { isNullOrUndefined } from "../../../helpers/functions/is-null-or-undefined";
import { ErroresXML } from "../../../global/components/ErroresXML";

export const GuiaRemisionPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [cabecera, setCabecera] = useState({});
  const [detalles, setDetalles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [fechaDocumento, setFechaDocumento] = useState("");

  const [documento, setDocumento] = useState({});
  const [erroresXML, setErroresXML] = useState(null);


  useEffect(() => {
    setLoading(true);

    const dataRequest = {
      id_documento: id,
    };

    datosGuiaRemisionRequest(dataRequest)
      .then((res) => {
        if (res.guia_remision) {
          setDocumento(res.guia_remision);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [reload]);

  useEffect(() => {
    erroresRecepcionAutorizacionRequest({ id_documento: id })
      .then((response) => {
        const { errores } = response;
        if (errores) {
          setErroresXML(errores);
        }
      })
      .catch();
  }, [reload]);

  const reloadPage = () => setReload(!reload);

  const { accionFacturas, accionCreditos } = useContext(AccionesContext);

  const [autorizando, setAutorizando] = useState(false);
  const [enviandoEmail, setEnviandoEmail] = useState(false);
  const [anulado, setAnulando] = useState(false);

  const [openAlert, setOpenAlert] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [openAnulando, setOpenAnulando] = React.useState(false);
  const [toast, setToast] = useState({
    open: false,
    text: "",
  });

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  //ANULACION LOADIN
  const handleClickAnulando = () => {
    setOpenAnulando(true);
  };

  const handleCloseAnulando = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAnulando(false);
  };

  //ALERT
  const handleClickOpen = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleGenerarPDF = (id_doc, num_doc) => {
    generarDocumentoRequest({ id_documento: id_doc })
      .then((resp) => {
        const doc = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: "A4",
        });

        doc.html(
          resp.html_string,

          {
            async callback(doc) {
              doc.save("pdf_name_" + num_doc);
            },
          }
        );
      })
      .catch((error) => {
      });
  };

  const handleGenerarXML = (id_doc) => {
    generarDocumentoXMLRequest({ id_documento: id_doc })
      .then((resp) => {

        const anchorTag = document.createElement("a");
        anchorTag.href = resp;
        anchorTag.download = "FacturaXML.xml";
        anchorTag.click();
      })
      .catch((error) => {
      });
  };

  const descargarPDF = (id, num_doc) => {
    descargarDocumentoPDFRequest({ id_documento: id })
      .then((res) => {
        if (res.result === false) {
          alert("No existe el documento !");
          return;
        }
        fileDownload(res, `GUIA ${num_doc}.pdf`);
      })
      .catch((err) => {
        alert("Ocurrio un error al descargar el documento !");
      });
  };

  const descargarXML = (id, num_doc) => {
    generarDocumentoXMLRequest({ id_documento: id })
      .then((res) => {
        if (res.result === false) {
          alert("No existe el documento !");
          return;
        }
        fileDownload(res, `GUIA ${num_doc}.xml`);
      })
      .catch((err) => {
        alert("Ocurrio un error al descargar el documento !");
      });
  };

  const autorizar = (id) => {
    setAutorizando(true);
    autorizarDocumentoRequest({ id_documento: id }).finally(() => {
      setAutorizando(false);
      accionFacturas();
      accionCreditos();
      reloadPage();
    });
  };

  const enviarEmail = (id) => {
    handleClick();
    setEnviandoEmail(true);
    enviarCorreoRequest({ id_documento: id })
      .then((res) => {
        if (res.result) {
          openToast("Correo enviado correctamente !");
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        setEnviandoEmail(false);
      });
  };

  const anularDocumento = (id) => {
    handleCloseAlert();
    handleClickAnulando(handleClickAnulando);
    setAnulando(true);
    anularDocumentoRequest({ id_documento: id })
      .then((res) => {
        if (res.result) {
        } else {
          alert(res.message);
        }
        accionFacturas();
      })
      .catch((err) => {
      })
      .finally(() => {
        setAnulando(false);
        handleCloseAnulando();
        reloadPage();
      });
  };

  const closeToast = () => {
    setToast({
      open: false,
      text: "",
    });
  };

  const openToast = (text = "") => {
    setToast({
      open: true,
      text: text,
    });
  };

  return (
    <AppLayout>
      <Grid xs={12} container>
        <Grid item xs={12} sm={12} md={9}>
          <Card
            sx={{
              borderRadius: 3,
              p: 2,
            }}
          >
            <Grid
              container
              xs={12}
              component={"div"}
              flexDirection={"row"}
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6" fontWeight={"bold"} fontSize={16}>
                Datos guía de remisión
              </Typography>
              <Typography fontWeight={"bold"} fontSize={14}>
                Estado documento:{" "}
                {ESTADO_DOCUMENTO[documento.estado_documento_electronico]}
              </Typography>
              {(documento.estado_documento_electronico == 1 ||
                documento.estado_documento_electronico == 2) && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    navigate(`/guias/editar/${id}`);
                  }}
                >
                  Editar
                </Button>
              )}
            </Grid>
            {loading && <CircularProgress size={20} sx={{ marginLeft: 2 }} />}

            <Grid mt={1}>
              {/* Botones */}
              <Grid container>
                <Grid
                  container
                  xs={12}
                  sx={{
                    justifyContent: {
                      xs: "space-between",
                      md: "flex-start",
                    },
                    alignItems: "center",
                  }}
                >
                  {/* AUTORIZAR */}
                  {ESTADO_DOCUMENTO[documento?.estado_documento_electronico] ===
                    "CREADO" ||
                  ESTADO_DOCUMENTO[documento?.estado_documento_electronico] ===
                    "EN PROCESO" ||
                  ESTADO_DOCUMENTO[documento?.estado_documento_electronico] ===
                    "DEVUELTO" ? (
                    <Button
                      variant={"contained"}
                      disabled={autorizando}
                      sx={{
                        height:
                          ESTADO_DOCUMENTO[
                            documento?.estado_documento_electronico
                          ] == "CREADO"
                            ? 28
                            : 35,
                        backgroundColor: "#00a23e",
                        fontSize:
                          ESTADO_DOCUMENTO[
                            documento?.estado_documento_electronico
                          ] == "CREADO"
                            ? 14
                            : 12,
                      }}
                      onClick={() => {
                        if (documento?.estado_documento_electronico == 2) {
                          const confirm = window.confirm(
                            "Su documento fue devuelto por el SRI, asegúrese de revisar los datos antes de volver a enviar."
                          );
                          if (!confirm) {
                            return;
                          }
                        }
                        if (documento?.estado_documento_electronico == 4) {
                          const confirm = window.confirm(
                            "El SRI devolvió el estado EN PROCESO, esperar un par de horas para volver enviar, si ya espero un tiempo prudente darle en Aceptar."
                          );
                          if (!confirm) {
                            return;
                          }
                        }
                        autorizar(documento?.id_documento);
                      }}
                    >
                      {autorizando ? (
                        <CircularProgress size={20} color={"secondary"} />
                      ) : ESTADO_DOCUMENTO[
                          documento?.estado_documento_electronico
                        ] == "CREADO" ? (
                        "AUTORIZAR"
                      ) : (
                        `AUTORIZAR - ${
                          ESTADO_DOCUMENTO[
                            documento?.estado_documento_electronico
                          ]
                        }`
                      )}
                    </Button>
                  ) : (
                    ESTADO_DOCUMENTO[documento?.estado_documento_electronico]
                  )}
                  {/* PDF */}
                  <Tooltip
                    title={`Descargar ${documento.numero_documento}.pdf`}
                    placement={"top"}
                  >
                    <IconButton
                      onClick={() => {
                        // handleGenerarPDF(
                        //   documento.id_documento,
                        //   documento.numero_documento
                        // );

                        descargarPDF(
                          documento.id_documento,
                          documento.numero_documento
                        );
                      }}
                      color={"primary"}
                    >
                      <PictureAsPdf />
                    </IconButton>
                  </Tooltip>
                  {/* XML */}
                  <Tooltip
                    title={`Descargar ${documento.numero_documento}.xml`}
                    placement="top"
                  >
                    <IconButton
                      color="primary"
                      disabled={
                        !(
                          ESTADO_DOCUMENTO[
                            documento.estado_documento_electronico
                          ] == "AUTORIZADO"
                        )
                      }
                      onClick={() =>
                        descargarXML(
                          documento.id_documento,
                          documento.numero_documento
                        )
                      }
                    >
                      <DescriptionRounded />
                    </IconButton>
                  </Tooltip>
                  {/* ANULAR */}
                  <Tooltip placement="top" title={"Anular documento"}>
                    <IconButton
                      color="error"
                      disabled={documento.estado_documento_electronico == 0}
                      onClick={() => handleClickOpen()}
                    >
                      <RemoveCircle />
                    </IconButton>
                  </Tooltip>
                  {/* EMAIL */}
                  {enviandoEmail ? (
                    <CircularProgress size={20} color={"primary"} />
                  ) : (
                    <Tooltip
                      title={`Reenviar a ${documento.cliente?.emails_contacto
                        ?.replaceAll("[", "")
                        .replaceAll("]", "")
                        .replaceAll(/['"]+/g, "")
                        .replaceAll(",", ", ")}`}
                      placement={"top"}
                    >
                      <IconButton
                        color="primary"
                        disabled={
                          !(
                            ESTADO_DOCUMENTO[
                              documento.estado_documento_electronico
                            ] == "AUTORIZADO"
                          )
                        }
                        onClick={() => enviarEmail(documento.id_documento)}
                      >
                        <EmailRounded />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
              {!isNullOrUndefined(erroresXML) && (
                <Grid>
                  <ErroresXML errores={erroresXML} />
                </Grid>
              )}
              {/* DOCUMENTO EMISION */}
              <DocumentoGuiaEmision documento={documento} />
              {/* DOCUMENTO NOTA DE CREDITO */}
              <DocumentoGuiaSustento documento={documento} />
              {/* DOCUMENTO DETALLE */}
              <DocumentoDetalle documento={documento} />
            </Grid>
          </Card>
        </Grid>
        {/* LOADING */}
        <Snackbar
          TransitionComponent={Slide}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            everity="info"
            variant="standard"
            color="info"
            icon={false}
            sx={{ width: "100%", pr: 15 }}
          >
            <CircularProgress size={18} sx={{ mr: 2 }} />
            Enviando correo...
          </Alert>
        </Snackbar>
        {/* LOADING ANULADO*/}
        <Snackbar
          TransitionComponent={Slide}
          open={openAnulando}
          autoHideDuration={6000}
          onClose={handleCloseAnulando}
        >
          <Alert
            everity="info"
            variant="standard"
            color="info"
            icon={false}
            sx={{ width: "100%", pr: 15 }}
          >
            <CircularProgress size={18} sx={{ mr: 2 }} />
            Anulando documento...
          </Alert>
        </Snackbar>

        {/* TOAST */}
        <Snackbar
          open={toast.open}
          autoHideDuration={6000}
          onClose={closeToast}
        >
          <Alert onClose={closeToast} severity="success" sx={{ width: "100%" }}>
            {toast.text}
          </Alert>
        </Snackbar>
        {/* ALERTA ANULACION */}
        <AnularAlerta
          open={openAlert}
          handleClose={handleCloseAlert}
          anular={anularDocumento}
          documento={documento}
        />
      </Grid>
    </AppLayout>
  );
};
