import API from "./ApiConfig"

const URL_COMPRA = '/compra';


export const comprasRequest = async (data) => {
    const request = await API.post(URL_COMPRA + '/listar_documentos_compra', data);
    const response = request.data;
    return response;
}

export const busquedaComprasRequest = async (data) => {
    const request = await API.post(URL_COMPRA + '/busqueda_documento_compra', data);
    const response = request.data;
    return response;
}

export const subirCompraRequest = async (data) => {
    const request = await API.post(URL_COMPRA + '/subir_compra', data, { headers: { 'Content-Type': 'multipart/form-data' } });
    const response = request.data;
    return response;
}

export const eliminarCompraRequest = async (data) => {
    const request = await API.post(URL_COMPRA + '/eliminar_compra', data);
    const response = request.data;
    return response;
}
