import { Typography, Grid } from "@mui/material";
import React from "react";
import { FormatoNumero } from "../../../../helpers/FormatNumber";

export const TotalesReporteCobro = ({ option = 1, columns, totales }) => {
  return (
    <Grid xs={12}>
      <Grid
        container
        sx={{
          border: "1px solid #353535",
        }}
      >
        <Grid
          container
          justifyContent={"center"}
          xs={12}
          sx={{
            minHeight: 30,
            alignItems: "center",
            backgroundColor: "#fff",
          }}
        >
          <Grid xs={1.5}>
            <Typography></Typography>
          </Grid>
          <Grid xs={1}>
            <Typography></Typography>
          </Grid>
          <Grid xs={1}>
            <Typography></Typography>
          </Grid>
          <Grid xs={1}>
            <Typography></Typography>
          </Grid>
          <Grid xs={3}>
            <Typography></Typography>
          </Grid>
          <Grid
            xs={1}
            container
            sx={{
              px: 1,
              justifyContent: "flex-end",
            }}
          >
            <Typography fontWeight={"bold"}>DEBITO</Typography>
          </Grid>
          <Grid
            xs={1}
            container
            sx={{
              px: 1,
              justifyContent: "flex-end",
            }}
          >
            <Typography fontWeight={"bold"}>CREDITO</Typography>
          </Grid>
          <Grid
            xs={1}
            container
            sx={{
              px: 1,
              justifyContent: "flex-end",
            }}
          >
            <Typography fontWeight={"bold"}>SALDO</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          xs={12}
          sx={{
            minHeight: 30,
            alignItems: "center",
            backgroundColor: "#fff",
          }}
        >
          <Grid xs={1.5}>
            <Typography></Typography>
          </Grid>
          <Grid xs={1}>
            <Typography></Typography>
          </Grid>
          <Grid xs={1}>
            <Typography></Typography>
          </Grid>
          <Grid xs={1}>
            <Typography></Typography>
          </Grid>
          <Grid xs={3}>
            <Typography></Typography>
          </Grid>
          <Grid
            xs={1}
            container
            sx={{
              px: 1,
              justifyContent: "flex-end",
              borderTop: "1px solid #353535",
              borderLeft: "1px solid #353535",
            }}
          >
            <Typography fontWeight={"bold"}>
              {FormatoNumero(totales?.documentos_total?.valor_total)}
            </Typography>
          </Grid>
          <Grid
            xs={1}
            container
            sx={{
              px: 1,
              justifyContent: "flex-end",
              borderTop: "1px solid #353535",
              borderLeft: "1px solid #353535",
            }}
          >
            <Typography fontWeight={"bold"}>
              -{FormatoNumero((totales?.documentos_total?.valor_total) - (totales?.documentos_0?.valor_total))}
            </Typography>
          </Grid>
          <Grid
            xs={1}
            container
            sx={{
              px: 1,
              justifyContent: "flex-end",
              borderTop: "1px solid #353535",
              borderLeft: "1px solid #353535",
              borderRight: "1px solid #353535",
            }}
          >
            <Typography fontWeight={"bold"}>
              {FormatoNumero(totales?.documentos_0?.valor_total)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
