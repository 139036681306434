import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { isNullOrUndefined } from "../../../../helpers/functions/is-null-or-undefined";

export const Producto = ({ producto, seleccionarProducto }) => {
  return (
    <Box mt={0.5}>
      <Box component={"div"} onClick={() => seleccionarProducto(producto)}>
        <Box
          component={"div"}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box
            width={"60%"}
            // backgroundColor={'red'}
          >
            <Typography>{producto.nombre_producto}</Typography>
          </Box>
          
          <Box
            width={"25%"}
            // backgroundColor={'red'}
          >
            <Typography align="center">
              {isNullOrUndefined(producto?.iva)
                ? producto?.precio_iva
                : producto?.iva?.descripcion}
            </Typography>
          </Box>
          <Box width={"15%"}>
            <Typography fontWeight={"bold"} textAlign={"end"}>
              $ {producto.precio_unitario}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};
