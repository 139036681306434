import { Box } from '@mui/material';
import React from 'react'

export const ModalLayout = ({ children }) => {
  return (
    <Box
        sx={{...style, 
            width: {
                xs: '100%',
                sm: 400,
            }
        }}

    >
    { children }
    </Box>
  )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    minHeight: 360,
    backgroundColor: '#fff',
    borderRadius: 3,
    boxShadow: 24,
    p: 3,
  };