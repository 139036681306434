import { DeleteRounded, Edit, RemoveCircle } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";

export const ClienteEmailsForm = ({
  emails = [],
  removeEmail = (i) => {},
}) => {
  return (
    <Box
      component={"div"}
      container
      sx={{
        // backgroundColor: 'red',
        width: "100%",
      }}
    >
      {emails.map((e, index) => {
        return (
          <Box
            component={"div"}
            position={"relative"}
            sx={{ width: "100%" }}
            py={1}
            key={index}
          >
            <Typography fontSize={13}>{e}</Typography>
            <IconButton
              onClick={() => removeEmail(index)}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <RemoveCircle color="error" />
            </IconButton>
          </Box>
        );
      })}
    </Box>
  );
};