import { Divider, Grid, Typography } from "@mui/material";
import React from "react";

export const DocumentoEmision = ({ documento }) => {

    const emails = documento?.cliente?.emails_contacto;

  return (
    <Grid
      container
      xs={12}
      sx={{
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: { sm: "space-between" },
      }}
    >
      <Grid xs={12} sm={5.8}>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Typography fontWeight={"bold"}>Documento Nº.</Typography>
          <Typography>{documento.numero_documento}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Typography fontWeight={"bold"}>Fecha</Typography>
          <Typography>
            {documento.fecha_emision_documento?.substring(0, 10)}
          </Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Typography fontWeight={"bold"}>RUC</Typography>
          <Typography>{documento.ruc}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Typography fontWeight={"bold"}>Establecimiento</Typography>
          <Typography>{documento.direccion_establecimiento}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Typography fontWeight={"bold"}>Matriz</Typography>
          <Typography>{documento.direccion_matriz}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Typography fontWeight={"bold"}>
            Obligado a llevar contabilidad
          </Typography>
          <Typography>{documento.obligado_contabilidad}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"column"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Typography fontWeight={"bold"}>Clave de acceso</Typography>
          <Typography
            textAlign={"center"}
            sx={{
              wordWrap: "break-word",
              maxWidth: "100%",
            }}
          >
            {documento.clave_acceso}
          </Typography>
        </Grid>
      </Grid>
      <Divider mt={2} mb={2} />
      <Grid xs={12} sm={5.8}>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Typography fontWeight={"bold"}>Cliente</Typography>
          <Typography>{documento.razon_social_cliente}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Typography fontWeight={"bold"}>Identificación</Typography>
          <Typography>{documento.num_identificacion_cliente}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Typography fontWeight={"bold"}>Dirección</Typography>
          <Typography>{documento?.cliente?.direccion_cliente}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Typography fontWeight={"bold"}>Email</Typography>
          <Typography>{emails?.replaceAll('[', '').replaceAll(']', '').replaceAll(/['"]+/g, '').replaceAll(',', ', ')}</Typography>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Typography fontWeight={"bold"}>Telefono</Typography>
          <Typography>{documento?.cliente?.telefono_cliente}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
