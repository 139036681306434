import { UploadFile } from "@mui/icons-material";
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";

export const FirmaForm = ({ data, setData }) => {
  return (
    <Grid container flexDirection={"column"} alignItems={"center"}>
      <Grid>
        <Typography fontWeight={"bold"} color={"darkgrey"} my={1}>
          FIRMA
        </Typography>
      </Grid>
      <Grid>
        <input
          id="input-file"
          className={"input-file"}
          type="file"
          accept=".p12"
          onChange={(e) => {
            if(!e.target.files[0]) return;
            setData({
              ...data,
              firma: e.target.files[0],
            });
          }}
        />
        <label htmlFor="input-file" className="input-file-label">
          <UploadFile sx={{ marginRight: 1, height: 20 }} />
          {
            data.firma?.name ?? 'Archivo P12'
          }
        </label>
      </Grid>
      <Grid mt={1}>
        <TextField
          value={data.password_firma}
          onChange={(e) => {
            setData({
              ...data,
              password_firma: e.target.value,
            });
          }}
          label={"Clave de firma"}
          size={"small"}
          fullWidth
        />
      </Grid>
      <Grid mt={1}>
        <TextField
          value={data.fecha_valida}
          onChange={(e) => {
            setData({
              ...data,
              fecha_valida: e.target.value,
            });
          }}
          label={"Fecha vigente"}
          type={"date"}
          size={"small"}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
