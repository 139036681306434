import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ModalLayout } from "../../../../layouts/ModalLayout";
import { ArrowBack, Delete, Image } from "@mui/icons-material";
import {
  establecimientoUpdateRequest,
  establecimientoUpdateValidRequest,
  firmaUpdateRequest,
  usuarioUpdateRequest,
} from "../../../../services/api/UsuariosApi";

const EstablecimientoModal = ({
  establecimiento,
  visible,
  close,
  reload = () => {},
}) => {
  const matrizInit = establecimiento.matriz;

  const [data, setData] = useState({
    id_establecimiento: establecimiento.id_establecimiento,
    nombre_comercial: establecimiento.nombre_comercial,
    url_logo: establecimiento.url_logo,
    direccion_establecimiento: establecimiento.direccion_establecimiento,
    codigo_establecimiento: establecimiento.codigo_establecimiento,
    matriz: establecimiento.matriz,
    punto_emision: establecimiento.punto_emision,
    logo: {},
  });

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState({
    text: "",
    show: false,
  });

  const handleSave = async () => {
    setError({
      text: "",
      show: false,
    });

    const confirm = window.confirm(
      "¿Esta seguro de editar los datos del establecimiento?"
    );

    if (!confirm) return;
    //VALIDAR DATOS
    const validaciones = validarDatos();
    const errores = Object.keys(validaciones);
    if (errores.length > 0) {
      let alert = "";
      errores.forEach((key) => {
        alert += `${validaciones[key]}\n`;
      });
      window.alert(alert);
      return;
    }

    const dataRequest = {
      id_establecimiento: data.id_establecimiento,
      nombre_comercial: data.nombre_comercial,
      url_logo: data.url_logo,
      direccion_establecimiento: data.direccion_establecimiento,
      codigo_establecimiento: data.codigo_establecimiento,
      matriz: data.matriz,
      punto_emision: data.punto_emision,
      logo: data.logo,
    };

    const formData = new FormData();

    formData.append("id_establecimiento", dataRequest.id_establecimiento);
    formData.append(
      "nombre_comercial_establecimiento",
      dataRequest.nombre_comercial == "" ? " " : dataRequest.nombre_comercial
    );
    formData.append(
      "url_logo_establecimiento",
      dataRequest.url_logo == "" ? " " : dataRequest.url_logo
    );
    formData.append(
      "direccion_establecimiento",
      dataRequest.direccion_establecimiento
    );
    formData.append(
      "codigo_establecimiento",
      dataRequest.codigo_establecimiento
    );
    formData.append("estado_matriz_establecimiento", dataRequest.matriz);
    formData.append(
      "id_punto_emision",
      dataRequest.punto_emision.id_punto_emision
    );
    formData.append(
      "codigo_punto_emision",
      dataRequest.punto_emision.codigo_punto_emision
    );
    if (dataRequest.logo instanceof File) {
      formData.append("logo", dataRequest.logo);
    }

    setIsLoading(true);

    let has_confirm = false;
    let count = 0;
    await establecimientoUpdateValidRequest(formData)
      .then((res) => {
        if (res.result) {
          has_confirm = res.data.has_to_confirm;
          count = parseInt(res.data.document_count);
        } else {
          setError({
            text: res.message,
            show: true,
          });
        }
      })
      .catch()
      .finally(() => setIsLoading(false));

    if (has_confirm) {
      const msg = `LEER DETENIDAMENTE ANTES DE ACEPTAR\nEste establecimiento tiene ${count} documento${
        count > 1 ? "s" : ""
      }, si desea cambiar el código de establecimiento o el código del punto de emisión, debe tener en cuenta que se va a volver a crear los documentos y la clave de acceso, también si los documentos están autorizados el emisor tendrá que manualmente volver a autorizarlos.\n¿Está seguro de continuar?`;
      const confirm_es = window.confirm(msg);
      if (!confirm_es) return;
    }

    setIsLoading(true);
    establecimientoUpdateRequest(formData)
      .then((res) => {
        if (res.result) {
          reload();
          close();
        } else {
          setError({
            text: res.message,
            show: true,
          });
        }
      })
      .catch()
      .finally(() => setIsLoading(false));
  };

  const validarDatos = () => {
    let errores = {};

    if (
      data.direccion_establecimiento.trim() === "" ||
      data.codigo_establecimiento.trim() === "" ||
      Object.keys(data.punto_emision).length === 0
    ) {
      errores.datos_obligatorios =
        "La dirección del establecimiento, código del establecimiento y código del punto de emisión no pueden estar vacios";
    }

    const codigoEstablecimientoRegex = /^[0-9]{1,3}$/;
    if (!codigoEstablecimientoRegex.test(data.codigo_establecimiento)) {
      errores.formato_codigo_establecimiento =
        "El codigo del establecimiento debe cumplir con el formato establecido por el SRI";
    }

    const puntoEmisionRegex = /^[0-9]{1,3}$/;
    if (!puntoEmisionRegex.test(data.punto_emision?.codigo_punto_emision)) {
      errores.formato_codigo_punto_emision =
        "El codigo del punto de emision debe cumplir con el formato establecido por el SRI";
    }

    return errores;
  };

  const handleDeleteLogo = () => {
    setData({
      ...data,
      logo: {},
      url_logo: "",
    });
  };

  return (
    <Modal
      open={visible}
      onClose={() => {
        close();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout>
        <Grid container alignItems={"center"} flexDirection={"column"}>
          {/* Header */}
          <Grid
            sx={{
              height: 40,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
              }}
            >
              Editar Establecimiento
            </Typography>
            <IconButton
              onClick={close}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <ArrowBack />
            </IconButton>
          </Grid>
          {/* Body */}
          <Grid
            container
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid>
              <TextField
                value={data.direccion_establecimiento}
                onChange={(e) => {
                  setData({
                    ...data,
                    direccion_establecimiento: e.target.value.toUpperCase(),
                  });
                }}
                label={"Dirección"}
                size={"small"}
                fullWidth
                required
              />
            </Grid>
            <Grid mt={1}>
              <TextField
                value={data.codigo_establecimiento}
                onChange={(e) => {
                  setData({
                    ...data,
                    codigo_establecimiento: e.target.value,
                  });
                }}
                label={"Codigo establecimiento ej. 001"}
                size={"small"}
                fullWidth
                required
                inputProps={{
                  maxLength: 3,
                }}
              />
            </Grid>
            <Grid mt={1}>
              <TextField
                value={data.punto_emision.codigo_punto_emision ?? ""}
                onChange={(e) => {
                  setData({
                    ...data,
                    punto_emision: {
                      ...data.punto_emision,
                      codigo_punto_emision: e.target.value,
                    },
                  });
                }}
                label={"Punto emision ej. 001"}
                size={"small"}
                fullWidth
                required
                inputProps={{
                  maxLength: 3,
                }}
              />
            </Grid>
            <Grid mt={1}>
              <FormControlLabel
                disabled={matrizInit}
                sx={{
                  width: 230,
                }}
                control={
                  <Checkbox
                    checked={data.matriz}
                    onChange={(e) => {
                      setData({
                        ...data,
                        matriz: e.target.checked,
                      });
                    }}
                  />
                }
                label="Matriz"
                required
              />
            </Grid>
            <Grid mt={1}>
              <TextField
                value={data.nombre_comercial}
                onChange={(e) => {
                  setData({
                    ...data,
                    nombre_comercial: e.target.value.toUpperCase(),
                  });
                }}
                label={"Nombre comercial"}
                size={"small"}
                fullWidth
              />
            </Grid>
            <Grid mt={1}>
              {data.url_logo.length > 0 && (
                <Grid
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  marginBottom={1}
                  position={"relative"}
                >
                  <img className="image_logo" src={data.url_logo} />
                  <Tooltip title={"Quitar logo"}>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={handleDeleteLogo}
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                      }}
                    >
                      <Delete style={{ height: 22, width: 22 }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              <input
                id="input-file-logo"
                className={"input-file"}
                type="file"
                accept="image/*"
                onChange={(e) => {
                  if (!e.target.files[0]) return;

                  setData({
                    ...data,
                    logo: e.target.files[0],
                    url_logo: URL.createObjectURL(e.target.files[0]),
                  });
                }}
              />
              <label htmlFor="input-file-logo" className="input-file-label">
                <Image sx={{ marginRight: 1, height: 20 }} />
                {data.logo?.name ?? "Logo"}
              </label>
            </Grid>
            <Box
              mt={3}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {isLoading && <CircularProgress size={25} />}
              {error.show && <Alert severity="error">{error.text}</Alert>}
              <Button
                size="small"
                onClick={handleSave}
                variant="contained"
                disabled={isLoading}
                style={{
                  borderRadius: 100,
                  px: 4,
                  backgroundColor: isLoading ? "GrayText" : "#000590",
                  width: "200px",
                }}
              >
                Guardar cambios
              </Button>
            </Box>
          </Grid>
        </Grid>
      </ModalLayout>
    </Modal>
  );
};

export default EstablecimientoModal;
