import API from "./ApiConfig"

const URL_PRODUCTO = '/producto';


export const productosRequest = async (data) => {
    const request = await API.post(URL_PRODUCTO + '/consultar_producto_establecimiento', data);
    const response = request.data;
    return response;
}

export const productoRequest = async (data) => {
    const request = await API.post(URL_PRODUCTO + '/consultar_producto_id', data);
    const response = request.data;
    return response;
}

export const editarProductoRequest = async (data) => {
    const request = await API.post(URL_PRODUCTO + '/editar_producto', data);
    const response = request.data;
    return response;
}

export const eliminarProductoRequest = async (data) => {
    const request = await API.post(URL_PRODUCTO + '/eliminar_producto', data);
    const response = request.data;
    return response;
}

export const productosBusquedaRequest = async (data) => {
    const request = await API.post(URL_PRODUCTO + '/consultar_producto_nombre', data);
    const response = request.data;
    return response;
}

export const crearProductoRequest = async (data) => {
    const request = await API.post(URL_PRODUCTO + '/registrar_producto', data);
    const response = request.data;
    return response;
}

