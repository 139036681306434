import { Checkbox, Divider, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";

export const DocumentoDetalle = ({ documento }) => {
  return (
    <Grid container mt={2}>
      {/* Header */}
      <Grid
        container
        sx={{
          backgroundColor: "#0032d4",
          height: "40px",
          justifyContent: "space-between",
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        }}
      >
        {/* Codigo */}
        <Grid
          container
          xs={1.5}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography color={"#fff"}>Código</Typography>
        </Grid>
        {/* Cantidad */}
        <Grid container xs={2} alignItems={"center"} justifyContent={"center"}>
          <Typography color={"#fff"}>Cantidad</Typography>
        </Grid>
        {/* Descripcion */}
        <Grid container xs={3} alignItems={"center"}>
          <Typography color={"#fff"}>Descripción</Typography>
        </Grid>
        {/* Precio unitario*/}
        <Grid container xs={2} alignItems={"center"} justifyContent={"center"}>
          <Typography color={"#fff"}>Precio unitario</Typography>
        </Grid>
        {/* IVA */}
        <Grid container xs={1} alignItems={"center"} justifyContent={"center"}>
          <Typography color={"#fff"}>IVA</Typography>
        </Grid>
        {/* Total */}
        <Grid container xs={2} alignItems={"center"} justifyContent={"center"}>
          <Typography color={"#fff"}>Total</Typography>
        </Grid>
      </Grid>
      {/* Detalles */}
      {documento?.detalles?.map((detalle) => {
        return (
          <Fragment key={detalle.id_detalle_documento + detalle.id_documento}>
            <Grid
              container
              sx={{
                minHeight: "40px",
                justifyContent: "space-between",
              }}
            >
              {/* Codigo */}
              <Grid
                container
                xs={1.5}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography>{detalle.codigo_producto}</Typography>
              </Grid>
              {/* Cantidad */}
              <Grid
                container
                xs={2}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography>{detalle.cantidad}</Typography>
              </Grid>
              {/* Descripcion */}
              <Grid container xs={3} alignItems={"center"}>
                <Typography>
                  {detalle.descripcion}{" "} {detalle.anexos && ' - '}{" "} 
                  {detalle.anexos
                    ?.map((a) => a.anexo)
                    .toString()
                    .replaceAll("[", "")
                    .replaceAll("]", "")
                    .replaceAll(",", " - ")}
                </Typography>
              </Grid>
              {/* Precio unitario*/}
              <Grid
                container
                xs={2}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography>
                  {Number(detalle.valor_unitario).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>
              {/* IVA */}
              <Grid
                container
                xs={1}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Checkbox
                  size="small"
                  disabled
                  checked={detalle.tiene_iva == "SI"}
                />
              </Grid>
              {/* Total */}
              <Grid
                container
                xs={2}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography>
                  {Number(detalle.valor_total).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
          </Fragment>
        );
      })}
    </Grid>
  );
};
