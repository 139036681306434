import { AccountCircle, Search } from "@mui/icons-material";
import {
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { transportistaIdentificacionRequest } from "../../../../services/api/TransportistasApi";

export const GuiaTransporteForm = ({
  form,
  setForm,
  onChangeForm,
  tipos_identificaciones,
}) => {
  const { establecimiento } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const buscarTransportisa = (e) => {
    e.preventDefault();
    const identificacion_transportista = form.guia_ruc_transportista;
    const data_request = {
      identificacion_transportista: identificacion_transportista,
      id_establecimiento: establecimiento.id_establecimiento,
    };

    setLoading(true);
    transportistaIdentificacionRequest(data_request)
      .then((res) => {
        if (res.result && res.transportista) {
          const t = res.transportista;
          setForm({
            ...form,
            guia_razon_social_transportista: t.razon_social_transportista,
            guia_placa: t.placa_transportista,
            guia_tipo_identificacion_transportista:
              t.tipo_identificacion_transportista,
            transportista_new: false,
          });
        } else {
          setForm({
            ...form,
            guia_razon_social_transportista: "",
            guia_placa: "",
            guia_tipo_identificacion_transportista: "",
            transportista_new: true,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid xs={12}>
      {/* DATOS DE TRANSPORTISTA */}
      <Grid mb={1}>
        <Typography fontWeight={"bold"} color={"darkgray"}>
          DATOS TRANSPORTISTA (OBLIGATORIO *)
        </Typography>
        <Grid
          mt={1}
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <form>
              <TextField
                label="Identificación"
                size="small"
                required
                fullWidth
                value={form.guia_ruc_transportista}
                onChange={(e) =>
                  onChangeForm("guia_ruc_transportista", e.target.value)
                }
                InputProps={{
                  sx: {
                    paddingRight: 0,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={!loading && buscarTransportisa}
                        color="primary"
                        type="submit"
                      >
                        {loading ? <CircularProgress size={25} /> : <Search />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Grid>
          <Grid
            item
            sx={{
              marginTop: { xs: 1, sm: 0 },
              display: "flex",
              justifyContent: { xs: "flex-start", sm: "flex-end" },
            }}
          >
            <FormControl size="small">
              <InputLabel id="demo-simple-select-label">
                Tipo identficación
              </InputLabel>
              <Select
                id="demo-simple-select"
                value={form.guia_tipo_identificacion_transportista}
                label="Tipo identificación"
                sx={{
                  width: 230,
                }}
                size="small"
                onChange={(e) => {
                  onChangeForm(
                    "guia_tipo_identificacion_transportista",
                    e.target.value
                  );
                }}
              >
                {tipos_identificaciones.map((tipo) => {
                  return (
                    <MenuItem
                      key={tipo.id_tipo_identificacion}
                      value={tipo.codigo_identificacion}
                      disabled={tipo.codigo_identificacion === "07"}
                    >
                      {tipo.descripcion_tipo}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          mt={1}
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <TextField
              label="Razon social transportista"
              size="small"
              fullWidth
              required
              value={form.guia_razon_social_transportista}
              onChange={(e) =>
                onChangeForm("guia_razon_social_transportista", e.target.value)
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              marginTop: { xs: 1, sm: 0 },
              display: "flex",
              justifyContent: { xs: "flex-start", sm: "flex-end" },
            }}
          >
            <TextField
              label="Placa"
              size="small"
              required
              fullWidth
              value={form.guia_placa}
              onChange={(e) =>
                onChangeForm("guia_placa", e.target.value.toUpperCase())
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      {/* DATOS DE TRANSPORTE */}
      <Grid mt={1} xs={12} mb={1}>
        <Typography fontWeight={"bold"} color={"darkgray"}>
          DATOS TRANSPORTE (OBLIGATORIO *)
        </Typography>
        <Grid
          mt={1}
          container
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              label="Dirección de partida"
              size="small"
              fullWidth
              required
              value={form.guia_direccion_partida}
              onChange={(e) =>
                onChangeForm("guia_direccion_partida", e.target.value)
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              marginTop: { xs: 1, sm: 0 },
              display: "flex",
              justifyContent: { xs: "flex-start", sm: "flex-end" },
            }}
          >
            <TextField
              label="Dirección de destino"
              size="small"
              fullWidth
              required
              value={form.guia_direccion_destinatario}
              onChange={(e) => {
                onChangeForm("guia_direccion_destinatario", e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid
          mt={1}
          container
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              disabled
              label="Fecha Inicio Transporte"
              size="small"
              type={"date"}
              required
              onChange={(e) => {
                onChangeForm("guia_fecha_inicio_transporte", e.target.value);
              }}
              value={form.guia_fecha_inicio_transporte}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              marginTop: { xs: 1, sm: 0 },
              display: "flex",
              justifyContent: { xs: "flex-start", sm: "flex-end" },
            }}
          >
            <TextField
              fullWidth
              label="Fecha Fin Transporte"
              size="small"
              type={"date"}
              required
              onChange={(e) => {
                onChangeForm("guia_fecha_fin_transporte", e.target.value);
              }}
              value={form.guia_fecha_fin_transporte}
            />
          </Grid>
        </Grid>
        <Grid 
          mt={1}>
          <TextField
            label="Motivo de transporte"
            size="small"
            required
            fullWidth
            multiline
            inputProps={{
              maxLength: 300,
            }}
            sx={{
              width: "100%",
            }}
            value={form.guia_motivo_traslado}
            onChange={(e) =>
              onChangeForm("guia_motivo_traslado", e.target.value)
            }
          />
        </Grid>
        <Grid
          mt={1}
          container
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              label="Nombre destinatario"
              size="small"
              fullWidth
              required
              value={form.guia_razon_social_destinatario}
              onChange={(e) =>
                onChangeForm("guia_razon_social_destinatario", e.target.value)
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              marginTop: { xs: 1, sm: 0 },
              display: "flex",
              justifyContent: { xs: "flex-start", sm: "flex-end" },
            }}
          >
            <TextField
              label="Identificación"
              size="small"
              required
              fullWidth
              value={form.guia_identificacion_destinatario}
              onChange={(e) =>
                onChangeForm("guia_identificacion_destinatario", e.target.value)
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
