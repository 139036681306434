import { Divider, Grid, TextField, Typography } from "@mui/material";
import React from "react";

export const GuiaDestinatarioForm = ({ form, onChangeForm }) => {
  return (
    <Grid xs={12}>
      {/* DATOS EMISION */}
      <Grid mt={1} xs={12} mb={1}>
        <Typography fontWeight={"bold"} color={"darkgray"}>
          DATOS EMISION (NO OBLIGATORIO)
        </Typography>

        <Grid
          mt={1}
          container
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              label="Nº de documento sustento"
              size="small"
              onChange={(e) => {
                onChangeForm("guia_numero_documento_sustento", e.target.value);
              }}
              value={form.guia_numero_documento_sustento}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              marginTop: { xs: 1, sm: 0 },
              display: "flex",
              justifyContent: { xs: "flex-start", sm: "flex-end" },
            }}
          >
              <TextField
                label="Nº de autorizacion documento sustento"
                size="small"
                fullWidth
                onChange={(e) => {
                  onChangeForm(
                    "guia_numero_autorizacion_documento_sustento",
                    e.target.value
                  );
                }}
                value={form.guia_numero_autorizacion_documento_sustento}
              />
          </Grid>
        </Grid>
        <Grid mt={1}></Grid>
        {/* <Grid mt={1}>
          <TextField
            label="Documento aduanero unico"
            size="small"
            fullWidth
            onChange={(e) => {
              onChangeForm("guia_documento_aduanero_unico", e.target.value);
            }}
            value={form.guia_documento_aduanero_unico}
          />
        </Grid> */}
      </Grid>
    </Grid>
  );
};
