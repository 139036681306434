import { AddCircle, ArrowBack, RemoveCircle } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { ModalLayout } from "../../../../layouts/ModalLayout";

export const ModalAnexos = ({ index, item, visible, close, addAnexo, removeAnexo }) => {
  const [anexo, setAnexo] = useState("");

  const onChangeAnexo = (e) => {
    const value = e.target.value;
    setAnexo(value);
  };

  return (
    <Modal
      open={visible}
      onClose={() => {
        close();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          position={"relative"}
        >
          <IconButton
            onClick={close}
            sx={{
              position: "absolute",
              left: 0,
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography fontWeight={"bold"}>Anexos</Typography>
        </Grid>
        <Grid
          my={1}
          container
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Typography>{item.nombre_producto}</Typography>

          {item.anexos?.map((a, iAnexo) => {
            return (
              <Grid mt={1}
                xs={12}
                sx={{
                  minWidth: '100%',
                  position: 'relative',
                  //backgroundColor: 'red'
                }}
              >
                <Typography>{a.anexo}</Typography>
                <IconButton
                  onClick={() => removeAnexo(index, iAnexo)}
                  color="error"
                  size="small"
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: -10
                  }}
                >
                  <RemoveCircle />
                </IconButton>
              </Grid>
            );
          })}

          {(!!!item.anexos || item.anexos?.length <= 2 )&& (
            <Grid item mt={1}>
              <TextField
                label={`Anexo ${(item.anexos?.length ?? 0) + 1}`}
                size="small"
                fullWidth
                value={anexo}
                onChange={onChangeAnexo}
              />
              <IconButton
                color={"success"}
                onClick={() => {
                  addAnexo(index, anexo);
                  setAnexo('');
                }}
                sx={{
                  position: "absolute",
                }}
              >
                <AddCircle />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </ModalLayout>
    </Modal>
  );
};
