import { Close, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputBase,
  Modal,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AccionesContext } from "../../../../context/AccionesContext";
import { AuthContext } from "../../../../context/AuthContext";
import { ModalLayout } from "../../../../layouts/ModalLayout";
import {
  productosBusquedaRequest,
  productosRequest,
} from "../../../../services/api/ProductosApi";
import { Productos } from "./Productos";

export const ModalBuscarProducto = ({ modal, close, seleccionarProducto }) => {
  const { user, establecimiento } = useContext(AuthContext);
  const { recargarClientes } = useContext(AccionesContext);

  const [productos, setProductos] = useState([]);
  const [productosSearch, setProductosSearch] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setProductos([]);
    setLoading(true);
    const dataRequest = {
      id_establecimiento: establecimiento.id_establecimiento,
    };
    productosRequest(dataRequest)
      .then((res) => {
        if (res.producto) {
          setProductos(res.producto);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [establecimiento, recargarClientes]);

  useEffect(() => {
    if (search.length > 1) {
      setLoading(true);
      const dataRequest = {
        search_producto: search,
        id_establecimiento: establecimiento.id_establecimiento,
      };

      productosBusquedaRequest(dataRequest)
        .then((res) => {
          if (res.productos) {
            setProductosSearch(res.productos);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [search, establecimiento]);

  const onChangeSearch = (text) => {
    setSearch(text);
  };

  return (
    <Modal
      open={modal}
      onClose={() => {
        close();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout>
        <Box>
          {/* BUSQUEDA */}
          <Box
            component={"div"}
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box>
              <IconButton
                onClick={() => {
                  setSearch("");
                  close();
                }}
              >
                <Close />
              </IconButton>
            </Box>
            <Box
              sx={{
                backgroundColor: "#e3e2ff",
                borderRadius: 3,
                width: "100%",
              }}
            >
              <InputBase
                placeholder={"Buscar producto"}
                value={search}
                sx={{
                  height: "100%",
                  width: "100%",
                  px: 2,
                }}
                onChange={(e) => onChangeSearch(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                }
              />
            </Box>
          </Box>
          {/* PRODUCTOS */}
          {search.length > 1 ? (
            <Productos
              productos={productosSearch}
              seleccionarProducto={seleccionarProducto}
            />
          ) : (
            <Productos
              productos={productos}
              seleccionarProducto={seleccionarProducto}
            />
          )}
        </Box>
      </ModalLayout>
    </Modal>
  );
};
